const attributes = [
  "balkon",
  "terrasse",
  "garten",
  "keller"
];

/**
 *
 * @param unit
 * @param ratioSettings
 */
export function calcAreaRatios(unit, ratioSettings) {
  let offerArea; let
    saleArea;

  if (unit.offer_area) {
    offerArea = parseFloat(unit.offer_area);

    // since default calculation includes loggia we have to subtract loggia first before adding weighted loggia area
    const wheightedLoggia = getWeightedAttribute(unit.offer_loggia_area, ratioSettings.custom_loggia_ratio);

    offerArea = offerArea - unit.offer_loggia_area + wheightedLoggia;

    attributes.forEach((attr) => {
      const weightedAttribute = getWeightedAttribute(unit[`offer_${attr}_area`], ratioSettings[`custom_${attr}_ratio`]);

      offerArea += weightedAttribute;
    });
  }

  if (unit.sale_area) {
    saleArea = parseFloat(unit.sale_area);

    // since default calculation includes loggia we have to subtract loggia first before adding weighted loggia area
    const wheightedLoggia = getWeightedAttribute(unit.sale_loggia_area, ratioSettings.custom_loggia_ratio);

    saleArea = saleArea - unit.sale_loggia_area + wheightedLoggia;

    attributes.forEach((attr) => {
      const weightedAttribute = getWeightedAttribute(unit[`sale_${attr}_area`], ratioSettings[`custom_${attr}_ratio`]);

      saleArea += weightedAttribute;
    });
  }

  return {
    offerArea,
    saleArea
  };
}

function getWeightedAttribute(area, ratio) {
  return area / 100 * ratio;
}
