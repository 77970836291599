import cn from "classnames";
import { kebabCase } from "lodash-es";

import Header from "~/src/ui/headers/header";

/**
 *
 * @param root0
 * @param root0.title
 * @param root0.className
 * @param root0.wrap
 * @param root0.id
 * @param root0.sub
 * @param root0.children
 */
export default function SectionContainer({
  title, className, wrap = false, id = kebabCase(title), sub, children
}) {
  return (
    <div className={cn("w-full space-y-1 space-y-reverse", { "break-inside-avoid": !wrap }, className)} id={id}>
      <Header sub={sub} className="mt-2 mb-2 w-full flex justify-center bg-gray-100 py-1">{title}</Header>
      {children}
    </div>
  );
}
