/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */
import { useMemo, useState } from "react";

import EmptyChartContainer from "../empty-chart-container";

import { SquareMeterPriceByAreaOfferChartForPrint } from "./for-print";
import { generateLineData } from "./tools";

import useMediaQuery from "~/src/hooks/use-media-query";
import DetailStatsTools from "~/src/lib/detail-stats-tools";
import ChartLegend from "~/src/ui/charts/legend";
import LineChart from "~/src/ui/charts/line-chart";
import PriceSelect from "~/src/ui/selects/price-select";
import { priceLabels } from "~/src/modules/labels.js";

/**
 *
 * @param props
 * @param props.statistic
 * @param props.isLoading
 * @param props.isError
 */
// eslint-disable-next-line max-lines-per-function
export default function SquareMeterPriceByAreaOfferChart({ statistic }) {
  const isPrint = useMediaQuery("print");

  const [
    selectedGraphLabel,
    setSelectedGraphLabel
  ] = useState(null);

  const allGraphLabels = Object.keys(statistic);

  const labelsOfNonemptyGraphs = DetailStatsTools
    .getVariablesWithData(statistic);

  if (isPrint) {
    return <SquareMeterPriceByAreaOfferChartForPrint
      graphLabels={labelsOfNonemptyGraphs}
      statistic={statistic} />;
  }

  const activeGraphLabel = selectedGraphLabel ?? labelsOfNonemptyGraphs?.[0];

  const lineData = useMemo(
    () => (activeGraphLabel
      ? generateLineData(activeGraphLabel, statistic)
      : null),
    [
      statistic,
      activeGraphLabel
    ]
  );

  if (!labelsOfNonemptyGraphs?.[0] || !lineData) {
    return <EmptyChartContainer
      chartLabel={`${priceLabels.offerPricePerSquareMeter} nach Fläche`}
      allGraphLabels={allGraphLabels}
    />;
  }

  return (
    <div className="flex flex-col gap-4 p-2 bg-white border border-gray-200 rounded shadow-sm lg:p-4">
      <div className="flex flex-col justify-between gap-2 lg:flex-row">
        <div className="flex items-center gap-2">
          <h2 className="font-medium text-gray-900 text-md">{priceLabels.offerPricePerSquareMeter} nach Fläche</h2>
          {

            /*
          <MetaTooltip
            {...((barData) && {
              meta: barData.meta
            })}
          />
            */
          }

        </div>
        <PriceSelect
          value={activeGraphLabel}
          options={allGraphLabels}
          onChange={setSelectedGraphLabel}
          enabledOptions={labelsOfNonemptyGraphs}
          className="lg:self-end w-80"
          buttonClassName="w-80"
        />
      </div>

      <div className="w-full h-[450px] relative">
        <div className="absolute w-full h-[450px]">
          <LineChart
            data={lineData.chartData}
            withCategories
            x={{
              type: "squareMeters",
              min: 0,
              max: 200
            }}
            y={{
              min: lineData.chartData[0].min,
              max: lineData.chartData[0].max
            }}
          />
        </div>
      </div>
      <div className="w-full">
        <ChartLegend data={lineData.legendData} simple />
      </div>
    </div>
  );
}
