import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";
import { fundingFilterLabels } from "~/src/modules/labels";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 */
export default function FundingSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const tempItems = tempQuery?.split(",") || [];

  const hasFreifinanziert = items.includes("freifinanziert");
  const hasGefoerdert = items.includes("gefoerdert");
  const hasGemeinde = items.includes("gemeinde");

  const hasTempFreifinanziert = tempItems.includes("freifinanziert");
  const hasTempGefoerdert = tempItems.includes("gefoerdert");
  const hasTempGemeinde = tempItems.includes("gemeinde");

  function onCheck(event, key) {
    handleCheck("foerderung", event, tempQuery, items, tempItems, key, update);
  }

  function checked(item, tempItem) {
    return handleCheckedStatus(query, item, tempQuery, tempItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Förderungen
      </p>
      <div className="grid grid-cols-1 mt-6 font-light sm:grid-cols-2 gap-x-2 gap-y-4">
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasFreifinanziert, hasTempFreifinanziert)} onChange={(e) => onCheck(e, "freifinanziert")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{fundingFilterLabels.freifinanziert}</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasGefoerdert, hasTempGefoerdert)} onChange={(e) => onCheck(e, "gefoerdert")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{fundingFilterLabels.gefoerdert}</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasGemeinde, hasTempGemeinde)} onChange={(e) => onCheck(e, "gemeinde")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{fundingFilterLabels.gemeinde}</span>
          </label>
        </div>
      </div>
    </div>
  );
}
