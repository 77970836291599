/**
 *
 * @param options - The root object.
 * @param options.currentMarketingPhase - The root object.
 * @example
 */
const getMarkerOuterColor = ({ currentMarketingPhase }) => {
  switch (currentMarketingPhase) {
    case "marketing_phase_coming_soon":
      return "#E28413";
    case "marketing_phase_active":
      return "#98344D";
    case "marketing_phase_completed_apartments":
    case "marketing_phase_completed_all":
    case "marketing_phase_project_finished":
      return "#008C7C";
    default:
      return "#9CA3AF";
  }
};

export default getMarkerOuterColor;
