/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { Dialog, Transition } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";

import FeedbackModal from "~/src/features/feedback-modal/index.jsx";
import SlidePropertyCard from "~/src/features/slide-property-card/index.jsx";
import { formatDate } from "~/src/modules/formatters.js";
import CloseIcon from "~/src/ui/icons/close-icon/index.jsx";

/**
 *
 * @param {object} props
 * @param {boolean} props.active
 * @param {Function} props.hide
 * @param {object} props.insolvency
 */
export default function InsolvencySlide({
  active, hide, insolvency
}) {
  const [
    showFeedback,
    setShowFeedback
  ] = useState(false);

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 z-[150] overflow-hidden" open={active} onClose={hide}>

        <FeedbackModal show={showFeedback} close={() => setShowFeedback(false)} entity_id={insolvency?.id} feedback_type="insolvency" feedback_type_label={insolvency.record_number} />

        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-5xl">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                    <button
                      className="text-white rounded-md hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-white"
                      onClick={hide}
                    >
                      <span className="sr-only">Schließen</span>
                      <CloseIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-col h-full pb-6 overflow-y-scroll bg-white shadow-xl">

                  <div className="flex items-center justify-between h-16 px-4 sm:px-12 shrink-0 bg-primary">
                    <h2 id="slide-over-heading" className="text-lg font-medium text-white">
                      Details zur Insolvenz
                    </h2>
                  </div>

                  <div className="flex-1 mt-4 mb-8">

                    <dl className="px-4 sm:px-8">

                      <div className="px-4">
                        <div className="flex items-center justify-between">
                          <h3 className="text-2xl font-semibold text-gray-700">{insolvency?.company_name}<br />{insolvency?.company_fbnr}</h3>
                          <button type="button" onClick={() => setShowFeedback(true)} className="px-3 py-1 text-sm text-white border rounded bg-primary hover:bg-primary-lighter border-primary focus:outline-none">Feedback</button>
                        </div>
                        <div className="mt-1 text-gray-900">
                          <p className="leading-5">{insolvency?.company_city}, {insolvency?.company_street}</p>
                        </div>
                      </div>

                      <div>

                        <div className="p-4 mt-4">

                          <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Veröffentlichung</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{formatDate({ date: insolvency?.published_date })}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Letzte Änderung</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{formatDate({ date: insolvency?.last_change_date })}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Verfahrensart</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{insolvency?.category?.name}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Aktenzeichen</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
                              <a
                                className="inline-flex items-center hover:underline"
                                href={insolvency?.url}
                                target="_blank" rel="noreferrer">
                                <span>{insolvency?.record_number}</span>
                                <ArrowTopRightOnSquareIcon className="w-5 h-5 ml-1 -mr-1 text-primary" />
                              </a>
                            </dd>
                          </div>

                        </div>

                        {insolvency?.properties?.length > 0
                          ? (
                            <div className="mt-10">

                              <div className="flex items-end justify-between px-4 mt-3">
                                <p className="text-xl ">Alle Liegenschaften des Schuldners ({insolvency?.properties?.length})</p>
                                {/* <p className="text-sm leading-none">Stichtagsdaten vom 01.04.2021</p>*/}
                              </div>

                              <div className="mt-2.5 space-y-5 px-4 pb-4">
                                {insolvency?.properties?.map((property, index) => (
                                  <SlidePropertyCard key={`property_${index}`} property={property} />
                                ))}
                              </div>
                            </div>
                          )
                          : null
                        }

                      </div>

                    </dl>

                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
