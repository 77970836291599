/**
 *
 */
export default function UnitsSkeleton() {
  return (
    <div className="space-y-12">

      {[...Array(12)].map((e, i) => (
        <div key={`block_0_${i}`} className="animate-pulse bg-white border border-gray-200 rounded-sm px-4 py-4 sm:px-4">
          <div className="grid grid-cols-1 md:grid-cols-8 space-x-4">
            <div className="h-4 bg-gray-300 col-span-1 rounded" />
          </div>
          <div className="mt-3 hidden md:grid grid-cols-1 md:grid-cols-8 space-x-4">
            <div className="h-4 bg-gray-300 col-span-3 rounded" />
            <div className="h-4 col-span-3 rounded" />
            <div className="h-4 bg-gray-300 col-span-2 rounded" />
          </div>
          <div className="mt-5 hidden md:grid grid-cols-1 md:grid-cols-12 space-x-4">
            <div className="h-16 bg-gray-300 col-span-1 md:col-span-2 rounded" />
            <div className="h-26 bg-gray-300 col-span-1 md:col-span-2 rounded" />
            <div className="h-16 bg-gray-300 col-span-1 md:col-span-2 rounded" />
            <div className="hidden md:grid col-span-6 grid-cols-6 space-x-4">
              <div className="ml-4 h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
              <div className="h-4 bg-gray-300 col-span-1 rounded" />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-8 space-x-4">
            <div className="h-4 bg-gray-300 col-span-2 rounded" />
          </div>
          <div className="mt-3 grid grid-cols-8 space-x-4">
            <div className="h-4 bg-gray-300 col-span-2 rounded" />
          </div>
        </div>
      ))}

    </div>
  );
}
