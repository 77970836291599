import { useParams } from "react-router-dom";

import useProjectExport from "~/src/hooks/use-project-export";
import dateFormatter from "~/src/modules/date-formatter";
import { formatNumber } from "~/src/modules/formatters";
import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

/**
 *
 */
export default function ProjectWidmungen() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    widmungen
  } = project;

  const columnTitles = [
    "Plandokument",
    "Kundmachung",
    "Widmung"
  ];

  const rows = widmungen.map(({
    plan_number,
    publish_date,
    widmung,
    bauklasse,
    height_limit,
    height_limit_sign,
    height_limit_to,
    bauweise,
    additional
  }) => [
    plan_number,
    publish_date && dateFormatter(publish_date),
    [
      widmung,
      bauklasse,
      bauweise,
      `${height_limit_sign === true ? "+" : ""}${formatNumber(height_limit)}${height_limit_to ? `-${formatNumber(height_limit_to)}` : ""}${height_limit ? "m" : ""}`,
      additional
    ]
      .filter(Boolean)
      .join(" ")
  ]);

  const columnWidths = [
    ...Array(2).fill("2/12"),
    "8/12"
  ];

  const emptyText = "Flächenwidmungen nicht bekannt";

  return (
    <SectionContainer title="Flächenwidmungen">
      <Table {...{
        columnTitles,
        rows,
        emptyText,
        columnWidths
      }} />
    </SectionContainer>
  );
}
