import ClusterMarker from "../cluster-marker";

import ProjectMarker from "~/src/features/project-marker";

/**
 *
 * @param props
 * @param props.points
 * @param props.simple
 * @param props.children
 * @param props.mapRef
 * @param props.groupRef
 * @param props.clusterExpansionZooms
 * @param props.leaves
 * @param props.clustersSpiderfied
 * @param props.setClustersSpiderfied
 * @param props.isSimple
 * @param props.map
 */
const ProjectMarkerClusterGroup = ({
  points,
  simple,
  children,
  mapRef,
  map,
  groupRef,
  clusterExpansionZooms,
  leaves,
  clustersSpiderfied,
  setClustersSpiderfied,
  isSimple: isSimpleMap
}) => (
  <>
    {points?.map((point) => {
      const {
        geometry, id, properties: { cluster, slug }
      } = point;

      if (cluster) {
        return (
          <ClusterMarker
            key={id}
            {...{
              point,
              simple,
              mapRef,
              groupRef,
              clusterExpansionZooms,
              leaves,
              clustersSpiderfied,
              setClustersSpiderfied
            }}
          />
        );
      }

      return (
        <ProjectMarker
          key={slug}
          {...{
            point,
            simple,
            map,
            isSimpleMap
          }}
        />
      );
    })}
    {children}
  </>
);

export default ProjectMarkerClusterGroup;
