import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import {
  Fragment, useRef, useState
} from "react";

import { handleDelete } from "./handlers";

import { showError, showSuccess } from "~/src/modules/toast-helper";

/**
 *
 * @param root0
 * @param root0.watchlist
 * @param root0.project
 * @param root0.show
 * @param root0.hideModal
 * @param root0.mutateWatchlist
 * @param root0.mutateWatchlists
 */
export default function DeleteWatchlistItemModal({
  watchlist, project, show, hideModal, mutateWatchlist, mutateWatchlists
}) {
  const cancelButtonRef = useRef(null);
  const [
    isLoading,
    setIsLoading
  ] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    if (!isLoading) {
      setIsLoading(true);
      handleDelete(watchlist, project, handleSuccess, handleError);
    }
  }

  function handleHide() {
    setIsLoading(false);
    hideModal();
  }

  function handleSuccess() {
    handleHide();
    mutateWatchlist();
    mutateWatchlists();
    showSuccess();
  }

  function handleError() {
    setIsLoading(false);
    showError();
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-20 overflow-y-auto" initialFocus={cancelButtonRef} open={show} onClose={handleHide}>
        <div className="flex items-end justify-center min-h-full px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full shrink-0 bg-primary-yellow sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon className="w-6 h-6 text-primary-yellow-dark" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="mt-2 text-lg font-medium leading-6 text-gray-900">
                    Projekt entfernen?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-gray-500">
                      Sind Sie sicher, dass dieses Projekt aus der Merkliste entfernt werden soll?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={cn("w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm")}
                  onClick={handleSubmit}
                >
                  {isLoading &&
                    <svg className="w-5 h-5 text-white animate-spin" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                  }
                  {!isLoading &&
                    <span>Entfernen</span>
                  }
                </button>
                <button
                  ref={cancelButtonRef}
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={handleHide}
                >
                  Abbrechen
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
