import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import { Fragment } from "react";
import { useController } from "react-hook-form";

const Option = ({
  label,
  value,
  selectedValue
}) => (
  <Listbox.Option
    className={({ active }) => {
      const selected = value === selectedValue;

      return cn(
        "relative cursor-pointer select-none py-2 pl-10 pr-4",
        {
          "bg-gray-300": selected,
          "bg-gray-200": active && !selected,
          white: !active && !selected
        }
      );
    }}
    value={value}
  >
    {() => {
      const selected = value === selectedValue;

      return (
        <>
          <span
            className={`block truncate ${selected ? "font-medium" : "font-normal"
            }`}
          >
            {label}
          </span>
          {selected
            ? (
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            )
            : null}
        </>
      );
    }}
  </Listbox.Option>
);

/**
 *
 * @param props
 * @param props.name
 * @param props.label
 * @param props.options
 * @param props.defaultValue
 * @param props.control
 * @param props.className
 * @param props.onChange
 * @param props.color
 */
export default function Select({
  name,
  label,
  options = [
    {
      label: "",
      value: ""
    }
  ],
  defaultValue,
  control,
  className,
  color = "red",
  onChange = () => { }
}) {
  const {
    field: {
      onChange: innerOnChange, onBlur, value, ref
    },
    fieldState: {
      invalid, isTouched, isDirty
    },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue
  });

  const flatOptions = options.map((option) => (Array.isArray(option.value) ? option.value : option)).flat();

  const disabled = flatOptions.length === 0;

  return (
    <Listbox
      {...{
        value: value?.value ?? "",
        onChange: (e) => {
          innerOnChange(e);
          onChange(e);
        },
        onBlur,
        disabled
      }}
    >
      <div className={cn("relative", className)}>
        {label && <Listbox.Label>{label}</Listbox.Label>}
        <Listbox.Button
          className={({ disabled }) => cn(
            "relative min-h-[41px] w-full not-disabled:cursor-pointer rounded bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-gray-200  outline outline-2 outline-offset-[-2px] outline-transparent cursor-pointer not-disabled:hover:border-gray-400 disabled:cursor-not-allowed disabled:opacity-50 disabled:shadow-none",
            {
              "ui-open:outline-primary": !disabled && color === "red",
              "ui-open:outline-primary-green-dark": !disabled && color === "green"
            }
          )
          }
        >
          <span className="block truncate">{flatOptions.find((option) => value === option.value)?.label ?? <span className="text-gray-400">Keine Preisdaten vorhanden</span>}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-72 w-full overflow-auto rounded bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 z-50 focus:outline-none">
            {options
              .map((option, index) => {
                if (Array.isArray(option.value)) {
                  return (
                    <li className="flex flex-col" key={index}>
                      <span className="py-2 px-4 bg-gray-100 w-full text-sm font-medium">{option.label}</span>
                      <ul>
                        {
                          option.value
                            .filter(({ shown }) => shown)
                            .map((groupOption, groupOptionIndex) => (
                              <Option label={groupOption.label} value={groupOption.value} selectedValue={value} key={groupOptionIndex} />
                            ))
                        }
                      </ul>
                    </li>
                  );
                }

                if (option.shown) {
                  return (
                    <Option label={option.label} value={option.value} selectedValue={value} key={index} />
                  );
                }

                return null;
              })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
