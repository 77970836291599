import { liegenschaftLabels } from "~/src/modules/labels";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function TransactionFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const liegenschaft = queryItems.split(",");

  function handleClick(li) {
    const newLi = liegenschaft.filter((item) => item !== li).join(",");

    submit("liegenschaft", newLi);
  }

  return liegenschaft.map((li, index) => (
    <DefaultFilterTag key={`li_tag_${index}`} handleClick={() => handleClick(li)}>
      <span>Liegenschaftsankauf: {liegenschaftLabels[li]}</span>
    </DefaultFilterTag>
  ));
}
