/* eslint-disable lines-around-comment */
/* eslint-disable padded-blocks */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { camelCase } from "lodash-es";

const transfromArrayNumber = (value) => {
  if (value.includes("_")) {
    const transformedValue = value.includes("_") ? value.split("_") : value;

    return Array.isArray(transformedValue)
      ? transformedValue.map(Number)
      : Number(transformedValue);
  }

  const transformedValue = value.includes(",") ? value.split(",") : value;

  return Array.isArray(transformedValue)
    ? transformedValue.map(Number)
    : Number(transformedValue);
};

const transfromArrayString = (value) => {
  if (value.includes("_")) {
    const transformedValue = value.includes("_") ? value.split("_") : value;

    return Array.isArray(transformedValue)
      ? transformedValue.map(String)
      : String(transformedValue);
  }

  const transformedValue = value.includes(",") ? value.split(",") : value;

  return Array.isArray(transformedValue)
    ? transformedValue.map(String)
    : String(transformedValue);
};

/**
 *
 * @param root0
 * @param root0."0"
 * @param root0."1"
 */
export const parseUrlParams = ([
  searchKey,
  searchValue
]) => {
  const transformationKeyMap = {
    sortPath: () => "sort",
    default: (value) => value
  };

  const transformationValueMap = {
    automated: (value) => Boolean(value),
    certificates: transfromArrayString,
    default: (value) => value,
    finish_year: (value) => (Array.isArray(value.split(","))
      ? value.split(",").map(Number)
      : Number(value)),
    marked: (value) => value === "true" || value === "1",
    page: (value) => Number(value),
    quantity: (value) => (Array.isArray(value.split(","))
      ? value.split(",").map(Number)
      : Number(value)),
    region: transfromArrayNumber,
    sort: (value) => {
      /* two really strange edge cases regarding sorting:
        in swr cache zipcode value is location.state.zip[0]
        and if you go for a street search sort value is locations[0]
      */
      let transformedValue = value.includes("zipcode") ? "zipcode" : value;

      transformedValue = (transformedValue === "locations[0]") ? "radius" : transformedValue;

      return camelCase(transformedValue);
    },
    stats: (value) => (Array.isArray(value.split(","))
      ? value.split(",")
      : value)
  };

  const transformationKey = transformationKeyMap[searchKey] || transformationKeyMap.default;
  const returnKey = transformationKey(searchKey);

  const transformationValue = transformationValueMap[returnKey] || transformationValueMap.default;
  const returnValue = transformationValue(searchValue);

  return [
    returnKey,
    returnValue
  ];
};
