import React from "react";
import ReactDOM from "react-dom";

/**
 *
 * @param root0
 * @param root0.children
 */
const NotificationContainer = ({ children }) => {
  // Find our portal container in the DOM
  const portalRoot = document.getElementById("notification-root");

  /*
     Create a div as a wrapper for our toast
     using the useMemo hook so that a new value isn't
     computed on every render
  */
  const container = React.useMemo(() => document.createElement("div"), []);

  React.useEffect(() => {
  /*
     Append our toast container to the portal root
  */
    portalRoot.appendChild(container);

    /*
     Clean up the DOM by removing our toast container
     when the component is unmounted
  */
    return () => {
      container.remove();
    };
  });

  /*
     Render any child elements to the portal root
  */
  return ReactDOM.createPortal(children, portalRoot);
};

export default NotificationContainer;
