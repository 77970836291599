/* eslint-disable max-params */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-magic-numbers */
import dayjs from "dayjs";

import {
  formatYear, formatMonthDotSeparated, formatEuro
} from "~/src/modules/formatters.js";
import { priceLabels } from "~/src/modules/labels.js";

export const unitHeaders = [
  {
    title: "Name",
    width: 24
  },
  {
    title: "Objektkategorie",
    width: 24
  },
  {
    title: "Projekt",
    width: 20
  },
  {
    title: "Bauteil",
    width: 20
  },
  {
    title: "Stiege",
    width: 20
  },
  {
    title: "Geschoß",
    width: 20
  },
  {
    title: "Zimmer (A)",
    type: "offer",
    width: 20
  },
  {
    title: "Zimmer (V)",
    type: "sale",
    width: 20
  },
  {
    title: "verwertet (A)",
    type: "offer",
    width: 20
  },
  {
    title: "Datum verwertet (A)",
    type: "offer",
    width: 20
  },
  {
    title: "KV Datum (V)",
    type: "sale",
    width: 20
  },
  {
    title: "TZ (V)",
    type: "sale",
    width: 20
  },
  {
    title: "Nutzfläche (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Nutzfläche Gewichtet (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Nutzfläche (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Nutzfläche Gewichtet (V)",
    type: "sale",
    width: 24
  },
  {
    title: priceLabels.averageRentNetPerSquareMeter,
    type: "offer",
    width: 30
  },
  {
    title: priceLabels.averageRentTotalPerSquareMeter,
    type: "offer",
    width: 30
  },
  {
    title: priceLabels.rentNet,
    type: "offer",
    width: 30
  },
  {
    title: priceLabels.rentTotal,
    type: "offer",
    width: 30
  },
  {
    title: priceLabels.averageOfferPriceNormalPerSquareMeter,
    type: "offer",
    width: 28
  },
  {
    title: priceLabels.averageOfferPriceInvestorNetPerSquareMeter,
    type: "offer",
    width: 28
  },
  {
    title: priceLabels.averageSalePriceNetPerSquareMeter,
    type: "sale",
    width: 28
  },
  {
    title: priceLabels.averageSalePriceGrossPerSquareMeter,
    type: "sale",
    width: 28
  },
  {
    title: priceLabels.offerPriceNormal,
    type: "offer",
    width: 28
  },
  {
    title: priceLabels.offerPriceInvestorNet,
    type: "offer",
    width: 28
  },
  {
    title: priceLabels.salePriceNet,
    type: "sale",
    width: 28
  },
  {
    title: priceLabels.salePriceGross,
    type: "sale",
    width: 28
  },
  {
    title: "Loggia Anzahl (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Loggia m² (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Loggia Anzahl (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Loggia m² (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Balkon Anzahl (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Balkon m² (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Balkon Anzahl (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Balkon m² (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Terrasse Anzahl (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Terrasse m² (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Terrasse Anzahl (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Terrasse m² (V)",
    type: "sale",
    width: 24
  },
  //  { title: "Dachterrasse Anzahl (A)", type: "offer", width: 24 },
  //  { title: "Dachterrasse m² (A)", type: "offer", width: 24 },
  //  { title: "Dachterrasse Anzahl (V)", type: "sale", width: 24 },
  //  { title: "Dachterrasse m² (V)", type: "sale", width: 24 },
  {
    title: "Garten Anzahl (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Garten m² (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Garten Anzahl (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Garten m² (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Keller Anzahl (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Keller m² (A)",
    type: "offer",
    width: 24
  },
  {
    title: "Keller Anzahl (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Keller m² (V)",
    type: "sale",
    width: 24
  },
  {
    title: "Bemerkungen (A)",
    type: "offer",
    width: 30
  },
  {
    title: "Bemerkungen (V)",
    type: "sale",
    width: 30
  },
  {
    title: "Historisch: Miete Netto (A)",
    type: "offer",
    width: 35
  },
  {
    title: "Historisch: Miete Gesamtbelastung (A)",
    type: "offer",
    width: 35
  },
  {
    title: "Historisch: Kaufpreis Eigennutzung (A)",
    type: "offer",
    width: 35
  },
  {
    title: "Historisch: Kaufpreis  Investment (A)",
    type: "offer",
    width: 35
  }
  // { title: "Kaufende Partei (V)", type: "sale", width: 30 },
];

/**
 *
 * @param sheet
 */
export const buildUnitColumns = (sheet) => {
  unitHeaders.forEach((header, index) => {
    sheet.getColumn(index + 1).width = header.width;
  });
};

/**
 *
 * @param root0
 * @param root0.row
 * @param root0.cellIndex
 * @param root0.value
 * @param root0.font
 * @param root0.bgColor
 * @param root0.borders
 * @param root0.alignment
 */
export const buildCell = ({
  row, cellIndex, value, font, bgColor, borders, alignment
}) => {
  const cell = row.getCell(cellIndex);

  cell.value = value;
  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: bgColor }
  };
  cell.font = {
    size: font?.size || 12,
    color: { argb: font?.color },
    bold: font?.bold
  };
  cell.border = {
    top: borders?.top && { style: "thin" },
    right: borders?.right && { style: "thin" },
    bottom: borders?.bottom && { style: "thin" },
    left: borders?.left && { style: "thin" }
  };
  cell.alignment = alignment && { horizontal: alignment };
};

/**
 *
 * @param index
 * @param row
 * @param unit
 * @param projects
 */
export const setUnitData = (index, row, unit, projects) => {
  if (unit.offer_data_available && unit.sale_data_available) {
    unit.verwertet = true;

    if (unit.sale_date && !unit.verwertet_date) {
      unit.verwertet_date = dayjs(unit.sale_date).subtract(3, "month").date(1)
        .hour(23)
        .toISOString();
      unit.verwertet_date_format = "month";
    }
  }

  if (dayjs(unit.verwertet_date).isAfter(unit.sale_date)) {
    unit.verwertet_date = null;
  }

  const getProjectNameById = (id) => {
    for (const project of projects) {
      if (project.id === id) {
        return project.name;
      }
    }

    return "no project found";
  };

  const transformToExcelDatapoint = function (historicPrice) {
    if (historicPrice.date !== "") {
      return `bis ${formatMonthDotSeparated(historicPrice.date)}: ${formatEuro(parseFloat(historicPrice.price))}`;
    }

    return `${formatEuro(parseFloat(historicPrice.price))}`;
  };

  const cleanHistoricPrices = function (historicPriceArray) {
    const countHistoricPricesWithoutDate = historicPriceArray
      .filter((historicPrice) => historicPrice.date === "");

    return (countHistoricPricesWithoutDate.length > 1)
      ? historicPriceArray
        .filter((historicPrice) => historicPrice.date !== "")
        .map((historicPrice) => transformToExcelDatapoint(historicPrice))
      : historicPriceArray.map((historicPrice) => transformToExcelDatapoint(historicPrice));
  };

  const offerPriceNormalHistory = cleanHistoricPrices(unit.offer_price_normal_history);
  const offerPriceInvestorHistory = cleanHistoricPrices(unit.offer_price_investor_history);
  const rentNettoHistory = cleanHistoricPrices(unit.rent_netto_history);
  const rentBkHistory = cleanHistoricPrices(unit.rent_bk_history);

  const rowHeightFactor = Math.max(
    rentNettoHistory.length,
    rentBkHistory.length,
    offerPriceNormalHistory.length,
    offerPriceInvestorHistory.length
  );

  const standardRowHeight = 15;

  row.getCell(1).value = unit?.name;
  row.getCell(2).value = unit?.unit_category?.name;
  row.getCell(3).value = getProjectNameById(unit.project_id);
  row.getCell(4).value = unit?.building_part?.name;
  row.getCell(4).alignment = { horizontal: "left" };
  row.getCell(5).value = unit?.staircase;
  row.getCell(5).alignment = { horizontal: "left" };
  row.getCell(6).value = unit?.floor;
  row.getCell(6).alignment = { horizontal: "left" };
  row.getCell(7).value = unit?.offer_room_count;
  row.getCell(7).alignment = { horizontal: "right" };
  row.getCell(8).value = unit?.sale_room_count;
  row.getCell(8).alignment = { horizontal: "right" };
  row.getCell(9).value = unit?.verwertet ? "ja" : "nein";
  row.getCell(9).alignment = { horizontal: "right" };
  row.getCell(10).value = unit.verwertet_date && new Date(unit.verwertet_date);
  row.getCell(10).numFmt = "DD/MM/YYYY";
  row.getCell(11).value = unit.sale_date && new Date(unit.sale_date);
  row.getCell(11).numFmt = "DD/MM/YYYY";
  row.getCell(12).value = (unit?.tz_number && unit?.tz_year) && `${unit.tz_number}/${formatYear(unit.tz_year)}`;
  row.getCell(12).alignment = { horizontal: "right" };

  // Nutzfläche (A)
  row.getCell(13).value = unit?.offer_area && parseFloat(unit?.offer_area);
  row.getCell(13).numFmt = "#,##0.00\" m²\"";
  // Nutzfläche gewichtet (A)
  row.getCell(14).value = { formula: `IF(ISBLANK(M${index}),"",(M${index}-AD${index})+(AD${index}/100*$C$2)+(AH${index}/100*$D$2)+(AL${index}/100*$E$2)+(AP${index}/100*$G$2)+(AT${index}/100*$H$2))` };
  row.getCell(14).numFmt = "#,##0.00\" m²\"";

  // Nutzfläche (V)
  row.getCell(15).value = unit?.sale_area && parseFloat(unit?.sale_area);
  row.getCell(15).numFmt = "#,##0.00\" m²\"";
  // Nutzfläche gewichtet (V)
  row.getCell(16).value = { formula: `IF(ISBLANK(O${index}),"",(O${index}-AF${index})+(AF${index}/100*$C$2)+(AJ${index}/100*$D$2)+(AN${index}/100*$E$2)+(AR${index}/100*$G$2)+(AV${index}/100*$H$2))` };
  row.getCell(16).numFmt = "#,##0.00\" m²\"";

  // ⌀ Miete/m² Netto
  row.getCell(17).value = { formula: `IFERROR(IF(OR(ISBLANK(T${index}),ISBLANK(N${index})),"",T${index}/N${index}),"")` };
  row.getCell(17).numFmt = "\"€ \"#,##0.00";

  // ⌀ Miete/m² Brutto
  // row.getCell(18).value = { formula: `IFERROR(IF(OR(ISBLANK(U${index}),ISBLANK(N${index})),"",U${index}/N${index}),"")` };
  // row.getCell(18).numFmt = "\"€ \"#,##0.00";

  // "⌀ Miete/m² Gesamtbelastung"
  row.getCell(18).value = { formula: `IFERROR(IF(OR(ISBLANK(V${index}),ISBLANK(N${index})),"",V${index}/N${index}),"")` };
  row.getCell(18).numFmt = "\"€ \"#,##0.00";

  row.getCell(19).value = unit?.rent_netto && parseFloat(unit.rent_netto);
  row.getCell(19).numFmt = "\"€ \"#,##0.00";
  // row.getCell(21).value = unit?.rent_brutto && parseFloat(unit.rent_brutto);
  // row.getCell(21).numFmt = "\"€ \"#,##0.00";
  row.getCell(20).value = unit?.rent_bk && parseFloat(unit.rent_bk);
  row.getCell(20).numFmt = "\"€ \"#,##0.00";

  // ⌀ Kaufpreis/m² Eigennutzung (A)
  row.getCell(21).value = { formula: `IFERROR(IF(OR(ISBLANK(AA${index}),ISBLANK($N${index})),"",AA${index}/$N${index}),"")` };
  row.getCell(21).numFmt = "\"€ \"#,##0.00";

  // ⌀ Kaufpreis/m² Investment (A)
  row.getCell(22).value = { formula: `IFERROR(IF(OR(ISBLANK(AB${index}),ISBLANK($N${index})),"",AB${index}/$N${index}),"")` };
  row.getCell(22).numFmt = "\"€ \"#,##0.00";

  // ⌀ Kaufpreis/m² exkl. USt (V)
  row.getCell(23).value = { formula: `IFERROR(IF(OR(ISBLANK(AA${index}),ISBLANK($P${index})),"",AA${index}/$P${index}),"")` };
  row.getCell(23).numFmt = "\"€ \"#,##0.00";

  // ⌀ Kaufpreis/m² inkl. USt (V)
  row.getCell(24).value = { formula: `IFERROR(IF(OR(ISBLANK(Ab${index}),ISBLANK($P${index})),"",AB${index}/$P${index}),"")` };
  row.getCell(24).numFmt = "\"€ \"#,##0.00";

  row.getCell(25).value = unit?.offer_price_normal && parseFloat(unit.offer_price_normal);
  row.getCell(25).numFmt = "\"€ \"#,##0.00";
  row.getCell(26).value = unit?.offer_price_investor && parseFloat(unit.offer_price_investor);
  row.getCell(26).numFmt = "\"€ \"#,##0.00";
  row.getCell(27).value = unit?.sale_price_net && parseFloat(unit.sale_price_net);
  row.getCell(27).numFmt = "\"€ \"#,##0.00";
  row.getCell(28).value = unit?.sale_price_gross && parseFloat(unit.sale_price_gross);
  row.getCell(28).numFmt = "\"€ \"#,##0.00";

  row.getCell(29).value = unit?.offer_loggia_count && parseFloat(unit.offer_loggia_count);
  row.getCell(30).value = unit?.offer_loggia_area && parseFloat(unit.offer_loggia_area);
  row.getCell(30).numFmt = "#,##0.00\" m²\"";
  row.getCell(31).value = unit?.sale_loggia_count && parseFloat(unit.sale_loggia_count);
  row.getCell(32).value = unit?.sale_loggia_area && parseFloat(unit.sale_loggia_area);
  row.getCell(32).numFmt = "#,##0.00\" m²\"";

  row.getCell(33).value = unit?.offer_balkon_count && parseFloat(unit.offer_balkon_count);
  row.getCell(34).value = unit?.offer_balkon_area && parseFloat(unit.offer_balkon_area);
  row.getCell(34).numFmt = "#,##0.00\" m²\"";
  row.getCell(35).value = unit?.sale_balkon_count && parseFloat(unit.sale_balkon_count);
  row.getCell(36).value = unit?.sale_balkon_area && parseFloat(unit.sale_balkon_area);
  row.getCell(36).numFmt = "#,##0.00\" m²\"";

  row.getCell(37).value = unit?.offer_terrasse_count && parseFloat(unit.offer_terrasse_count);
  row.getCell(38).value = unit?.offer_terrasse_area && parseFloat(unit.offer_terrasse_area);
  row.getCell(38).numFmt = "#,##0.00\" m²\"";
  row.getCell(39).value = unit?.sale_terrasse_count && parseFloat(unit.sale_terrasse_count);
  row.getCell(40).value = unit?.sale_terrasse_area && parseFloat(unit.sale_terrasse_area);
  row.getCell(40).numFmt = "#,##0.00\" m²\"";

  //  row.getCell(43).value = null // dachterrasse
  //  row.getCell(44).value = null
  //  row.getCell(45).value = null
  //  row.getCell(46).value = null

  row.getCell(41).value = unit?.offer_garten_count && parseFloat(unit.offer_garten_count);
  row.getCell(42).value = unit?.offer_garten_area && parseFloat(unit.offer_garten_area);
  row.getCell(42).numFmt = "#,##0.00\" m²\"";
  row.getCell(43).value = unit?.sale_garten_count && parseFloat(unit.sale_garten_count);
  row.getCell(44).value = unit?.sale_garten_area && parseFloat(unit.sale_garten_area);
  row.getCell(44).numFmt = "#,##0.00\" m²\"";

  row.getCell(45).value = unit?.offer_keller_count && parseFloat(unit.offer_keller_count);
  row.getCell(46).value = unit?.offer_keller_area && parseFloat(unit.offer_keller_area);
  row.getCell(46).numFmt = "#,##0.00\" m²\"";
  row.getCell(47).value = unit?.sale_keller_count && parseFloat(unit.sale_keller_count);
  row.getCell(48).value = unit?.sale_keller_area && parseFloat(unit.sale_keller_area);
  row.getCell(48).numFmt = "#,##0.00\" m²\"";

  row.getCell(49).value = unit?.offer_notice;
  row.getCell(50).value = unit?.sale_notice;

  const historyData = [
    {
      cell: row.getCell(51),
      data: rentNettoHistory && rentNettoHistory
    },
    {
      cell: row.getCell(52),
      data: rentBkHistory && rentBkHistory
    },
    {
      cell: row.getCell(53),
      data: offerPriceNormalHistory && offerPriceNormalHistory
    },
    {
      cell: row.getCell(54),
      data: offerPriceInvestorHistory && offerPriceInvestorHistory
    }
  ];

  historyData.forEach(({ cell, data }) => {
    if (data && data.length > 0) {
      cell.value = data.join("\n");
      cell.alignment = { wrapText: true };
    }
  });

  row.height = (rowHeightFactor === 0) ? standardRowHeight : rowHeightFactor * standardRowHeight;
  row.commit();
};
