import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { priceLabels, unitSortLabels } from "~/src/modules/labels";

/**
 *
 * @param root0
 * @param root0.sortBy
 * @param root0.submit
 */
export default function SortUnitsDropdown({ sortBy = "name", submit }) {
  function handleClick(value) {
    submit(value);
  }

  return (
    <Menu as="div" className="relative -mt-0.5 ml-8">
      <Menu.Button className="w-full h-10 inline-flex border-b border-secondary-light justify-center items-center text-base font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-0">
        Sortierung: {unitSortLabels[sortBy]}
        <ChevronDownIcon className="ml-0.5 -mr-1 h-5 w-5 text-gray-500" aria-hidden="true" />
      </Menu.Button>
      <Menu.Items className="origin-top-right z-10 absolute right-0 -mt-0.5 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1 w-full">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleClick({ sortBy: "name" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                <span>{unitSortLabels.name}</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleClick({ sortBy: "flaecheAngebot" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {unitSortLabels.flaecheAngebot}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleClick({ sortBy: "priceOfferNormal" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.offerPriceNormalPerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleClick({ sortBy: "priceOfferInvestor" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.offerPriceInvestorPerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleClick({ sortBy: "rentNetto" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.rentNetPerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleClick({ sortBy: "rentBk" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.rentTotalPerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleClick({ sortBy: "flaecheVerkauf" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {unitSortLabels.flaecheVerkauf}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleClick({ sortBy: "priceSale" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.salePricePerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
