/**
 *
 */
export default function DetailSkeleton() {
  return (
    <div className="mt-8 py-2 bg-white border border-gray-200 rounded space-y-8">

      {[...Array(3)].map((e, i) => (
        <div key={`block_0_${i}`} className="animate-pulse px-3 py-1.5 sm:px-6 sm:py-4 space-y-8">
          <div className="grid grid-cols-3 space-x-4">
            <div className="h-12 bg-gray-300 col-span-1 rounded" />
            <div className="h-12 bg-gray-300 col-span-1 rounded" />
            <div className="h-12 bg-gray-300 col-span-1 rounded" />
          </div>
          <div className="grid grid-cols-3 space-x-4">
            <div className="h-12 bg-gray-300 col-span-1 rounded" />
            <div className="h-12 bg-gray-300 col-span-1 rounded" />
            <div className="h-12 bg-gray-300 col-span-1 rounded" />
          </div>
        </div>
      ))}

    </div>
  );
}
