import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { sortLabels } from "~/src/modules/labels";

/**
 *
 * @param root0
 * @param root0.sortBy
 * @param root0.onSubmit
 */
export default function SortPurchasesDropdown({ sortBy = "dateVerbuecherung", onSubmit }) {
  return (
    <Menu as="div" className="relative ml-8">
      <Menu.Button className="w-full h-10 inline-flex border-b border-secondary-light justify-center items-center text-base font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        {sortLabels[sortBy]}
        <ChevronDownIcon className="ml-0.5 -mr-1 h-5 w-5 text-gray-500" aria-hidden="true" />
      </Menu.Button>
      <Menu.Items className="origin-top-right z-10 absolute right-0 -mt-0.5 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1 w-full">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "dateVerbuecherung" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "dateVerbuecherung"
                })}
              >
                {sortLabels.dateVerbuecherung}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "dateKV" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "dateKV"
                })}
              >
                {sortLabels.dateKv}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "plz" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "plz"
                })}
              >
                {sortLabels.plz}
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
