import { useLocation } from "react-router-dom";

/**
 *
 */
export default function useTracking() {
  const location = useLocation();

  function trackPageView(recordId, title) {
    if (!window._paq) {
      return;
    }

    window._paq.push([
      "setCustomUrl",
      `${location.pathname}/docId=${recordId}${location.search}`
    ]);
    window._paq.push([
      "setDocumentTitle",
      `${title} Id:${recordId}`
    ]);
    window._paq.push(["trackPageView"]);
  }

  return {
    trackPageView
  };
}
