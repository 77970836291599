import { useParams } from "react-router-dom";

import useProjectExport from "~/src/hooks/use-project-export";
import SectionContainer from "~/src/ui/containers/section-container";
import UnorderedList from "~/src/ui/lists/unordered-list";

/**
 *
 */
export default function ProjectLocations() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    locations
  } = project;

  return (
    <SectionContainer title="Projektadressen">
      <UnorderedList listItemClassName="first:font-semibold">
        {
          [...locations]
            .sort(({ default_location: defaultA }, { default_location: defaultB }) => (defaultA ? -1 : 1))
            .map((location, index) => {
              const {
                city: {
                  zipcode,
                  name: cityName
                } = {},
                street: {
                  name: streetName
                } = {},
                housenumber
              } = location;

              return (
                <div key={index}>
                  <span>{zipcode} {cityName}, </span>
                  <span>{streetName}{housenumber && ` ${housenumber}`}</span>
                </div>
              );
            })
        }
      </UnorderedList>
    </SectionContainer>
  );
}
