/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import cn from "classnames";
import dayjs from "dayjs";

import {
  formatArea,
  SalePrices,
  SqmSalePrices
} from "./project-unit-item/_exports.js";

import {
  formatNumber, formatDate, formatYear
} from "~/src/modules/formatters";
import dateFormatter from "~/src/modules/date-formatter";
import UnitTd from "~/src/ui/tables/unit-td";
import UnitPrices from "~/src/features/unit-prices";
import UnitAvgOfferPrices from "~/src/features/unit-avg-offer-prices";
import SignedUnitDocument from "~/src/ui/links/signed-unit-document";
import { calcAreaRatios } from "~/src/modules/area-calculation-helper";

const showArea = function showArea(area) {
  if (!area) {
    return "-";
  }

  return `${formatNumber(area)} m²`;
};

/**
 *
 * @param root0
 * @param root0.unit
 * @param root0.userSettings
 */
export default function ProjectUnitItem({ unit, userSettings }) {
  const showCustom = userSettings.use_custom_area_ratios;
  // const showAllRatios = userSettings.show_all_ratios

  let customAreas;

  const defaultVerwertetDateOffset = {
    // eslint-disable-next-line no-magic-numbers
    month: 3,
    date: 1,
    // eslint-disable-next-line no-magic-numbers
    hour: 23
  };

  if (showCustom) {
    customAreas = calcAreaRatios(unit, userSettings);
  }

  const offerDocuments = unit.documents.filter((doc) => doc.is_offer_document);
  const saleDocuments = unit.documents.filter((doc) => !doc.is_offer_document);

  if (unit.offer_data_available && unit.sale_data_available) {
    unit.verwertet = true;

    if (unit.sale_date && !unit.verwertet_date) {
      unit.verwertet_date = dayjs(unit.sale_date).subtract(defaultVerwertetDateOffset.month, "month").date(defaultVerwertetDateOffset.date)
        .hour(defaultVerwertetDateOffset.hour)
        .toISOString();
      unit.verwertet_date_format = "month";
    }
  }

  if (dayjs(unit.verwertet_date).isAfter(unit.sale_date)) {
    unit.verwertet_date = null;
  }

  return (
    <div key={`unit_${unit.id}`} className={cn("mb-12 bg-white shadow-sm", {
      // "shadow": unit.sale_data_available
    })}>
      {unit.offer_data_available &&
        <div className="px-2 py-2 rounded-sm border-l-6 border-primary sm:rounded-none">
          <p className="w-16 py-px text-xs font-medium text-center text-white rounded-sm sm:hidden bg-primary">
            Angebot
          </p>
          <div className="items-center justify-between sm:flex">
            <div className="flex items-center mt-2 sm:mt-0">
              <h3 className="text-base font-medium text-gray-800">{unit.name}</h3>
              <p className="hidden w-16 py-px ml-4 text-xs font-medium text-center text-white rounded-sm sm:block bg-primary">
                Angebot
              </p>
            </div>
            {unit.verwertet && !unit.verwertet_date &&
              <p className="mt-1 text-xs font-medium text-gray-600 sm:mt-0 sm:text-sm">verwertet</p>
            }
            {unit.verwertet && unit.verwertet_date &&
              <p className="mt-1 text-xs font-medium text-gray-600 sm:mt-0 sm:text-sm">verwertet: {dateFormatter(unit.verwertet_date, unit.verwertet_date_format)}</p>
            }
          </div>
          <div className="justify-between mt-4 text-base text-gray-600 sm:flex">
            <div>
              <span>Bauteil: {unit.building_part ? unit.building_part.name : "-"}</span>
              <span className="ml-6">Stiege: {unit.staircase ? unit.staircase : "-"}</span>
              <span className="ml-6">Geschoss: {unit.floor ? unit.floor : "-"}</span>
            </div>
            {unit.offer_room_count &&
              <span>{unit.offer_room_count} Zimmer</span>
            }
          </div>
          <div className="grid grid-cols-3 gap-1 mt-4 xl:grid-cols-6">
            <div className="grid grid-cols-1 col-span-3 gap-1 sm:grid-cols-3">
              <UnitAvgOfferPrices unit={unit} showCustom={showCustom} customArea={customAreas?.offerArea} />
              <div className="p-2 text-sm text-center text-gray-500 bg-gray-200">
                <p className="">Nutzfläche</p>
                <div className="mt-0.5 space-y-3">
                  {showCustom && (parseFloat(customAreas?.offerArea).toFixed(2).toString() !== unit.offer_area) &&
                    <div>
                      <p className="font-bold">
                        {showArea(customAreas?.offerArea)}
                      </p>
                      <p className="text-xs font-semibold leading-none text-primary">individuelle Gewichtung</p>
                    </div>
                  }
                  <div>
                    <p className="font-bold">
                      {formatArea(unit.offer_area)}
                    </p>
                    {showCustom && (parseFloat(customAreas?.offerArea).toFixed(2).toString() !== unit.offer_area) &&
                      <p className="text-xs leading-none">Standardgewichtung</p>
                    }
                  </div>
                  {/* } */}
                </div>
              </div>
              <UnitPrices unit={unit} />
            </div>
            <div className="col-span-3">
              <div className="grid grid-cols-3 gap-1 mt-2 text-sm text-gray-500 sm:grid-cols-5 text-center xl:text-right">
                <div>
                  <p>
                    <span>Loggia: </span>
                    <UnitTd val={unit.offer_loggia_count} />
                  </p>
                  <UnitTd val={unit.offer_loggia_area} area={true} hideDash />
                </div>
                <div>
                  <p>
                    <span>Balkon: </span>
                    <UnitTd val={unit.offer_balkon_count} />
                  </p>
                  <UnitTd val={unit.offer_balkon_area} area={true} hideDash />
                </div>
                <div>
                  <p>
                    <span>Terrasse: </span>
                    <UnitTd val={unit.offer_terrasse_count} />
                  </p>
                  <UnitTd val={unit.offer_terrasse_area} area={true} hideDash />
                </div>
                <div>
                  <p>
                    <span>Garten: </span>
                    <UnitTd val={unit.offer_garten_count} />
                  </p>
                  <UnitTd val={unit.offer_garten_area} area={true} hideDash />
                </div>
                <div>
                  <p>
                    <span>Keller: </span>
                    <UnitTd val={unit.offer_keller_count} />
                  </p>
                  <UnitTd val={unit.offer_keller_area} area={true} hideDash />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 break-words whitespace-pre-line">
            <p className="text-sm text-gray-500">Bemerkungen: {unit.offer_notice ? unit.offer_notice : "-"}</p>
            <div className="flex items-start justify-start mt-2">
              <p className="text-sm text-gray-500">Dokumente:</p>
              <div className="flex flex-wrap items-center justify-start h-full ml-2 space-x-3">
                {offerDocuments.length === 0 && <span className="text-sm text-gray-500">-</span>}
                {offerDocuments.map((document) => (
                  <SignedUnitDocument
                    key={`document_unit_${document.id}`}
                    document={document}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      }
      {unit.sale_data_available &&
        <div className={cn("text-sm", {
          "border-t-4 border-gray-200": unit.offer_data_available
        })}>
          <div className="py-2 pl-2 pr-3 border-l-6 border-primary-green-dark">
            <p className="w-20 py-px text-xs font-medium text-center text-white rounded-sm sm:hidden bg-primary-green-dark">
              Kaufvertrag
            </p>
            <div className="items-center justify-between sm:flex">
              <div className="flex items-center mt-2 sm:mt-0">
                <h3 className="text-base font-medium text-gray-800">{unit.name}</h3>
                <p className="hidden w-20 py-px ml-4 text-xs font-medium text-center text-white rounded-sm sm:block bg-primary-green-dark">
                  Kaufvertrag
                </p>
              </div>
              <p className="mt-1 text-xs font-medium text-gray-600 sm:mt-0 sm:text-sm">
                {unit.kv_id &&
                  <a href={`https://immobase.immounited.com/IMMOmapping/Default.aspx?mapmode=1&contractOfPurchaseId=${unit.kv_id}`} target="_blank" rel="noreferrer noopener" className="underline">
                    TZ {(unit.tz_number && unit.tz_year) && `${unit.tz_number}/${formatYear(unit.tz_year)}`}
                  </a>
                }
                {!unit.kv_id &&
                  <span>TZ {(unit.tz_number && unit.tz_year) && `${unit.tz_number}/${formatYear(unit.tz_year)}`}</span>
                }
                <span> vom {formatDate({ date: unit.sale_date })}</span>
              </p>
            </div>
            <div className="justify-between mt-4 text-base text-gray-600 sm:flex">
              <div>
                <span>Bauteil: {unit.building_part ? unit.building_part.name : "-"}</span>
                <span className="ml-6">Stiege: {unit.staircase ? unit.staircase : "-"}</span>
                <span className="ml-6">Geschoss: {unit.floor ? unit.floor : "-"}</span>
              </div>
              {unit.sale_room_count &&
                <span>{unit.sale_room_count} Zimmer</span>
              }
            </div>
            <div className="grid grid-cols-3 gap-1 mt-2 sm:mt-4 xl:grid-cols-6">
              <div className="grid grid-cols-1 col-span-3 gap-1 sm:grid-cols-3">
                <SqmSalePrices unit={unit} showCustom={showCustom} customArea={customAreas?.saleArea} />
                <div className="p-2 text-sm text-center text-gray-500 bg-gray-200">
                  <p className="">Nutzfläche</p>
                  <div className="mt-0.5 space-y-3">
                    {showCustom && (parseFloat(customAreas?.saleArea).toFixed(2).toString() !== unit.sale_area) &&
                      <div>
                        <p className="font-bold">
                          {showArea(customAreas?.saleArea)}
                        </p>
                        <p className="text-xs font-semibold leading-none text-primary">individuelle Gewichtung</p>
                      </div>
                    }
                    {/* {(!showCustom || (showCustom && showAllRatios)) && */}
                    <div>
                      <p className="font-bold">
                        {formatArea(unit.sale_area)}
                      </p>
                      {showCustom && (parseFloat(customAreas?.saleArea).toFixed(2).toString() !== unit.sale_area) &&
                        <p className="text-xs leading-none">Standardgewichtung</p>
                      }
                    </div>
                    {/* } */}
                  </div>
                </div>
                <div className="p-2 text-sm text-center text-gray-500 bg-gray-200">
                  <p>Preis</p>
                  <div className="mt-0.5 space-y-3">
                    <SalePrices unit={unit} />
                  </div>
                </div>
              </div>
              <div className="col-span-3">
                <div className="grid grid-cols-3 gap-1 mt-2 text-sm text-gray-500 sm:grid-cols-5 text-center xl:text-right">
                  <div>
                    <p>
                      <span>Loggia: </span>
                      <UnitTd val={unit.sale_loggia_count} />
                    </p>
                    <UnitTd val={unit.sale_loggia_area} area={true} hideDash />
                  </div>
                  <div>
                    <p>
                      <span>Balkon: </span>
                      <UnitTd val={unit.sale_balkon_count} />
                    </p>
                    <UnitTd val={unit.sale_balkon_area} area={true} hideDash />
                  </div>
                  <div>
                    <p>
                      <span>Terrasse: </span>
                      <UnitTd val={unit.sale_terrasse_count} />
                    </p>
                    <UnitTd val={unit.sale_terrasse_area} area={true} hideDash />
                  </div>
                  <div>
                    <p>
                      <span>Garten: </span>
                      <UnitTd val={unit.sale_garten_count} />
                    </p>
                    <UnitTd val={unit.sale_garten_area} area={true} hideDash />
                  </div>
                  <div>
                    <p>
                      <span>Keller: </span>
                      <UnitTd val={unit.sale_keller_count} />
                    </p>
                    <UnitTd val={unit.sale_keller_area} area={true} hideDash />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 break-words whitespace-pre-line">
              <p className="text-sm text-gray-500 [font-variant-ligatures:no-contextual]">Kaufende Partei: {unit.buyer.length > 0 ? unit.buyer.join(", ") : "-"}</p>
              <p className="mt-2 text-sm text-gray-500 ">Bemerkungen: {unit.sale_notice ? unit.sale_notice : "-"}</p>
              <div className="flex items-center mt-2">
                <p className="text-sm text-gray-500">Dokumente:</p>
                <div className="flex flex-wrap items-center justify-start h-full ml-2 space-x-3">
                  {saleDocuments.length === 0 && <span className="text-sm text-gray-500">-</span>}
                  {saleDocuments.map((document) => (
                    <SignedUnitDocument
                      key={`document_unit_${document.id}`}
                      document={document}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
