import { formatEuro } from "~/src/modules/formatters";

const SqmSalePrice = ({ label, value }) => (
  <div>
    <p className="font-bold">{formatEuro(value)}</p>
    <p className="text-xs leading-none">{label}</p>
  </div>
);

export default SqmSalePrice;
