import cn from "classnames";

/**
 *
 * @param props
 * @param props.title
 * @param props.icon
 * @param props.children
 * @param props.className
 */
const ContentSection = ({
  title,
  icon,
  children,
  className,
  ...props
}) => (
  <article className={cn("flex flex-col gap-x-2 gap-y-0.5 w-full text-sm items-start", className)} {...props}>
    {
      title && (
        <h2 className="text-gray-500 flex gap-2 items-center font-medium">
          {icon && <span className={cn(
            "w-5 h-5",
            {
              "text-primary": props.id !== "stats"
            }
          )}>{icon}</span>}
          <span className={cn(
            "font-semibold text-lg",
            {
              "text-primary": props.id !== "stats"
            }
          )}>
            {title}
          </span>
        </h2>
      )
    }
    {
      children
    }
  </article>
);

export default ContentSection;
