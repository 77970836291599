import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param id
 */
export default function useProject(id) {
  const shouldFetch = Boolean(id);

  const {
    data, error, mutate
  } = useSWR(shouldFetch ? `/projects/${id}` : null, fetcher);

  return {
    project: data?.data,
    isLoading: !error && !data,
    error,
    mutate
  };
}
