import { useRef } from "react";

import { Popup } from "./project-marker-popup/_exports.js";
import PopupContent from "./popup-content/index.jsx";

/**
 *
 * @param root0
 * @param root0.project
 * @param root0.selected
 * @param root0.handleCheckbox
 * @param root0.checkboxDisabled
 */
export default function ProjectMarkerPopup({
  project,
  selected,
  handleCheckbox,
  checkboxDisabled
}) {
  const popupRef = useRef(null);

  const location = project?.locations?.find((innerLocation) => innerLocation.default_location) ??
    project?.locations?.[0];

  const displayPicture = (project?.show_image_public && project?.image_file_name);

  return (
    <Popup
      autoPan={false}
      ref={popupRef}
      className={`${displayPicture ? "with-picture" : "without-picture"}`}
    >
      {
        project
          ? <PopupContent
            project={project}
            handleCheckbox={handleCheckbox}
            selected={selected}
            checkboxDisabled={checkboxDisabled}
            location={location}
            displayPicture={displayPicture}
            className={`${displayPicture ? "with-picture" : "without-picture"}`}
          />
          : null
      }
    </Popup>
  );
}
