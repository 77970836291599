import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import {
  Fragment, useEffect, useRef, useState
} from "react";

import { handleUpdate } from "./handlers";

import { showSuccess } from "~/src/modules/toast-helper";

/**
 *
 * @param root0
 * @param root0.watchlist
 * @param root0.show
 * @param root0.hideModal
 * @param root0.mutateWatchlists
 */
export default function EditWatchlistModal({
  watchlist, show, hideModal, mutateWatchlists
}) {
  const cancelButtonRef = useRef(null);
  const [
    name,
    setName
  ] = useState(watchlist?.name || "");
  const [
    isLoading,
    setIsLoading
  ] = useState(false);
  const [
    isError,
    setIsError
  ] = useState(false);

  useEffect(() => {
    if (show) {
      setName(watchlist.name);
    }
  }, [
    watchlist,
    show
  ]);

  function handleSubmit(e) {
    e.preventDefault();

    if (!isLoading && !formDisabled) {
      setIsLoading(true);
      handleUpdate(watchlist.uuid, name, handleSuccess, handleError);
    }
  }

  function handleChange(e) {
    const { value } = e.target;

    if (value.length === 0) {
      setIsError(false);
      setName(value);
    }
    if (value.length === 1 && /[a-zA-Z0-9]/.test(value)) {
      setIsError(false);
      setName(value);
    }

    if (value.length > 1 && /^[A-Za-z0-9 ]+$/.test(value)) {
      setIsError(false);
      setName(value.replace(/  +/g, " "));
    }
  }

  function handleHide() {
    setIsLoading(false);
    setName("");
    setIsError(false);
    hideModal();
  }

  function handleSuccess() {
    showSuccess();
    mutateWatchlists();
    handleHide();
  }

  function handleError(status) {
    setIsLoading(false);
    setIsError(status);
  }

  const formDisabled = name.length === 0;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-20 overflow-y-auto" initialFocus={cancelButtonRef} open={show} onClose={handleHide}>
        <div className="flex items-end justify-center min-h-full px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Merkliste bearbeiten
                </Dialog.Title>
                <form onSubmit={handleSubmit}>
                  <div className="mt-5">
                    <div className="relative rounded-md shadow-sm">
                      <input
                        ref={cancelButtonRef}
                        autoComplete="off"
                        autoFocus
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        maxLength={60}
                        onChange={handleChange}
                        placeholder="Name der Merkliste..."
                        className={cn("block w-full pr-10 focus:ring-0 focus:outline-none sm:text-sm rounded-md", {
                          "border-primary text-primary placeholder-primary focus:border-primary": isError,
                          "border-gray-300 focus:border-gray-300": !isError
                        })}
                      />
                      {isError &&
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon className="w-5 h-5 text-primary" />
                        </div>
                      }
                    </div>
                    <p className="mt-1 text-xs text-right text-gray-700">{name.length} / 60</p>
                    <p className="h-4 mt-2 text-sm text-primary">
                      {isError && isError === 409 &&
                        <span>Eine Merkliste mit diesem Namen existiert bereits!</span>
                      }
                      {isError && isError !== 409 &&
                        <span>Merkliste konnte nicht gespeichert werden!</span>
                      }
                    </p>
                  </div>
                </form>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  disabled={formDisabled}
                  type="button"
                  className={cn("w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm", {
                    "cursor-not-allowed": formDisabled
                  })}
                  onClick={handleSubmit}
                >
                  {isLoading &&
                    <svg className="w-5 h-5 text-white animate-spin" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                  }
                  {!isLoading &&
                    <span>Speichern</span>
                  }
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={handleHide}

                >
                  Abbrechen
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
