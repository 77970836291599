/* eslint-disable func-style */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */
import { generateLineData } from "./tools";

import MultiLineChart from "~/src/ui/charts/multi-line-chart";
import { nullFn } from "~/src/lib/standard";
import { priceLabels } from "~/src/modules/labels";
import ChartLegend from "~/src/ui/charts/legend";

/**
 *
 * @param root0
 * @param root0.graphLabels
 * @param root0.statistic
 * @param root0.areaGroupsWithoutUnselected
 */
function MarketTrendByAreaOfferChartForPrint({
  graphLabels,
  statistic,
  areaGroupsWithoutUnselected

}) {
  return graphLabels
    .map((graphLabel) => {
      const lineData = generateLineData(graphLabel, statistic);

      return (
        <div key={graphLabel} className="w-full space-y-4 bg-white break-inside-avoid">
          <h2 className="font-medium text-gray-900 text-md">{priceLabels[graphLabel]} Trend nach Fläche</h2>
          <div className="h-[19rem]">
            <MultiLineChart
              data={lineData.chartData}
              x={{ type: "month" }}
              activeAreaGroups={areaGroupsWithoutUnselected}
            />
          </div>
          <ChartLegend
            data={lineData.legendData}
            displayAreaGroups={areaGroupsWithoutUnselected}
            activeAreaGroups={areaGroupsWithoutUnselected}
            setActiveAreaGroups={nullFn}
            setHoveredAreaGroup={nullFn}
            simple
          />
        </div>
      );
    });
}

export { MarketTrendByAreaOfferChartForPrint };
