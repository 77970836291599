import {
  useEffect, useRef, useState
} from "react";

import HistoricFilterTags from "../../filters/historic-filter-tags";

import ConstructionPhaseFilterTags from "~/src/features/filters/construction-phase-filter-tags";
import ConstructionTypeFilter from "~/src/features/filters/construction-type-filter";
import FinishYearFilterTag from "~/src/features/filters/finish-year-filter-tags";
import FundingFilterTag from "~/src/features/filters/funding-filter-tags";
import MarketingPhaseFilterTags from "~/src/features/filters/marketing-phase-filter-tags";
import PolygonFilterTags from "~/src/features/filters/polygon-filter-tags";
import QuantityFilterTag from "~/src/features/filters/quantity-filter-tags";
import RechtsformFilterTag from "~/src/features/filters/rechtsform-filter";
import RegionFilterTag from "~/src/features/filters/region-filter-tags";
import CertificateFilterTag from "~/src/features/filters/certificate-filter-tags";
import TransactionFilterTag from "~/src/features/filters/transaction-filter-tags";
import { parseActiveFilters } from "~/src/modules/search-filter-helper";
import CompanyFilterTag from "~/src/ui/search-filter-tags/company-filter-tag";
import ProjectFilterTag from "~/src/ui/search-filter-tags/project-filter-tag";
import StreetFilterTag from "~/src/ui/search-filter-tags/street-filter-tag";
import ObjektTypeFilterTag from "~/src/features/filters/objekt-typ-filter-tags";
import useMediaQuery from "~/src/hooks/use-media-query";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.submit
 * @param root0.handleClearFilters
 * @param root0.onClick
 * @param root0.selectionFilter
 * @param root0.children
 * @param root0.filterModals
 */
export default function ProjectFilterTags({
  query,
  submit,
  filterModals
}) {
  const isPrint = useMediaQuery("print");

  const divRef = useRef(null);

  const [
    childrenCount,
    setChildrenCount
  ] = useState(0);

  function handleClick(key) {
    submit(key, null);
  }

  // const currentSelectionStatus = useStore((state) => state.currentSelectionStatus);
  // const activeSelection = (currentSelectionStatus !== "all");
  // const setSelectionResetByFilter = useStore((state) => state.setSelectionResetByFilter);

  const activeFilters = parseActiveFilters(query);

  // const resetSelection = () => {
  //   if (selectionFilter) {
  //     setSelectionResetByFilter(true);
  //   }
  // };

  useEffect(() => {
    setChildrenCount(divRef.current.children.length);
  }, [divRef.current]);

  return (
    <div className="flex flex-wrap gap-y-1 gap-x-2 w-full pr-6 py-0.5 print:p-0 print:overflow-hidden print:max-h-[50mm] break-inside-avoid" ref={divRef}>
      {/* <SelectionFilterTag handleClick={() => resetSelection()} label={selectionFilter} /> */}
      <RegionFilterTag onClick={() => filterModals.setRegionFilterModal(true)} queryItems={query?.region} submit={submit} />
      <CertificateFilterTag onClick={() => filterModals.setEsgCertificatesFilterModal(true)} queryItems={query?.certificates} submit={submit} />
      <StreetFilterTag handleClick={() => handleClick("street")} label={query?.streetLabel} slug={query?.street} radius={query?.radius} locationType={query?.locationType}/>
      <ProjectFilterTag handleClick={() => handleClick("name")} label={query?.name} />
      <CompanyFilterTag handleClick={() => handleClick("company")} label={query?.companyLabel} slug={query?.company} />
      <ObjektTypeFilterTag queryItems={query?.objekttyp} submit={submit} />
      <FinishYearFilterTag queryItems={query?.finish_year} submit={submit} />
      <ConstructionPhaseFilterTags queryItems={query?.construction_phase} submit={submit} />
      <MarketingPhaseFilterTags queryItems={query?.marketing_phase} submit={submit} />
      <RechtsformFilterTag queryItems={query?.rechtsform} submit={submit} />
      <QuantityFilterTag queryItems={query?.quantity} submit={submit} />
      <TransactionFilterTag queryItems={query?.liegenschaft} submit={submit} />
      <FundingFilterTag queryItems={query?.foerderung} submit={submit} />
      <ConstructionTypeFilter queryItems={query?.construction_type} submit={submit} />
      <PolygonFilterTags queryItems={query?.polygon} submit={submit} />
      {
        isPrint && <HistoricFilterTags queryItems={query?.automated} submit={submit} />
      }
    </div>
  );
}
