/**
 *
 * @param selectedProjects
 */
export function calculateStats(selectedProjects) {
  if (!selectedProjects) {
    return null;
  }

  return selectedProjects.reduce((prev, current = {}) => {
    const updated = {
      ...prev
    };

    updated.project_count = prev.project_count + 1;

    if (current.count_wohnungen) {
      updated.unit_count = prev.unit_count + current.count_wohnungen;
    }

    if (current.show_gka && current.gka) {
      updated.gka_count = prev.gka_count + 1;
      updated.gka = prev.gka + parseFloat(current.gka);
      updated.gka_avg = updated.gka / updated.gka_count;
    }

    if (current.avg_miete) {
      updated.rent_count = prev.rent_count + 1;
      updated.rent = prev.rent + parseFloat(current.avg_miete);
      updated.rent_avg = updated.rent / updated.rent_count;
    }

    if (current.avg_offer_price_eigennutzer) {
      updated.avg_offer_price_eigennutzer_count = prev.avg_offer_price_eigennutzer_count + 1;
      updated.offer_price_eigennutzer = prev.offer_price_eigennutzer + parseFloat(current.avg_offer_price_eigennutzer);
      updated.avg_offer_price_eigennutzer = updated.offer_price_eigennutzer / updated.avg_offer_price_eigennutzer_count;
    }

    if (current.avg_offer_price_investor) {
      updated.avg_offer_price_investor_count = prev.avg_offer_price_investor_count + 1;
      updated.offer_price_investor = prev.offer_price_investor + parseFloat(current.avg_offer_price_investor);
      updated.avg_offer_price_investor = updated.offer_price_investor / updated.avg_offer_price_investor_count;
    }

    if (current.avg_sale_price_investor) {
      updated.avg_sale_price_investor_count = prev.avg_sale_price_investor_count + 1;
      updated.sale_price_investor = prev.sale_price_investor + parseFloat(current.avg_sale_price_investor);
      updated.avg_sale_price_investor = updated.sale_price_investor / updated.avg_sale_price_investor_count;
    }

    if (current.avg_sale_price_eigennutzer) {
      updated.avg_sale_price_eigennutzer_count = prev.avg_sale_price_eigennutzer_count + 1;
      updated.sale_price_eigennutzer = prev.sale_price_eigennutzer + parseFloat(current.avg_sale_price_eigennutzer);
      updated.avg_sale_price_eigennutzer = updated.sale_price_eigennutzer / updated.avg_sale_price_eigennutzer_count;
    }

    return updated;
  }, {
    avg_offer_price_eigennutzer: null,
    avg_offer_price_eigennutzer_count: null,
    avg_offer_price_investor: null,
    avg_offer_price_investor_count: null,
    avg_sale_price_eigennutzer: null,
    avg_sale_price_eigennutzer_count: null,
    avg_sale_price_investor: null,
    avg_sale_price_investor_count: null,
    gka: null,
    gka_avg: null,
    gka_count: null,
    offer_price_eigennutzer: null,
    offer_price_investor: null,
    project_count: null,
    rent: null,
    rent_avg: null,
    rent_count: null,
    sale_price_eigennutzer: null,
    sale_price_investor: null,
    unit_count: null
  });
}
