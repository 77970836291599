/* eslint-disable max-len */
import { ScaleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

import priceTerms from "~/src/lib/price-terms.js";
import { formatEuro } from "~/src/modules/formatters";
import { priceLabels } from "~/src/modules/labels.js";

const {
  normal: normalTerm,
  investment: investmentTerm,
  price: priceTerm,
  perSquareMeter: perSquareMeterTerm
} = priceTerms;

const getPricePerSqm = (price, area) => {
  const hasValue = area > 0 && Number.isFinite(price);

  if (!hasValue) {
    return null;
  }

  return price / area;
};

/**
 *
 * @param root0
 * @param root0.unit
 * @param root0.showCustom
 * @param root0.customArea
 */
// eslint-disable-next-line complexity
export default function UnitAvgOfferPrices({
  unit, showCustom, customArea
}) {
  const area = customArea ?? unit.offer_area;
  const offerPrices = [];

  const offerNormalPerSqm = getPricePerSqm(unit.offer_price_normal, area);

  if (offerNormalPerSqm !== null) {
    offerPrices.push(<div>
      <p className="font-bold">{formatEuro(offerNormalPerSqm)}</p>
      <p className="text-xs leading-none">{normalTerm}</p>
    </div>);
  }

  const offerInvestmentPerSqm = getPricePerSqm(unit.offer_price_investor, area);

  if (offerInvestmentPerSqm !== null) {
    offerPrices.push(<div>
      <p className="font-bold">{formatEuro(offerInvestmentPerSqm)}</p>
      <p className="text-xs leading-none">{investmentTerm}</p>
    </div>);
  }

  const rentPrices = [];

  const rentBasePerSqm = getPricePerSqm(unit.rent_netto, area);

  if (rentBasePerSqm !== null) {
    rentPrices.push(<div>
      <p className="font-bold">{formatEuro(rentBasePerSqm)}</p>
      <p className="text-xs leading-none">{priceLabels.rentNet}</p>
    </div>);
  }

  const rentTotalPerSqm = getPricePerSqm(unit.rent_bk, area);

  if (rentTotalPerSqm !== null) {
    rentPrices.push(<div>
      <p className="font-bold">{formatEuro(rentTotalPerSqm)}</p>
      <p className="text-xs leading-none">{priceLabels.rentTotal}</p>
    </div>);
  }

  const prices = [];

  if (unit.buyable) {
    prices.push(...offerPrices);
  }

  if (unit.rentable) {
    prices.push(...rentPrices);
  }

  if (!prices.length) {
    prices.push(<p className="font-bold">-</p>);
  }

  return (
    <div className="relative p-2 text-sm text-center text-gray-500 bg-gray-200">
      {showCustom && (parseFloat(customArea).toFixed(2).toString() !== unit.offer_area) &&
        <div className="absolute top-2 right-2">
          <ScaleIcon
            data-tooltip-id="tooltip-unit-offer-avg-price"
            data-tooltip-content="Preis / m² aus individueller Gewichtung berechnet" className="-mt-0.5 p-0.5 w-6 h-6 rounded-full bg-primary text-white" />
          <Tooltip
            id="tooltip-unit-offer-avg-price"
            place="bottom"
            className="z-50"
            delayShow={500}
          />
        </div>
      }

      <p>{priceTerm}{perSquareMeterTerm}</p>
      <div className="mt-0.5 space-y-3">{prices}</div>
    </div>
  );
}
