import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.title
 * @param root0.onClick
 * @param root0.customWidth
 * @param root0.fontSize
 */
export default function FilterButton({
  title, onClick, customWidth = null, fontSize = "text-sm"
}) {
  return (
    <button type="button" onClick={onClick} className={cn("py-0.5 text-center bg-secondary-dark hover:bg-secondary-darker border border-secondary-darker rounded focus:outline-none text-gray-600", {
      "w-32": !customWidth,
      [customWidth]: customWidth,
      [fontSize]: fontSize
    })}>
      <span className="font-medium">{title}</span>
    </button>
  );
}

