import cn from "classnames";

import MapMarker from "~/src/ui/markers/map-marker";

function getIconClass(currentMarketingPhase) {
  switch (currentMarketingPhase) {
    case "marketing_phase_coming_soon":
      return "text-marker-orange";
    case "marketing_phase_active":
      return "text-marker-red";
    case "marketing_phase_completed_apartments":
    case "marketing_phase_completed_all":
    case "marketing_phase_project_finished":
      return "text-marker-green";
    default:
      return "text-marker-gray";
  }
}

function getIconName(currentConstructionPhase) {
  switch (currentConstructionPhase) {
    case "construction_phase_purchase":
      return "purchase";
    case "construction_phase_active":
      return "construction";
    case "construction_phase_completed":
      return "completion";
    default:
      return "empty";
  }
}

/**
 *
 * @param root0
 * @param root0.currentMarketingPhase
 * @param root0.currentConstructionPhase
 * @param root0.selected
 * @param root0.active
 */
export function getIconHtml({
  currentMarketingPhase,
  currentConstructionPhase,
  selected,
  active
}) {
  return <MapMarker
    className={cn(
      getIconClass(currentMarketingPhase),
      "w-full h-full",
      {
        "opacity-60": !selected,
        "scale-105 -translate-y-[1px]": active
      }
    )}
    icon={getIconName(currentConstructionPhase)}
  />;
}
