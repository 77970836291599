import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react";
import cn from "classnames";
import { useController } from "react-hook-form";

/**
 *
 * @param props
 * @param props.name
 * @param props.label
 * @param props.options
 * @param props.defaultValue
 * @param props.control
 * @param props.className
 * @param props.onChange
 */
// eslint-disable-next-line max-lines-per-function
export default function RadioGroup({
  name,
  label,
  options = [
    {
      label: "",
      value: "",
      shown: false
    }
  ],
  defaultValue,
  control,
  className,
  onChange = () => { }
}) {
  const {
    field: {
      onChange: innerOnChange,
      onBlur,
      value,
      ref
    },
    fieldState: {
      invalid, isTouched, isDirty
    },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue
  });

  return (
    <HeadlessRadioGroup
      {...{
        value: value?.value ?? "",
        onChange: (e) => {
          innerOnChange(e);
          onChange(e);
        },
        onBlur
      }}
      className={cn("flex flex-col gap-2", className)}
    >
      <HeadlessRadioGroup.Label className="sr-only">{label}</HeadlessRadioGroup.Label>
      <div className="flex flex-col sm:flex-row gap-4 w-full flex-wrap">
        {options.map((option, index) => (
          <HeadlessRadioGroup.Option
            key={option.value}
            value={option.value}
            disabled={!option.shown}
            className={({ active, disabled }) => {
              const {
                value: optionValue,
                color = "red"
              } = option;

              const checked = optionValue === value;

              return cn(
                "relative flex flex-grow flex-shrink w-full sm:w-5/12 rounded border border-gray-200 px-4 py-2.5 shadow-sm cursor-pointer",
                {
                  "outline outline-2  outline-offset-[-2px]": active && !disabled,
                  "outline outline-2  outline-offset-[-2px]  text-white": checked,
                  "bg-white text-gray-900": !checked && !disabled,
                  "hover:border-gray-400": !disabled && !checked,
                  "bg-gray-200 text-gray-400 cursor-default": disabled,
                  "outline-primary": active && !disabled && color === "red",
                  "outline-primary bg-primary": checked && color === "red",
                  "outline-primary-green-dark": active && !disabled && color === "green",
                  "outline-primary-green-dark bg-primary-green-dark": checked && color === "green"
                }
              );
            }}
          >
            {({ active, disabled }) => {
              const checked = option.value === value;
              const {
                color = "red"
              } = option;

              return (
                <>
                  <div className="flex gap-2 w-full items-center">
                    {/* <div
                      className={cn(
                        "rounded-full border w-5 h-5 flex-grow-0 flex-shrink-0 flex justify-center items-center",
                        {
                          "bg-primary border-transparent": checked,
                          "bg-white border-gray-400": !checked
                        }
                      )}
                    >
                      {checked && (
                        <div className="bg-white rounded-full w-2 h-2 flex-grow-0 flex-shrink-0" />
                      )}
                    </div> */}

                    <div className="flex w-full items-center">
                      <div className="text-sm w-full">
                        <HeadlessRadioGroup.Label
                          as="div"
                          className="flex w-full items-center justify-between text-sm font-medium gap-2"
                        >
                          <span>{option.label}</span>
                          {
                            option.tag !== "" && (
                              <span className={cn("text-md rounded px-2 pt-1 pb-1 flex justify-center items-center font-mono", {
                                "bg-white text-gray-900": (checked && color === "red") || (checked && color === "green"),
                                "bg-primary text-white": !checked && color === "red",
                                "bg-primary-green-dark text-white": !checked && color === "green"
                              })}>
                                {option.tag}
                              </span>
                            )
                          }
                        </HeadlessRadioGroup.Label>

                        {
                          option.description && (
                            <HeadlessRadioGroup.Description
                              as="span"
                              className="text-gray-500"
                            >
                              <span>{option.description}</span>
                            </HeadlessRadioGroup.Description>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </HeadlessRadioGroup.Option>
        ))}
      </div>
    </HeadlessRadioGroup>
  );
}
