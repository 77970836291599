import { objectMap } from "../lib/standard";

import { hsl2Argb } from "./helpers/color-palletes";

const colorPaletteWohnungenByYear = {
  buyable_funded: { buyable_fundedColor: "hsl(193, 70%, 42%)" },
  buyable_notFunded: { buyable_notFundedColor: "hsl(193, 70%, 30%)" },
  rentable_funded: { rentable_fundedColor: "hsl(344, 26%, 54%)" },
  rentable_notFunded: { rentable_notFundedColor: "hsl(345, 65%, 30%)" },
  unknown_funded: { unknown_fundedColor: "hsl(38, 88%, 72%)" },
  unknown_notFunded: { unknown_notFundedColor: "hsl(38, 80%, 53%)" }
};

const colorPaletteWohnungenByYearArgbHex = objectMap(colorPaletteWohnungenByYear, ([
  key,
  value
]) => [
  key,
  hsl2Argb.cssString2Hex(Object.values(value).pop())
]);

export {
  colorPaletteWohnungenByYear, colorPaletteWohnungenByYearArgbHex
};
