/**
 *
 * @param props
 * @param props.minWidth
 */
export default function Logo({ minWidth = "" }) {
  return (
    <svg className={`w-full ${minWidth}`} viewBox="0 0 664.21 72.69">
      <defs><style dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#fff}" }} /></defs>
      <path className="cls-1" d="M0 11.65h9.32v49.8H0zM61.54 25.38L45.32 47h-1.85L27.6 25.31v36.21h-9.39v-49.8h10.81L44.6 33.13l15.58-21.41h10.75v49.8h-9.39V25.38zM123.14 25.38L106.92 47h-1.84L89.21 25.31v36.21h-9.39v-49.8h10.81l15.58 21.41 15.58-21.41h10.75v49.8h-9.4V25.38z" />
      <path className="cls-1" d="M191.2 36.83c-.15 12.88-8 25.75-25.54 25.75S140.05 50 140.05 36.9s8.39-26.25 25.61-26.25 25.68 13.16 25.54 26.18m-41.9.17c.21 8.19 4.62 16.94 16.36 16.94S181.81 45.15 182 37c.14-8.39-4.56-17.78-16.29-17.78S149.08 28.65 149.3 37M269.72 72.69h-57.59a7.47 7.47 0 01-7.55-7.38V7.38A7.47 7.47 0 01212.13 0h57.59a7.48 7.48 0 017.55 7.38v57.93a7.48 7.48 0 01-7.55 7.38m-57.59-69a3.7 3.7 0 00-3.74 3.65v57.97a3.7 3.7 0 003.74 3.69h57.59a3.69 3.69 0 003.73-3.65V7.38a3.69 3.69 0 00-3.73-3.65z" transform="translate(-.89)" />
      <path className="cls-1" d="M264.33 35.35c.29 13.18-8.14 26.5-26.28 26.5h-17.54V10h17.54c17.54 0 25.95 12.62 26.28 25.35zm-39.82-21.53V58h13.54c15.4 0 22.5-11.55 22.28-22.65-.22-10.8-7.33-21.53-22.28-21.53zM293.62 34H324v3.62h-30.38V58h32.71v3.85H289.7V10h35.74v3.78h-31.82zM379 10l-21.37 52h-4.29L332 10h4.29l19.17 47.37L374.65 10zM389.75 34h30.34v3.62h-30.34V58h32.71v3.85h-36.64V10h35.75v3.78h-31.82zM435.26 10v48h29.15v3.78h-33.15V10zM520.43 36c-.07 13.62-8.73 26.71-26.12 26.71s-26.12-13.17-26.12-26.86c0-13.91 8.66-26.64 26.12-26.64S520.51 22 520.43 36zm-48.32-.08c.07 11.7 7.47 23.09 22.2 23.09s22.28-11.39 22.28-23.09c0-12-7.4-23-22.28-23S472 23.73 472.11 35.94zM533.9 43.94v17.91h-4V10h22.72c22.5 0 22.5 33.9 0 33.9zm0-30.2v26.57h18.72c17.24 0 17.24-26.57 0-26.57zM582.22 34h30.34v3.62h-30.34V58h32.71v3.85H578.3V10H614v3.78h-31.78zM665.1 61.85h-5.25l-17.91-19.61H627.8v19.61h-4V10h22.8c11.25 0 16.95 8.07 17 16.14.08 8.43-5.47 15.83-16.8 15.83zm-37.3-23.39h18.36c9 0 13-5.11 13.1-12.36.07-6.07-4.07-12.36-12.66-12.36h-18.8z" transform="translate(-.89)" />
    </svg>
  );
}
