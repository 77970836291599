import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

import { generatePageItems, generatePageItemsMobile } from "./pagination-helper";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

/**
 *
 * @param props
 * @param props.children
 * @param props.pagination
 * @param props.page
 * @param props.onPageChange
 */
export default function Pagination({
  children, pagination, page, onPageChange
}) {
  if (!pagination) {
    return null;
  }

  function handlePageClick(pageItem) {
    if ((page === undefined && pageItem === 1) || page !== pageItem) {
      onPageChange(pageItem);
    }
  }

  function handleClickBack() {
    onPageChange(page - 1);
  }

  function handleClickNext() {
    onPageChange(page ? parseInt(page) + 1 : 2);
  }

  if (pagination?.totalRecords === 0) {
    return null;
  }

  return (
    <nav className="flex items-center justify-between">

      {children}

      <div className="hidden flex-1 sm:flex justify-center xl:justify-end space-x-1 flex-wrap ">

        {generatePageItems(pagination?.totalPages, page ? parseInt(page) : 1).map((pageItem) => {
          if (pageItem === LEFT_PAGE) {
            return (
              <button key={`page_item_${pageItem}`} onClick={handleClickBack} type="button" className={cn("flex justify-center items-center w-10 h-8  bg-white border border-gray-200 rounded-sm focus:outline-none")}>
                <ChevronDoubleLeftIcon className="w-4 h-4 text-gray-800" />
              </button>
            );
          }

          if (pageItem === RIGHT_PAGE) {
            return (
              <button key={`page_item_${pageItem}`} onClick={handleClickNext} type="button" className={cn("flex justify-center items-center w-10 h-8 bg-white rounded-sm border border-gray-200 focus:outline-none")}>
                <ChevronDoubleRightIcon className="w-4 h-4 text-gray-800" />
              </button>
            );
          }

          const isCurrentPage = ([
            null,
            undefined
          ].includes(page) && pageItem === 1) || parseInt(page) === pageItem;

          return (
            <button key={`page_item_${pageItem}`} onClick={() => handlePageClick(pageItem)} type="button" className={cn("flex justify-center items-center w-10 h-8 rounded-sm border border-gray-200 focus:outline-none", {
              "bg-white hover:bg-gray-50": !isCurrentPage,
              "bg-primary hover:bg-primary text-white cursor-default": isCurrentPage
            })}>
              {pageItem}
            </button>
          );
        })}

      </div>

      <div className="flex flex-1 sm:hidden justify-center xl:justify-end space-x-1 flex-wrap ">

        {generatePageItemsMobile(pagination?.totalPages, page ? parseInt(page) : 1).map((pageItem) => {
          if (pageItem === LEFT_PAGE) {
            return (
              <button key={`page_item_${pageItem}`} onClick={handleClickBack} type="button" className={cn("flex justify-center items-center w-10 h-8  bg-white border border-gray-200 rounded-sm focus:outline-none")}>
                <ChevronDoubleLeftIcon className="w-4 h-4 text-gray-800" />
              </button>
            );
          }

          if (pageItem === RIGHT_PAGE) {
            return (
              <button key={`page_item_${pageItem}`} onClick={handleClickNext} type="button" className={cn("flex justify-center items-center w-10 h-8 bg-white rounded-sm border border-gray-200 focus:outline-none")}>
                <ChevronDoubleRightIcon className="w-4 h-4 text-gray-800" />
              </button>
            );
          }

          const isCurrentPage = ([
            null,
            undefined
          ].includes(page) && pageItem === 1) || parseInt(page) === pageItem;

          return (
            <button key={`page_item_${pageItem}`} onClick={() => handlePageClick(pageItem)} type="button" className={cn("flex justify-center items-center w-10 h-8 rounded-sm border border-gray-200 focus:outline-none", {
              "bg-white hover:bg-gray-50": !isCurrentPage,
              "bg-primary hover:bg-primary text-white cursor-default": isCurrentPage
            })}>
              {pageItem}
            </button>
          );
        })}

      </div>

    </nav>
  );
}
