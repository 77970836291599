import { useEffect } from "react";

import API from "~/src/modules/api";

/**
 *
 * @param projectSlug
 */
export default function useProjectVisit(projectSlug) {
  useEffect(() => {
    if (!projectSlug) {
      return;
    }

    const controller = new AbortController();

    createProjectVisit(projectSlug, controller.signal)
      .then((res) => {
      })
      .catch((e) => {
      });

    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSlug]);
}

function createProjectVisit(projectSlug, signal) {
  return new Promise((resolve, reject) => {
    API.post(`/project-visits/${projectSlug}`, {}, {
      signal
    })
      .then((res) => {
        resolve(null);
      })
      .catch((e) => {
        resolve(null);
      });
  });
}
