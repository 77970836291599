import ProjectListItem from "./watchlist-project-list-item";

import Skeleton from "~/src/ui/loading/item-skeleton";
import useWatchlists from "~/src/hooks/use-watchlists";

/**
 *
 * @param root0
 * @param root0.isLoading
 * @param root0.projects
 * @param root0.watchlist
 * @param root0.mutateWatchlist
 * @param root0.handleSingleMapProject
 */
export default function ProjectList({
  isLoading, projects = [], watchlist, mutateWatchlist, handleSingleMapProject = () => null
}) {
  const { mutate: mutateWatchlists } = useWatchlists();

  if (isLoading) {
    return (
      <div className="mt-2">
        <Skeleton />
      </div>
    );
  }

  if (projects.length === 0) {
    return null;
  }

  return (
    <div className="mt-2 lg:mx-12 bg-white shadow-sm border border-gray-200 rounded divide-y divide-gray-300 sm:divide-gray-300">

      {projects.map((project) => (
        <ProjectListItem
          key={`project_${project.slug}`}
          project={project}
          watchlist={watchlist}
          handleSingleMapProject={handleSingleMapProject}
          mutateWatchlist={mutateWatchlist}
          mutateWatchlists={mutateWatchlists}
        />
      ))}

    </div>
  );
}
