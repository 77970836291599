import { HomeIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

import SubNav from "../subnav";

import useAuth from "~/src/hooks/use-auth";
import Logo from "~/src/ui/logos/logo";
import BackLink from "~/src/ui/links/back-link";

/**
 *
 * @param props
 * @param props.fixed
 */
const Nav = ({ fixed = false }) => {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <nav id="main-nav-root" className={cn("inset-x-0 bg-primary z-[100] shadow sticky", {
      "fixed top-0": fixed
    })}>

      <div className="px-4 mx-auto">
        <div className="flex justify-between h-[48px] gap-2">
          <div className="flex w-full">
            <div className="flex items-center justify-start w-full gap-4 shrink-0">

              <Link to="/">
                <Logo minWidth={"hidden sm:block min-w-[14rem] max-w-[14rem]"} />
                <HomeIcon className="block w-5 h-5 text-white sm:hidden" />
              </Link>

              {location.pathname.split("/")[1].includes("projekte")
                ? <div className="flex h-full max-h-[36px] w-full justify-between pl-4">
                  <BackLink label="Projektliste" alternate="/projekte" />
                  <SubNav pageKey={`/${location.pathname.split("/")[1]}`} onlyMenu={true} />
                </div>
                : <div className="flex h-full max-h-[36px] w-full justify-start gap-4">
                  <SubNav pageKey={`/${location.pathname.split("/")[1]}`} searchKey={(!location.search.includes("slugs") ? location.search : "")} onlyMenu={false} />
                </div>
              }

            </div>
          </div>

          <div className="z-20 flex items-center gap-3 absolute -bottom-2 left-4">
            {
              [
                "dev",
                "test",
                "staging"
              ].includes(window.settings.REACT_APP_HOST_ENV) &&
              <div>
                <p className="px-2 text-xs font-medium text-white uppercase bg-blue-500 rounded y-1">{window.settings.REACT_APP_HOST_ENV}</p>
              </div>
            }
          </div>

        </div>
      </div>
    </nav>
  );
};

export default Nav;
