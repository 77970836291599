/* eslint-disable max-len */
import { DocumentChartBarIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { Temporal } from "@js-temporal/polyfill";

import useAuth from "~/src/hooks/use-auth";
import LoadingNotification from "~/src/features/notifications/loading-notification";
import useStore from "~/src/hooks/use-store";

const {
  Now
} = Temporal;

/**
 *
 */
const PdfExportProjectsButton = () => {
  const location = useLocation();

  const { user } = useAuth();

  const exporting = useStore((state) => state.exporting);
  const setExporting = useStore((state) => state.setExporting);

  const {
    id: userId
  } = user ?? {};

  const reportParams = new URLSearchParams(location.search);

  reportParams.set("userId", userId);

  if (!reportParams.has("page") || location.pathname !== "/liste") {
    reportParams.set("page", 1);
  }

  const reportUrl = `${window.settings.BIDRA_API_ENDPOINT}/report?${reportParams}`;

  const handleClick = async () => {
    setExporting(true);

    const toastId = toast(
      <LoadingNotification>
        PDF wird exportiert...
      </LoadingNotification>,
      {
        autoClose: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "!p-0 !bg-transparent !shadow-none !overflow-visible",
        bodyClassName: "!p-0 !overflow-visible",
        closeButton: false,
        draggable: false,
        transition: Slide,
        closeOnClick: false
      }
    );

    let response;

    try {
      response = await fetch(reportUrl);

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");

      link.href = url;

      const date = Now.plainDateISO();

      const standardDateString = date.toString();

      link.setAttribute("download", `Export_${standardDateString}_IMMOdeveloper.pdf`);

      document.body.appendChild(link);

      link.click();

      link.remove();

      window.URL.revokeObjectURL(url);

      toast.dismiss(toastId);

      setExporting(false);
    }
    catch (error) {
      toast.dismiss(toastId);

      setExporting(false);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <button
        data-tooltip-id="tooltip-pdf-export"
        data-tooltip-content="PDF-Export: bis zu 25 Projekte"
        className="flex items-center justify-center w-8 h-8 p-1 rounded-full cursor-pointer bg-primary disabled:grayscale disabled:cursor-wait hover:bg-primary-dark"
        onClick={handleClick}
        disabled={exporting}
      >
        <DocumentChartBarIcon className="w-full text-white" />
      </button>
      <Tooltip
        id="tooltip-pdf-export"
        place="bottom"
        className="z-50"
        delayShow={500}
      />
    </>
  );
};

export default PdfExportProjectsButton;
