import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Redirect } from "react-router-dom";

import DeleteWatchlistModal from "~/src/features/delete-watchlist-modal";
import EditWatchlistModal from "~/src/features/edit-watchlist-modal";
import NewWatchlistModal from "~/src/features/new-watchlist-modal";
import WatchlistItem from "~/src/features/watchlist-item";
import useWatchlists from "~/src/hooks/use-watchlists";
import Page from "~/src/ui/page";

/**
 *
 */
export default function WatchlistsPage() {
  const {
    watchlists, isError, mutate
  } = useWatchlists();
  const [
    showWatchlistNewModal,
    setWatchlistNewModal
  ] = useState(false);
  const [
    showWatchlistEditModal,
    setWatchlistEditModal
  ] = useState(false);
  const [
    showWatchlistDeleteModal,
    setWatchlistDeleteModal
  ] = useState(false);
  const [
    editableWatchlist,
    setEditableWatchlist
  ] = useState(null);
  const [
    deletableWatchlist,
    setDeletableWatchlist
  ] = useState(null);
  const [
    nameFilter,
    setNameFilter
  ] = useState("");

  if (isError) {
    <Redirect to="/error" />;
  }

  function handleEdit(watchlist) {
    setEditableWatchlist(watchlist);
    setWatchlistEditModal(true);
  }

  function handleDelete(watchlist) {
    setDeletableWatchlist(watchlist);
    setWatchlistDeleteModal(true);
  }

  return (
    <Page title="Merklisten">

      <NewWatchlistModal show={showWatchlistNewModal} hideModal={() => setWatchlistNewModal(false)} mutateWatchlists={mutate} />
      <EditWatchlistModal show={showWatchlistEditModal} watchlist={editableWatchlist} hideModal={() => setWatchlistEditModal(false)} mutateWatchlists={mutate} />
      <DeleteWatchlistModal show={showWatchlistDeleteModal} watchlist={deletableWatchlist} hideModal={() => setWatchlistDeleteModal(false)} mutateWatchlists={mutate} />

      <div className="xl:container mx-auto mt-8 px-4 lg:px-16 flex justify-between items-center">
        <div>
          <h2 className="text-3xl text-gray-700 font-medium">Merklisten</h2>
          <p className="mt-3 text-gray-500 text-lg">
            Organisieren Sie Projekte in eigenen Merklisten.
          </p>
        </div>

        <div>
          {watchlists?.length < 20 &&
            <>
              <button type="button" onClick={() => setWatchlistNewModal(true)} className="focus:outline-none flex items-center text-gray-700 underline hover:no-underline">
                Merkliste hinzufügen
              </button>
              <p className="mt-1 text-right text-sm  text-gray-500">{watchlists.length} von 20 Merklisten</p>
            </>
          }
          {watchlists?.length >= 20 &&
            <>
              <p className="text-right text-lg font-light text-gray-700">20 von 20 Merklisten</p>
              <p className="mt-1 text-right text-sm  text-gray-500">maximale Anzahl erreicht</p>
            </>
          }
        </div>
      </div>

      <div className="xl:container mx-auto px-4 py-5 lg:px-16">

        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          <div className="relative col-span-1">
            <input
              autoComplete="off"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              type="text"
              name="watchlist-filter"
              id="watchlist-filter"
              className="h-12 pl-10 focus:ring-0 focus:border-gray-800 hover:border-gray-800 block w-full border-gray-200 placeholder-gray-400 rounded-sm"
              placeholder="Merklisten filtern..."
            />
            <div className="absolute left-0 inset-y-0 pl-2 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="w-6 h-6 text-gray-400 " />
            </div>
          </div>
        </div>

        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {watchlists?.map((watchlist) => <WatchlistItem key={`watchlist_${watchlist.uuid}`} watchlist={watchlist} nameFilter={nameFilter} handleEdit={handleEdit} handleDelete={handleDelete} />)}
        </div>

      </div>

    </Page>
  );
}
