import {
  formatDate, formatEuro, formatNumber, formatNumberInt
} from "~/src/modules/formatters";

/**
 *
 * @param props
 * @param props.data
 * @param props.tooltipStructure
 * @param props.xyz
 */
export default function HoverTooltip({
  data,
  xyz = undefined,
  tooltipStructure
}) {
  const getTooltipElement = (tooltipPart) => {
    switch (tooltipPart) {
      case "percentage":
        return `Anteil: ${formatNumber(data.percentage)}%`;

      case "totalPercentage":
        return `Anteil: ${formatNumber(data.totalPercentage)}%`;

      case "label":
        return data.label;

      case "averageSquareMeterPrice":
        return `ø Preis/m² Netto: ${data.averageSquareMeterPrice ? `€ ${formatNumber(data.averageSquareMeterPrice)}` : "unbekannt"}`;

      case "amount":
        return `Anzahl: ${formatNumberInt(data.value)}`;

      case "top":
        return `${data.name}`;

      case "area":
        return `Fläche: ${data[xyz.zKey] ? `${formatNumber(data[xyz.zKey])} m²` : "unbekannt"}`;

      case "price":
        return `Preis: ${formatEuro(data[xyz.yKey])}`;

      case "date":
        return `Datum: ${formatDate({ date: data[xyz.xKey] })}`;

      default:
    }
  };

  return (
    <div
      className="flex flex-col gap-1 px-3 py-2 text-sm bg-white rounded border border-gray-200 shadow-sm absolute w-max"
      style={{ transform: "translate(-50%, -120%)" }}
    >
      {tooltipStructure.map((tooltipPart) => {
        switch (tooltipPart) {
          case "label":
            return (
              <div className="flex gap-2 items-center pt-1" key={tooltipPart}>
                <span
                  className="inline-block w-3 h-3 rounded-full"
                  style={{ backgroundColor: data.color }}
                ></span>
                <span>{getTooltipElement(tooltipPart)}</span>
              </div>
            );

          default:
            return <span className="flex gap-1" key={tooltipPart}>
              {getTooltipElement(tooltipPart)}
            </span>;
        }
      })}

    </div>

  );
}
