import useSWR from "swr";
import { stringify } from "query-string";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.query
 */
export default function useShareDeals({ query }) {
  const { data, error } = useSWR(query.type === "liegenschaft" ? `/share-deal-properties?${stringify(query, { arrayFormat: "comma" })}` : `/share-deal-transactions?${stringify(query, { arrayFormat: "comma" })}`, fetcher);

  return {
    shareDeals: data?.payload?.shareDeals,
    pagination: data?.payload?.pagination,
    sortDirection: data?.payload?.sortDirection,
    isLoading: !error && !data,
    isError: error
  };
}
