import { useEffect, useState } from "react";
import cn from "classnames";

import { formatMonth } from "~/src/modules/formatters";
import useTracking from "~/src/hooks/use-tracking";
import ShareDealsTransactionSlide from "~/src/features/share-deal-transaction-slide";

/**
 *
 * @param root0
 * @param root0.isLoading
 * @param root0.transactions
 * @param root0.loadingItems
 * @param root0.query
 */
export default function ShareDealsTransactionList({
  isLoading, transactions = [], loadingItems = 10, query
}) {
  const { trackPageView } = useTracking();
  const [
    showShareDealDetails,
    setShowShareDealDetails
  ] = useState(false);
  const [
    activeTransaction,
    setActiveTransaction
  ] = useState(false);
  const [
    activeColumn,
    setActiveColumn
  ] = useState("date");

  function handleShareDealClick(shareDeal) {
    setActiveTransaction(shareDeal);
    setShowShareDealDetails(true);
    trackPageView(shareDeal.doc_id, "ShareDeal");
  }

  function hideShareDealDetails() {
    setShowShareDealDetails(false);
  }

  useEffect(() => {
    if (query.sortByTransaction) {
      setActiveColumn(query.sortByTransaction);
    }
  }, [query.sortByTransaction]);

  return (
    <div>
      <ShareDealsTransactionSlide active={showShareDealDetails} hide={hideShareDealDetails} transaction={activeTransaction} />

      <div className="ml-4 sm:ml-0 mb-4 flex flex-col">
        <div id="scrollable-table" className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow-sm overflow-hidden border border-b border-gray-200 sm:rounded">

              <div className="min-w-full divide-y divide-gray-200">

                <div className="flex lg:grid grid-cols-12 items-center bg-gray-100">
                  <div className="col-span-1 w-20 lg:w-auto px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Datum
                  </div>
                  <div className="col-span-4 w-64 lg:w-auto px-4 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Firmenname
                  </div>
                  <div className="col-span-3 w-64 lg:w-auto px-4 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Firmenadresse
                  </div>
                  <div className="col-span-2 w-48 lg:w-auto px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Änderung
                  </div>
                  <div className="col-span-2 w-48 lg:w-auto px-4 py-3 text-right whitespace-nowrap text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Liegenschaften
                  </div>
                </div>

                <div className="bg-white divide-y divide-gray-200">
                  {isLoading &&
                    [...Array(loadingItems)].map((e, i) => (
                      <div key={`share_deal_transactions_skeleton_${i}`} className="h-20 flex lg:grid grid-cols-12 items-center hover:bg-gray-50 cursor-pointer">
                        <div className="w-20 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-4 w-64 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-3 w-64 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-3 w-56 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-1 w-48 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                      </div>
                    ))
                  }

                  {!isLoading && transactions?.length === 0 &&
                    <div className="bg-white">
                      <p className="px-4 py-4 whitespace-nowrap text-center font-light text-gray-900">keine Personen- und Anteilsänderungen für die ausgewählten Kriterien gefunden</p>
                    </div>
                  }

                  {transactions?.map((transaction) => (
                    <div key={transaction.id} className="flex lg:grid grid-cols-12 items-center hover:bg-gray-50 cursor-pointer" onClick={() => handleShareDealClick(transaction)}>

                      <div className={cn("w-20 lg:w-auto px-4 py-4 whitespace-nowrap text-sm", {
                        "text-primary font-bold": activeColumn === "date",
                        "text-gray-900 font-medium": activeColumn !== "date"
                      })}>{formatMonth(transaction.change_date)}</div>

                      <div className="col-span-4 w-64 lg:w-auto px-4 py-4 whitespace-nowrap text-sm text-gray-600 truncate">
                        <span className={cn("", {
                          "text-primary font-bold": activeColumn === "company"
                        })}>{transaction.company_name}</span>
                        <br />
                        <span className={cn("", {
                          "text-primary font-bold": activeColumn === "fbnr"
                        })}>{transaction.company_fbnr}</span>
                      </div>

                      <div className="col-span-3 w-64 lg:w-auto px-4 py-4 whitespace-nowrap text-sm text-gray-600 truncate">{transaction.company_zipcode} {transaction.company_city}, {transaction.company_street}</div>
                      <div className="col-span-3 w-56 lg:w-auto px-4 py-4 whitespace-nowrap text-sm text-gray-600">{transaction.category?.name}</div>
                      <div className="col-span-1 w-48 lg:w-auto px-4 py-4 whitespace-nowrap text-sm text-gray-600 text-center">
                        <span className={cn("", {
                          "text-primary font-bold": activeColumn === "propertyCount"
                        })}>{transaction.propertyCount}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
