import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.children
 * @param root0.project
 * @param root0.target
 * @param root0.backlink
 * @param root0.className
 * @param root0.inNewTab
 */
export default function ProjectLink({
  children, project, target, backlink = null, inNewTab = false, className
}) {
  const { pathname, search } = useLocation();

  const linkOptions = {
    pathname: `/projekte/${project.slug}`,
    state: {
      project,
      backlink,
      pathname,
      search
    }
  };

  return (
    <Link to={linkOptions} target={(inNewTab) ? "_blank" : target} className={cn("focus:outline-none detail-link", className)}>
      {children}
    </Link>
  );
}
