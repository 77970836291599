/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable import/max-dependencies */
import {
  useEffect,
  useState
} from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "react-tooltip";
import { useLocation } from "react-router-dom";
import cn from "classnames";

import LegendBox from "../legend-box/index.jsx";
import EsgCertificatesFilterModal from "../esg-certificate-filter-modal/index.jsx";

import {
  deleteFilterQueries,
  updateQuery,
  updateQueryFromTemp
} from "~/src/modules/query-helper.js";
import AddressSelect from "~/src/ui/selects/address-select/index.jsx";
import CompanySelect from "~/src/ui/selects/company-select/index.jsx";
import FilterButton from "~/src/ui/buttons/filter-button/index.jsx";
import ProjectFilterTags from "~/src/features/filter-tags/project-filter-tags/index.jsx";
import ProjectSelect from "~/src/ui/selects/project-select/index.jsx";
import RadiusDropdown from "~/src/features/radius-dropdown/index.jsx";
import RegionFilterModal from "~/src/features/region-filter-modal/index.jsx";
import SearchFilterModals from "~/src/features/search-filter-modals/index.jsx";
import { parseActiveFilters } from "~/src/modules/search-filter-helper.js";
import useFilterModals from "~/src/hooks/use-filter-modals.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useMediaQuery from "~/src/hooks/use-media-query.js";
import CollapsibleContainer from "~/src/ui/containers/collapsible-container.jsx";
import useStore from "~/src/hooks/use-store.js";

/**
 *
 * @param props
 * @param props.className
 */
const ProjectsFiltersTile = ({ className = "hidden lg:flex" }) => {
  const { query, setQuery } = useProjectQueryParams();

  const isMobile = useMediaQuery("(max-width: 1023px)");
  const isLaptop = useMediaQuery("(max-width: 1535px)");

  const [
    automated,
    setAutomated
  ] = useState(false);

  const getActiveFilter = () => {
    const active = parseActiveFilters(query);

    return active;
  };

  const { pathname } = useLocation();

  const queryAutomated = (typeof query.automated === "boolean")
    ? query.automated
    : false;

  const onChange = (currentAutomated) => {
    setQuery({
      automated: currentAutomated,
      page: 1
    });
  };

  useEffect(() => {
    setAutomated(queryAutomated);
  }, [queryAutomated]);

  const [
    tempQuery,
    setTempQuery
  ] = useState();

  const filterModals = useFilterModals();

  const handleCheckbox = ({ target: { checked } }) => {
    onChange(checked);
  };

  const updateTempQuery = (key, value) => {
    const updatedQuery = {
      ...tempQuery,
      [key]: value
    };

    setTempQuery(updatedQuery);
  };

  const handleSubmitQuery = () => {
    const updatedQuery = updateQueryFromTemp(query, tempQuery);

    setQuery(updatedQuery);
    setTempQuery({});
  };

  const cancelRegionFilter = () => {
    const updatedQuery = {
      ...tempQuery
    };

    delete updatedQuery.region;
    setTempQuery(updatedQuery);
  };

  const cancelEsgCertificatesFilter = () => {
    const updatedQuery = {
      ...tempQuery
    };

    delete updatedQuery.certificates;
    setTempQuery(updatedQuery);
  };

  const cancelSearchFilter = () => {
    const updatedQuery = {
      ...tempQuery
    };

    delete updatedQuery.finish_year;
    delete updatedQuery.construction_phase;
    delete updatedQuery.marketing_phase;
    delete updatedQuery.rechtsform;
    delete updatedQuery.quantity;
    delete updatedQuery.foerderung;
    delete updatedQuery.construction_type;
    delete updatedQuery.polygon;
    setTempQuery(updatedQuery);
  };

  const handleDirectSubmit = (filter, value) => {
    const updatedQuery = updateQuery(query, filter, value);

    setQuery(updatedQuery, "replace");

    if (![
      "page",
      "direction",
      "sort"
    ].includes(filter)) {
      // handleAllChecked(true)
    }
  };

  const handleRadiusChange = (radius) => {
    setQuery({ radius });
  };

  const handleClearFilters = () => {
    const updatedQuery = deleteFilterQueries(query);

    setQuery(updatedQuery, "replace");
  };

  const filterTileCollapsed = useStore((state) => state.filterTileCollapsed);
  const setFilterTileCollapsed = useStore((state) => state.setFilterTileCollapsed);

  const userClickedFilterTileCollapse = useStore((state) => state.userClickedFilterTileCollapse);
  const setUserClickedFilterTileCollapse = useStore((state) => state.setUserClickedFilterTileCollapse);

  // only use this if filter tile should be collapsed on laptop by default
  // useEffect(() => {
  //   if (!userClickedFilterTileCollapse) {
  //     setFilterTileCollapsed(isLaptop);
  //   }
  // }, [isLaptop]);

  return (
    <CollapsibleContainer
      position="right"
      className={cn(
        "h-full shrink-0",
        {
          "bg-white p-3.5 border border-zinc-200 rounded w-[280px]": !isMobile
        },
        className
      )}
      collapsedClassName="h-full w-0"
      collapsed={isMobile ? false : filterTileCollapsed}
      onChange={(collapsed) => {
        setFilterTileCollapsed(collapsed);

        setUserClickedFilterTileCollapse(true);
      }}
      tooltipTexts={{
        collapsed: "Filter anzeigen",
        expanded: "Filter ausblenden"
      }}
      active={!isMobile}
    >
      <RegionFilterModal
        active={filterModals.regionFilterModal}
        hide={() => filterModals.setRegionFilterModal(false)}
        queryRegion={query.region}
        tempQuery={tempQuery}
        update={updateTempQuery}
        submit={handleSubmitQuery}
        cancelRegionFilter={cancelRegionFilter}
      />

      <EsgCertificatesFilterModal
        active={filterModals.esgCertificatesFilterModal}
        hide={() => filterModals.setEsgCertificatesFilterModal(false)}
        queryEsg={query.certificates}
        tempQuery={tempQuery}
        update={updateTempQuery}
        submit={handleSubmitQuery}
        cancelEsgFilter={cancelEsgCertificatesFilter}
      />

      <SearchFilterModals
        query={query}
        tempQuery={tempQuery ?? {}}
        update={updateTempQuery}
        submit={handleSubmitQuery}
        cancelSearchFilter={cancelSearchFilter}
        filterModals={filterModals}
      />

      <div id="filters-tile-flex-container" className="flex w-full h-full text-sm justify-items-stretch">
        <div className="flex flex-col items-center w-full h-full gap-2">
          <div className="w-full">
            <ProjectSelect handleUpdate={handleDirectSubmit} />
          </div>
          <div className="w-full">
            <CompanySelect company={tempQuery?.company} handleUpdate={handleDirectSubmit} />
          </div>
          <div className="relative flex flex-col justify-center w-full ">
            <AddressSelect street={tempQuery?.street} handleUpdate={handleDirectSubmit} />
            {(query?.locationType === "street_address") &&
            <RadiusDropdown
              className="absolute right-3"
              onChange={handleRadiusChange}
              radius={query.radius || 2}
            />
            }
          </div>

          <div className="flex flex-col justify-center w-full gap-5 mt-2">
            <div className="grid w-full grid-cols-2 gap-x-4 gap-y-4">
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Region" onClick={() => filterModals.setRegionFilterModal(true)} />
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Fertigstellung" onClick={() => filterModals.setFinishYearModal(true)} />
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Projektphase" onClick={() => filterModals.setProjectPhaseModal(true)} />
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Projektgröße" onClick={() => filterModals.setQuantityModal(true)} />
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Eigentum/Miete" onClick={() => filterModals.setRechtsformModal(true)} />
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Förderung" onClick={() => filterModals.setFundingModal(true)} />
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Liegenschaft" onClick={() => filterModals.setTransactionModal(true)} />
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Bautyp" onClick={() => filterModals.setConstructionTypeModal(true)} />
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Objektart" onClick={() => filterModals.setObjectTypeModal(true)} />
              <FilterButton fontSize="text-xs" customWidth={"w-full px-1"} title="Gebäudezertifikat" onClick={() => filterModals.setEsgCertificatesFilterModal(true)} />
            </div>
          </div>

          <div className="flex items-center justify-start w-full gap-2 mt-2">
            <div className="flex items-start">
              <label htmlFor="automated" className="flex items-center gap-1 cursor-pointer">
                <span>Historische Projekte anzeigen</span>
              </label>
              <div>
                <InformationCircleIcon
                  data-tooltip-id="tooltip-historic-projects"
                  data-tooltip-html={"Historische Projekte kennzeichnen sich durch mindestens drei<br>Transaktionen desselben Unternehmens innerhalb einer Einlage<br>bis zum Release von „IMMOdeveloper“ (2021)."}
                  className="w-4 h-4 -mt-1 text-gray-500 focus:outline-none hover:text-gray-600"
                />

                <Tooltip
                  id="tooltip-historic-projects"
                  place="top"
                  className="z-50"
                  delayShow={500}
                />
              </div>

            </div>
            <input type="checkbox" id="automated" name="automated" onChange={handleCheckbox} checked={typeof automated === "boolean" ? automated : false} className="w-4 h-4 border border-gray-400 rounded cursor-pointer text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />

          </div>

          <div id="filter-tags-container" className="w-full mt-2 overflow-y-auto">
            <ProjectFilterTags query={query} submit={handleDirectSubmit} filterModals={filterModals} />
          </div>
          {getActiveFilter().length > 0 &&
            <button type="button" onClick={handleClearFilters} className="flex self-start ml-0.5 text-xs font-semibold tracking-tight flex-non focus:outline-none hover:underline text-primary ">
              Filter zurücksetzen
            </button>
          }
          <div className="flex-1"/>
          {
            [
              "/",
              "/karte"
            ].includes(pathname) && (
              <LegendBox className="hidden sticky w-full h-[152px] mt-2 lg:flex !pl-0"/>
            )
          }
        </div>
      </div>
    </CollapsibleContainer>
  );
};

export default ProjectsFiltersTile;
