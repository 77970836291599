import { Disclosure, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

/**
 *
 * @param root0
 * @param root0.id
 * @param root0.name
 * @param root0.checked
 * @param root0.indeterminate
 * @param root0.regions
 * @param root0.updateStateRegions
 */
export default function RegionFilterState({
  id,
  name,
  checked,
  indeterminate,
  regions,
  updateStateRegions
}) {
  const [
    showRegions,
    setShowRegions
  ] = useState(false);

  const handleStateCheck = (e, regions) => {
    updateStateRegions(id, regions.map((region) => ({
      ...region,
      checked: e.target.checked
    })));
  };

  const handleCheck = (e, regionId) => {
    const regionIndex = regions.map(({ id }) => id).indexOf(regionId);

    const newRegions = [...regions];

    newRegions[regionIndex] = {
      ...newRegions[regionIndex],
      checked: e.target.checked
    };

    updateStateRegions(id, newRegions);
  };

  return (
    <div className="py-8">
      <Disclosure>
        <div className="flex justify-between items-center">
          <label className="inline-flex items-center cursor-pointer text-xl font-medium">
            <input checked={checked || indeterminate} ref={(el) => el && (el.indeterminate = indeterminate)} onChange={(e) => handleStateCheck(e, regions)} type="checkbox" className="cursor-pointer w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-4">{name}</span>
          </label>

          <div className="pl-2">
            <Disclosure.Button as={Fragment}>
              <button onClick={() => setShowRegions((prev) => !prev)} type="button" className="font-light underline flex items-center focus:outline-none">
                {!showRegions &&
                  <>
                    <span>Bezirke anzeigen</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </>
                }
                {showRegions &&
                  <>
                    <span>Bezirke zuklappen</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                    </svg>
                  </>
                }
              </button>
            </Disclosure.Button>
          </div>
        </div>
        <Transition
          show={showRegions}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 font-light">
              {
                regions.map(({
                  id,
                  name,
                  checked
                }) => (
                  <div key={`region_${id}`} className="pl-2">
                    <label className="inline-flex items-center cursor-pointer">
                      <input checked={checked} onChange={(e) => handleCheck(e, id)} type="checkbox" className="cursor-pointer w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
                      <span className="ml-3">{name}</span>
                    </label>
                  </div>
                ))
              }
            </div>
          </Disclosure.Panel>
        </Transition>
      </Disclosure>
    </div>
  );
}
