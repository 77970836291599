/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { useEffect, useState } from "react";
import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.changeKey
 * @param root0.title
 * @param root0.showCustom
 * @param root0.hasChanges
 * @param root0.defaultValue
 * @param root0.storedValue
 * @param root0.changedValue
 * @param root0.updateChangedValue
 */
export default function AreaSettingsSliderContainer({
  changeKey,
  title,
  showCustom = false,
  hasChanges = false,
  defaultValue,
  storedValue,
  changedValue,
  updateChangedValue = () => null
}) {
  const [
    value,
    setValue
  ] = useState(defaultValue);
  const [
    textVal,
    setTextVal
  ] = useState(null);

  const disabled = !showCustom;

  const handleSliderChange = (event) => {
    updateChangedValue(changeKey, event.target.value);
  };

  const handleTextChange = (event) => {
    const newValue = event.target.value;

    if (newValue === "") {
      setTextVal("");

      return;
    }

    const numericValue = parseFloat(newValue);

    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
      setTextVal(numericValue);
    }
  };

  const handleTextBlur = (event) => {
    event.target.value === ""
      ? updateChangedValue(changeKey, 0)
      : updateChangedValue(changeKey, event.target.value || 0);
    setTextVal(null);
  };

  useEffect(() => {
    if (showCustom) {
      hasChanges
        ? setValue(changedValue)
        : setValue(storedValue);
    }
    else {
      setValue(defaultValue);
    }
  }, [
    showCustom,
    hasChanges,
    defaultValue,
    storedValue,
    changedValue
  ]);

  const evaluateValue = () => {
    if (value || value === 0) {
      return value;
    }

    return showCustom
      ? (changedValue || storedValue)
      : storedValue;
  };

  return (
    <div className="h-20 sm:h-12 px-4 grid grid-cols-8 bg-gray-100 rounded items-center">

      <div className="col-span-8 sm:col-span-2">
        <p>{title}</p>
      </div>

      <div className="mt-1 col-span-6 sm:col-span-5 flex items-center">
        <input
          disabled={disabled}
          type="range"
          min="0" max="100"
          value={evaluateValue()}
          step={1}
          onChange={handleSliderChange}
          className={cn("appearance-none w-full outline-none focus:ring-offset-primary focus:ring-2 h-1 bg-secondary-dark rounded", {
            "disabled-slider cursor-not-allowed": disabled,
            "slider cursor-pointer": !disabled
          })} />
      </div>

      <div className="mt-0.5 flex justify-end col-span-2 sm:col-span-1">
        <div className="text-xl font-medium text-gray-500 flex items-center">
          {disabled
            ? (
              <span>{value}</span>
            )
            : (
              <input
                value={(textVal || textVal === "") ? textVal : value}
                type="number"
                min={0}
                max={100}
                onChange={handleTextChange}
                onBlur={handleTextBlur}
                className="shadow-sm focus:ring-primary focus:border-primary block w-12 h-8 py-0 pl-1 pr-1.5 text-right text-md border-gray-300 rounded-md font-medium"
              />
            )}

          <span className="text-sm ml-2">%</span>
        </div>
      </div>

    </div>
  );
}
