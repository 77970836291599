import "core-js/actual/array/index.js";
import "@changey/react-leaflet-markercluster/dist/styles.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import { Intl as PolyFilledIntl, toTemporalInstant } from "@js-temporal/polyfill";
import { HelmetProvider } from "react-helmet-async";
import { SWRConfig } from "swr";
import { createRoot } from "react-dom/client";

import App from "./App";
import DashboardProvider from "./contexts/dashboard-context";
import ModalProvider from "./contexts/modal-context";
import NotificationContainer from "./features/notifications/notification-container";
import NotificationProvider from "./contexts/notification-context";
import SuccessNotification from "./features/notifications/success-notification";
import {
  errorHasHttpStatus,
  getHttpErrorResponseBody,
  getHttpErrorRelativeUrl
} from "./lib/error";
import httpStatus from "./lib/http-status";

Date.prototype.toTemporalInstant = toTemporalInstant;

Intl.DateTimeFormat = PolyFilledIntl.DateTimeFormat;

const container = document.getElementById("root");
const root = createRoot(container);

const ignoredEndpoints = [
  "/auth/session",
  "/selections",
  "/regions",
  "/account-regions"
];

root.render(
  <HelmetProvider>
    <SWRConfig value={{
      onError: (error, key) => {
        if (errorHasHttpStatus({
          error,
          status: httpStatus.forbidden
        })) {
          const errorResponseBody = getHttpErrorResponseBody({ error });
          const logoutUrl = errorResponseBody?.data?.payload?.logoutUrl;
          const relativeUrl = getHttpErrorRelativeUrl({ error });

          const doLogin = !ignoredEndpoints.some((endpoint) => relativeUrl.includes(endpoint)) && !window.location.pathname.startsWith("/print");

          if (logoutUrl) {
            window.location.replace(logoutUrl);
          }
          else if (doLogin) {
            const newUrl = new URL(window.location.href);

            newUrl.pathname = "/login";

            newUrl.search = "";

            newUrl.hash = "";

            window.location.replace(newUrl);
          }
        }
      }
    }}>
      <NotificationProvider>
        <ModalProvider>
          <DashboardProvider>
            <App />
            <NotificationContainer>
              <SuccessNotification />
            </NotificationContainer>
          </DashboardProvider>
        </ModalProvider>
      </NotificationProvider>
    </SWRConfig>
  </HelmetProvider>
);

navigator.serviceWorker
  .register(
    new URL("../public/service-worker.js", import.meta.url),
    { type: "module" }
  );
