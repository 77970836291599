import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { shareDealPropertySortLabels } from "~/src/modules/labels";

/**
 *
 * @param root0
 * @param root0.sortBy
 * @param root0.onSubmit
 */
export default function SortShareDealPropertiesDropdown({ sortBy = "date", onSubmit }) {
  return (
    <Menu as="div" className="relative ml-8">
      <Menu.Button className="w-full h-10 inline-flex border-b border-secondary-light justify-center items-center text-base font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        {shareDealPropertySortLabels[sortBy]}
        <ChevronDownIcon className="ml-0.5 -mr-1 h-5 w-5 text-gray-500" aria-hidden="true" />
      </Menu.Button>
      <Menu.Items className="origin-top-right z-10 absolute right-0 -mt-0.5 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1 w-full">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "date" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "date"
                })}
              >
                <span>Datum</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "kg" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "kg"
                })}
              >
                KG
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "ez" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "ez"
                })}
              >
                EZ
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "zipcode" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "zipcode"
                })}
              >
                PLZ (Liegenschaft)
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "street" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "street"
                })}
              >
                Straße (Liegenschaft)
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "company" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "company"
                })}
              >
                Eigentümer
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "fbnr" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "fbnr"
                })}
              >
                Fb-Nr. Eigentümer
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortBy: "area" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortBy === "area"
                })}
              >
                Fläche EZ
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
