import useSWR from "swr";
import { stringify } from "query-string";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.id
 * @param root0.query
 */
export default function useWatchlist({ id, query }) {
  const {
    data, error, mutate
  } = useSWR(`/watchlists/${id}?${stringify(query)}`, fetcher);

  return {
    watchlist: data?.payload?.watchlist,
    projects: data?.payload?.projects,
    pagination: data?.payload?.pagination,
    sortDirection: data?.payload?.sortDirection,
    isLoading: !error && !data,
    error,
    mutate
  };
}
