import { Switch } from "@headlessui/react";
import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.dateType
 * @param root0.onChange
 */
export default function PurchaseDateToggle({ dateType = "dateVerbuecherung", onChange = () => null }) {
  const right = dateType === "dateVerbuecherung";

  function handleChange(val) {
    const query = val ? "dateKV" : "dateVerbuecherung";

    onChange({
      dateType: query,
      sortBy: query
    });
  }

  return (
    <Switch.Group as="div" className="flex items-center lg:justify-between">
      <span className="lg:grow flex flex-col mr-3">
        <button onClick={() => handleChange(false)} className={cn("text-sm font-medium text-gray-600", {
          "cursor-default pointer-events-none": right
        })}>
          Verbücherung
        </button>
      </span>
      <Switch
        checked={!right}
        onChange={handleChange}
        className={cn(
          !right ? "bg-secondary-dark" : "bg-gray-200",
          "relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-transparent"
        )}
      >
        <span
          aria-hidden="true"
          className={cn(
            !right ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
      <span className="ml-3 lg:grow flex flex-col">
        <button onClick={() => handleChange(true)} className={cn("focus:outline-none text-sm font-medium text-gray-600", {
          "cursor-default pointer-events-none": !right
        })}>
          KV-Datum
        </button>
      </span>
    </Switch.Group>
  );
}
