import { permuteArrayIndex } from "~/src/lib/standard";

/**
 *
 * @param keyLabelcomponents
 * @param keyComponentPositions
 */
const getKeyComponents = (keyLabelcomponents, keyComponentPositions) => {
  const keyComponents = keyLabelcomponents.map(
    ([keyComponent]) => keyComponent
  );

  return keyComponentPositions
    ? permuteArrayIndex(keyComponents, keyComponentPositions)
    : keyComponents;
};

export default getKeyComponents;
