import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 * SWR hook to get project statistics.
 *
 * @param {object} options - Options object.
 * @param {string} options.projectId - Project slug, unique project identifier.
 * @param {string[]} options.types - Types of statistics to query for. Specifying multiple types
 * will return a combined result, and in the case of `structure` being `composition`, the
 * result will be a nested object, where each category has a `sub` property with the subcategories.
 * The order of the types specified will determine the "hierarchy" of these nested objects.
 * @param {"composition"|"correlation"} options.structure - Structure of the statistics.
 * @example
 * const { statistics, isLoading, isError, mutate } = useProjectStatistics({
 *  projectId: "my-project",
 *  types: ["units-buyers-gender", "units-buyers-age"],
 *  structure: "composition"
 * });
 * @return {object} The payload from the request.
 */
export default function useProjectStatistics({
  projectId,
  types,
  structure
}) {
  const params = new URLSearchParams({
    types,
    structure
  });

  const {
    data,
    error,
    mutate
  } = useSWR(`/projects/${projectId}/statistics?${params}`, fetcher);

  return {
    statistics: data?.payload?.statistics,
    isLoading: !error && !data,
    isError: error || data === "",
    mutate
  };
}
