import cn from "classnames";
import { Tooltip } from "react-tooltip";

/**
 *
 * @param root0
 * @param root0.className
 * @param root0.styles
 * @param root0.innerClassName
 */
export default function EsgIcon({ className, innerClassName }) {
  return (
    <>
      <div className="relative"
        data-tooltip-id={"tooltip-esg-test"}
        data-tooltip-content="Gebäudezertifikat vorhanden"
      >
        <div
          className={cn(
            "w-6 h-6 rounded-full bg-esg-green flex items-center justify-center",
            className
          )}
        >
          <svg version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="129.000000pt"
            height="141.000000pt"
            viewBox="0 0 129.000000 141.000000"
            preserveAspectRatio="xMidYMid meet"
            className={cn(
              "w-4 h-4",
              innerClassName
            )}
          >
            <g transform="translate(0.000000,141.000000) scale(0.100000,-0.100000)"
              fill="#FFF" stroke="none">
              <path d="M71 1368 c-138 -520 -56 -955 221 -1178 71 -57 171 -113 260 -145 38   -14 73 -25 79 -25 32 0 -14 285 -77 483 -65 202 -174 449 -231 525 -48 64 -2   30 67 -50 89 -103 150 -194 215 -320 l51 -98 27 97 c15 54 47 142 71 196 l44   97 -74 79 c-119 127 -294 236 -555 346 -46 19 -84 35 -85 35 -1 0 -6 -19 -13   -42z"/>
              <path d="M1012 1096 c-147 -158 -259 -361 -297 -542 -19 -89 -19 -239 0 -329   16 -75 53 -175 65 -175 23 0 86 107 129 220 39 102 101 349 101 402 0 49 16   49 24 1 25 -154 -29 -424 -121 -606 -13 -27 -22 -51 -20 -54 8 -7 65 24 133   73 300 212 341 562 113 965 -69 123 -58 119 -127 45z"/>
            </g>
          </svg>
        </div>
      </div>
      <Tooltip
        id="tooltip-esg-test"
        place="bottom"
        className="z-50"
        delayShow={500}
      />
    </>

  );
}
