import { formatNumber } from "~/src/modules/formatters";

/**
 *
 * @param root0
 * @param root0.val
 * @param root0.area
 * @param root0.hideDash
 */
const UnitTd = ({
  val, area = false, hideDash = false
}) => {
  if (area) {
    return (
      <span className={`${val ? "font-bold text-gray-500" : ""}`}>
        {val ? `${formatNumber(val)} m²` : <span className={`${hideDash && "text-white"}`}>-</span>}
      </span>
    );
  }

  return (
    <span className={`${val ? "font-bold text-gray-500" : ""}`}>
      {val || <span className={`${hideDash && "text-white"}`}>-</span>}
    </span>
  );
};

export default UnitTd;
