import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

/**
 *
 * @param props
 * @param props.data
 * @param props.changeDisplayWithChild
 * @param props.sortByValue
 * @param props.small
 * @param props.name
 */
export default function PieChart({
  data = [],
  changeDisplayWithChild = () => {},
  sortByValue = true,
  small = false,
  ...props
}) {
  const [
    animatedData,
    setAnimatedData
  ] = useState([]);

  const defineArcLabel = (data) => `${data.label}`;

  const commonProperties = (small)
    ? {
      margin: {
        top: 125,
        right: 145,
        bottom: 165,
        left: 165
      },
      innerRadius: 0.82,
      enableArcLabels: false,
      enableArcLinkLabels: false,
      borderColor: "#ffffff",
      borderWidth: 1
    }
    : {
      activeInnerRadiusOffset: 0,
      activeOuterRadiusOffset: 7,
      arcLabelsSkipAngle: 10,
      arcLabelsTextColor: "#ffffff",
      arcLinkLabel: (datum) => (datum.label.match(/ \| /gu)
        ? (
          <tspan>
            {datum.label.split(" | ").map((part, index) => <tspan x="0" dy={`${-0.75 + (index * 2)}em`}>{part}</tspan>)}
          </tspan>
        )
        : datum.label),
      arcLinkLabelsColor: { from: "color" },
      arcLinkLabelsSkipAngle: 16,
      arcLinkLabelsTextColor: "#333333",
      arcLinkLabelsThickness: 2,
      borderColor: "#ffffff",
      borderWidth: 1,
      innerRadius: 0.5,
      margin: {
        top: 40,
        right: 80,
        bottom: 80,
        left: 100
      },
      theme: {
        labels: {
          text: {
            fontSize: 12
          }
        }
      }
    };

  const updatedProperties = {
    ...commonProperties,
    ...props.props
  };

  useEffect(() => {
    // Delay setting the animated data to create the initial animation
    const timeoutId = setTimeout(() => setAnimatedData(data), 1);

    // Cleanup function
    return () => clearTimeout(timeoutId);
  }, [data]);

  return (
    <ResponsivePie
      data={animatedData}
      colors={{ datum: "data.color" }}
      animate={true}
      motionConfig={"slow"}
      arcLinkLabel={(data) => defineArcLabel(data)}
      sortByValue={sortByValue}
      onClick = {changeDisplayWithChild}
      {...updatedProperties}
      tooltip={(datum) => datum.datum.data.tooltip}
    />
  );
}

/*
      onMouseEnter = {() => {
        changeDisplayWithChild();
      }}
      onMouseLeave = {() => {
        changeDisplayWithChild();
      }}
*/
