import { useParams } from "react-router-dom";
import React from "react";

import ContentSection from "~/src/ui/sections/content-section";
import { formatArea } from "~/src/modules/formatters";
import useProject from "~/src/hooks/use-project";

/**
 *
 */
// eslint-disable-next-line max-lines-per-function
const ProjectOverviewProperties = () => {
  const { id } = useParams();
  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    einlagen
  } = project;

  const hasBaurecht = Object.entries(einlagen).some(([
    , einlage
  ]) => einlage.baurecht);

  return (
    // eslint-disable-next-line no-nested-ternary
    (einlagen.length)
      ? (hasBaurecht)
        ? <ContentSection id="properties" title="Liegenschaften">
          <div className="grid grid-cols-6 md:grid-cols-9 gap-x-8">
            <span className="font-bold col-span-1 md:col-span-2">BG</span>
            <span className="font-bold col-span-1 md:col-span-2">KG</span>
            <span className="font-bold col-span-1">EZ</span>
            <span className="font-bold col-span-1">BREZ</span>
            <span className="font-bold col-span-1">Fläche</span>
            <span className="font-bold col-span-1 md:col-span-2">GST-Nr.</span>
            {
              einlagen
                .map((einlage) => Object.fromEntries(Object.entries(einlage).filter(([
                  key,
                  value
                ]) => value !== null)))
                .map(({
                  id: einlageId,
                  area,
                  ez_number: ez,
                  gst_numbers: gstNumbers,
                  katastralgemeinde: {
                    name: katastralgemeindeName,
                    code: katastralgemeindeCode,
                    bezirksgericht: {
                      name: bezirksgerichtName,
                      code: bezirksgerichtCode
                    }
                  },
                  baurecht,
                  br_katastralgemeinde: {
                    name: brKatastralgemeindeName,
                    code: brKatastralgemeindeCode,
                    bezirksgericht: {
                      name: brBezirksgerichtName,
                      code: brBezirksgerichtCode
                    } = {}
                  } = {},
                  brez_number: brezNumber
                }) => (
                  <React.Fragment key={einlageId}>
                    <span className="col-span-1 md:col-span-2 break-words">
                      {bezirksgerichtName} ({bezirksgerichtCode})
                    </span>
                    <span className="col-span-1 md:col-span-2 break-words">
                      {katastralgemeindeName} ({katastralgemeindeCode})
                    </span>
                    <span className="col-span-1">
                      {ez}
                    </span>
                    <span className="col-span-1">
                      {brezNumber || ""}
                    </span>
                    <span className="col-span-1 md:whitespace-nowrap text-right">
                      {formatArea(area)}
                    </span>
                    <span className="col-span-1 md:col-span-2">
                      {gstNumbers.join(", ")}
                    </span>
                  </React.Fragment>
                ))
            }

          </div>
        </ContentSection>
        : <ContentSection id="properties" title="Liegenschaften">
          <div className="grid grid-cols-5 md:grid-cols-8 gap-x-8">
            <span className="font-bold col-span-1 md:col-span-2">BG</span>
            <span className="font-bold col-span-1 md:col-span-2">KG</span>
            <span className="font-bold col-span-1">EZ</span>
            <span className="font-bold col-span-1">Fläche</span>
            <span className="font-bold col-span-1 md:col-span-2">GST-Nr.</span>
            {
              einlagen
                .map((einlage) => Object.fromEntries(Object.entries(einlage).filter(([
                  key,
                  value
                ]) => value !== null)))
                .map(({
                  id: einlageId,
                  area,
                  ez_number: ez,
                  gst_numbers: gstNumbers,
                  katastralgemeinde: {
                    name: katastralgemeindeName,
                    code: katastralgemeindeCode,
                    bezirksgericht: {
                      name: bezirksgerichtName,
                      code: bezirksgerichtCode
                    }
                  },
                  baurecht,
                  br_katastralgemeinde: {
                    name: brKatastralgemeindeName,
                    code: brKatastralgemeindeCode,
                    bezirksgericht: {
                      name: brBezirksgerichtName,
                      code: brBezirksgerichtCode
                    } = {}
                  } = {},
                  brez_number: brezNumber
                }) => (
                  <React.Fragment key={einlageId}>
                    <span className="col-span-1 md:col-span-2 break-words">
                      {bezirksgerichtName} ({bezirksgerichtCode})
                    </span>
                    <span className="col-span-1 md:col-span-2 break-words">
                      {katastralgemeindeName} ({katastralgemeindeCode})
                    </span>
                    <span className="col-span-1">
                      {ez}
                    </span>
                    <span className="col-span-1 md:whitespace-nowrap">
                      {formatArea(area)}
                    </span>
                    <span className="col-span-1 md:col-span-2">
                      {gstNumbers.join(", ")}
                    </span>
                  </React.Fragment>
                ))
            }

          </div>
        </ContentSection>
      : <ContentSection id="properties" title="Liegenschaften">
      -
      </ContentSection>
  );
};

export default ProjectOverviewProperties;
