import API from "~/src/modules/api";

/**
 *
 * @param name
 * @param handleSuccess
 * @param handleError
 */
export function handleCreate(name, handleSuccess, handleError) {
  API.post("/watchlists", {
    watchlist: {
      name
    }
  }).then((res) => {
    handleSuccess();
  }).catch((e) => {
    handleError(e.response.status);
  });
}
