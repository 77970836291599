import { useParams } from "react-router-dom";
import React from "react";

import NoteElement from "./note-element";

import ContentSection from "~/src/ui/sections/content-section";
import useProject from "~/src/hooks/use-project";
import useMediaQuery from "~/src/hooks/use-media-query";
import { formatNumberInt } from "~/src/modules/formatters";

/**
 *
 */
// eslint-disable-next-line max-lines-per-function
const ProjectESGCertificates = () => {
  const { id } = useParams();

  const isMobile = useMediaQuery("(max-width: 1279px)");

  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    project_certificates: projectCertificates
  } = project;

  if (isMobile) {
    return (projectCertificates.length > 0)
      ? <ContentSection id="esgCertificates" title="Gebäudezertifikate">
        {
          projectCertificates
            .map((certificate) => Object.fromEntries(Object.entries(certificate).filter(([
              key,
              value
            ]) => value !== null)))
            .map(({
              id: certificateId,
              issuer: {
                name: issuerName,
                max_points: maxPoints
              } = { },
              assigned_points: assignedPoints,
              issuer_certificate: {
                name: certificateName
              } = { },
              notes
            }) => (
              <div key={`certificate_${certificateId}`} className="flex flex-col w-full gap-2">
                <div className="grid grid-cols-3 gap-x-8">
                  <span className="col-span-1 font-bold md:col-span-1">Aussteller</span>
                  <span className="col-span-1 font-bold md:col-span-1">Zertifikat</span>
                  <span className="col-span-1 font-bold">Punkte</span>
                  <span className="col-span-1 break-words">
                    {(issuerName)
                      ? issuerName
                      : "-"}
                  </span>
                  <span className="col-span-1 break-words">
                    {(certificateName)
                      ? certificateName
                      : "-"}
                  </span>
                  <span className="col-span-1">
                    {(assignedPoints && maxPoints)
                      ? `${formatNumberInt(assignedPoints)}/${formatNumberInt(maxPoints)}`
                      : "-"}
                  </span>
                </div>
                <NoteElement id={`cert_note_${certificateId}`} notes={notes}/>
              </div>

            ))}
      </ContentSection>
      : <ContentSection id="esgCertificates" title="Gebäudezertifikate">
        -
      </ContentSection>;
  }

  return (
    (projectCertificates.length)
      ? <ContentSection id="esgCertificates" title="Gebäudezertifikate">
        <div className="grid w-full grid-cols-5 gap-x-8 gap-y-0.5">
          <span className="font-bold col-span-1 md:col-span-1">Aussteller</span>
          <span className="font-bold col-span-1 md:col-span-1">Zertifikat</span>
          <span className="font-bold col-span-1">Punkte</span>
          <span className="font-bold col-span-2">Bemerkung</span>
          {
            projectCertificates
              .map((certificate) => Object.fromEntries(Object.entries(certificate).filter(([
                key,
                value
              ]) => value !== null)))
              .map(({
                id: certificateId,
                issuer: {
                  name: issuerName,
                  max_points: maxPoints
                } = {},
                assigned_points: assignedPoints,
                issuer_certificate: {
                  name: certificateName
                } = {},
                notes
              }) => (
                <React.Fragment key={`certificate_${certificateId}`}>
                  <span className="col-span-1 break-words">
                    {(issuerName)
                      ? issuerName
                      : "-"}
                  </span>
                  <span className="col-span-1 break-words">
                    {(certificateName)
                      ? certificateName
                      : "-"}
                  </span>
                  <span className="col-span-1">
                    {(assignedPoints && maxPoints)
                      ? `${formatNumberInt(assignedPoints)}/${formatNumberInt(maxPoints)}`
                      : "-"}
                  </span>
                  <span className="col-span-2 md:whitespace-pre-line">
                    {(notes)
                      ? notes
                      : "-"}
                  </span>
                </React.Fragment>
              ))
          }
        </div>
      </ContentSection>
      : <ContentSection id="esgCertificates" title="Gebäudezertifikate">
      -
      </ContentSection>
  );
};

export default ProjectESGCertificates;
