/* eslint-disable camelcase */
/* eslint-disable max-params */
/* eslint-disable import/prefer-default-export */
import API from "~/src/modules/api";

/**
 *
 * @param watchlist
 * @param project
 * @param handleSuccess
 * @param handleError
 */
export const handleDelete = (watchlist, project, handleSuccess, handleError) => {
  API.delete("/watch-items", {
    watch_item: {
      watchlist_uuid: watchlist,
      project_slug: project
    }
  }).then((res) => {
    handleSuccess();
  }).catch((error) => {
    handleError(error.response.status);
  });
};
