import { RadioGroup } from "@headlessui/react";
import cn from "classnames";

import { pluralize } from "~/src/modules/formatters";
import { unitCategoryLabels } from "~/src/modules/labels";

/**
 *
 * @param root0
 * @param root0.selectedCategory
 * @param root0.categories
 * @param root0.submit
 */
export default function UnitFilters({
  selectedCategory, categories, submit
}) {
  function handleSelect(val) {
    submit({ category: val });
  }

  return (
    <div className="mt-6">
      <RadioGroup value={selectedCategory} onChange={handleSelect}>
        <RadioGroup.Label className="sr-only">Objektfilter</RadioGroup.Label>
        <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-4 xl:grid-cols-6 md:gap-4">
          {categories.map((category) => (
            <RadioGroup.Option
              key={category.code}
              value={category.code}
              className={cn("md:col-span-2 relative block rounded border border-gray-200 bg-white shadow-sm px-4 py-4 cursor-pointer hover:border-gray-400 focus:outline-none", {})}
            >
              {({ checked }) => (
                <>

                  <div>
                    <div className="flex items-start justify-between text-sm">
                      <div className="-mt-0.5 flex items-center">
                        <div>
                          <span
                            className={cn("h-5 w-5 cursor-pointer rounded-full border flex items-center justify-center", {
                              "bg-white border-gray-300": !checked,
                              "bg-primary border-transparent": checked
                            })}
                            aria-hidden="true"
                          >
                            <span className="w-2 h-2 bg-white rounded-full" />
                          </span>
                        </div>
                        <RadioGroup.Label as="p" className="ml-3 text-base font-medium text-gray-900">
                          {unitCategoryLabels[category.code].plural}
                        </RadioGroup.Label>
                      </div>

                      <RadioGroup.Description as="div" className="text-gray-500">
                        <p>
                          {category.count} {pluralize("erfasstes Objekt", "erfasste Objekte", "erfasste Objekte", category.count)}
                        </p>

                        {/* <div className="mt-0.5">
                          <p className="sm:inline">{category.offerCount} {pluralize("Angebot", "Angebote", category.offerCount)}</p>
                          <span className="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>{' '}
                          <p className="sm:inline">{category.verwertetCount} verwertet</p>
                          <span className="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>{' '}
                          <p className="sm:inline">{category.saleCount} {pluralize("Verkauf", "Verkäufe", category.saleCount)}</p>
                        </div> */}
                      </RadioGroup.Description>
                    </div>
                  </div>

                  {/* <div className="h-24 mt-6">
                    <p className="font-medium text-gray-600">Statistik aus Angebotsdaten</p>
                    <div className="flex mt-1 space-x-8">
                      {category.offerArea === 0 &&
                        category.offerPriceNormal === 0 && category.offerPriceNormalArea === 0 &&
                        category.offerPriceInvestor === 0 && category.offerPriceInvestorArea === 0 &&
                        category.rentPrice === 0 && category.rentPriceArea === 0 &&
                        <div className="text-base font-medium text-gray-900">-</div>
                      }
                      {category.offerArea !== 0 && category.offerCount !== 0 &&
                        <RadioGroup.Description as="div" className="flex text-sm sm:block">
                          <div className="text-base font-medium text-gray-900">{formatNumber(category.offerArea / category.offerCount)} m²</div>
                          <div className="font-medium text-gray-500">
                            <p>ø Fläche</p>
                          </div>
                        </RadioGroup.Description>
                      }
                      {category.offerPriceNormal !== 0 && category.offerPriceNormalArea !== 0 &&
                        <RadioGroup.Description as="div" className="flex text-sm sm:block">
                          <div className="text-base font-medium text-gray-900">{formatEuro(category.offerPriceNormal / category.offerPriceNormalArea)}</div>
                          <div className="font-medium text-gray-500">
                            <p>ø Preis / m²</p>
                            <p className="text-xs">Eigennutzung</p>
                          </div>
                        </RadioGroup.Description>
                      }
                      {category.offerPriceInvestor !== 0 && category.offerPriceInvestorArea !== 0 &&
                        <RadioGroup.Description as="div" className="flex text-sm sm:block">
                          <div className="text-base font-medium text-gray-900">{formatEuro(category.offerPriceInvestor / category.offerPriceInvestorArea)}</div>
                          <div className="font-medium text-gray-500">
                            <p>ø Preis / m²</p>
                            <p className="text-xs">Investment</p>
                          </div>
                        </RadioGroup.Description>
                      }
                      {category.rentPrice !== 0 && category.rentPriceArea !== 0 &&
                        <RadioGroup.Description as="div" className="flex text-sm sm:block">
                          <div className="text-base font-medium text-gray-900">{formatEuro(category.rentPrice / category.rentPriceArea)}</div>
                          <div className="font-medium text-gray-500">
                            <p>ø Mietpreis / m²</p>
                          </div>
                        </RadioGroup.Description>
                      }
                    </div>
                  </div> */}

                  {/* <div className="h-24 mt-6">
                    <p className="font-medium text-gray-600">Statistik aus Verkaufsdaten</p>
                    <div className="flex mt-1 space-x-8">
                      {category.saleArea === 0 && category.salePrice === 0 &&
                      category.salePriceArea === 0 && category.salePriceCount === 0 &&
                        <div className="text-base font-medium text-gray-900">-</div>
                      }
                      {category.saleArea !== 0 && category.saleCount !== 0 &&
                        <RadioGroup.Description as="div" className="flex text-sm sm:block">
                          <div className="text-base font-medium text-gray-900">{formatNumber(category.saleArea / category.saleCount)} m²</div>
                          <div className="font-medium text-gray-500">
                            <p>ø Fläche</p>
                          </div>
                        </RadioGroup.Description>
                      }
                      {category.salePrice !== 0 && category.salePriceArea !== 0 &&
                        <RadioGroup.Description as="div" className="flex text-sm sm:block">
                          <div className="text-base font-medium text-gray-900">{formatEuro(category.salePrice / category.salePriceArea)} m²</div>
                          <div className="font-medium text-gray-500">
                            <p>ø Preis / m²</p>
                          </div>
                        </RadioGroup.Description>
                      }
                    </div>
                  </div> */}

                  <div
                    className={cn("absolute -inset-px rounded border-2 pointer-events-none", {
                      "border-primary": checked,
                      "border-transparent": !checked
                    })}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}
