/**
 *
 * @param root0
 * @param root0.transactionId
 * @param root0.notes
 * @param root0.id
 */
const NoteElement = ({ id, notes }) => (
  <p id={id} className="w-full px-2.5 py-1.5 break-words rounded bg-gray-100 text-justify hyphens-auto leading-[1.4rem]">
    <span className="font-semibold ">Bemerkungen: </span> {notes || "-"}
  </p>
);

export default NoteElement;
