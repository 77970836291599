/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable import/max-dependencies */
/* eslint-disable function-paren-newline */
/* eslint-disable max-lines-per-function */
/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */
import {
  useEffect,
  useMemo,
  useState
} from "react";
import { Temporal } from "@js-temporal/polyfill";

import EmptyChartContainer from "../empty-chart-container";

import { generateLineData } from "./tools";

import ChartLegend from "~/src/ui/charts/legend";
import PriceSelect from "~/src/ui/selects/price-select";
import MultiLineChart from "~/src/ui/charts/multi-line-chart";
import { priceLabels } from "~/src/modules/labels";
import useMediaQuery from "~/src/hooks/use-media-query";
import DetailStatsTools from "~/src/lib/detail-stats-tools";
import { addElementUniq, nullFn } from "~/src/lib/standard";
import { areaGroups } from "~/src/schema/detail-stats";

const {
  PlainYearMonth
} = Temporal;

/**
 *
 * @param props
 * @param props.statistic
 */
export default function MarketTrendByAreaSaleChart({ statistic }) {
  const isPrint = useMediaQuery("print");

  const [selectedGraphLabel, setSelectedGraphLabel] = useState(null);

  const labelsOfNonemptyGraphs = DetailStatsTools
    .getVariablesWithData(statistic);

  const activeGraphLabel = selectedGraphLabel ?? labelsOfNonemptyGraphs?.[0];

  const lineData = useMemo(() => (activeGraphLabel
    ? generateLineData(activeGraphLabel, statistic)
    : null),
  [statistic, activeGraphLabel]);

  const defaultAreaGroups = ["61 - 80"];

  const [areaGroupsWithoutUnselected, setAreaGroupsWithoutUnselected] =
    useState(defaultAreaGroups);

  const [hoveredAreaGroup, setHoveredAreaGroup] = useState(null);

  const allGraphLabels = Object.keys(statistic);

  useEffect(() => {
    if (lineData) {
      const availableAreaGroups = lineData.legendData
        .map(({ id }) => id) ?? [];

      const initialAreaGroups = availableAreaGroups.includes("61 - 80")
        ? defaultAreaGroups
        : [availableAreaGroups[0]];

      setAreaGroupsWithoutUnselected(initialAreaGroups);
    }
  }, [lineData]);

  if (isPrint) {
    return labelsOfNonemptyGraphs
      .map((graphLabel) => {
        const lineData = generateLineData(graphLabel, statistic);

        return (
          <div key={graphLabel} className="w-full space-y-4 bg-white break-inside-avoid">
            <h2 className="font-medium text-gray-900 text-md">{priceLabels[graphLabel]} Trend nach Fläche</h2>
            <div className="h-[19rem]">
              <MultiLineChart
                data={lineData.chartData}
                x={{ type: "month" }}
                activeAreaGroups={areaGroups}
              />
            </div>
            <ChartLegend
              data={lineData.legendData}
              displayAreaGroups={areaGroups}
              activeAreaGroups={areaGroups}
              setActiveAreaGroups={nullFn}
              setHoveredAreaGroup={nullFn}
              simple
            />
          </div>
        );
      });
  }

  if (!labelsOfNonemptyGraphs?.length || !lineData) {
    return <EmptyChartContainer
      chartLabel={`${priceLabels.salePricePerSquareMeter} Trend nach Fläche`}
      allGraphLabels={allGraphLabels}
    />;
  }

  const activeAreaGroups = addElementUniq(
    areaGroupsWithoutUnselected,
    hoveredAreaGroup
  );

  return (
    <div className="flex flex-col gap-4 p-2 bg-white border border-gray-200 rounded lg:p-4">
      <div className="flex flex-col justify-between gap-2 lg:flex-row">
        <div className="flex items-center gap-2">
          <h2 className="font-medium text-gray-900 text-md">{priceLabels.salePricePerSquareMeter} Trend nach Fläche</h2>
          {

            /*
          <MetaTooltip
            {...((barData) && {
              meta: barData.meta
            })}
          />
            */
          }
        </div>

        <PriceSelect
          value={activeGraphLabel}
          options={allGraphLabels}
          onChange={setSelectedGraphLabel}
          enabledOptions={labelsOfNonemptyGraphs}
          className="lg:self-end w-80"
          buttonClassName="w-80"
        />
      </div>

      <div className="w-full h-[450px] relative">
        <div className="absolute w-full h-[450px]">
          {(lineData.chartData.some(({ data }) => data.length > 0))
            ? <MultiLineChart
              data={lineData.chartData}
              x={{ type: "month" }}
              activeAreaGroups={activeAreaGroups}
            />
            : null
          }

        </div>
      </div>
      <div className="w-full">
        <ChartLegend
          data={lineData.legendData}
          displayAreaGroups={activeAreaGroups}
          activeAreaGroups={areaGroupsWithoutUnselected}
          setActiveAreaGroups={setAreaGroupsWithoutUnselected}
          setHoveredAreaGroup={setHoveredAreaGroup}
          simple
        />
      </div>
    </div>
  );
}
