import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.className
 */
export default function MarkerIcon({ className }) {
  return (
    <svg width={0} height={0} viewBox="0 0 20 20" className={cn("fill-current", className)} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.9091 7.95455C16.9091 4.10227 13.8068 1 9.95455 1C6.10227 1 3 4.10227 3 7.95455C3 11.8068 9.95455 19 9.95455 19C9.95455 19 16.9091 11.8068 16.9091 7.95455ZM6.71591 7.81818C6.71591 6.04546 8.18182 4.57955 9.95455 4.57955C11.7273 4.57955 13.1932 6.01137 13.1932 7.81818C13.1932 9.59091 11.7614 11.0568 9.95455 11.0568C8.18182 11.0568 6.71591 9.59091 6.71591 7.81818Z" />
    </svg>
  );
}
