/* eslint-disable max-len */
/* eslint-disable no-undefined */
import { Tooltip } from "react-tooltip";

/**
 *
 * @param props
 * @param props.data
 * @param props.tooltipStructure
 * @param props.xyz
 * @param props.meta
 */
export default function MetaTooltip({ meta = undefined }) {
  const dataForTooltip = (meta === undefined)
    ? ""
    : `Besteht aus ${meta.total} Datenpunkten: <br>Errechnet aus ${meta.units.used} von ${meta.units.total} erfassten Objekten`;

  return (
    (meta === undefined)
      ? null
      : (
        <div>
          <p className="flex items-center text-base font-light">
            <svg
              data-tooltip-id="tooltip-meta"
              data-tooltip-html={dataForTooltip}
              className="w-5 h-5 text-gray-500 focus:outline-none hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
          </p>
          <Tooltip
            id="tooltip-meta"
            place="bottom"
            className="z-50"
            delayShow={500}
          />
        </div>
      )
  );
}
