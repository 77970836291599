/**
 *
 * @param root0
 * @param root0.className
 * @param root0.listItemClassName
 * @param root0.children
 */
export default function UnorderedList({
  className, listItemClassName, children
}) {
  return (
    <ul className={className}>
      {
        children?.map((child, index) => (
          <li className={listItemClassName} key={index}>{child}</li>
        ))
      }
    </ul>
  );
}
