import RegionFilterTag from "~/src/features/filters/share-deal-region-filter-tag";
import { formatDate } from "~/src/modules/formatters";
import SingleFilterTag from "~/src/ui/search-filter-tags/single-filter-tag";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.onSubmit
 * @param root0.handleClearFilters
 */
export default function InsolvencyFilterTags({
  query, onSubmit, handleClearFilters
}) {
  const activeFilters = [];

  Object.entries(query).forEach(([
    key,
    val
  ]) => {
    if (![
      "page",
      "direction",
      "sortBy",
      "sortByTransaction",
      "type",
      "automated",
      "selection"
    ].includes(key) && val !== undefined) {
      if (query.type === "insolvenz") {
        if ([
          "company",
          "cat",
          "dateFrom",
          "dateTo"
        ].includes(key) && val !== undefined) {
          activeFilters.push(key);
        }
      }
      else {
        if (key === "regions" && val.length === 0) {
          return;
        }
        if ([
          "company",
          "cat",
          "dateFrom",
          "dateTo"
        ].includes(key) && val !== undefined) {
          activeFilters.push(key);
        }
      }
    }
  });

  return (
    <div className="flex justify-start items-center flex-wrap gap-4 min-h-10">
      <SingleFilterTag label={query.cat} show={query.catId && query.cat} onClick={() => onSubmit({
        catId: undefined,
        cat: undefined
      })} />
      <SingleFilterTag label={`Eigentümer: ${query.owner}`} show={query.type !== "insolvenz" && query.owner} onClick={() => onSubmit({ owner: undefined })} />
      <SingleFilterTag label={`Schuldner: ${query.company}`} show={query.company} onClick={() => onSubmit({ company: undefined })} />
      <SingleFilterTag label={`Datum von: ${formatDate({ date: query.dateFrom })}`} show={query.dateFrom} onClick={() => onSubmit({
        dateFrom: undefined,
        direction: query.dateTo ? "desc" : undefined
      })} />
      <SingleFilterTag label={`Datum bis: ${formatDate({ date: query.dateTo })}`} show={query.dateTo} onClick={() => onSubmit({
        dateTo: undefined,
        direction: query.dateFrom ? "asc" : undefined
      })} />
      {query.type !== "insolvenz" &&
        <RegionFilterTag queryRegions={query.regions} submit={onSubmit} />
      }

      {(activeFilters.length > 0 || (query.type !== "insolvenz" && query.regions.length > 0)) &&
        <button type="button" onClick={handleClearFilters} className="mt-0.5 ml-2 focus:outline-none tracking-tight text-sm font-semibold hover:underline text-primary">
          Filter zurücksetzen
        </button>
      }
    </div>
  );
}
