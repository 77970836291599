import { Tooltip } from "react-tooltip";
import { ScaleIcon } from "@heroicons/react/24/outline";

import useStore from "~/src/hooks/use-store";

/**
 *
 */
const AreaSettingsButton = () => {
  const toggleAreaModal = useStore((state) => state.toggleAreaModal);

  const handleClick = () => {
    toggleAreaModal();
  };

  return (
    <>
      <span
        data-tooltip-id="tooltip-gewichtung"
        data-tooltip-content="Flächengewichtung"
        onClick={handleClick}
        className="w-8 h-8 p-1 rounded-full cursor-pointer bg-primary hover:bg-primary-dark"
      >
        <ScaleIcon className="w-full text-white" />
      </span>
      <Tooltip
        id="tooltip-gewichtung"
        place="bottom"
        className="z-50"
        delayShow={500}
      />
    </>
  );
};

export default AreaSettingsButton;
