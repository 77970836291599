import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import CloseIcon from "~/src/ui/icons/close-icon";
import {
  formatAreaInt, formatCurrency, formatDate, formatMonth
} from "~/src/modules/formatters";
import { propertyTypeLabels } from "~/src/modules/labels";
import ShareDealOwnerCard from "~/src/features/share-deal-owner-card";
import FeedbackModal from "~/src/features/feedback-modal";

/**
 *
 * @param root0
 * @param root0.active
 * @param root0.hide
 * @param root0.property
 */
export default function ShareDealPropertySlide({
  active, hide, property
}) {
  const [
    showFeedback,
    setShowFeedback
  ] = useState(false);

  function handleHide() {
    hide();
  }

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog as="div" static className="z-[150] fixed inset-0 overflow-hidden" open={active} onClose={handleHide}>

        <FeedbackModal show={showFeedback} close={() => setShowFeedback(false)} entity_id={property?.id} feedback_type="share-deal-property" feedback_type_label={`${formatMonth(property?.transaction?.change_date)} - ${property?.transaction?.company_fbnr}`} />

        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-5xl">

                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-white"
                      onClick={handleHide}
                    >
                      <span className="sr-only">Schließen</span>
                      <CloseIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>

                <div className="h-full flex flex-col pb-6 bg-white shadow-xl overflow-y-scroll">

                  <div className="px-4 sm:px-12 h-16 flex justify-between items-center shrink-0 bg-primary">
                    <h2 id="slide-over-heading" className="text-lg font-medium text-white">
                      Details zur Liegenschaft
                    </h2>
                  </div>

                  <div className="mt-4 flex-1">

                    <dl className="px-4 sm:px-8">

                      <div className="px-4 flex justify-between items-center">
                        <h3 className="text-2xl font-semibold text-gray-700">{property.property_street} {property.property_house_number}<br />{property.property_state}, {property.property_zipcode} {property.property_city?.replace(",", ", ")}</h3>
                        <button type="button" onClick={() => setShowFeedback(true)} className="px-3 py-1 text-sm bg-primary text-white hover:bg-primary-lighter border border-primary rounded focus:outline-none">Feedback</button>
                      </div>

                      <div>

                        <div className="mt-4 mx-4 py-2 px-4">

                          <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Änderungen am</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{renderDateList(property.date_list)}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Art der Änderung</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property.category?.name}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Gebäudeart</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">
                              {renderPropertyTypes(property) || "-"}
                            </dd>
                          </div>

                        </div>

                        <div className="py-2 mx-4 px-4">
                          <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">KG</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property.kgnr}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">EZ</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property.ez}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">EZ Fläche</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{formatAreaInt(property.ez_area)}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">GST-Nr.</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property.gst_numbers?.join("; ")}</dd>
                          </div>
                        </div>

                        <div className="mt-10 mb-4">

                          <div className="mt-6">

                            <p className="ml-4 text-xl">Eigentümer (Liegenschaft)</p>

                            <div className="mt-2 py-2 mx-4 px-4">
                              <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                                <dt className="font-semibold text-gray-800">Firmenname</dt>
                                <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property?.transaction?.company_name}</dd>
                              </div>

                              <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                                <dt className="font-semibold text-gray-800">FB-Nr.</dt>
                                <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property?.transaction?.company_fbnr}</dd>
                              </div>

                              <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                                <dt className="font-semibold text-gray-800">Straße</dt>
                                <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property?.transaction?.company_street}</dd>
                              </div>
                              <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                                <dt className="font-semibold text-gray-800">Ort</dt>
                                <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property?.transaction?.company_zipcode} {property?.transaction?.company_city}</dd>
                              </div>

                              <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                                <dt className="font-semibold text-gray-800">Kapital</dt>
                                <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property?.transaction?.company_capital
                                  ? formatCurrency({
                                    val: property?.transaction?.company_capital,
                                    currency: property?.transaction?.capital_currency
                                  })
                                  : "keine Angabe"}</dd>
                              </div>
                            </div>

                          </div>

                          <div className="mt-6">

                            <div className="flex justify-between items-end">
                              <p className="ml-4 text-xl">Personen- und Anteilsänderungen des Eigentümers</p>
                              <p className="mr-4 text-right text-sm text-gray-800">PersNr.</p>
                            </div>

                            <div className="mt-2.5 px-4 space-y-5">
                              {property?.transaction?.owners?.map((owner, index) => (
                                <ShareDealOwnerCard key={`owner_${index}`} owner={owner} />
                              ))}
                            </div>
                          </div>

                        </div>

                      </div>

                    </dl>

                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function renderPropertyTypes(property) {
  let hasValue = false;
  const propertyTypes = Array(9).fill(1).map((_, i) => {
    const availableCount = property[`property_type_${i}_count`];

    if (availableCount) {
      hasValue = true;

      return (
        <p key={`property_type_${i}`}>{availableCount} x {propertyTypeLabels[`type_${i}`]}</p>
      );
    }

    return null;
  });

  return hasValue ? propertyTypes : null;
}

function renderDateList(dateList) {
  return dateList?.split(", ").map((date) => formatDate({
    date,
    withTime: false
  })).join(", ");
}
