import { useParams } from "react-router-dom";

import BuyersAgeChart from "~/src/features/charts/buyers/age";
import BuyersGenderChart from "~/src/features/charts/buyers/gender";
import BuyersGraduatedChart from "~/src/features/charts/buyers/graduated";
import BuyersProvenanceChart from "~/src/features/charts/buyers/provenance";
import ChartContainer from "~/src/ui/containers/chart-container";
import useProjectStatistics from "~/src/hooks/use-project-statistics";
import ChartError from "~/src/ui/charts/error";
import { operator, identityFn } from "~/src/lib/standard";

/**
 *
 */
export default function DetailStatistics() {
  const { id: projectId } = useParams();

  const typesGender = [
    "units-buyers-gender",
    "units-buyers-partnership"
  ];
  const structureGender = "composition";
  const {
    statistics: buyersGender,
    isError: isErrorGender,
    isLoading: isLoadingGender
  } = useProjectStatistics({
    projectId,
    types: typesGender,
    structure: structureGender
  });

  const typesAge = ["units-buyers-age"];
  const structureAge = "composition";
  const {
    statistics: buyersAge,
    isLoading: isLoadingAge,
    isError: isErrorAge
  } = useProjectStatistics({
    projectId,
    types: typesAge,
    structure: structureAge
  });

  const typesProvenance = [
    "units-buyers-type",
    "units-buyers-provenance"
  ];
  const structureProvenance = "composition";
  const {
    statistics: buyersProvenance,
    isLoading: isLoadingProvenance,
    isError: isErrorProvenance
  } = useProjectStatistics({
    projectId,
    types: typesProvenance,
    structure: structureProvenance
  });

  const typesGraduated = ["units-buyers-graduated"];
  const structureGraduated = "composition";
  const {
    statistics: buyersGraduated,
    isLoading: isLoadingGraduated,
    isError: isErrorGraduated
  } = useProjectStatistics({
    projectId,
    types: typesGraduated,
    structure: structureGraduated
  });

  const isError = [
    isErrorAge,
    isErrorGender,
    isErrorGraduated,
    isErrorProvenance
  ].every(identityFn);

  const isEmpty = [
    buyersAge,
    buyersGender,
    buyersGraduated,
    buyersProvenance
  ].every(operator["!"]);

  const showNoResultsMessage = isError || isEmpty;

  if (showNoResultsMessage) {
    return (
      <ChartError />
    );
  }

  return (
    <ChartContainer cols={2}>
      <BuyersGenderChart statistic={buyersGender} isLoading={isLoadingGender} isError={isErrorGender}/>
      <BuyersAgeChart statistic={buyersAge} isLoading={isLoadingAge} isError={isErrorAge}/>
      <BuyersProvenanceChart statistic={buyersProvenance} isLoading={isLoadingProvenance} isError={isErrorProvenance}/>
      <BuyersGraduatedChart statistic={buyersGraduated} isLoading={isLoadingGraduated} isError={isErrorGraduated}/>
    </ChartContainer>
  );
}
