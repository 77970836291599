import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.setShowStats
 * @param root0.setShowMap
 */
export default function ToggleViewButton({ setShowStats, setShowMap }) {
  return (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      <button
        type="button"
        onClick={() => setShowStats(true)}
        className={cn("relative w-24 inline-flex justify-center items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0")}
      >
        Statistik
      </button>

      <button
        type="button"
        onClick={() => setShowMap(true)}
        className={cn("-ml-px w-24 relative inline-flex justify-center items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0")}
      >
        Karte
      </button>
    </span>
  );
}
