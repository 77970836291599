import { Redirect } from "react-router-dom";
import {
  useQueryParams, StringParam, NumberParam
} from "use-query-params";
import animateScrollTo from "animated-scroll-to";

import usePurchases from "~/src/hooks/use-purchases";
import { pluralize } from "~/src/modules/formatters";
import Page from "~/src/ui/page";
import Pagination from "~/src/features/pagination";
import DateInput from "~/src/ui/inputs/date-input";
import StateSelect from "~/src/ui/selects/state-select";
import CitySelect from "~/src/ui/selects/city-select";
import PurchaseFilterTags from "~/src/features/filter-tags/purchase-filter-tags";
import SortPurchasesDirection from "~/src/features/sort-purchases-direction";
import SortPurchasesDropdown from "~/src/features/sort-purchases-dropdown";
import PurchaseList from "~/src/features/purchase-list";
import PurchaseDateToggle from "~/src/features/purchase-date-toggle";
import PurchaseTypeSelect from "~/src/ui/selects/purchase-type-select";

/**
 *
 */
export default function PurchasesPage() {
  const [
    query,
    setQuery
  ] = useQueryParams({
    city: StringParam,
    cityId: NumberParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    dateType: StringParam,
    direction: StringParam,
    page: NumberParam,
    purchaseType: StringParam,
    purchaseTypeId: NumberParam,
    sortBy: StringParam,
    state: StringParam,
    stateId: NumberParam
  });
  const {
    purchases, pagination, sortDirection, isLoading, isError
  } = usePurchases({ query });

  function handlePageChange(newPage) {
    setQuery({ page: newPage });
    setTimeout(() => {
      animateScrollTo(0, {
        speed: 120
      });
    }, 120);
  }

  function handleClearFilters() {
    setQuery({
      ...query,
      city: undefined,
      cityId: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      direction: undefined,
      page: 1,
      purchaseType: undefined,
      purchaseTypeId: undefined,
      state: undefined,
      stateId: undefined
    });
  }

  function handleSubmit(val) {
    setQuery({
      ...val,
      page: 1
    });
  }

  if (isError) {
    <Redirect to="/error" />;
  }

  return (
    <Page title="Transaktionen">
      <div className="xl:container mx-auto px-4 lg:px-16">
        <h2 className="mt-8 text-3xl text-gray-700 font-medium">Transaktionen</h2>
      </div>

      <div className="xl:container mx-auto mt-1 px-4 lg:px-16 py-5">

        <div className="lg:flex justify-between items-center w-full">

          <div>
            <div className="lg:flex items-center">
              <div className="w-full lg:w-80">
                <StateSelect onUpdate={handleSubmit} />
              </div>
              <div className="lg:ml-2 mt-2 lg:mt-0 w-full lg:w-80">
                <CitySelect currentStateId={query.stateId} onUpdate={handleSubmit} />
              </div>
              <div className="lg:ml-2 mt-2 lg:mt-0 w-full lg:w-80">
                <PurchaseTypeSelect onUpdate={handleSubmit} />
              </div>
            </div>

            <div className="mt-5 h-full lg:flex items-center gap-x-3">
              <div className="flex justify-start lg:justify-center items-center gap-x-3">
                <DateInput name="dateFrom" hasOtherDate={query?.dateTo} maxDate={query?.dateTo ? new Date(query.dateTo) : new Date()} placeholder="Datum von" onChange={handleSubmit} />
                <DateInput name="dateTo" hasOtherDate={query?.dateFrom} minDate={query?.dateFrom ? new Date(query.dateFrom) : undefined} maxDate={new Date()} placeholder="Datum bis" onChange={handleSubmit} />
              </div>
              <div className="mt-8 lg:mt-0 lg:ml-2">
                <PurchaseDateToggle dateType={query?.dateType} onChange={handleSubmit} />
              </div>
            </div>
          </div>

        </div>

        <div className="mt-5">

          <PurchaseFilterTags query={query} onSubmit={handleSubmit} handleClearFilters={handleClearFilters} />

          <div className="mt-4 flex flex-col lg:flex-row justify-between items-center lg:items-end">

            {!isLoading &&
              <h3 className="font-light text-2xl text-gray-800 text-center lg:text-left mb-3 lg:mb-0">{pagination?.totalRecords?.toLocaleString("de")} {pluralize("Transaktion", "Transaktionen", "Transaktionen", pagination?.totalRecords)}</h3>
            }

            {!pagination?.totalRecords && isLoading &&
              <div className="h-5 bg-gray-300 w-64 rounded animate-pulse" />
            }

            {!isLoading &&
              <div className="lg:flex justify-end items-center">
                <SortPurchasesDropdown sortBy={query.sortBy} onSubmit={handleSubmit} />
                <div className="ml-6 mr-1 mb-2 lg:mb-0">
                  <Pagination pagination={pagination} page={query.page} onPageChange={handlePageChange} />
                </div>
                <div className="flex lg:block justify-center">
                  <SortPurchasesDirection isLoading={isLoading} direction={sortDirection} onSubmit={handleSubmit} />
                </div>
              </div>
            }

            {isLoading &&
              <div className="mt-4 mb-1 h-5 bg-gray-300 w-96 rounded animate-pulse" />
            }
          </div>

          <PurchaseList purchases={purchases} isLoading={isLoading} loadingItems={30} query={query.sortBy || "dateVerbuecherung"} />

          <Pagination pagination={pagination} page={query.page} onPageChange={handlePageChange} />

        </div>

      </div>

    </Page>
  );
}
