import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 */
export default function ConstructionTypeSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const tempItems = tempQuery?.split(",") || [];

  const hasNeubau = items.includes("neubau");
  const hasDGAusbau = items.includes("dg_ausbau");
  const hasSanierung = items.includes("sanierung");
  const hasUnsaniert = items.includes("unsaniert");

  const hasTempNeubau = tempItems.includes("neubau");
  const hasTempDGAusbau = tempItems.includes("dg_ausbau");
  const hasTempSanierung = tempItems.includes("sanierung");
  const hasTempUnsaniert = tempItems.includes("unsaniert");

  function onCheck(event, key) {
    handleCheck("construction_type", event, tempQuery, items, tempItems, key, update);
  }

  function checked(item, tempItem) {
    return handleCheckedStatus(query, item, tempQuery, tempItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Bautyp
      </p>
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 font-light">
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasNeubau, hasTempNeubau)} onChange={(e) => onCheck(e, "neubau")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Neubau</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasSanierung, hasTempSanierung)} onChange={(e) => onCheck(e, "sanierung")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Sanierung Altbestand</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasDGAusbau, hasTempDGAusbau)} onChange={(e) => onCheck(e, "dg_ausbau")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">DG Ausbau</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasUnsaniert, hasTempUnsaniert)} onChange={(e) => onCheck(e, "unsaniert")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">unsaniert</span>
          </label>
        </div>
      </div>
    </div>
  );
}
