import useRegions from "~/src/hooks/use-regions";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryRegions
 * @param root0.submit
 */
export default function ShareDealRegionFilterTag({ queryRegions = [], submit }) {
  const {
    regions, states, isLoading
  } = useRegions();

  if (queryRegions.length === 0 || isLoading) {
    return null;
  }

  function handleClick(regionIds) {
    const newRegionQueryItems = queryRegions.filter((item) => !regionIds.includes(parseInt(item)));

    submit({ regions: newRegionQueryItems.length > 0 ? newRegionQueryItems : undefined });
  }

  const regionStates = queryRegions?.sort((a, b) => parseInt(a) - parseInt(b)).map((queryRegion) => {
    const region = regions?.find((region) => region.id === parseInt(queryRegion));

    return region || null;
  });

  const grouped = regionStates?.reduce((acc, value) => {
    if (acc[value.state.code]) {
      acc[value.state.code].regions.push(value);
    }
    else {
      acc[value.state.code] = {
        regions: [value],
        name: value.state.name,
        code: value.state.code,
        numberOfRegions: parseInt(states?.find((state) => state.code === value.state.code)?.numberOfRegions)
      };
    }

    return acc;
  }, {});

  const tags = Object.keys(grouped).map((key) => {
    const group = grouped[key];

    if (group.numberOfRegions === group.regions.length) {
      return (
        <DefaultFilterTag key={`filter_region_state_${group.code}`} handleClick={() => handleClick(group.regions.map((region) => region.id))}>
          <span>{group.name}</span>
        </DefaultFilterTag>
      );
    }

    return group.regions.map((region) => (
      <DefaultFilterTag key={`filter_region_${region.id}`} handleClick={() => handleClick([region.id])}>
        <span>{region?.name}</span>
      </DefaultFilterTag>
    ));
  });

  return (
    <>
      {tags}
    </>
  );
}
