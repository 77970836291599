/* eslint-disable import/max-dependencies */
/* eslint-disable max-len */
import cn from "classnames";
import { useParams } from "react-router-dom";

import ContentContainer from "~/src/ui/containers/content-container/index.new.jsx";
import ProjectOverviewCompanyRoles from "~/src/features/project-overview/company-roles.jsx";
import ProjectOverviewConstructionInfos from "~/src/features/project-overview/construction-infos.jsx";
import ProjectOverviewConstructionTypes from "~/src/features/project-overview/construction-types.jsx";
import ProjectOverviewDescription from "~/src/features/project-overview/description.jsx";
import ProjectOverviewDocuments from "~/src/features/project-overview/documents.jsx";
import ProjectOverviewEnergyClasses from "~/src/features/project-overview/energy-classes.jsx";
import ProjectOverviewFunding from "~/src/features/project-overview/funding.jsx";
import ProjectOverviewMap from "~/src/features/project-overview/map.jsx";
import ProjectOverviewNotes from "~/src/features/project-overview/notes.jsx";
import ProjectOverviewProperties from "~/src/features/project-overview/properties.jsx";
import ProjectOverviewQuantities from "~/src/features/project-overview/quantities.jsx";
import ProjectOverviewStats from "~/src/features/project-overview/stats.jsx";
import ProjectOverviewTransactions from "~/src/features/project-overview/transactions.jsx";
import ProjectOverviewZonings from "~/src/features/project-overview/zonings.jsx";
import useProject from "~/src/hooks/use-project.js";
import ProjectESGCertificates from "~/src/features/project-overview/esg-certificates.jsx";

/**
 *
 */
export default function ProjectInfos() {
  const { id } = useParams();
  const {
    project, isLoading, isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    name,
    image_file_name: imageFileName,
    image_landscape: imageLandscape,
    automated
  } = project;

  return (
    <main className="flex flex-col w-full gap-4">
      <ContentContainer>
        <ProjectOverviewStats />
      </ContentContainer>
      <ContentContainer>
        <ProjectOverviewMap />
      </ContentContainer>
      <ContentContainer
        image={
          !automated && (
            <div className={cn("relative w-64 xl:w-48 2xl:w-64 max-w-[calc(100vw-66px)] pb-2/3 rounded overflow-hidden", {
              "h-40 xl:h-32 2xl:h-40": imageLandscape,
              "h-64 xl:h-64 2xl:h-64": !imageLandscape
            })}>
              {(imageFileName) &&
              <img className="absolute object-cover w-full h-full transition duration-500 transform" src={`https://immodeveloper.fra1.digitaloceanspaces.com/${imageFileName}`} alt={name} />
              }
            </div>)
        }
      >
        <ProjectOverviewQuantities />
        <ProjectOverviewConstructionInfos />

        {
          !automated && (
            <>
              <ProjectOverviewFunding />
              <ProjectOverviewConstructionTypes />
            </>
          )
        }

      </ContentContainer>
      {
        !automated && (
          <>
            <ContentContainer>
              <ProjectOverviewCompanyRoles />
            </ContentContainer>
            <ContentContainer>
              <div className="flex flex-col justify-between w-full gap-6 lg:gap-x-4 lg:flex-row">
                <div className="flex flex-col lg:max-w-[60%] min-w-[60%]">
                  <ProjectOverviewProperties />
                </div>
                <ProjectOverviewZonings />
              </div>
            </ContentContainer>
            <ContentContainer>
              <ProjectOverviewTransactions historic={false}/>
              <ProjectOverviewTransactions historic={true}/>
            </ContentContainer>
            <ContentContainer>
              <div className="xl:w-[60%]">
                <ProjectESGCertificates />
              </div>
            </ContentContainer>
            <ContentContainer>
              <ProjectOverviewEnergyClasses />
            </ContentContainer>
            <ContentContainer>
              <div className="flex flex-col justify-between w-full gap-6 lg:gap-x-4 lg:flex-row">
                <div className="flex flex-col lg:max-w-[60%] min-w-[60%] gap-y-6">
                  <ProjectOverviewDescription />
                  <ProjectOverviewNotes />
                </div>
                <div className="w-full">
                  <ProjectOverviewDocuments />
                </div>

              </div>

            </ContentContainer>
          </>
        )
      }

    </main>
  );
}
