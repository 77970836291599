import {
  Route,
  Switch
} from "react-router-dom";

import ProjectOverviewPage from "~/src/pages/projects/[id]/overview";
import Statistics from "~/src/pages/projects/[id]/statistics";
import Timeline from "~/src/pages/projects/[id]/timeline";
import Units from "~/src/pages/projects/[id]/units";

/**
 * Contains the different routes, which are rendered based on the selection at projects-page/[:id]/index .
 *
 * @return {React.ReactElement} Returns an element to switch between the different routes and render the corresponding project page 
 */
export default function Tabs() {
  return (
    <Switch>
      <Route exact path={"/projekte/:id"}>
        <ProjectOverviewPage />
      </Route>

      <Route exact path={"/projekte/:id/objekte"}>
        <Units />
      </Route>

      <Route path={"/projekte/:id/statistiken"}>
        <Statistics />
      </Route>

      <Route path={"/projekte/:id/timeline"}>
        <Timeline />
      </Route>
    </Switch>
  );
}
