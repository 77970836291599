/* eslint-disable max-len */
import { Dialog, Transition } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import {
  Fragment, useState
} from "react";
import { Tooltip } from "react-tooltip";

import FeedbackModal from "~/src/features/feedback-modal";
import SlidePropertyCard from "~/src/features/slide-property-card";
import { formatAreaInt, formatDate } from "~/src/modules/formatters";
import { propertyTypeLabels } from "~/src/modules/labels";
import CloseIcon from "~/src/ui/icons/close-icon";

const propertyTypeCount = 9;

const renderPropertyTypes = (property) => {
  let hasValue = false;

  const propertyTypes = Array.from(
    { length: propertyTypeCount },
    (empty, index) => {
      const availableCount = property[`property_type_${index}_count`];

      if (availableCount) {
        hasValue = true;

        return (
          <p key={`property_type_${index}`}>{availableCount} x {propertyTypeLabels[`type_${index}`]}</p>
        );
      }

      return null;
    }
  );

  return hasValue ? propertyTypes : null;
};

/**
 *
 * @param root0
 * @param root0.active
 * @param root0.hide
 * @param root0.property
 */
// eslint-disable-next-line max-lines-per-function
export default function InsolvencyPropertySlide({
  active, hide, property
}) {
  const [
    showFeedback,
    setShowFeedback
  ] = useState(false);

  const handleHide = () => {
    hide();
  };

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog
        as="div"
        static
        className="z-[150] fixed inset-0 overflow-hidden"
        open={active}
        onClose={handleHide}
      >
        <FeedbackModal
          show={showFeedback}
          close={() => setShowFeedback(false)}
          entity_id={property?.id}
          feedback_type="insolvency-property"
          feedback_type_label={property?.insolvency?.record_number}
        />
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-5xl">

                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-white"
                      onClick={handleHide}
                    >
                      <span className="sr-only">Schließen</span>
                      <CloseIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>

                <div className="h-full flex flex-col pb-6 bg-white shadow-xl overflow-y-scroll">

                  <div className="px-4 sm:px-12 h-16 flex justify-between items-center shrink-0 bg-primary">
                    <h2 id="slide-over-heading" className="text-lg font-medium text-white">
                      Details zur Liegenschaft
                    </h2>
                  </div>

                  <div className="mt-4 flex-1">

                    <dl className="px-4 sm:px-8">

                      <div className="px-4 flex justify-between items-center">
                        <h3 className="text-2xl font-semibold text-gray-700">{property.property_street} {property.property_house_number}<br />{property.property_state}, {property.property_zipcode} {property.property_city?.replace(",", ", ")}</h3>
                        <button type="button" onClick={() => setShowFeedback(true)} className="px-3 py-1 text-sm bg-primary text-white hover:bg-primary-lighter border border-primary rounded focus:outline-none">Feedback</button>
                      </div>

                      <div className="mt-8">

                        <div className="py-2 mx-4 px-4">
                          <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">KG</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property.kgnr}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">EZ</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property.ez}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">EZ Fläche</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{formatAreaInt(property.ez_area)}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">GST-Nr.</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property.gst_numbers?.join("; ")}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Gebäudeart</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">
                              {renderPropertyTypes(property) || "-"}
                            </dd>
                          </div>
                        </div>

                        <div className="mt-10 mb-4">

                          <div className="flex justify-between items-end">
                            <div className="ml-4 text-xl flex">
                              <p>Liegenschaftseigentümer/Schuldner</p>
                              <svg
                                data-tooltip-id="tooltip-insolvency-property-owner"
                                data-tooltip-content="Stichtagsdaten vom 01.04.2021"
                                className="focus:outline-none mt-0.5 ml-2 text-gray-600 hover:text-gray-700 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                              </svg>
                              <Tooltip
                                id="tooltip-insolvency-property-owner"
                                place="bottom"
                                assName="z-50"
                                delayShow={500}
                              />
                            </div>

                          </div>

                          <div className="mt-3 py-2 mx-4 px-4">
                            <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                              <dt className="font-semibold text-gray-800">Firmenname</dt>
                              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property?.owner_name}</dd>
                            </div>

                            <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                              <dt className="font-semibold text-gray-800">FB-Nr.</dt>
                              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property?.owner_fbnr}</dd>
                            </div>

                            <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                              <dt className="font-semibold text-gray-800">Adresse</dt>
                              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">{property?.owner_address}</dd>
                            </div>
                          </div>
                        </div>

                        <div className="mt-10 mb-4">
                          <p className="ml-4 text-xl">Details zum Insolvenzverfahren</p>

                          <div className="mt-2 mx-4 py-2 px-4 ">
                            <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                              <dt className="font-semibold text-gray-800">Veröffentlichung</dt>
                              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{formatDate({ date: property.insolvency?.published_date })}</dd>
                            </div>

                            <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                              <dt className="font-semibold text-gray-800">Letzte Änderung</dt>
                              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{formatDate({ date: property.insolvency?.last_change_date })}</dd>
                            </div>

                            <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                              <dt className="font-semibold text-gray-800">Verfahrensart</dt>
                              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{property.insolvency?.category?.name}</dd>
                            </div>

                            <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                              <dt className="font-semibold text-gray-800">Aktenzeichen</dt>
                              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
                                <a
                                  className="inline-flex items-center hover:underline"
                                  href={property.insolvency?.url}
                                  target="_blank" rel="noreferrer">
                                  <span>{property.insolvency?.record_number}</span>
                                  <ArrowTopRightOnSquareIcon className=" -mr-1 ml-1 w-5 h-5 text-primary" />
                                </a>
                              </dd>
                            </div>

                          </div>
                        </div>

                        <div className="mt-16">

                          <div className="mt-3 px-4 flex justify-between items-end">
                            <p className="text-xl">Alle Liegenschaften des Schuldners ({property.insolvency?.properties?.length})</p>
                            <p className="text-sm leading-none">Stichtagsdaten vom 01.04.2021</p>
                          </div>

                          <div className="mt-2.5 space-y-5 px-4 pb-4">
                            {property.insolvency?.properties?.map((insolvencyProperty, index) => (
                              <SlidePropertyCard key={`property_${index}`} property={insolvencyProperty} />
                            ))}
                          </div>
                        </div>

                      </div>

                    </dl>

                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
