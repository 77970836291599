import { useParams } from "react-router-dom";

import useProject from "~/src/hooks/use-project";
import SectionContainer from "~/src/ui/containers/section-container";
import AreaRange from "~/src/ui/displays/area-range";
import Table from "~/src/ui/tables/table";

/**
 *
 */
export default function ProjectQuantities() {
  const { id: projectId } = useParams();
  const { project } = useProject(projectId);

  const {
    quantities
  } = project;

  const columnTitles = [
    "Objektkategorie",
    "Einheiten",
    "Flächen"
  ];

  const rows = quantities.map(({
    category: {
      name: categoryName
    },
    total_units,
    total_area_range_from,
    total_area_range_to
  }) => [
    categoryName,
    total_units,
    <AreaRange area={total_area_range_from} toArea={total_area_range_to} />
  ]);

  const emptyText = "Einheiten nicht bekannt";

  const columnWidths = [
    "2/12",
    "6/12",
    "4/12"
  ];

  return (
    <SectionContainer title="Einheiten" className="break-after-page">
      <Table {...{
        columnTitles,
        rows,
        emptyText,
        columnWidths
      }} />
    </SectionContainer>
  );
}
