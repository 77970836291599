/**
 *
 * @param query
 */
const getQueryDoesMatch = (query) => {
  if (typeof window !== "undefined") {
    return window.matchMedia(query).matches;
  }

  return false;
};

/**
 *
 * @param query
 */
const getMediaQueryList = (query) => window.matchMedia(query);

export { getQueryDoesMatch, getMediaQueryList };
