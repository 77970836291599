import { finishYearLabels } from "~/src/modules/labels";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function FinishYearFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const finish_year = queryItems.split(",");

  function handleClick(fy) {
    const newFY = finish_year.filter((item) => item !== fy).join(",");

    submit("finish_year", newFY);
  }

  return finish_year.map((fy, index) => (
    <DefaultFilterTag key={`fy_tag_${index}`} handleClick={() => handleClick(fy)}>
      <span>Fertigstellung (Bau): {finishYearLabels[fy]}</span>
    </DefaultFilterTag>
  ));
}
