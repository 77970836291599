import produce from "immer";

/**
 *
 * @param watchlists
 * @param project
 * @param watchlist
 */
export function addWatchlist(watchlists, project, watchlist) {
  return produce(watchlists, (draft) => {
    draft.push({
      uuid: watchlist.uuid,
      name: watchlist.name,
      projects: [
        {
          name: project.name,
          slug: project.slug
        }
      ]
    });
  });
}

/**
 *
 * @param watchlists
 * @param project
 * @param uuid
 */
export function addProject(watchlists, project, uuid) {
  return produce(watchlists, (draft) => {
    const draftWatchlist = draft.find((wl) => wl.uuid === uuid);

    if (!draftWatchlist.projects) {
      draftWatchlist.projects = [];
    }
    draftWatchlist.projects.push({
      name: project.name,
      slug: project.slug
    });
  });
}

/**
 *
 * @param watchlists
 * @param project
 * @param uuid
 */
export function removeProject(watchlists, project, uuid) {
  return produce(watchlists, (draft) => {
    const draftWatchlist = draft.find((wl) => wl.uuid === uuid);

    draftWatchlist.projects = draftWatchlist.projects.filter((wl) => wl.slug !== project.slug);
  });
}

