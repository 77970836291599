/* eslint-disable func-style */
/* eslint-disable no-magic-numbers */
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

const noOptionsMessage = (data) => "Nach Projektnamen suchen...";

function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} className="pl-2">
      <BuildingOfficeIcon className="w-5 h-5 text-gray-400" /> {children}
    </components.Control>
  );
}

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = {
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af"
  }),
  control: (styles, state) => ({
    ...styles,
    height: 33,
    minHeight: 33,
    borderRadius: "0.25rem",
    boxShadow: "state.isFocused ? \"0 0 0 20px rgba(56,178,172,1)\" : 0",
    background: "#f4f4f5",
    borderColor: state.isFocused ? "#a3a3a3" : "#e5e7eb",
    borderWidth: state.isFocused ? "1px" : "1px",
    "&:hover": {
      borderColor: state.isFocused ? "#525252" : "#a3a3a3"
    }
  }),

  singleValue: (base) => ({
    ...base,
    borderRadius: "5px",
    padding: "0 6px",
    background: "#822c42",
    color: "white"
  })
};

/**
 *
 * @param root0
 * @param root0.handleUpdate
 * @param root0.placeholderStyle
 */
export default function ProjectSelect({
  handleUpdate,
  placeholderStyle
}) {
  const handleCreate = (value) => {
    handleUpdate("name", value.trim());
  };

  return (
    <CreatableSelect
      className="react-select"
      classNames={{ placeholder: () => "text-sm" }}
      instanceId="project-select"
      styles={customStyles}
      value={""}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input
      }}
      isClearable
      name="project-select"
      placeholder="Projektname"
      onCreateOption={handleCreate}
      blurInputOnSelect={true}
      noOptionsMessage={noOptionsMessage}
      formatCreateLabel={(userinput) => `Nach "${userinput}" suchen`}
    />
  );
}
