/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { Dialog, Transition } from "@headlessui/react";
import {
  Fragment,
  useEffect, useRef,
  useState
} from "react";

import CertificateFilterState from "./certificate-filter-state";
import { adjustCertificatesByQuery, expandCertificates } from "./_common/_exports";

import CloseIcon from "~/src/ui/icons/close-icon";
import useEsgCertificates from "~/src/hooks/use-esg-certificates";

// eslint-disable-next-line max-lines-per-function
/**
 *
 * @param root0
 * @param root0.active
 * @param root0.hide
 * @param root0.queryEsg
 * @param root0.update
 * @param root0.submit
 * @param root0.cancelEsgFilter
 * @param root0.tempQuery
 * @param root0.queryKey
 */
export default function EsgCertificatesFilterModal({
  active, hide, queryEsg, update, submit, cancelEsgFilter, tempQuery, queryKey = "certificates"
}) {
  const { certificates, mutate } = useEsgCertificates();

  const cancelButtonRef = useRef();

  const [
    exisitingCertificates,
    setExisitingCertificates
  ] = useState([]);

  const [
    updated,
    setUpdated
  ] = useState(false);

  const updateIssuer = (id, checked) => {
    const issuerAndCertificateIndex = exisitingCertificates.map(({ id: issuerAndCertificateId }) => issuerAndCertificateId).indexOf(id);

    const newIssuerAndCertificate = {
      ...exisitingCertificates[issuerAndCertificateIndex],
      checked
    };

    const newIssuerAndCertificates = exisitingCertificates;

    newIssuerAndCertificates[issuerAndCertificateIndex] = newIssuerAndCertificate;

    setExisitingCertificates(newIssuerAndCertificates);
    setUpdated(true);
  };

  const updateCertificates = (id, certificates) => {
    const issuerAndCertificateIndex = exisitingCertificates.map(({ id: issuerAndCertificateId }) => issuerAndCertificateId).indexOf(id);

    const newCertificates = [...certificates];

    const checked = newCertificates.every(({ checked: certificateChecked }) => certificateChecked);
    const indeterminate = newCertificates.some(({ checked: certificateChecked }) => certificateChecked) && !checked;

    const newIssuerAndCertificate = {
      ...exisitingCertificates[issuerAndCertificateIndex],
      issuerCertificates: newCertificates,
      checked,
      indeterminate
    };

    const newIssuerAndCertificates = exisitingCertificates;

    newIssuerAndCertificates[issuerAndCertificateIndex] = newIssuerAndCertificate;

    setExisitingCertificates(newIssuerAndCertificates);
    setUpdated(true);
  };

  useEffect(() => {
    if (updated) {
      setUpdated(false);

      const filterCertificates = exisitingCertificates
        .map((issuer) => {
          const {
            id: issuerId, issuerCertificates
          } = issuer;

          if (issuerCertificates.length) {
            const certificateIds = issuerCertificates.map((certificate) => {
              const { checked, id: certificateId } = certificate;

              if (checked) {
                return `${issuerId}-${certificateId}`;
              }

              return null;
            })
              .filter((value) => value !== null);

            return certificateIds;
          }

          return null;
        })
        .filter((value) => value?.length)
        .flat();

      const filterIssuers = exisitingCertificates
        .map((issuer) => {
          const {
            id: issuerId, checked: issuerChecked
          } = issuer;

          if (issuerChecked) {
            return `${issuerId}`;
          }

          return null;
        })
        .filter((value) => value?.length)
        .flat();

      update(
        queryKey,
        [...filterIssuers, ...filterCertificates]
      );
    }
  }, [updated]);

  const setInitialCertificates = (query) => {
    if (certificates) {
      const initialCertificates = certificates?.sort((certA, certB) => certA.name.localeCompare(certB.name, "de"))
        .map((certificates) => expandCertificates(certificates))
        .map((certificates) => adjustCertificatesByQuery(certificates, query));

      setExisitingCertificates(initialCertificates);
    }
  };

  useEffect(() => {
    if (certificates && queryEsg) {
      setInitialCertificates(queryEsg);
    }
  }, [certificates]);

  useEffect(() => {
    update(
      queryKey,
      queryEsg
    );
  }, []);

  useEffect(() => {
    setInitialCertificates(queryEsg);
  }, [queryEsg]);

  const handleSubmit = function () {
    submit();
    hide();
  };

  const handleCancel = () => {
    setInitialCertificates(queryEsg);
    cancelEsgFilter();
    hide();
  };

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-[150] overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={active}
        onClose={handleCancel}
      >
        <div className="flex min-h-full w-screen items-end justify-center text-center sm:block sm:w-auto sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all sm:my-8 sm:max-w-3xl">
              <div className="relative border-b border-gray-200 px-4 py-4 sm:px-6">
                <p className="text-center text-lg font-medium">Gebäudezertifikat filtern</p>
                <div className="absolute right-4 top-0 hidden h-full items-center sm:flex">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={handleCancel}
                  >
                    <CloseIcon className="h-5 w-5 text-black" />
                  </button>
                </div>
              </div>

              <div className="max-h-screen-66 min-h-0 divide-y divide-gray-300 overflow-y-scroll bg-white px-4 sm:max-h-screen-80 sm:divide-gray-200 sm:px-12">
                {
                  exisitingCertificates.map(({
                    id,
                    name,
                    checked,
                    indeterminate,
                    issuerCertificates
                  }, index) => (
                    <CertificateFilterState key={index} {...{
                      id,
                      name,
                      checked,
                      indeterminate,
                      issuerCertificates,
                      updateCertificates,
                      updateIssuer
                    }} />
                  ))
                }
              </div>

              <div className="space-y-4 border-t border-gray-200 px-4 py-4 text-center sm:flex sm:flex-row-reverse sm:items-center sm:justify-between sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none sm:ml-3 sm:w-auto"
                  onClick={handleSubmit}
                >
                  Suchen
                </button>
                <button
                  type="button"
                  className="font-medium text-gray-700 underline hover:bg-gray-50 focus:outline-none"
                  onClick={handleCancel}
                  ref={cancelButtonRef}
                >
                  zurück
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
