/* eslint-disable id-length */
/* eslint-disable no-undefined */
/* eslint-disable max-statements */
/* eslint-disable import/exports-last */

const removeEmptyArrayParamFromURL = function (q) {
  for (const [
    key,
    value
  ] of Object.entries(q)) {
    if (Array.isArray(value) && value.length === 0) {
      q[key] = undefined;
    }
  }
};

/**
 *
 * @param query
 * @param changedFilter
 * @param value
 */
export const updateQuery = function (query, changedFilter, value) {
  const q = {
    ...query,
    page: 1
  };

  if (q.sort === "radius") {
    delete q.sort;
    delete q.direction;
  }

  if (value) {
    if (changedFilter === "company") {
      q.company = value.value;
      q.companyLabel = value.label;
    }
    else if (changedFilter === "street") {
      q.streetLabel = value.label;
      q.locationType = value.locationType;

      if (q.locationType === "street_address") {
        q.street = value.value;
        q.sort = "radius";
        q.direction = "asc";
      }

      if ([
        "plz",
        "city",
        "city_plz",
        "street"
      ].includes(q.locationType)) {
        q.locationId = value.locationId;
        delete q.radius;
        delete q.street;
      }
    }
    else {
      const changedKey = changedFilter;

      q[changedKey] = value;
    }
  }
  else {
    const changedKey = changedFilter;

    delete q[changedKey];

    if (changedFilter === "company") {
      delete q.companyLabel;
    }
    if (changedFilter === "street") {
      delete q.streetLabel;
      delete q.locationType;
      delete q.street;
      delete q.locationId;
      if (q.sort === "radius") {
        delete q.sort;
      }
    }
  }

  if (changedFilter !== "page") {
    q.page = 1;
  }

  removeEmptyArrayParamFromURL(q);
  //  console.log({
  //    "[i]": "updateQuery",
  //    value, changedFilter, query, q
  //  });

  return q;
};

/**
 *
 * @param query
 */
export const deleteFilterQueries = function (query) {
  const {
    direction, sort, stats, marked
  } = query;

  const q = {
    page: 1,
    direction,
    sort,
    stats,
    marked
  };

  if (sort === "radius") {
    delete q.sort;
  }

  return q;
};

/**
 *
 * @param query
 * @param tempQuery
 */
export const updateQueryFromTemp = function (query, tempQuery) {
  const q = {
    ...query,
    page: 1
  };

  Object.keys(tempQuery).forEach((key) => {
    const item = tempQuery[key];

    if (!item) {
      q[key] = undefined;

      return;
    }

    if (key === "company") {
      q.company = item.value;
      q.companyLabel = item.label;
    }
    else if (key === "street") {
      q.street = item.value;
      q.streetLabel = item.label;
    }
    else {
      q[key] = item;
    }
  });

  removeEmptyArrayParamFromURL(q);

  return q;
};

/**
 *
 * @param query
 */
export const getQueryObject = function (query) {
  const searchParams = new URLSearchParams(query);

  const q = {};

  for (const key of searchParams.keys()) {
    q[key] = searchParams.get(key);
  }

  return q;
};
