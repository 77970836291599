/* eslint-disable max-len */
import { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import { useParams } from "react-router-dom";

import { handleExcelExport } from "./helpers";

import useProject from "~/src/hooks/use-project";
import ExcelIcon from "~/src/ui/icons/excel-icon";
import useUnitsExport from "~/src/hooks/use-units-export";
import useUserSettings from "~/src/hooks/use-user-settings";

/**
 *
 */
const ExcelExportProjectButton = () => {
  const [
    shouldFetch,
    setShouldFetch
  ] = useState(false);

  const { id: projectId } = useParams();
  const { project } = useProject(projectId);
  const { units } = useUnitsExport({
    project,
    shouldFetch
  });
  const { userSettings } = useUserSettings();

  const handleClick = () => {
    setShouldFetch(true);
  };

  useEffect(() => {
    if (shouldFetch && project && units) {
      setShouldFetch(false);
      handleExcelExport(userSettings, project, units);
    }
  }, [
    shouldFetch,
    project,
    units,
    userSettings
  ]);

  return (
    <div>
      <button
        data-tooltip-id="tooltip-excel-export"
        data-tooltip-content="Excel-Export"
        onClick={handleClick}
        className="flex items-center justify-center w-8 h-8 p-1 rounded-full cursor-pointer bg-excel-green disabled:grayscale disabled:cursor-wait hover:bg-excel-green-dark"
      >
        <ExcelIcon />
      </button>
      <Tooltip
        id="tooltip-excel-export"
        place="bottom"
        className="z-50"
        delayShow={500}
      />
    </div>
  );
};

export default ExcelExportProjectButton;
