import { useEffect } from "react";

import useRegions from "~/src/hooks/use-regions";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 * @param root0.onClick
 */
export default function RegionFilterTag({
  queryItems, submit, onClick
}) {
  const {
    regions,
    states,
    isLoading,
    mutate
  } = useRegions();

  useEffect(() => {
    mutate();
  }, [queryItems]);

  if (!queryItems || isLoading) {
    return null;
  }

  const regionQueryItems = queryItems;

  function handleClick(regionIds) {
    const newRegionQueryItems = regionQueryItems.filter((item) => !regionIds.includes(parseInt(item)));

    submit("region", newRegionQueryItems);
  }

  function handleModal() {
    onClick();
  }

  const regionStates = regionQueryItems?.sort((a, b) => a - b).map((queryRegion) => {
    const region = regions?.find((region) => region.id === queryRegion);

    return region || null;
  });

  const grouped = regionStates?.reduce((acc, value) => {
    if (acc[value?.state?.code]) {
      acc[value?.state?.code].regions.push(value);
    }
    else {
      acc[value?.state?.code] = {
        regions: [value],
        name: value?.state?.name,
        code: value?.state?.code,
        numberOfRegions: parseInt(states?.find((state) => state.code === value?.state?.code)?.numberOfRegions)
      };
    }

    return acc;
  }, {});

  const totalRegionsSelected = Object.values(grouped).reduce((acc, curr) => (acc + ((curr.numberOfRegions === curr.regions.length) ? 1 : curr.regions.length)), 0);

  const tags = (totalRegionsSelected > 10)
    ? <DefaultFilterTag key={"filter_region_more_than"} handleClick={() => handleModal()}>
      <span>Region: mehr als 10 ausgewählt</span>
    </DefaultFilterTag>
    : Object.keys(grouped).map((key) => {
      const group = grouped[key];

      if (group.numberOfRegions === group.regions.length) {
        return (
          <DefaultFilterTag key={`filter_region_state_${group.code}`} handleClick={() => handleClick(group.regions.map((region) => region.id))}>
            <span>Region: {group.name}</span>
          </DefaultFilterTag>
        );
      }

      return group.regions.map((region) => (
        <DefaultFilterTag key={`filter_region_${region?.id}`} handleClick={() => handleClick([region.id])}>
          <span>Region: {region?.name}</span>
        </DefaultFilterTag>
      ));
    });

  return (
    <>
      {tags}
    </>
  );
}
