import useSWR from "swr";
import { stringify } from "query-string";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.query
 */
export default function usePurchases({ query }) {
  const { data, error } = useSWR(`/purchases?${stringify(query)}`, fetcher);

  return {
    purchases: data?.payload?.purchases,
    pagination: data?.payload?.pagination,
    sortDirection: data?.payload?.sortDirection,
    isLoading: !error && !data,
    isError: error
  };
}
