import API from "~/src/modules/api";

/**
 *
 * @param userSettings
 * @param handleSuccess
 * @param handleError
 */
export function updateUserSettings(userSettings, handleSuccess, handleError) {
  API.put("/user-settings", {
    user_settings: userSettings
  }).then((res) => {
    handleSuccess(res.data.payload, "create");
  }).catch((e) => {
    handleError(e?.response?.status || 500, "user-settings");
  });
}
