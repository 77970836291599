/* eslint-disable object-property-newline */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable func-style */
import { generateLineData } from "./tools";

import LineChart from "~/src/ui/charts/line-chart";
import { priceLabels } from "~/src/modules/labels";
import ChartLegend from "~/src/ui/charts/legend";

/**
 *
 * @param root0
 * @param root0.graphLabels
 * @param root0.statistic
 */
function SquareMeterPriceByAreaOfferChartForPrint({
  graphLabels,
  statistic
}) {
  return graphLabels
    .map((graphLabel) => {
      const lineData = generateLineData(graphLabel, statistic);

      return (
        <div key={graphLabel} className="w-full space-y-4 bg-white break-inside-avoid">
          <h2 className="font-medium text-gray-900 text-md">{priceLabels[graphLabel]} nach Fläche</h2>
          <div className="h-72">
            <LineChart
              data={lineData.chartData}
              withCategories
              x={{
                type: "squareMeters",
                min: 0,
                max: 200
              }}
              y={{
                min: lineData.chartData[0].min,
                max: lineData.chartData[0].max
              }}
            />
          </div>
          <ChartLegend data={lineData.legendData} simple />
        </div>
      );
    });
}

export { SquareMeterPriceByAreaOfferChartForPrint };
