/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable import/max-dependencies */
import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import DeleteWatchlistItemModal from "~/src/features/delete-watchlist-item-modal/index.jsx";
import dateFormatter from "~/src/modules/date-formatter.js";
import { formatEuro } from "~/src/modules/formatters.js";
import {
  constructionPhaseLabels, liegenschaftLabels, marketingPhaseLabels,
  priceLabels
} from "~/src/modules/labels.js";
import { getQueryObject } from "~/src/modules/query-helper.js";
import ProjectCompany from "~/src/ui/displays/project-company/index.jsx";
import ProjectLocation from "~/src/ui/displays/project-location/index.jsx";
import CheckmarkCircleIcon from "~/src/ui/icons/checkmark-circle-icon/index.jsx";
import EsgIcon from "~/src/ui/icons/esg-icon/index.jsx";
import ProjectLink from "~/src/ui/links/project-link/index.jsx";

const displayRechtsform = (project) => {
  if (project.rentable && project.buyable) {
    return "Eigentum, Miete";
  }
  if (project.rentable) {
    return "Miete";
  }
  if (project.buyable) {
    return "Eigentum";
  }

  return "nicht bekannt";
};

/**
 *
 * @param props
 * @param props.project
 * @param props.handleSingleMapProject
 * @param props.watchlist
 * @param props.mutateWatchlist
 * @param props.mutateWatchlists
 */
// eslint-disable-next-line max-lines-per-function, complexity
const WatchlistProjectListItem = ({
  project,
  handleSingleMapProject,
  watchlist,
  mutateWatchlist,
  mutateWatchlists
}) => {
  const location = useLocation();
  const [
    currentSortValue,
    setCurrentSortValue
  ] = useState(null);
  const [
    showDelete,
    setShowDelete
  ] = useState(false);
  const [
    showMultipleLocations,
    setShowMultipleLocations
  ] = useState(false);

  useEffect(() => {
    const query = getQueryObject(location.search);

    if (query && query.sortBy) {
      setCurrentSortValue(query.sortBy);
    }
    else {
      setCurrentSortValue(null);
    }
  }, [location]);

  const builders = project?.company_roles.filter((role) => role.name === "builder");
  const owners = project?.company_roles.filter((role) => role.name === "owner");
  const marketers = project?.company_roles.filter((role) => role.name === "marketer");

  const completed = project.timeline.current_construction_phase === "construction_phase_completed";
  let completedDate;

  if (completed) {
    completedDate = (project?.timeline?.construction_phase_completed_date !== null && project?.timeline?.construction_phase_completed_date_format !== null)
      ? dateFormatter(project?.timeline?.construction_phase_completed_date, project?.timeline?.construction_phase_completed_date_format)
      : project.finish_year;
  }

  if (!completed && project.construction_infos.estimated_finish_date) {
    completedDate = dateFormatter(
      project.construction_infos.estimated_finish_date,
      project.construction_infos?.estimated_finish_date_format
    );
  }

  const lastContractDate = project?.timeline?.last_contract_date
    ? dateFormatter(project.timeline.last_contract_date, "year")
    : null;

  return (
    <div key={`project_${project.slug}`} className="relative">

      <DeleteWatchlistItemModal project={project?.slug} watchlist={watchlist?.uuid} show={showDelete} hideModal={() => setShowDelete(false)} mutateWatchlist={mutateWatchlist} mutateWatchlists={mutateWatchlists} />

      <div className="grid-cols-9 px-2 pt-5 pb-8 sm:px-6 lg:grid gap-x-2">

        <div className="mt-1 lg:mt-0.5 col-span-6 xl:col-span-3 2xl:col-span-4 break-words flex flex-col items-start">
          <ProjectLink project={project} backlink="watchlist">
            <p className="inline-block font-medium text-primary sm:text-xl">
              <span className="border-b-2 border-primary hover:text-primary-dark">{project.name}</span>
            </p>
          </ProjectLink>

          <div className="mt-2">
            {project.locations.length > 1 &&
              <div className="flex">
                <button type="button" onClick={() => handleSingleMapProject(project, 0)} className="block focus:outline-none hover:underline">
                  <ProjectLocation location={project.locations.find(({ default_location }) => default_location) ?? project.locations[0]} />
                </button>
                {!showMultipleLocations &&
                  <button type="button" onClick={() => setShowMultipleLocations(true)} className="ml-2">
                    <ChevronDownIcon className="w-6 h-6 text-gray-500" />
                  </button>
                }
                {showMultipleLocations &&
                  <button type="button" onClick={() => setShowMultipleLocations(false)} className="ml-2">
                    <ChevronUpIcon className="w-6 h-6 text-gray-500" />
                  </button>
                }
              </div>
            }

            <Transition
              show={showMultipleLocations}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {
                project.locations.length > 1 && project.locations
                  .filter(({ default_location }) => !default_location)
                  .map((currentLocation, index) => (
                    <div className="flex" key={`multiple_location_${currentLocation.id}`}>
                      <button type="button" onClick={() => handleSingleMapProject(project, index)} className="block focus:outline-none hover:underline">
                        <ProjectLocation location={currentLocation} />
                      </button>
                    </div>
                  ))
              }
            </Transition>

            {project.locations.length <= 1 && project.locations.map((currentLocation, index) => (
              <div className="flex" key={`single_location_${currentLocation.id}`}>
                <button type="button" onClick={() => handleSingleMapProject(project, index)} className="block focus:outline-none hover:underline">
                  <ProjectLocation location={currentLocation} />
                </button>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-start mt-4 overflow-hidden">
            {[
              ...owners,
              ...builders,
              ...marketers
            ].map((role, index) => <ProjectCompany key={index} role={role} automated={project?.automated} />)}
          </div>

        </div>

        <div className="col-span-3 mt-10 mr-2 lg:mt-2 xl:col-span-2">
          {project?.show_image_public && project?.image_file_name &&
            <ProjectLink project={project} backlink="projects">
              <div className={cn("relative w-64 xl:w-48 2xl:w-64 pb-2/3 rounded-lg overflow-hidden", {
                "h-40 xl:h-32 2xl:h-40": project?.image_landscape,
                "h-64 xl:h-64 2xl:h-64": !project?.image_landscape
              })}>
                <img className={cn("absolute object-cover w-full h-full transition duration-500 transform", { "hover:scale-110": !project?.automated })} src={`https://immodeveloper.fra1.digitaloceanspaces.com/${project?.image_file_name}`} alt={project?.name} />
              </div>
            </ProjectLink>
          }
        </div>

        <div className="col-span-4 xl:col-span-4 2xl:col-span-3 mt-12 lg:mt-0 xl:mt-1.5">
          <div className="flex items-start justify-start xl:justify-between">
            <div className="flex justify-between items-center w-[min(100%,17.5rem)]">
              <div
                className={"-ml-1 flex items-center text-sm gap-2 text-gray-500"}
              >
                <CheckmarkCircleIcon />
                <p className="text-sm sm:text-base">
                  {
                    project?.automated
                      ? `Historisches Projekt${lastContractDate ? ` - ${lastContractDate}` : ""}`
                      : (completedDate ?? "nicht bekannt")
                  }
                </p>

              </div>
              {
                (project?.project_certificates && project?.project_certificates.length > 0)
                  ? <EsgIcon />
                  : null
              }
            </div>

            <div className="ml-4 xl:ml-4 mt-0.5">
              <button type="button" onClick={() => setShowDelete(true)} className="flex items-center px-2 py-0.5 text-xs text-gray-600 border border-gray-400 rounded bg-gray-50 hover:bg-gray-100 focus:outline-none">
                <span>Projekt entfernen</span> <XMarkIcon className="ml-1 -mr-0.5 w-3 h-3 text-gray-600" />
              </button>
            </div>
          </div>

          <div className="mt-4 space-y-1">
            {(project?.timeline.current_construction_phase && project?.timeline.current_construction_phase !== "unknown") &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64 shrink-0">
                  <p className="font-semibold leading-4 text-gray-600">Bauphase:</p>
                </div>
                <div>
                  <p className="leading-4.5">{constructionPhaseLabels[project?.timeline.current_construction_phase || ""]}</p>
                </div>
              </div>
            }

            {(project?.timeline.current_marketing_phase && project?.timeline.current_marketing_phase !== "unknown") &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64 shrink-0">
                  <p className="font-semibold leading-4 text-gray-600">Vermarktungsphase:</p>
                </div>
                <div>
                  <p className="leading-4">{marketingPhaseLabels[project?.timeline.current_marketing_phase || ""]}</p>
                </div>
              </div>
            }
          </div>

          <div className="mt-5">

            {(project?.transaction_filter && liegenschaftLabels[project?.transaction_filter]) &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className="font-semibold text-gray-600">Liegenschaftsankauf:</p>
                </div>
                <div>
                  <p>{liegenschaftLabels[project.transaction_filter]}</p>
                </div>
              </div>
            }

            <div className="flex flex-col sm:flex-row">
              <div className="w-64">
                <p className="font-semibold text-gray-600">Rechtsform:</p>
              </div>
              <div>
                {displayRechtsform(project)}
              </div>
            </div>

            {
              project.count_wohnungen !== 0 &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "wohnungen" })}>
                    {project.automated ? "Erfasste Transaktionen" : "Gesamtanzahl Wohneinheiten"}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "wohnungen" })}>
                    {project.count_wohnungen}
                  </p>
                </div>
              </div>
            }
          </div>

          {
            (project?.funding.not_funded || project?.funding.normal_funded) &&
            <div className="flex flex-col sm:flex-row">
              <div className="w-64">
                <p className={cn("font-semibold text-gray-600")}>
                  Förderung:
                </p>
              </div>
              <div>
                {project?.funding.not_funded && project?.funding.normal_funded &&
                  <p>gemischt</p>
                }
                {!project?.funding.not_funded && project?.funding.normal_funded &&
                  <p>gefördert</p>
                }
                {project?.funding.not_funded && !project?.funding.normal_funded &&
                  <p>freifinanziert</p>
                }
              </div>
            </div>
          }

          <div className="mt-5">
            {project?.gka &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "gka" })}>
                    {priceLabels.gka}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "gka" })}>
                    {formatEuro(project?.gka)}/m²
                  </p>
                </div>
              </div>
            }
            {project?.avg_offer_price_eigennutzer &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgPreisEigennutzer" })}>
                    {priceLabels.averageOfferPriceNormal}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgPreisEigennutzer" })}>
                    {formatEuro(project?.avg_offer_price_eigennutzer)}/m²
                  </p>
                </div>
              </div>
            }
            {project?.avg_offer_price_investor &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgPreisEigennutzer" })}>
                    {priceLabels.averageOfferPriceInvestorNet}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgPreisEigennutzer" })}>
                    {formatEuro(project?.avg_offer_price_investor)}/m²
                  </p>
                </div>
              </div>
            }
            {project?.avg_miete &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgRent" })}>
                    {priceLabels.averageRentNet}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgRent" })}>
                    {formatEuro(project?.avg_miete)}/m²
                  </p>
                </div>
              </div>
            }
            {project?.avg_rent_bk &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgRent" })}>
                    {priceLabels.averageRentTotal}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgRent" })}>
                    {formatEuro(project?.avg_rent_bk)}/m²
                  </p>
                </div>
              </div>
            }
            {project?.avg_sale_price_eigennutzer &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgPreisVerkauf" })}>
                    {priceLabels.averageSalePriceNormal}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgPreisVerkauf" })}>
                    {formatEuro(project?.avg_sale_price_eigennutzer)}/m²
                  </p>
                </div>
              </div>
            }
            {project?.avg_sale_price_investor &&
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgPreisVerkauf" })}>
                    {priceLabels.averageSalePriceInvestorNet}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgPreisVerkauf" })}>
                    {formatEuro(project?.avg_sale_price_investor)}/m²
                  </p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchlistProjectListItem;
