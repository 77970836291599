import { useParams } from "react-router-dom";

import ContentSection from "~/src/ui/sections/content-section";
import dateFormatter from "~/src/modules/date-formatter";
import useProject from "~/src/hooks/use-project";

/**
 *
 */
const ProjectOverviewConstructionInfos = () => {
  const { id } = useParams();
  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    construction_infos: {
      is_new: isNew,
      construction_year: constructionYear,
      construction_start_date: constructionStartDate,
      construction_start_date_format: constructionStartDateFormat,
      estimated_finish_date: estimatedFinishDate,
      estimated_finish_date_format: estimatedFinishDateFormat
    }
  } = project;

  return (
    <ContentSection id="constructionInfos" title="Bauinformationen">
      <ul className="flex flex-col gap-0.5">
        <li>{isNew ? "Neubau" : "Sanierung"}</li>
        <li>
          Baubewilligung: {constructionYear ? dateFormatter(constructionYear, "year") : "-"}
        </li>
        <li>
          Geplanter Baubeginn: {constructionStartDate ? dateFormatter(constructionStartDate, constructionStartDateFormat) : "-"}
        </li>
        <li>
          Geplante Fertigstellung: {estimatedFinishDate ? dateFormatter(estimatedFinishDate, estimatedFinishDateFormat) : "-"}
        </li>
      </ul>
    </ContentSection>
  );
};

export default ProjectOverviewConstructionInfos;
