import useStore from "~/src/hooks/use-store";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function PolygonFilterTags({ queryItems, submit }) {
  const setReadPolygonsFromQuery = useStore((state) => state.setReadPolygonsFromQuery);

  if (!queryItems) {
    return null;
  }

  const polygons = queryItems;

  function handleClick(polygon) {
    const newPolygon = polygons.filter((item) => item !== polygon);

    setReadPolygonsFromQuery(true);

    submit("polygon", newPolygon);
  }

  return polygons.map((polygon, index) => (
    <DefaultFilterTag key={`polygon_${index}`} handleClick={() => handleClick(polygon)}>
      <span>Polygon: {index + 1}</span>
    </DefaultFilterTag>
  ));
}
