import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 */
export default function FinishYearSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const tempItems = tempQuery?.split(",") || [];

  const has2026 = items.includes("2026");
  const has2025 = items.includes("2025");
  const has2024 = items.includes("2024");
  const has2023 = items.includes("2023");
  const has2022 = items.includes("2022");
  const has2021 = items.includes("2021");
  const has2020 = items.includes("2020");
  const has2019 = items.includes("2019");

  const hasTemp2026 = tempItems.includes("2026");
  const hasTemp2025 = tempItems.includes("2025");
  const hasTemp2024 = tempItems.includes("2024");
  const hasTemp2023 = tempItems.includes("2023");
  const hasTemp2022 = tempItems.includes("2022");
  const hasTemp2021 = tempItems.includes("2021");
  const hasTemp2020 = tempItems.includes("2020");
  const hasTemp2019 = tempItems.includes("2019");

  function onCheck(event, key) {
    handleCheck("finish_year", event, tempQuery, items, tempItems, key, update);
  }

  function checked(item, tempItem) {
    return handleCheckedStatus(query, item, tempQuery, tempItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Fertigstellung <span className="font-light">(Bau)</span>
      </p>
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 font-light">
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has2026, hasTemp2026)} onChange={(e) => onCheck(e, "2026")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">ab 2026</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has2025, hasTemp2025)} onChange={(e) => onCheck(e, "2025")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">2025</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has2024, hasTemp2024)} onChange={(e) => onCheck(e, "2024")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">2024</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has2023, hasTemp2023)} onChange={(e) => onCheck(e, "2023")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">2023</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has2022, hasTemp2022)} onChange={(e) => onCheck(e, "2022")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">2022</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has2021, hasTemp2021)} onChange={(e) => onCheck(e, "2021")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">2021</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has2020, hasTemp2020)} onChange={(e) => onCheck(e, "2020")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">2020</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has2019, hasTemp2019)} onChange={(e) => onCheck(e, "2019")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">bis 2019</span>
          </label>
        </div>
      </div>
    </div>
  );
}
