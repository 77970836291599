import {
  markerIconCircle,
  markerIconCompletion,
  markerIconConstruction,
  markerIconEmpty,
  markerIconNone,
  markerIconPurchase
} from "./get-inner-icon/_exports.js";

/**
 *
 * @param iconName
 * @param outerColor
 * @example
 */
const getInnerIcon = (iconName, outerColor) => {
  switch (iconName) {
    case "completion":
      return markerIconCompletion(outerColor);
    case "construction":
      return markerIconConstruction(outerColor);
    case "purchase":
      return markerIconPurchase(outerColor);
    case "circle":
      return markerIconCircle(outerColor);
    case "empty":
      return markerIconEmpty(outerColor);
    case "none":
      return markerIconNone();
    default:
      break;
  }
};

export default getInnerIcon;
