/* eslint-disable max-len */
/* eslint-disable import/max-dependencies */
import { RadioGroup, Transition } from "@headlessui/react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import { useState } from "react";

import LegendBox from "../legend-box/index.jsx";

import GoogleMapsWrapper from "~/src/features/maps/google-maps.jsx";
import LocationSearchSelect from "~/src/ui/selects/location-search-select/index.jsx";
import ProjectsMap from "~/src/features/maps/projects-map/index.jsx";
import useMediaQuery from "~/src/hooks/use-media-query.js";
import SelectAllProjectsFilter from "~/src/ui/filters/select-all-projects-filter/index.jsx";
import useStore from "~/src/hooks/use-store.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useProjectList from "~/src/hooks/use-project-list.js";
import useCount from "~/src/hooks/use-count.js";

/**
 *
 * @param props
 * @param props.projectSlugs
 * @param props.isSimple
 * @param props.handleCheckbox
 * @param props.automated
 */
// eslint-disable-next-line max-lines-per-function
const ProjectsMapTile = ({
  projectSlugs = null,
  isSimple = false,
  automated = null,
  handleCheckbox
}) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const { query } = useProjectQueryParams();
  const {
    selectionStatus
  } = useProjectList({
    query,
    isSimple
  });

  const [
    mapType,
    setMapType
  ] = useState("leaflet");

  const [
    searchLocation,
    setSearchLocation
  ] = useState(null);

  const [
    isStreetView,
    setIsStreetView
  ] = useState(false);
  const [
    legendOpen,
    setLegendOpen
  ] = useState(false);

  const currentSelectionStatus = useStore((state) => state.currentSelectionStatus);
  const selectionLoading = useStore((state) => state.selectionLoading);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);
  const setChange = useStore((state) => state.setChange);

  const {
    projects: {
      filtered: projectCountFiltered,
      selected: projectCountSelected
    } = {}
  } = useCount({ query });

  const updateSelection = (update) => {
    setChange(update);
  };

  const setAll = async (value) => {
    const newMainType = value ? "addAll" : "removeAll";

    setMainSelectionType(newMainType);

    await updateSelection({
      type: newMainType,
      source: "map"
    });
  };

  const handleAllChecked = async (value) => {
    if (!selectionLoading) {
      const checkAll = selectionStatus === "some" && projectCountSelected > projectCountFiltered / 2
        ? false
        : value;

      await setAll(checkAll);
    }
  };

  return (
    <>
      <div className="relative flex flex-col w-full h-full overflow-hidden bg-white border-gray-200 lg:rounded lg:border">
        {
          !isSimple && (
            <div className="top-0 z-[15] flex flex-col items-center justify-between gap-4 p-4 text-sm bg-white border-b border-gray-200 lg:flex-row">
              <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-4 lg:h-[30px]">
                <div className="shrink-0">
                  <SelectAllProjectsFilter selectionStatus={currentSelectionStatus} handleCheck={handleAllChecked} disabled={selectionLoading} />
                </div>
                {
                  (!isSimple && !isMobile) && (
                    <div className="text-md">
                      <LocationSearchSelect isMobile={isMobile} title={searchLocation?.title} onSelect={setSearchLocation} className="hidden w-60 shrink-0 lg:block" />
                    </div>
                  )
                }
                <RadioGroup value={mapType} onChange={setMapType} className="grid w-full h-full grid-cols-2 overflow-hidden text-xs rounded cursor-pointer">
                  <RadioGroup.Option value={"leaflet"}>
                    {({ checked }) => (
                      <span
                        className={cn(
                          "flex h-full items-center justify-center px-2 py-1",
                          {
                            "text-white bg-primary": checked,
                            "text-gray-900 bg-secondary-light": !checked
                          }
                        )}
                      >
                        Standard
                      </span>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option value={"google"}>
                    {({ checked }) => (
                      <span
                        className={cn(
                          "flex h-full items-center justify-center px-2 py-1",
                          {
                            "text-white bg-primary-green": checked,
                            "text-gray-900 bg-secondary-light": !checked
                          }
                        )}
                      >
                        Street View
                      </span>
                    )}
                  </RadioGroup.Option>
                </RadioGroup>
              </div>

            </div>
          )
        }

        {
          (isMobile && !isSimple && mapType === "leaflet") && (
            <>
              <button
                className="absolute z-20 text-white rounded-full bg-secondary-light bottom-16 right-4"
                onClick={() => setLegendOpen(!legendOpen)}
              >
                <InformationCircleIcon className="w-12 h-12 text-primary" />
              </button>
              <Transition
                show={legendOpen}
                className="absolute bottom-0 z-20"
              >
                <Transition.Child
                  show={legendOpen}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="w-[calc(100vw-5rem)] max-w-[330px] p-4 rounded bg-secondary-light text-xs">
                    <LegendBox />
                    <button
                      className="absolute top-4 right-4"
                      onClick={() => setLegendOpen(false)}
                    >
                      <XMarkIcon className="w-4 h-4 text-gray-900" />
                    </button>
                  </div>
                </Transition.Child>
              </Transition>
            </>

          )
        }

        <div className="h-full">
          {
            {
              leaflet: <ProjectsMap {...{
                handleCheckbox,
                setIsStreetView,
                isSimple,
                automated,
                projectSlugs,
                searchLocation
              }} />,
              google: <GoogleMapsWrapper {...{
                handleCheckbox,
                setIsStreetView,
                isSimple,
                automated,
                projectSlugs,
                searchLocation
              }} />
            }[mapType]
          }
        </div>

      </div>
    </>
  );
};

export default ProjectsMapTile;
