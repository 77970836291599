/**
 *
 * @param root0
 * @param root0.className
 */
export default function MarkerIconConstruction({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={className}>
      <g>
        <path
          d="M93.2,87.3c-2.3,0-5,0-7.3,0l-5-15.8H19.1l-5,16.2c-2.3,0-5,0-7.3,0c-7.3,0-7.3,12.4,0,12.4c29,0,57.5,0,86.5,0C100.6,99.6,100.6,87.3,93.2,87.3z"
          fill="currentColor"
        />
        <path
          d="M60.4,3.9c-0.8-1.9-3.1-3.9-5-3.9h-10c-2.3,0-4.6,1.5-5,3.9l-6.6,21.2h33.6L60.4,3.9z"
          fill="currentColor"
        />
        <polygon
          points="71.6,40.9 28.8,40.9 24.1,56 76.3,56"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
