/* eslint-disable no-magic-numbers */
/* eslint-disable max-params */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { groupBy } from "lodash-es";

import {
  buildCell, buildUnitColumns, setUnitData, unitHeaders
} from "./builders";

const GRAY = "FFD9D9D9";
const WHITE = "FFFFFFFF";
const PURPLE = "FF706FD3";
const GREEN = "FF218C74";

/**
 *
 * @param workbook
 * @param userSettings
 * @param projects
 * @param units
 * @param sheetLabel
 */
export default function unitSheetGenerator(
  workbook,
  userSettings,
  projects,
  units,
  sheetLabel = "Exportierte Wohneinheiten"
) {
  const { use_custom_area_ratios: customArea } = userSettings;

  const groupedUnits = Object.values(groupBy(units, "unit_category_id"))
    .flat()
    .sort((unitA, unitB) => unitA.unit_category_id - unitB.unit_category_id);

  const unitSheet = workbook.addWorksheet(sheetLabel, {
    properties: {
      tabColor: {
        argb: PURPLE
      }
    }
  });

  const row1 = unitSheet.addRow();
  const row2 = unitSheet.addRow();

  buildUnitColumns(unitSheet);

  buildCell({
    row: row1,
    cellIndex: 1,
    value: "Angebotsdaten (A)",
    font: { color: WHITE },
    bgColor: PURPLE
  });
  buildCell({
    row: row2,
    cellIndex: 1,
    value: "Verkaufsdaten (V)",
    font: { color: WHITE },
    bgColor: GREEN
  });

  buildCell({
    row: row1,
    cellIndex: 3,
    value: "Loggia %",
    font: { bold: true },
    bgColor: GRAY,
    borders: { left: true }
  });
  buildCell({
    row: row2,
    cellIndex: 3,
    value: customArea ? userSettings.custom_loggia_ratio : 100,
    bgColor: GRAY,
    borders: {
      left: true,
      bottom: true
    },
    alignment: "left"
  });

  buildCell({
    row: row1,
    cellIndex: 4,
    value: "Balkon %",
    font: { bold: true },
    bgColor: GRAY
  });
  buildCell({
    row: row2,
    cellIndex: 4,
    value: customArea ? userSettings.custom_balkon_ratio : 0,
    bgColor: GRAY,
    borders: { bottom: true },
    alignment: "left"
  });

  buildCell({
    row: row1,
    cellIndex: 5,
    value: "Terrasse %",
    font: { bold: true },
    bgColor: GRAY
  });
  buildCell({
    row: row2,
    cellIndex: 5,
    value: customArea ? userSettings.custom_terrasse_ratio : 0,
    bgColor: GRAY,
    borders: { bottom: true },
    alignment: "left"
  });

  buildCell({
    row: row1,
    cellIndex: 6,
    value: "Dachterrasse %",
    font: { bold: true },
    bgColor: GRAY
  });
  buildCell({
    row: row2,
    cellIndex: 6,
    value: customArea ? userSettings.custom_dachterrasse_ratio : 0,
    bgColor: GRAY,
    borders: { bottom: true },
    alignment: "left"
  });

  buildCell({
    row: row1,
    cellIndex: 7,
    value: "Garten %",
    font: { bold: true },
    bgColor: GRAY
  });
  buildCell({
    row: row2,
    cellIndex: 7,
    value: customArea ? userSettings.custom_garten_ratio : 0,
    bgColor: GRAY,
    borders: { bottom: true },
    alignment: "left"
  });

  buildCell({
    row: row1,
    cellIndex: 8,
    value: "Keller %",
    font: { bold: true },
    bgColor: GRAY,
    borders: { right: true }
  });
  buildCell({
    row: row2,
    cellIndex: 8,
    value: customArea ? userSettings.custom_keller_ratio : 0,
    bgColor: GRAY,
    borders: { bottom: true },
    alignment: "left"
  });

  buildCell({
    row: row2,
    cellIndex: 9,
    value: "Prozentwerte anpassen für individuelle Flächengewichtung",
    bgColor: GRAY,
    borders: {
      top: true,
      bottom: true
    }
  });
  buildCell({
    row: row2,
    cellIndex: 10,
    value: null,
    bgColor: GRAY,
    borders: {
      top: true,
      bottom: true
    }
  });
  buildCell({
    row: row2,
    cellIndex: 11,
    value: null,
    bgColor: GRAY,
    borders: {
      top: true,
      bottom: true,
      right: true
    }
  });

  unitSheet.addRow();
  unitSheet.addRow();

  const headerRow = unitSheet.addRow();

  unitHeaders.forEach((column, index) => {
    const cell = headerRow.getCell(index + 1);

    cell.value = column.title;
    cell.font = { size: 12 };

    if (column.type === "offer") {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: PURPLE }
      };
      cell.font = {
        color: { argb: WHITE }
      };
    }

    if (column.type === "sale") {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: GREEN }
      };
      cell.font = {
        color: { argb: WHITE }
      };
    }
  });

  groupedUnits.forEach((unit, index) => {
    // 1 based index + starting from 6. row
    const adjustedIndex = index + 1 + 5;
    const row = unitSheet.addRow();

    setUnitData(adjustedIndex, row, unit, projects);
  });

  unitSheet.views = [
    {
      state: "frozen",
      ySplit: 5,
      xSplit: 1
    }
  ];

  unitSheet.autoFilter = "A5:BB5";
}
