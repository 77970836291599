import { useParams } from "react-router-dom";

import SectionContainer from "~/src/ui/containers/section-container";
import useProjectExport from "~/src/hooks/use-project-export";

/**
 *
 */
export default function ProjectDescription() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    description
  } = project;

  return (
    <SectionContainer title="Beschreibung">
      <p className="text-justify">{description}</p>
    </SectionContainer>
  );
}
