import { constructionPhaseLabels } from "~/src/modules/labels";
import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 */
export default function ConstructionPhaseSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const tempItems = tempQuery?.split(",") || [];

  const hasPurchase = items.includes("construction_phase_purchase");
  const hasConstructionActive = items.includes("construction_phase_active");
  const hasConstructionCompleted = items.includes("construction_phase_completed");
  const hasUnknown = items.includes("unknown");

  const hasTempPurchase = tempItems.includes("construction_phase_purchase");
  const hasTempConstructionActive = tempItems.includes("construction_phase_active");
  const hasTempConstructionCompleted = tempItems.includes("construction_phase_completed");
  const hasTempUnknown = tempItems.includes("unknown");

  function onCheck(event, key) {
    handleCheck("construction_phase", event, tempQuery, items, tempItems, key, update);
  }

  function checked(item, tempItem) {
    return handleCheckedStatus(query, item, tempQuery, tempItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Bauphase
      </p>
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 font-light">
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasPurchase, hasTempPurchase)} onChange={(e) => onCheck(e, "construction_phase_purchase")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{constructionPhaseLabels.construction_phase_purchase}</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasConstructionActive, hasTempConstructionActive)} onChange={(e) => onCheck(e, "construction_phase_active")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{constructionPhaseLabels.construction_phase_active}</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasConstructionCompleted, hasTempConstructionCompleted)} onChange={(e) => onCheck(e, "construction_phase_completed")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{constructionPhaseLabels.construction_phase_completed}</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasUnknown, hasTempUnknown)} onChange={(e) => onCheck(e, "unknown")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{constructionPhaseLabels.unknown}</span>
          </label>
        </div>
      </div>
    </div>
  );
}
