import { useParams } from "react-router-dom";
import React from "react";

import ContentSection from "~/src/ui/sections/content-section";
import useProject from "~/src/hooks/use-project";
import { formatNumber } from "~/src/modules/formatters";
import dateFormatter from "~/src/modules/date-formatter";

/**
 *
 */
const ProjectOverviewZonings = () => {
  const { id } = useParams();
  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    widmungen: zonings
  } = project;

  if (!zonings.length) {
    return <ContentSection id="zonings" title="Flächenwidmungen">
      <span>-</span>
    </ContentSection>;
  }

  return (
    <ContentSection id="zonings" title="Flächenwidmungen">
      <div className="grid grid-cols-2 gap-x-8">
        <span className="font-bold">Plandokument</span>
        <span className="font-bold">Widmung</span>
        {
          zonings.map(
            ({
              id,
              widmung,
              bauklasse,
              height_limit_sign: heightLimitSign,
              height_limit: heightLimit,
              height_limit_to: heightLimitTo,
              bauweise,
              additional,
              plan_number: planNumber,
              publish_date: publishDate
            }) => (
              <React.Fragment key={id}>
                <div className="flex gap-x-2">
                  <span>{planNumber}</span>
                  <span>{dateFormatter(publishDate)}</span>
                </div>
                <div className="flex gap-x-2">
                  {
                    [
                      widmung,
                      bauklasse,
                      heightLimit && [
                        heightLimitSign && "+",
                        formatNumber(heightLimit),
                        heightLimitTo && `-${formatNumber(heightLimitTo)}`,
                        "m"
                      ]
                        .filter(Boolean)
                        .join(""),
                      bauweise,
                      additional
                    ]
                      .join(" ")
                  }
                </div>
              </React.Fragment>
            )
          )
        }

      </div>
    </ContentSection>
  );
};

export default ProjectOverviewZonings;
