import { useParams } from "react-router-dom";

import useProjectExport from "~/src/hooks/use-project-export";
import ProjectCompanyRoles from "~/src/features/project-company-roles";
import ProjectConstructionInfos from "~/src/features/project-construction-infos";
import ProjectConstructionTypes from "~/src/features/project-construction-types/index.new";
import ProjectDescription from "~/src/features/project-description/index.new";
import ProjectEinlagen from "~/src/features/project-einlagen";
import ProjectEnergyClasses from "~/src/features/project-energy-classes";
import ProjectFundings from "~/src/features/project-fundings/index.new";
import ProjectLocations from "~/src/features/project-locations";
import ProjectNotes from "~/src/features/project-notes/index.new";
import ProjectQuantities from "~/src/features/project-quantities/index.new";
import ProjectStatisticsPage from "~/src/features/project-statistics";
import ProjectStatus from "~/src/features/project-status";
import ProjectTimeline from "~/src/features/project-timeline";
import ProjectTransactions from "~/src/features/project-transactions";
import ProjectUnits from "~/src/features/project-units";
import ProjectWidmungen from "~/src/features/project-widmungen";
import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 */
export default function ProjectPrintPage() {
  const { id: projectId } = useParams();

  const { project, query } = useProjectExport(projectId);

  if (!project) {
    return null;
  }

  const {
    name,
    locations,
    image_file_name,
    buyable,
    rentable,
    description,
    public_notes,
    construction_types,
    construction_infos,
    funding,
    einlagen,
    energy_classes,
    widmungen,
    transactions
  } = project;

  const location = locations ? locations.find(({ default_location }) => default_location) : {};

  const {
    city: {
      zipcode,
      name: cityName
    } = {},
    street: {
      name: streetName
    } = {},
    housenumber
  } = location;

  return (
    <div id="print-page" className="space-y-8 space-y-reverse text-xs bg-white">
      <div className="flex flex-col items-center gap-6 mb-8">
        <div className="flex flex-col items-center gap-1">
          <span className="text-2xl font-bold">{name}</span>
          {locations?.length > 0 && <div>
            <span>{zipcode} {cityName}, </span>
            <span>{streetName}{housenumber && ` ${housenumber}`}</span>
          </div>}
        </div>
        {image_file_name && <img className="rounded-lg object-cover w-40 h-[6.25rem]" src={`https://immodeveloper.fra1.digitaloceanspaces.com/${image_file_name}`} />}
      </div>
      <ProjectStatisticsPage />
      <ProjectStatus />
      <ProjectTimeline />
      <ProjectQuantities />
      <ProjectConstructionInfos />
      <ProjectCompanyRoles />
      {
        description || public_notes
          ? (
            <div className="flex gap-8 break-inside-avoid">
              <div className="w-6/12 space-y-3">
                {description && <ProjectDescription />}
                {public_notes && <ProjectNotes />}
              </div>
              <div className="w-6/12">
                <ProjectLocations />
              </div>
            </div>
          )
          : <ProjectLocations />
      }
      <ProjectConstructionTypes />
      <ProjectFundings />
      <ProjectEnergyClasses />

      {(widmungen && widmungen.length > 0) && <ProjectWidmungen />}

      <ProjectEinlagen />
      <ProjectTransactions />

      {query.exportUnits && <ProjectUnits />}

      <div id="footer" className="flex justify-center items-center text-[0.6em] w-full relative !mb-6">
        <span className="absolute bottom-0 left-0">{Intl.DateTimeFormat("de").format(new Date())}</span>
        <div className="flex flex-col items-center">
          <span>{name}</span>
          <LogoColor className="w-[156px] h-[18px]" />
        </div>

        <div className="absolute bottom-0 right-0"><span className="pageNumber"></span>/<span className="totalPages"></span></div>
      </div>
    </div>
  );
}
