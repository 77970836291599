import useSWR from "swr";
import { Temporal } from "@js-temporal/polyfill";

import { fetcher } from "~/src/modules/api";

const {
  PlainYearMonth,
  Now
} = Temporal;

/**
 *
 * @param options
 * @param options.query
 * @param options.shouldFetch
 * @param swrOptions
 */
const useCount = ({ query, shouldFetch = true }, swrOptions) => {
  const {
    polygon,
    street,
    radius,
    page,
    layout,
    sort,
    direction,
    marked,
    ...restQuery
  } = query;

  const newQuery = {
    street,
    ...restQuery,
    polygon: polygon?.join("_"),
    ...(street && {
      radius
    })
  };

  const params = new URLSearchParams(
    Object.fromEntries(
      Object.entries(newQuery).filter(([
        key,
        value
      ]) => typeof value !== "undefined")
    )
  );

  const {
    data,
    mutate,
    error,
    isLoading,
    isValidating
  } = useSWR(() => (shouldFetch ? `/count?${params}` : null), fetcher, swrOptions);

  return {
    ...(data?.payload ?? {}),
    mutate,
    isLoading,
    isValidating,
    isError: error,
    updateTime: Now.instant().epochMilliseconds
  };
};

export default useCount;
