/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-return-assign */
import create from "zustand";

import {
  setProjectPurchasePriceChartHasError,
  setProjectRentPriceChartHasError
} from "./helpers/set-price-chart-has-error.js";

const centerOfAustria = {
  lat: 47.65073145703942,
  lng: 13.245450349650449
};
const defaultMapViewState = {
  zoom: 6,
  ...centerOfAustria
};
const defaultSimpleMapViewState = {
  zoom: 5,
  ...centerOfAustria
};

const defaultClusterState = "6.668701171875001%2C44.855868807357275%2C19.830322265625004%2C50.31039245071915_7";

const useStore = create((set) => ({
  // logout modal
  showLogoutModal: false,
  toggleLogoutModal: () => set((state) => ({ showLogoutModal: !state.showLogoutModal })),

  // projects map view state
  mapViewState: defaultMapViewState,

  clusterState: defaultClusterState,

  setClusterState: (val) => set({ clusterState: val }),

  autoZoomed: false,
  setAutoZoomed: (val) => set({ autoZoomed: val }),

  prevQuery: null,
  setPrevQuery: (val) => set({ prevQuery: val }),

  // projects map modal
  showProjectsFilters: false,

  resetMapViewState: () => set({ mapViewState: defaultMapViewState }),
  setMapViewState: (val) => set({ mapViewState: val }),
  toggleProjectsFiltersTile: () => set(
    (state) => (
      { showProjectsFilters: !state.showProjectsFilters })
  ),

  setSimpleMapViewState: (val) => set({ simpleMapViewState: val }),

  resetSimpleMapViewState: () => set({ simpleMapViewState: defaultSimpleMapViewState }),

  setSimpleMapViewStateFresh: (val) => set({ simpleMapViewStateFresh: val }),
  // simple map view state
  simpleMapViewState: defaultSimpleMapViewState,
  simpleMapViewStateFresh: false,

  readPolygonsFromQuery: false,
  setReadPolygonsFromQuery: (val) => set({ readPolygonsFromQuery: val }),

  // area modal
  showAreaModal: false,
  toggleAreaModal: () => set((state) => ({ showAreaModal: !state.showAreaModal })),

  // project suggestion modal
  showProjectSuggestionModal: false,
  toggleProjectSuggestionModal: () => set((state) => ({ showProjectSuggestionModal: !state.showProjectSuggestionModal })),

  // project suggestion modal
  showFilterBehaviorModal: false,
  toggleFilterBehaviorModal: () => set((state) => ({ showFilterBehaviorModal: !state.showFilterBehaviorModal })),

  showPdfExportProjectModal: false,
  togglePdfExportProjectModal: () => set((state) => ({ showPdfExportProjectModal: !state.showPdfExportProjectModal })),

  pdfExportProjectExportUnits: true,
  setPdfExportProjectExportUnits: (val) => set({ pdfExportProjectExportUnits: val }),

  // checked projects for stats
  projectsSelectionBody: "",
  setProjectsSelectionBody: (val) => set({ projectsSelectionBody: val }),

  // current selection state for projects
  currentSelectionStatus: "all",
  setCurrentSelectionStatus: (val) => set({ currentSelectionStatus: val }),

  selectionLoading: false,
  setSelectionLoading: (val) => set({ selectionLoading: val }),

  selectionMutate: false,
  setSelectionMutate: (val) => set({ selectionMutate: val }),

  selectionResetByFilter: false,
  setSelectionResetByFilter: (val) => set({ selectionResetByFilter: val }),

  mainSelectionType: "addAll",
  setMainSelectionType: (val) => set({ mainSelectionType: val }),

  queryString: "",
  setQueryString: (val) => set({ queryString: val }),

  change: "",
  setChange: (val) => set({ change: val }),

  delayedLayout: "",
  setDelayedLayout: (val) => set({ delayedLayout: val }),

  filterOpen: false,
  setFilterOpen: (val) => set({ filterOpen: val }),

  onlyMarkedProjects: true,
  setOnlyMarkedProjects: (val) => set({ onlyMarkedProjects: val }),

  projectListEmpty: false,
  setProjectListEmpty: (val) => set({ projectListEmpty: val }),

  exporting: false,
  setExporting: (val) => set({ exporting: val }),

  projectRentPriceChartHasError: false,
  setProjectRentPriceChartHasError: setProjectRentPriceChartHasError(set),

  projectPurchasePriceChartHasError: false,
  setProjectPurchasePriceChartHasError:
   setProjectPurchasePriceChartHasError(set),

  bothProjectPriceChartsHaveErrors: false,

  setStatsBoxCollapsed: (val) => set({ statsBoxCollapsed: val }),
  statsBoxCollapsed: false,

  filterTileCollapsed: false,
  setFilterTileCollapsed: (val) => set({ filterTileCollapsed: val }),

  setUserClickedFilterTileCollapse: (val) => set({ userClickedFilterTileCollapse: val }),
  userClickedFilterTileCollapse: false,

  setUserClickedStatsBoxCollapse: (val) => set({ userClickedStatsBoxCollapse: val }),
  userClickedStatsBoxCollapse: false,

  loggingOut: false,
  setLoggingOut: (val) => set({ loggingOut: val })
}));

export default useStore;
