import { useParams } from "react-router-dom";

import useProjectExport from "~/src/hooks/use-project-export";
import dateFormatter from "~/src/modules/date-formatter";
import { constructionPhaseLabels, marketingPhaseLabels } from "~/src/modules/labels";
import SectionContainer from "~/src/ui/containers/section-container";
import BauIcon from "~/src/ui/icons/bau-icon";
import CheckmarkIcon from "~/src/ui/icons/checkmark-icon";
import VermarktungIcon from "~/src/ui/icons/vermarktung-icon";

/**
 *
 */
export default function ProjectTimeline() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    timeline = {},
    timeline: {
      construction_phase_purchase,
      construction_phase_purchase_date,
      construction_phase_purchase_date_format,
      construction_phase_active,
      construction_phase_active_date,
      construction_phase_active_date_format,
      construction_phase_completed,
      construction_phase_completed_date,
      construction_phase_completed_date_format,
      marketing_phase_coming_soon,
      marketing_phase_coming_soon_date,
      marketing_phase_coming_soon_date_format,
      marketing_phase_active,
      marketing_phase_active_date,
      marketing_phase_active_date_format,
      marketing_phase_project_finished,
      marketing_phase_project_finished_date,
      marketing_phase_project_finished_date_format,
      current_construction_phase,
      current_marketing_phase
    } = {}
  } = project;

  const columns = [
    {
      label: "Bauphase",
      type: "construction",
      icon: <BauIcon className="w-10 h-10 print:hidden" />,
      phases: [
        [
          "construction_phase_purchase",
          "construction_phase_active",
          "construction_phase_completed"
        ].includes(current_construction_phase) && "purchase",
        [
          "construction_phase_active",
          "construction_phase_completed"
        ].includes(current_construction_phase) && "active",
        current_construction_phase === "construction_phase_completed" && "completed"
      ]
    },
    {
      label: "Vermarktungsphase",
      type: "marketing",
      icon: <VermarktungIcon className="w-10 h-10 print:hidden" />,
      phases: [
        (current_marketing_phase === "marketing_phase_coming_soon" || marketing_phase_coming_soon_date) && "coming_soon",
        [
          "marketing_phase_active",
          "marketing_phase_project_finished"
        ].includes(current_marketing_phase) && "active",
        current_marketing_phase === "marketing_phase_project_finished" && "project_finished"
      ]
    }
  ]
    .map(({ phases, ...column }) => ({
      phases: phases.filter(Boolean),
      ...column
    }));

  return (
    <SectionContainer title="Timeline">
      <div className="flex w-full gap-4">
        {
          columns.map(({
            label, type, icon, phases
          }) => (
            <div className="space-y-1 w-6/12 text-gray-500 print:text-black" key={label}>
              <div className="flex gap-2 items-center justify-center font-medium text-sm print:justify-start">
                {icon}
                {label}
              </div>
              <div className="space-y-1">
                {
                  phases.length > 0
                    ? phases.map((phase, index) => (
                      <div className="flex flex-col gap-1 p-1 bg-gray-200 relative" key={phase}>
                        {!(phase === "active" && index === phases.length - 1) && <CheckmarkIcon className="absolute top-2 left-2 w-4 h-4 !mt-0" />}
                        <span className="block text-center">{{
                          constructionPhaseLabels,
                          marketingPhaseLabels
                        }[`${type}PhaseLabels`][`${type}_phase_${phase}`]}</span>
                        <span className="block text-center font-semibold">
                          {
                            timeline[`${type}_phase_${phase}_date`]
                              ? dateFormatter(timeline[`${type}_phase_${phase}_date`], timeline[`${type}_phase_${phase}_date_format`])
                              : "-"
                          }
                        </span>
                      </div>
                    ))
                    : "Keine Daten vorhanden"
                }
              </div>
            </div>
          ))
        }
      </div>
    </SectionContainer>
  );
}
