import { Dialog, Transition } from "@headlessui/react";
import { ScaleIcon } from "@heroicons/react/24/outline";
import {
  Fragment, useEffect, useRef, useState
} from "react";

import { updateUserSettings } from "./handlers";

import AreaSettingsSliderContainer from "~/src/features/area-settings-slider-container";
import useStore from "~/src/hooks/use-store";
import useUserSettings from "~/src/hooks/use-user-settings";
import { showError, showSuccess } from "~/src/modules/toast-helper";
import CloseIcon from "~/src/ui/icons/close-icon";

const sliders = [
  {
    key: "loggia",
    title: "Loggia"
  },
  {
    key: "balkon",
    title: "Balkon"
  },
  {
    key: "terrasse",
    title: "Terrasse"
  },
  {
    key: "dachterrasse",
    title: "Dachterrasse"
  },
  {
    key: "garten",
    title: "Garten"
  },
  {
    key: "keller",
    title: "Keller"
  }
];

/**
 *
 * @param root0
 * @param root0.isOpen
 */
export default function AreaSettingsModal({ isOpen = false }) {
  const initialFocusRef = useRef(null);
  const { userSettings, mutate } = useUserSettings();

  const toggleAreaModal = useStore((state) => state.toggleAreaModal);

  const [
    submitting,
    setSubmitting
  ] = useState(false);
  const [
    showCustom,
    setShowCustom
  ] = useState(false);
  const [
    showAllRatios,
    setShowAllRatios
  ] = useState(false);
  const [
    hasChanges,
    setHasChanges
  ] = useState(false);

  const defaultSettings = {
    loggia: 100,
    balkon: 0,
    terrasse: 0,
    dachterrasse: 0,
    garten: 0,
    keller: 0
  };

  const [
    storedSettings,
    setStoredSettings
  ] = useState({
    loggia: 0,
    balkon: 0,
    terrasse: 0,
    dachterrasse: 0,
    garten: 0,
    keller: 0
  });

  const [
    changedSettings,
    setChangedSettings
  ] = useState({
    loggia: 0,
    balkon: 0,
    terrasse: 0,
    dachterrasse: 0,
    garten: 0,
    keller: 0
  });

  function handleClose() {
    toggleAreaModal();
    setTimeout(() => {
      setHasChanges(false);
    }, 200);
  }

  function handleRadioChange(type) {
    if (type === "custom") {
      setShowCustom(true);
    }
    else {
      setShowCustom(false);
    }
  }

  function updateStoredSettings(settings) {
    setStoredSettings({
      loggia: settings.custom_loggia_ratio,
      balkon: settings.custom_balkon_ratio,
      terrasse: settings.custom_terrasse_ratio,
      dachterrasse: settings.custom_dachterrasse_ratio,
      garten: settings.custom_garten_ratio,
      keller: settings.custom_keller_ratio
    });
  }

  function updateChangedValue(key, value) {
    if (hasChanges) {
      setChangedSettings((prev) => ({
        ...prev,
        [key]: value
      }));
    }
    else {
      setChangedSettings({
        ...storedSettings,
        [key]: value
      });
      setHasChanges(true);
    }
  }

  function handleSubmit() {
    setSubmitting(true);
    const submitValues = showCustom && hasChanges ? changedSettings : storedSettings;

    updateUserSettings({
      use_custom_area_ratios: showCustom,
      show_all_ratios: showAllRatios,
      custom_loggia_ratio: submitValues.loggia,
      custom_balkon_ratio: submitValues.balkon,
      custom_terrasse_ratio: submitValues.terrasse,
      custom_dachterrasse_ratio: submitValues.dachterrasse,
      custom_garten_ratio: submitValues.garten,
      custom_keller_ratio: submitValues.keller
    }, handleSuccess, handleError);
  }

  function handleSuccess() {
    handleClose();
    setSubmitting(false);
    mutate();
    showSuccess("user-settings-success");
  }

  function handleError(status) {
    setSubmitting(false);
    showError("user-settings-error");
  }

  useEffect(() => {
    if (isOpen && userSettings) {
      setShowCustom(userSettings.use_custom_area_ratios);
      setShowAllRatios(userSettings.show_all_ratios);
      updateStoredSettings(userSettings);
    }
  }, [
    isOpen,
    userSettings
  ]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-[100] h-full overflow-y-auto lg:h-auto"
        initialFocus={initialFocusRef}
        open={isOpen}
        onClose={handleClose}
      >
        <div className="flex items-center justify-center h-full min-h-full px-4 pt-4 pb-4 text-center sm:p-0 lg:h-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="flex flex-col w-full h-full overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-3xl">
              <div className="relative px-4 py-4 border-b border-gray-200 sm:px-6">
                <div className="flex items-center justify-center text-lg font-medium">
                  <ScaleIcon className="-mt-0.5 p-1 w-8 h-8 rounded-full bg-primary text-white" />
                  <p className="ml-3">Anteilsberechnung von Freiflächen und Zubehörsobjekten</p>
                </div>
                <div className="absolute top-0 items-center hidden h-full sm:flex right-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={handleClose}
                  >
                    <CloseIcon className="w-5 h-5 text-black" />
                  </button>
                </div>
              </div>

              <div className="h-full px-4 pb-5 overflow-y-scroll bg-white divide-y divide-gray-300 sm:divide-gray-200 sm:px-12 max-h-screen-66 sm:max-h-screen-80">

                <div className="py-10">
                  <div className="space-y-1.5">

                    <div className="inline-flex items-center cursor-pointer">
                      <input
                        id="default"
                        name="area-settings"
                        onChange={() => handleRadioChange("default")}
                        type="radio"
                        checked={!showCustom}
                        className="w-5 h-5 border-gray-300 cursor-pointer text-primary focus:outline-none focus:ring-0 focus:ring-white"
                      />
                      <label htmlFor="default" className="block ml-3 font-medium text-gray-700 cursor-pointer">
                        Standardgewichtung
                      </label>
                    </div>

                    <div className="flex items-center cursor-pointer">
                      <input
                        id="custom"
                        name="area-settings"
                        onChange={() => handleRadioChange("custom")}
                        type="radio"
                        checked={showCustom}
                        className="w-5 h-5 border-gray-300 cursor-pointer text-primary focus:outline-none focus:ring-0 focus:ring-white"
                      />
                      <label htmlFor="custom" className="block ml-3 font-medium text-gray-700 cursor-pointer">
                        individuelle Flächengewichtung
                      </label>
                    </div>

                    {/* <div className="relative flex items-start h-8 pt-1 ml-8">
                      {showCustom &&
                       <>
                          <div className="flex items-center h-5">
                            <input
                              onChange={(e) => setShowAllRatios(e.target.checked)}
                              checked={showAllRatios}
                              id="calculation-type"
                              name="calculation-type"
                              type="checkbox"
                              className="w-4 h-4 border-gray-300 rounded focus:ring-0 focus:ring-white text-primary"
                            />
                          </div>
                          <div className="flex ml-2 text-sm">
                            <label htmlFor="calculation-type" className="text-sm font-medium text-gray-600 cursor-pointer">
                              beide Flächen anzeigen
                            </label>
                            <p className="ml-1 font-light text-gray-600">(individuelle Anteile für Kalkulationen anwenden)</p>
                          </div>
                        </>
                      }
                    </div> */}

                  </div>

                  <div className="mt-10 space-y-4">
                    {sliders.map((slider) => (
                      <AreaSettingsSliderContainer
                        key={slider.key}
                        changeKey={slider.key}
                        title={slider.title}
                        hasChanges={hasChanges}
                        showCustom={showCustom}
                        defaultValue={defaultSettings[slider.key]}
                        storedValue={storedSettings[slider.key]}
                        changedValue={changedSettings[slider.key]}
                        updateChangedValue={updateChangedValue}
                      />
                    ))}
                  </div>
                </div>

              </div>

              <div className="px-4 py-4 space-y-4 text-center border-t border-gray-200 sm:px-6 sm:flex sm:flex-row-reverse sm:justify-between sm:items-center sm:space-y-0">
                <button
                  type="button"
                  disabled={submitting}
                  className="flex items-center justify-center w-64 mx-auto mt-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm sm:mx-0 sm:mt-0 h-11 bg-primary hover:bg-primary-dark focus:outline-none"
                  onClick={handleSubmit}
                >
                  {submitting &&
                    <svg className="w-5 h-5 text-white animate-spin" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                  }
                  {!submitting && <span>Änderungen speichern</span>}
                </button>
                <button
                  ref={initialFocusRef}
                  type="button"
                  className="-ml-2.5 px-2.5 py-1.5 rounded-md underline font-medium text-gray-700 hover:bg-gray-100 focus:outline-none"
                  onClick={handleClose}
                >
                  Schließen
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
