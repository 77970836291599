import { useParams } from "react-router-dom";

import ContentSection from "~/src/ui/sections/content-section";
import ProjectQuantity from "~/src/ui/repeatables/project-quantity";
import useProject from "~/src/hooks/use-project";

/**
 *
 */
const ProjectOverviewQuantities = () => {
  const { id } = useParams();
  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    quantities
  } = project;

  return (
    <ContentSection id="quantities" title="Einheiten">
      <dl className="grid grid-cols-2-auto gap-x-4 gap-y-0.5">
        {(quantities.length)
          ? [...quantities]
            .sort(({ category: { id: idA } }, { category: { id: idB } }) => idA - idB)
            .map((quantity, index) => (
              <ProjectQuantity key={index} quantity={quantity} index={index} />
            ))
          : "-"

        }
      </dl>

    </ContentSection>
  );
};

export default ProjectOverviewQuantities;
