/* eslint-disable camelcase */
import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 */
export default function useAccountRegions() {
  const {
    data, mutate, error
  } = useSWR("/account-regions", fetcher, { revalidateOnMount: true });

  return {
    account_regions: data?.payload?.account_regions,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
