import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.project
 * @param root0.shouldFetch
 */
export default function useUnitsExport({ project, shouldFetch = false }) {
  const {
    data, error, mutate
  } = useSWR(() => (shouldFetch ? `/projects/${project.slug}/units-export` : null), fetcher);
  // This request is dependent on project
  // When passing a function, SWR will use the return
  // value as `key`. If the function throws or returns
  // falsy, SWR will know that some dependencies are not
  // ready.
  // https://swr.vercel.app/docs/conditional-fetching#conditional

  return {
    units: data?.payload?.units,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}

