import DetailStatistics from "~/src/features/projects-statistic-tile/chart";

/**
 *
 */
const PrintStatisticsPage = () => {
  return (
    <>
      <style>
        {`
          *:not(html, #print, #print *) {
            all: unset !important;
          }
          *:not(html, html > body, html > body > div#root, #print, #print *) {
            display: none !important;
          }
        `}
      </style>
      <div className="space-y-16 space-y-reverse bg-white w-[200mm] my-0 mx-auto" id="print">
        <section>
          <DetailStatistics />
        </section>

      </div>
    </>
  );
};

export default PrintStatisticsPage;
