/**
 *
 * @param outerColor
 * @example
 */
const markerIconEmpty = (outerColor) => `
	<path transform="translate(36, 14) scale(0.28)" fill="${outerColor}" d="M35,31c0.3-7.4,6.5-12.6,14.6-12.6c7.4,0,13.3,2.9,14.2,8.4C66,42,38.6,35.2,37.9,66.9h21c0-21,31.7-16.8,26.5-44.6C82.2,7.1,68.3,0,49.6,0C28.9,0,14,11,14,30.7h21V31z M36,87.6c0,16.5,24.9,16.5,24.9,0C61.2,70.8,36,70.8,36,87.6" />
`;

export default markerIconEmpty;
