/* eslint-disable no-underscore-dangle */
import {
  DomUtil
} from "leaflet";

import { adjustPoint } from "./_common/_exports.js";

// don't switch to arrow functions, because of leaflet's rotten class system

/**
 *
 * @param event
 * @this {object}
 */
const animateZoom = function (event) {
  const anchor = this._getAnchor();

  const point = adjustPoint({
    map: this._map,
    container: this._container,
    tipContainer: this._tipContainer,
    pointCoordinates: this._latlng,
    anchor,
    event
  });

  DomUtil.setPosition(this._container, point);
};

export default animateZoom;
