import {
  Route,
  Switch
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ProjectsPrintPage from "./pages/print";
import ProtectedInternalRoute from "./ui/protected-internal-route";
import PrintOverviewPage from "./pages/print/overview";
import PrintListPage from "./pages/print/list";
import PrintStatisticsPage from "./pages/print/statistics";

import DefaultErrorPage from "~/src/pages/errors/default-error";
import InsolvenciesPage from "~/src/pages/insolvencies";
import KartePage from "~/src/pages/karte";
import ListePage from "~/src/pages/liste";
import NotAuthorizedPage from "~/src/pages/errors/not-authorized";
import NotFoundPage from "~/src/pages/errors/not-found";
import Project from "~/src/pages/projects/[id]";
import ProjectPrintPage from "~/src/pages/projects/[id]/print";
import ProtectedRoute from "~/src/ui/protected-route";
import Purchases from "~/src/pages/purchases";
import ScrollTop from "~/src/ui/scroll-top";
import ShareDealsPage from "~/src/pages/share-deals";
import StatisticsPage from "~/src/pages/statistics";
import Watchlist from "~/src/pages/watchlists/[id]";
import Watchlists from "~/src/pages/watchlists";
import useAnaltytics from "~/src/hooks/use-analytics";

/**
 *
 */
export default function AppRouter() {
  useAnaltytics();

  return (
    <Switch>
      <Route path="/">
        <ScrollTop />
        <ToastContainer />
        <Switch>

          <ProtectedRoute exact path="/">
            {

              /* <DashboardPage /> */

            }
            <KartePage />
          </ProtectedRoute>

          <ProtectedRoute path="/karte">
            <KartePage />
          </ProtectedRoute>

          <ProtectedRoute path="/liste">
            <ListePage />
          </ProtectedRoute>

          <ProtectedRoute path="/statistik">
            <StatisticsPage />
          </ProtectedRoute>

          <ProtectedInternalRoute exact path="/print">
            <ProjectsPrintPage />
          </ProtectedInternalRoute>

          <ProtectedInternalRoute exact path="/print/overview">
            <PrintOverviewPage />
          </ProtectedInternalRoute>

          <ProtectedInternalRoute exact path="/print/list">
            <PrintListPage />
          </ProtectedInternalRoute>

          <ProtectedInternalRoute exact path="/print/statistics">
            <PrintStatisticsPage />
          </ProtectedInternalRoute>

          <ProtectedRoute exact path="/transaktionen">
            <Purchases />
          </ProtectedRoute>

          <ProtectedRoute exact path="/share-deals">
            <ShareDealsPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/insolvenzen">
            <InsolvenciesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/merklisten">
            <Watchlists />
          </ProtectedRoute>

          <ProtectedRoute exact path="/merklisten/:id">
            <Watchlist />
          </ProtectedRoute>

          <Route exact path="/projekte/:id/print">
            <ProjectPrintPage />
          </Route>

          <ProtectedRoute path="/projekte/:id">
            <Project />
          </ProtectedRoute>

          <Route path="/not-authorized">
            <NotAuthorizedPage />
          </Route>

          <Route path="/not-found">
            <NotFoundPage />
          </Route>

          <Route path="*">
            <DefaultErrorPage />
          </Route>

        </Switch>
      </Route>
    </Switch>
  );
}
