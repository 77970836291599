import AreaRange from "~/src/ui/displays/area-range";
import { pluralize } from "~/src/modules/formatters";
import { unitCategoryLabels } from "~/src/modules/labels";

/**
 *
 * @param props
 * @param props.quantity
 * @param props.quantity.category
 * @param props.quantity.category.code
 * @param props.quantity.total_area_range_from
 * @param props.quantity.total_area_range_to
 * @param props.quantity.total_units
 */
export default function ProjectQuantity({
  quantity: {
    category: {
      code: categoryCode
    },
    total_area_range_from: area,
    total_area_range_to: toArea,
    total_units: total
  }
}) {
  return (
    <>
      <dt className="flex gap-1">
        {total && <span className="font-bold">{total}</span>}
        <span>{pluralize(unitCategoryLabels[categoryCode].singular, unitCategoryLabels[categoryCode].plural, unitCategoryLabels[categoryCode].plural, total)}</span>
      </dt>
      <dd>
        {
          area && (
            <AreaRange
              {...{
                area,
                toArea
              }}
            />
          )
        }
      </dd>
    </>
  );
}
