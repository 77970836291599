import { objektTypFilterLabels } from "~/src/modules/labels";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function ObjektTypFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const objekttypen = queryItems.split(",");

  function handleClick(typ) {
    const newTypen = objekttypen.filter((item) => item !== typ).join(",");

    submit("objekttyp", newTypen);
  }

  return objekttypen.map((typ, index) => (
    <DefaultFilterTag key={`objekt_typ_tag_${index}`} handleClick={() => handleClick(typ)}>
      <span>Objektart: {objektTypFilterLabels[typ]}</span>
    </DefaultFilterTag>
  ));
}
