import ProjectsListPrintTile from "~/src/features/projects-list-print-tile";

/**
 *
 */
const PrintListPage = () => (
  <>
    <style>
      {`
          *:not(html, #print, #print *) {
            all: unset !important;
          }
          *:not(html, html > body, html > body > div#root, #print, #print *) {
            display: none !important;
          }
        `}
    </style>
    <div className="space-y-16 space-y-reverse bg-white w-[200mm] my-0 mx-auto" id="print">
      <section>
        <ProjectsListPrintTile />
      </section>
    </div>
  </>
);

export default PrintListPage;
