import { useState, useEffect } from "react";
import {
  useParams, Redirect, Link
} from "react-router-dom";
import {
  useQueryParams, NumberParam, StringParam
} from "use-query-params";
import animateScrollTo from "animated-scroll-to";

import BackIcon from "~/src/ui/icons/back-icon";
import useWatchlist from "~/src/hooks/use-watchlist";
import Page from "~/src/ui/page";
import ProjectList from "~/src/features/watchlist-project-list";
import Pagination from "~/src/features/pagination";
import SortPurchasesDirection from "~/src/features/sort-purchases-direction";
import SortWatchlistProjectsDropdown from "~/src/features/sort-watchlist-projects-dropdown";
import ToggleViewButton from "~/src/features/toggle-view-button";
import EmptyProjectBucket from "~/src/features/empty-project-bucket";
import StatsSlide from "~/src/features/stats-slide";
import { calculateStats } from "~/src/modules/stats-helper";
import MapSlide from "~/src/features/slides/map-slide";

/**
 *
 */
export default function WatchlistPage() {
  const { id } = useParams();
  const [
    query,
    setQuery
  ] = useQueryParams({
    page: NumberParam,
    direction: StringParam,
    sortBy: StringParam
  });
  const {
    watchlist, projects, pagination, sortDirection, isLoading, error, mutate: mutateWatchlist
  } = useWatchlist({
    query,
    id
  });
  const [
    stats,
    setStats
  ] = useState({});
  const [
    statsSlide,
    setStatsSlide
  ] = useState(false);
  const [
    showMap,
    setShowMap
  ] = useState(false);
  const [
    singleMapSlide,
    setSingleMapSlide
  ] = useState(false);
  const [
    singleMapProject,
    setSingleMapProject
  ] = useState(null);

  if (error) {
    <Redirect to="/error" />;
  }

  function handlePageChange(newPage) {
    setQuery({ page: newPage });
    setTimeout(() => {
      animateScrollTo(0, {
        speed: 50
      });
    }, 120);
  }

  function handleSubmit(val) {
    setQuery(val);
  }

  function handleSingleMapProject(project, showLocationNumber) {
    setSingleMapProject({
      project,
      showLocationNumber
    });
    setSingleMapSlide(true);
  }

  const stringProjects = JSON.stringify(projects);

  useEffect(() => {
    setStats(calculateStats(projects));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringProjects]);

  return (
    <Page title="Merkliste">
      <MapSlide show={singleMapSlide} hide={() => setSingleMapSlide(false)} projectSlugs={singleMapProject?.project?.slug ? [singleMapProject.project.slug] : []} />
      <MapSlide show={showMap} hide={() => setShowMap(false)} projectSlugs={projects?.map(({ slug }) => slug)} />
      <StatsSlide active={statsSlide} hide={() => setStatsSlide(false)} stats={stats} />

      <div className="xl:container mx-auto mt-8 px-4 lg:px-16 flex justify-between items-start">
        <div className="w-full">

          <div>
            <Link to="/merklisten" className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <BackIcon />
              Alle Merklisten
            </Link>
          </div>

          <div className="mt-2 flex justify-between items-start">
            {!isLoading &&
              <div className="truncate">
                <h2 className="text-3xl text-gray-700 font-medium truncate">{watchlist?.name}</h2>
                <p className="text-lg text-gray-600 font-light">{pagination?.totalRecords} / 200 Projekte</p>
              </div>
            }
            {isLoading &&
              <div className="">
                <p className="h-8 bg-gray-300 w-56 rounded" />
                <p className="mt-3 h-4 bg-gray-300 w-32 rounded" />
              </div>
            }
            <div className="ml-4">
              <ToggleViewButton setShowMap={setShowMap} setShowStats={setStatsSlide} />
            </div>
          </div>
        </div>

      </div>

      <div className="xl:container mt-8 mx-auto px-4">

        {projects?.length === 0 &&
          <div className="w-2/3 mx-auto">
            <EmptyProjectBucket />
          </div>
        }

        {!isLoading && projects?.length > 0 &&
          <div className="lg:mx-12 flex justify-end items-center">
            <SortWatchlistProjectsDropdown sortBy={query.sortBy} onSubmit={handleSubmit} />
            <div className="ml-6 mr-1">
              <Pagination pagination={pagination} page={query.page} onPageChange={handlePageChange} />
            </div>
            <SortPurchasesDirection isLoading={isLoading} direction={sortDirection} onSubmit={handleSubmit} />
          </div>
        }

        {isLoading &&
          <div className="mt-12 flex justify-end lg:mx-12 mb-1 ">
            <div className="h-5 bg-gray-300 w-56 rounded animate-pulse" />
          </div>
        }

        <ProjectList projects={projects} watchlist={watchlist} mutateWatchlist={mutateWatchlist} isLoading={isLoading} handleSingleMapProject={handleSingleMapProject} />

      </div>

    </Page>
  );
}
