import LinkIcon from "~/src/ui/icons/link-icon";
import SignedDocumentLink from "~/src/ui/links/signed-document-link";

/**
 *
 * @param props
 * @param props.documents
 */
export default function ProjectDocuments({ documents = [] }) {
  const createChildElement = (document) => (
    <li key={`document_${document.id}`} className="flex items-center justify-between w-full gap-2 py-2 pl-3 pr-4">
      <div className="flex items-center gap-2 truncate">
        <LinkIcon />
        <span className="text-black font-normal truncate">
          {document.public_name}
        </span>
      </div>
    </li>
  );

  return (
    <div className="max-w-full lg:max-w-prose">
      {documents.length === 0 &&
          <p>-</p>
      }

      {documents.length > 0 &&
          <ul className="border border-gray-200 divide-y divide-gray-200 rounded-md">
            {documents.map((document) => (
              <SignedDocumentLink key={`document_link_${document.id}`} document={document} childElements={createChildElement(document)}/>
            ))}
          </ul>
      }
    </div>
  );
}
