/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */

const statsKeys = {
  byYear: {
    wohnungenCount: "countWohnungenByYear"
  },
  byMonth: {
    priceSqm: "marketTrend"
  },
  byMonthAndArea: {
    priceSqm: "marketTrendAreaGroups"
  },
  byArea: {
    priceSqm: "squareMeterPriceByArea"
  }
};

/**
 *
 */
class DetailStatsTools {

  /**
   *
   * @param chart
   * @param stats
   * @param statsForVariable
   */
  static variableHasData(statsForVariable) {
    return Boolean(statsForVariable.meta.total);
  }

  /**
   *
   * @param stats
   */
  static someVariableHasData(stats) {
    for (const variable in stats) {
      const statsForVariable = stats[variable];

      if (DetailStatsTools.variableHasData(statsForVariable)) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   * @param stats
   */
  static getVariablesWithData(stats) {
    return Object.keys(stats).filter(
      (variable) => DetailStatsTools.variableHasData(stats[variable])
    );
  }

  //  static hasData(detailStatsStore) {
  //    for (const parameter in detailStatsStore) {
  //      for (const variable in detailStatsStore[parameter]) {
  //        if (detailStatsStore[parameter][variable].hasData) {
  //          return true;
  //        }
  //      }
  //    }
  //
  //    return false;
  //  }
  //
  // }

  /**
   *
   * @param statsByParameters
   * @param stats
   */
  static hasData(stats) {
    const wohnungenCountByYear = stats[statsKeys.byYear.wohnungenCount];

    if (DetailStatsTools.wohnungenCountHasData(wohnungenCountByYear)) {
      return true;
    }

    for (const parameters of ["byMonth", "byMonthAndArea", "byArea"]) {
      const statsByVariable = stats[statsKeys[parameters].priceSqm];

      if (DetailStatsTools.someVariableHasData(statsByVariable)) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   * @param statsByYear
   */
  // TODO it's easy to replace this with a meta field in the API
  static wohnungenCountHasData(statsByYear) {
    for (const { year, ...statsForYear } of statsByYear) {
      for (const variable in statsForYear) {
        const countForVariable = statsForYear[variable];

        if (countForVariable > 0) {
          return true;
        }
      }
    }

    return false;
  }

}

export default DetailStatsTools;
