import { groupBy } from "lodash-es";
import { useParams } from "react-router-dom";

import useProjectExport from "~/src/hooks/use-project-export";
import { companyRoleLabels } from "~/src/modules/labels";
import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

/**
 *
 */
export default function ProjectCompanyRoles() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    company_roles
  } = project;

  const columnTitles = [
    "FB-Nr./ZVR-Nr.",
    "Name",
    "Adresse"
  ];

  const tables = Object.entries({
    builder: [],
    owner: [],
    marketer: [],
    ...groupBy(company_roles, "name")
  }).map(([
    name,
    companies
  ]) => ({
    title: companyRoleLabels[name],
    emptyText: `${companyRoleLabels[name]} nicht bekannt`,
    rows: companies.map(({
      company: {
        reg_number,
        name: companyName,
        zipcode,
        city,
        street,
        house_number
      }
    }) => [
      reg_number,
      companyName,
      city && `${street} ${house_number}, ${zipcode} ${city}`
    ])
  }));

  const columnWidths = [
    "2/12",
    "6/12",
    "4/12"
  ];

  return (
    <div>
      {
        tables.map(({
          title, rows, emptyText
        }, index) => (
          <SectionContainer title={`${title}`} key={title}>
            <Table {...{
              columnTitles,
              rows,
              columnWidths,
              emptyText
            }} />
          </SectionContainer>
        ))
      }
    </div>
  );
}
