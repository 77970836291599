/**
 *
 * @param root0
 * @param root0.children
 */
export default function ContentContainer({ children }) {
  return (
    <div className="mt-4 first:mt-0 bg-white border border-gray-200 sm:rounded">
      <div className="px-4 py-5 sm:px-6">
        {children}
      </div>

    </div>
  );
}
