import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { components } from "react-select";
import { default as Select } from "react-select/async";
import { debounce, throttle } from "throttle-debounce";

import API from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.onUpdate
 */
export default function ShareDealTypeSelect({ onUpdate }) {
  const handleSelect = (selected) => {
    onUpdate(selected?.value, selected?.label);
  };

  return (
    <Select
      className="react-select"
      instanceId="sharedeal-type"
      styles={customStyles}
      value={""}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input,
        LoadingIndicator: () => null
      }}
      cacheOptions
      defaultOptions={true}
      isClearable
      name="sharedeal-type"
      placeholder="Art der Änderung"
      noOptionsMessage={noOptionsMessage}
      loadOptions={handleLoadOptions}
      onChange={handleSelect}
      blurInputOnSelect={true}
      isSearchable={false}
    />
  );
}

const loadOptions = async (inputValue, cb) => {
  try {
    const res = await API.get("/share-deal-categories");

    const options = res.data.payload.shareDealCategories.map((cat) => ({
      label: `${cat.name}`,
      value: cat.id,
      body: {
        cat
      }
    }));

    cb(options);
  }
  catch (e) {
    console.log("error", e);
  }
};

const loadOptionsDebounced = debounce(500, loadOptions);
const loadOptionsThrottled = throttle(500, loadOptions);

const handleLoadOptions = (inputValue) => new Promise((resolve, reject) => {
  if (inputValue.length < 4) {
    loadOptionsThrottled(inputValue, (options) => {
      resolve(options);
    });
  }
  else {
    loadOptionsDebounced(inputValue, (options) => {
      resolve(options);
    });
  }
});

const noOptionsMessage = (data) => {
  if (data.inputValue.length > 0) {
    return "Keine Vorschläge gefunden...";
  }

  return "Art der Änderung auswählen...";
};

function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} className="pl-2">
      <RectangleStackIcon className="h-5 w-5 text-gray-400" /> {children}
    </components.Control>
  );
}

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = {
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af",
    fontSize: "16px"
  }),
  control: (styles, state) => ({
    ...styles,
    height: 44,
    minHeight: 44,
    borderRadius: 2,
    boxShadow: "state.isFocused ? \"0 0 0 20px rgba(56,178,172,1)\" : 0",
    borderColor: state.isFocused ? "#9ca3af" : "#e5e5e5",
    borderWidth: state.isFocused ? "1px" : "1px",
    "&:hover": {
      borderColor: state.isFocused ? "#9ca3af" : "#9ca3af"
    }
  }),

  singleValue: (base) => ({
    ...base,
    borderRadius: "5px",
    padding: "0 6px",
    background: "#822c42",
    color: "white"
  })
};
