import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth";
import useStore from "~/src/hooks/use-store";

/**
 *
 */
const SettingsDropdown = () => {
  const { user } = useAuth();

  const toggleLogoutModal = useStore((state) => state.toggleLogoutModal);
  const toggleAreaModal = useStore((state) => state.toggleAreaModal);
  const toggleProjectSuggestionModal = useStore((state) => state.toggleProjectSuggestionModal);

  return (
    <div className="relative z-30 flex items-center">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="inline-flex items-center justify-center text-white rounded-md hover:text-gray-200 focus:outline-none focus:text-gray-100">
              <div className="flex flex-col items-center justify-center gap-1 group">
                <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full lg:w-5 lg:h-5 group-hover:bg-primary-light text-primary">
                  <span className="text-lg lg:text-xs pt-0.5">{user.username[0].toUpperCase()}</span>
                </div>
                <span className="hidden lg:flex text-[0.65rem] text-white group-hover:text-primary-light leading-none">Benutzer</span>
              </div>

            </Menu.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Menu.Items static>
                <div className="origin-top-right min-w-[14rem] max-w-[14rem] absolute right-3 mt-4 rounded-md shadow-lg px-1 py-1 bg-white ring-1 ring-black ring-opacity-5 text-sm lg:text-xs">
                  <Menu.Item>
                    <button disabled={true} onClick={() => { }} className="flex w-full px-4 py-2 text-gray-700 truncate bg-gray-200 rounded focus:outline-none" role="menuitem">
                      <p className="text-left">Eingeloggt als<br /><span className="font-semibold whitespace-nowrap">{`${user.email}`}</span></p>
                    </button>
                  </Menu.Item>

                  <Menu.Item>
                    <Link to="/merklisten" className="flex w-full px-4 py-2 text-gray-700 rounded hover:bg-primary hover:text-white focus:outline-none" role="menuitem">
                      Merklisten
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <button onClick={toggleAreaModal} className="flex w-full px-4 py-2 text-gray-700 rounded hover:bg-primary hover:text-white focus:outline-none" role="menuitem">
                      Flächengewichtung
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button onClick={toggleProjectSuggestionModal} className="flex w-full px-4 py-2 text-gray-700 rounded hover:bg-primary hover:text-white focus:outline-none" role="menuitem">
                      Projekt vorschlagen
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button onClick={toggleLogoutModal} className="flex w-full px-4 py-2 text-gray-700 rounded hover:bg-primary hover:text-white focus:outline-none" role="menuitem">
                      Logout
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>

          </>
        )}
      </Menu>
    </div>
  );
};

export default SettingsDropdown;
