import useSWR from "swr";
import {
  encodeQueryParams, useQueryParams, BooleanParam, StringParam
} from "use-query-params";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param projectId
 */
export default function useProjectExport(projectId) {
  const paramConfig = {
    exportUnits: BooleanParam,
    accessToken: StringParam,
    userId: StringParam
  };

  const [query] = useQueryParams(paramConfig);

  const queryParams = new URLSearchParams(encodeQueryParams(paramConfig, query));

  const {
    data, error, mutate
  } = useSWR(`/projects/${projectId}/export?${queryParams}`, fetcher);

  return {
    query,
    project: data?.payload.project,
    units: data?.payload.units,
    userSettings: data?.payload.userSettings,
    isLoading: !error && !data,
    error,
    mutate
  };
}
