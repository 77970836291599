import { Disclosure, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

/**
 *
 * @param root0
 * @param root0.id
 * @param root0.name
 * @param root0.checked
 * @param root0.indeterminate
 * @param root0.issuerCertificates
 * @param root0.updateCertificates
 * @param root0.updateIssuer
 */
export default function CertificateFilterState({
  id,
  name,
  checked,
  indeterminate,
  issuerCertificates,
  updateCertificates,
  updateIssuer
}) {
  const [
    showIssuerCertificates,
    setShowIssuerCertificates
  ] = useState(false);

  const handleStateCheck = (e, issuerCertificates, id) => {
    if (issuerCertificates?.length) {
      updateCertificates(id, issuerCertificates.map((certificate) => ({
        ...certificate,
        checked: e.target.checked
      })));
    }
  };

  const handleIssuerCheck = (e, issuerCertificates, id) => {
    if (!issuerCertificates.length) {
      updateIssuer(id, e.target.checked);
    }
  };

  const handleCheck = (e, issuerCertificateId) => {
    const issuerCertificateIndex = issuerCertificates.map(({ id: certId }) => certId).indexOf(issuerCertificateId);

    const newIssuerCertificates = [...issuerCertificates];

    newIssuerCertificates[issuerCertificateIndex] = {
      ...newIssuerCertificates[issuerCertificateIndex],
      checked: e.target.checked
    };

    updateCertificates(id, newIssuerCertificates);
  };

  return (
    <div className="py-8">
      <Disclosure>
        <div className="flex justify-between items-center">
          <label className="inline-flex items-center cursor-pointer text-xl font-medium">
            <input
              checked={checked || indeterminate}
              ref={(el) => el && (el.indeterminate = indeterminate)}
              onClick={(e) => {
                handleIssuerCheck(e, issuerCertificates, id);
              }}
              onChange={(e) => handleStateCheck(e, issuerCertificates, id)}
              type="checkbox"
              className="cursor-pointer w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-4">{name}</span>
          </label>
          {
            (issuerCertificates.length)
              ? <div className="pl-2">
                <Disclosure.Button as={Fragment}>
                  <button onClick={() => setShowIssuerCertificates((prev) => !prev)} type="button" className="font-light underline flex items-center focus:outline-none">
                    {!showIssuerCertificates &&
                  <>
                    <span>Zertifikate anzeigen</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </>
                    }
                    {showIssuerCertificates &&
                  <>
                    <span>Zertifikate zuklappen</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                    </svg>
                  </>
                    }
                  </button>
                </Disclosure.Button>
              </div>
              : null
          }
        </div>
        <Transition
          show={showIssuerCertificates}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 font-light">
              {
                issuerCertificates.map(({
                  id,
                  name,
                  checked
                }) => (
                  <div key={`region_${id}`} className="pl-2">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        checked={checked}
                        onChange={(e) => handleCheck(e, id)}
                        type="checkbox"
                        className="cursor-pointer w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
                      <span className="ml-3">{name}</span>
                    </label>
                  </div>
                ))
              }
            </div>
          </Disclosure.Panel>
        </Transition>
      </Disclosure>
    </div>
  );
}
