/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines-per-function */
/* eslint-disable import/max-dependencies */
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { StarIcon } from "@heroicons/react/24/outline";
import {
  PlusIcon, StarIcon as StarIconFilled, XCircleIcon
} from "@heroicons/react/24/solid";
import cn from "classnames";
import {
  Fragment, useEffect, useRef, useState
} from "react";

import {
  createItem, createWatchlist, deleteItem
} from "./handlers.js";
import {
  addProject, addWatchlist, removeProject
} from "./helpers.js";
import WatchlistDropdownHeader from "./watchlist-dropdown-header.jsx";
import WatchlistDropdownItem from "./watchlist-dropdown-item.jsx";

import { showError, showSuccess } from "~/src/modules/toast-helper.js";

/**
 * @typedef {import("react").JSX.Element} JSXElement
 */

/**
 * The watchlist dropdown component.
 *
 * @param {object} props - The props object.
 * @param {Array} props.watchlists - The watchlists array.
 * @param {Function} props.mutateWatchlists - The mutateWatchlists function.
 * @param {object} props.project - The project object.
 * @param {string} props.className - The className string.
 * @param {string} props.buttonClassName - The buttonClassName string.
 * @return {JSXElement} - The JSX element.
 */
export default function WatchlistDropdown({
  watchlists,
  mutateWatchlists,
  project,
  className = "", buttonClassName = ""
}) {
  const inputRef = useRef(null);
  const [
    formOpen,
    setFormOpen
  ] = useState(false);
  const [
    name,
    setName
  ] = useState("");
  const [
    submitting,
    setSubmitting
  ] = useState(false);
  const [
    isError,
    setIsError
  ] = useState(false);

  const projects = [];

  watchlists?.forEach((watchlist) => {
    watchlist?.projects.forEach((project) => {
      projects.push(project.slug);
    });
  });

  useEffect(() => {
    if (formOpen) {
      inputRef.current.focus();
    }
  }, [formOpen]);

  const handleErrorWatchlist = (status) => {
    setSubmitting(false);
    setIsError(status);
  };

  // eslint-disable-next-line no-empty-function
  const handleSuccess = (payload, type) => {
  };

  const handleError = (status, uuid) => {
    setSubmitting(false);
    showError(uuid);
  };

  const resetDropdown = () => {
    setName("");
    setIsError(false);
    setSubmitting(false);
    setFormOpen(false);
  };

  const handleSuccessWatchlist = (watchlist, closePopover) => {
    closePopover();
    resetDropdown();
    mutateWatchlists({
      payload: {
        watchlists: addWatchlist(watchlists, project, watchlist)
      }
    }, false);
    showSuccess(`${watchlist.uuid}newWatchlist`);
  };

  const handleSubmitWatchlist = (closePopover) => {
    setSubmitting(true);
    createWatchlist(
      name,
      project?.slug,
      closePopover,
      handleSuccessWatchlist,
      handleErrorWatchlist
    );
  };

  const handleChange = (event) => {
    const { value } = event.target;

    if (value.length === 0) {
      setIsError(false);
      setName(value);
    }
    if (value.length === 1 && /[a-zA-Z0-9]/u.test(value)) {
      setIsError(false);
      setName(value);
    }

    if (value.length > 1 && /^[A-Za-z0-9 ]+$/u.test(value)) {
      setIsError(false);
      setName(value.replace(/  +/gu, " "));
    }
  };

  const handleCheckbox = (event, uuid) => {
    const { checked } = event.target;

    if (checked) {
      mutateWatchlists({
        payload: {
          watchlists: addProject(watchlists, project, uuid)
        }
      }, false);

      createItem(uuid, project?.slug, handleSuccess, handleError);
    }
    else {
      mutateWatchlists({
        payload: {
          watchlists: removeProject(watchlists, project, uuid)
        }
      }, false);
      deleteItem(uuid, project?.slug, handleSuccess, handleError);
    }
  };

  const handleToggleForm = () => {
    if (formOpen) {
      inputRef.current.blur();
    }
    setFormOpen(!formOpen);
  };

  const watchlistActive = projects.includes(project?.slug);
  const watchlistLength = watchlists?.length || 0;

  return (
    <div className={`${className}`}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={cn(
                "px-1 py-0.5 border text-gray-500 hover:text-gray-600 group rounded inline-flex items-center text-base font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
                {
                  "border-gray-300 hover:bg-gray-50": !open && !watchlistActive,
                  "border-gray-200 bg-gray-200": open && !watchlistActive,
                  "border-primary bg-primary hover:bg-primary-dark": !open && watchlistActive,
                  "border-primary": open && watchlistActive
                },
                buttonClassName
              )}
            >
              {watchlistActive && !open &&
                <>
                  <StarIconFilled className="h-5 w-5 text-white" />
                  <ChevronDownIcon className="h-5 w-5 text-white" />
                </>
              }
              {watchlistActive && open &&
                <>
                  <StarIconFilled className="h-5 w-5 text-primary" />
                  <ChevronDownIcon className="h-5 w-5 text-primary" />
                </>
              }
              {!watchlistActive &&
                <>
                  <StarIcon className="h-5 w-5" />
                  <ChevronDownIcon className="h-5 w-5" />
                </>
              }
            </Popover.Button>
            {/* <Popover.Overlay
              className={`${open ? "opacity-30 fixed inset-0 z-20" : "opacity-0"
              } bg-black`}
            /> */}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                unmount={true}
                className="absolute right-1/2 z-[100] -mr-7 mt-1 w-80 transform lg:-mr-44 xl:-mr-7">
                {({ open, close }) => (
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative bg-white px-7 pt-4">

                      <WatchlistDropdownHeader
                        open={open}
                        close={close}
                        resetDropdown={resetDropdown}
                      />

                      <div className={cn("max-h-64", {
                        "overflow-y-scroll": watchlistLength > 4
                      })}>

                        <div className="grid gap-4">

                          {watchlists?.map((watchlist, index) => (
                            <WatchlistDropdownItem
                              key={`watchlist_${project?.slug}_watchlist_${watchlist.uuid}`}
                              watchlist={watchlist}
                              watchlistLength={watchlistLength}
                              index={index}
                              project={project}
                              handleCheckbox={handleCheckbox}
                            />
                          ))}

                        </div>
                      </div>

                    </div>
                    <div className="border-t border-gray-200 bg-gray-50 p-4">

                      {watchlistLength >= 20 &&
                        <span className="font-medium text-gray-800">
                          20 von 20 Merklisten erstellt
                        </span>
                      }

                      {watchlistLength < 20 &&
                        <button
                          type="button"
                          onClick={handleToggleForm}
                          className="p-2 px-4 focus:outline-none"
                        >
                          <div className="flex items-center">
                            <span className="font-medium text-gray-800">
                              Neue Merkliste
                            </span>

                            {!formOpen &&
                              <PlusIcon className="h-5 w-5 text-gray-600" />
                            }

                            {formOpen &&
                              <ChevronUpIcon className="h-5 w-5 text-gray-600" />
                            }
                          </div>
                        </button>
                      }

                      {formOpen && watchlistLength < 20 &&
                        <div className="h-32 px-4 pb-4 pt-2">

                          <div className="relative border-b border-gray-300">
                            <label className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                              ref={inputRef}
                              autoComplete="off"
                              name="watchlist"
                              type="text"
                              value={name}
                              maxLength={60}
                              onChange={handleChange}
                              placeholder="Neue Merkliste hinzufügen..."
                              // eslint-disable-next-line max-len
                              className="-mt-2 h-8 w-full border-none bg-transparent pl-0 text-sm text-gray-700 focus:outline-none focus:ring-transparent"
                            />
                            {name.length > 0 && !submitting &&
                              <button
                                className="absolute -right-5 bottom-0 block text-gray-400 hover:text-gray-500 focus:outline-none"
                                onClick={() => setName("")}
                              >
                                <XCircleIcon className="mb-1.5 h-5 w-5" />
                              </button>
                            }
                          </div>
                          <div className="mt-1 flex items-center justify-between text-xs">
                            {isError && isError === 409 &&
                              <p className="text-primary">Merkliste existiert bereits!</p>
                            }
                            {isError && isError !== 409 &&
                              <p className="text-primary">Ein Fehler ist aufgetreten!</p>
                            }
                            <p className={cn("text-gray-700 text-right", {

                            })}>{name.length} / 60</p>
                          </div>

                          <div className="mt-6 text-right">
                            <button type="button"
                              onClick={() => handleSubmitWatchlist(close)}
                              disabled={submitting || name.length === 0}
                              className={cn("focus:outline-none text-primary text-sm font-medium uppercase hover:text-primary-dark", {
                                "cursor-not-allowed": name.length === 0
                              })}>
                              {submitting &&
                                <svg className="h-5 w-5 animate-spin text-primary" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                </svg>
                              }
                              {!submitting && <span>Speichern</span>}
                            </button>
                          </div>

                        </div>
                      }

                    </div>
                  </div>

                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
