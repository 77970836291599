import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { useEffect, useState } from "react";

import {
  createMarkers,
  formatCurrency,
  formatCurrencyPerSquaremeter,
  getAxisLeftLegendOffset,
  getXMax,
  getXMin,
  getYMax,
  getYMin,
  ZKeyAwareNode
} from "./helpers";

/**
 *
 * @param props
 * @param props.data
 * @param props.timeline
 * @param props.legend
 */
// eslint-disable-next-line max-lines-per-function
export default function ScatterPlot({
  data,
  timeline,
  legend = {
    y: "Preis",
    x: "Zeitlinie"
  }
}) {
  const theme = {
    axis: {
      legend: {
        text: {
          fontSize: 16,
          fill: "#333333"
        }
      }
    }
  };

  const [
    animatedData,
    setAnimatedData
  ] = useState([]);
  const [
    activeNode,
    setActiveNode
  ] = useState(null);

  const mouseEnterNode = (node) => {
    setActiveNode(node.id);
  };

  const mouseLeaveNode = () => {
    setActiveNode(null);
  };

  const getNodeSize = (d) => ((activeNode === d.id)
    ? d.data.size * 1.3
    : d.data.size
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => setAnimatedData(data), 1);

    return () => clearTimeout(timeoutId);
  }, [data]);

  return (
    <ResponsiveScatterPlot
      data={animatedData}
      theme={theme}
      margin={{
        top: 40,
        right: 110,
        bottom: 70,
        left: 160
      }}
      xScale={{
        format: "native",
        type: "time",
        min: (animatedData.length)
          ? getXMin(animatedData[0].xMin, animatedData[0].xMax, timeline)
          : "auto",
        max: (animatedData.length)
          ? getXMax(animatedData[0].xMin, animatedData[0].xMax, timeline)
          : "auto"
      }}
      xFormat="time:%Y-%m-%dT%H"
      axisBottom={{
        format: "%b %y'",
        tickPadding: 20,
        legend: legend.x,
        legendPosition: "middle",
        legendOffset: 60
      }}
      yFormat=">-.2f"
      yScale={{
        type: "linear",
        min: (animatedData.length)
          ? getYMin(animatedData[0].yMin, animatedData[0].yMax)
          : 0,
        max: (animatedData.length)
          ? getYMax(animatedData[0].yMin, animatedData[0].yMax)
          : "auto"
      }}
      gridYValues = {(animatedData.length)
        ? undefined// getTickValues(animatedData[0].yMin, animatedData[0].yMax)
        : undefined}
      axisLeft={{
        format: (legend.y.includes("m²")) ? formatCurrencyPerSquaremeter : formatCurrency,
        tickValues: (animatedData.length)
          ? undefined// getTickValues(animatedData[0].yMin, animatedData[0].yMax)
          : undefined,
        tickPadding: 5,
        legend: legend.y,
        legendPosition: "middle",
        legendOffset: (animatedData.length)
          ? getAxisLeftLegendOffset(legend.y.includes("m²"), animatedData[0].yMax)
          : 0
      }}
      onMouseEnter = {(node) => mouseEnterNode(node)}
      onMouseLeave = {() => mouseLeaveNode()}
      animate={true}
      useMesh={false}
      enableGridX={true}
      enableGridY={true}
      markers={createMarkers(timeline)}
      nodeSize={(d) => getNodeSize(d)}
      colors={(d) => animatedData.find((item) => item.id === d.serieId).color}
      tooltip={(datum) => datum.node.data.tooltip}
      blendMode="normal"
      nodeOpacity={0.1}
      nodeComponent={ZKeyAwareNode}
    />
  );
}
