/* eslint-disable no-undefined */
/* eslint-disable max-params */
/**
 *
 * @param name
 * @param event
 * @param tempQuery
 * @param items
 * @param tempItems
 * @param key
 * @param update
 */
export const handleCheck = function (name, event, tempQuery, items, tempItems, key, update) {
  const isChecked = event.target.checked;

  let updatedItems = [];

  if (tempQuery === undefined) {
    if (isChecked) {
      updatedItems = [
        ...items,
        key
      ];
    }
    else {
      updatedItems = items.filter((item) => item !== key);
    }
  }

  if (tempItems.length > 0) {
    if (isChecked) {
      updatedItems = [
        ...tempItems,
        key
      ];
    }
    else {
      updatedItems = tempItems.filter((item) => item !== key);
    }
  }

  if (tempItems.length === 0 && tempQuery !== undefined) {
    if (isChecked) {
      updatedItems = [
        ...new Set([
          ...tempItems,
          key
        ])
      ];
    }
    else {
      updatedItems = tempItems.filter((item) => item !== key) || [];
    }
  }

  let updateValue = null;

  if (updatedItems.length > 0) {
    updateValue = updatedItems.sort().join(",");
  }

  update(name, updateValue);
};

/**
 *
 * @param query
 * @param item
 * @param tempQuery
 * @param tempItem
 */
export const handleCheckedStatus = function (query, item, tempQuery, tempItem) {
  if (tempQuery === null) {
    return false;
  }
  if (tempQuery) {
    return tempItem;
  }
  if (query) {
    return item;
  }

  return false;
};

/**
 *
 * @param query
 */
export const parseActiveFilters = function (query) {
  const activeFilters = [];

  Object.keys(query).forEach((key) => {
    const value = query[key];

    if (!value) {
      return;
    }

    if (Array.isArray(value) && value?.length === 0) {
      return;
    }

    if (![
      "page",
      "direction",
      "sort",
      "radius",
      "automated",
      "stats",
      "marked"
    ].includes(key)) {
      activeFilters.push(key);
    }
  });

  return activeFilters;
};
