/* eslint-disable no-magic-numbers */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { range } from "lodash-es";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const customRange = (from, to, step = 1) => {
  let stepCounter = from;
  const rangeValues = [];

  while (stepCounter <= to) {
    rangeValues.push(stepCounter);
    stepCounter += step;
  }

  return rangeValues;
};

/**
 *
 * @param totalPages
 * @param currentPage
 * @param pageNeighbours
 */
export const generatePageItems = (totalPages, currentPage, pageNeighbours = 2) => {
  // totalNumbers: the total page numbers to show on the control
  // totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
  const totalNumbers = (pageNeighbours * 2) + 3;
  const totalBlocks = totalNumbers + 2;

  if (totalPages > totalBlocks) {
    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
    let pages = customRange(startPage, endPage);

    // hasLeftSpill: has hidden pages to the left
    // hasRightSpill: has hidden pages to the right
    // spillOffset: number of hidden pages either to the left or to the right
    const hasLeftSpill = startPage > 2;
    const hasRightSpill = (totalPages - endPage) > 1;
    const spillOffset = totalNumbers - (pages.length + 1);

    switch (true) {
      // handle: (1) < {5 6} [7] {8 9} (10)
      case (hasLeftSpill && !hasRightSpill): {
        const extraPages = customRange(startPage - spillOffset, startPage - 1);

        pages = [
          LEFT_PAGE,
          ...extraPages,
          ...pages
        ];
        break;
      }

      // handle: (1) {2 3} [4] {5 6} > (10)
      case (!hasLeftSpill && hasRightSpill): {
        const extraPages = customRange(endPage + 1, endPage + spillOffset);

        pages = [
          ...pages,
          ...extraPages,
          RIGHT_PAGE
        ];
        break;
      }

      // handle: (1) < {4 5} [6] {7 8} > (10)
      case (hasLeftSpill && hasRightSpill):
      default: {
        pages = [
          LEFT_PAGE,
          ...pages,
          RIGHT_PAGE
        ];
        break;
      }
    }

    return [
      1,
      ...pages,
      totalPages
    ];
  }

  return customRange(1, totalPages);
};

/**
 *
 * @param totalPages
 * @param currentPage
 * @param pageNeighbours
 */
export const generatePageItemsMobile = (totalPages, currentPage, pageNeighbours = 2) => {
  // totalNumbers: the total page numbers to show on the control
  // totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
  const totalNumbers = (pageNeighbours * 2);
  const totalBlocks = totalNumbers + 2;

  if (totalPages > totalBlocks) {
    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
    let pages = customRange(startPage, endPage);

    // hasLeftSpill: has hidden pages to the left
    // hasRightSpill: has hidden pages to the right
    // spillOffset: number of hidden pages either to the left or to the right
    const hasLeftSpill = startPage > 2;
    const hasRightSpill = (totalPages - endPage) > 1;
    const spillOffset = totalNumbers - (pages.length + 1);

    switch (true) {
      // handle: (1) < {5 6} [7] {8 9} (10)
      case (hasLeftSpill && !hasRightSpill): {
        const extraPages = customRange(startPage - spillOffset, startPage - 1);

        pages = [
          LEFT_PAGE,
          ...extraPages,
          ...pages
        ];
        break;
      }

      // handle: (1) {2 3} [4] {5 6} > (10)
      case (!hasLeftSpill && hasRightSpill): {
        const extraPages = customRange(endPage + 1, endPage + spillOffset);

        pages = [
          ...pages,
          ...extraPages,
          RIGHT_PAGE
        ];
        break;
      }

      // handle: (1) < {4 5} [6] {7 8} > (10)
      case (hasLeftSpill && hasRightSpill):
      default: {
        pages = [
          LEFT_PAGE,
          ...pages,
          RIGHT_PAGE
        ];
        break;
      }
    }

    return [
      1,
      ...pages,
      totalPages
    ];
  }

  return customRange(1, totalPages);
};

/*
BELOW HELPERS FOR NEW IMPLEMENTED PAGINATION
*/

/**
 *
 * @param root0
 * @param root0.type
 * @param root0.currentPage
 * @param root0.count
 */
export const getButtonPage = ({
  type,
  currentPage,
  count
}) => {
  switch (type) {
    case "first":
      return 1;
    case "previous":
      return currentPage - 1;
    case "next":
      return currentPage + 1;
    case "last":
      return count;
    default:
      return typeof type === "number" ? type : null;
  }
};

/**
 *
 * @param type
 */
export const getButtonContent = (type) => {
  switch (type) {
    case "previous":
      return (
        <ChevronLeftIcon className="w-full" />
      );
    case "next":
      return (
        <ChevronRightIcon className="w-full" />
      );

    default:
      return type;
  }
};

/**
 *
 * @param root0
 * @param root0.siblingsStart
 * @param root0.boundaryCount
 * @param root0.count
 */
export const getStartEllipsis = ({
  siblingsStart, boundaryCount, count
}) => {
  if (siblingsStart > boundaryCount + 2) {
    return ["start-ellipsis"];
  }
  if (boundaryCount + 1 < count - boundaryCount) {
    return [boundaryCount + 1];
  }

  return [];
};

/**
 *
 * @param root0
 * @param root0.siblingsStart
 * @param root0.siblingsEnd
 */
export const getRangeCount = ({ siblingsStart, siblingsEnd }) => {
  if (siblingsStart < siblingsEnd + 1) {
    return range(siblingsStart, siblingsEnd + 1);
  }

  return [];
};

/**
 *
 * @param root0
 * @param root0.siblingsEnd
 * @param root0.count
 * @param root0.boundaryCount
 */
export const getEndEllipsis = ({
  siblingsEnd, count, boundaryCount
}) => {
  if (siblingsEnd < count - boundaryCount - 1) {
    return ["end-ellipsis"];
  }

  if (count - boundaryCount > boundaryCount) {
    return [count - boundaryCount];
  }

  return [];
};
