import { MapPinIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

import useProjectQueryParams from "~/src/hooks/use-project-query-params";

/**
 *
 * @param props
 * @param props.location
 * @param props.className
 */
export default function ProjectLocation({ location, className = "flex items-center text-gray-500 text-base print:text-xs gap-1.5 print:gap-[7px]" }) {
  const { query: { sort } } = useProjectQueryParams();

  return (
    <div className={className}>
      <MapPinIcon className="mb-0.5 ml-0.5 print:ml-px h-4 w-4 text-gray-500 shrink-0" />
      <p className="leading-tight text-left">
        <span className={cn({ "text-primary font-semibold": sort === "zipcode" })}>{location.city?.zipcode} </span>
        <span>{location.city?.name}, </span>
        <span>{location.street.name} </span>
        <span>{location.housenumber}</span>
      </p>
    </div>
  );
}
