/**
 *
 */
class rgbTools {

  /**
   *
   * @param rgbHex
   * @param argbHex
   * @param threshold
   */
  static applyThreshold(argbHex, threshold) {
    const BLACK = "FF000000";
    const WHITE = "FFFFFFFF";

    if (!argbHex) {
      return BLACK;
    }
    const [
      red,
      green,
      blue
    ] = argbHex
      .match(/(..)(..)(..)$/).splice(1)
      .map((_) => parseInt(_, 16));

    if (this.#isAboveIntensityThreshold([
      red,
      green,
      blue
    ], threshold)) {
      return BLACK;
    }

    return WHITE;
  }

  /**
   *
   * @param root0
   * @param root0."0"
   * @param root0."1"
   * @param root0."2"
   * @param threshold
   */
  static #isAboveIntensityThreshold([
    red,
    green,
    blue
  ], threshold) {
    return this.#toMono(red, green, blue) > threshold;
  }

  // https://stackoverflow.com/a/14331
  /**
   *
   * @param red
   * @param green
   * @param blue
   */
  static #toMono(red, green, blue) {
    return (0.2125 * red) + (0.7154 * green) + (0.0721 * blue);
  }

}
export { rgbTools };
