/* eslint-disable import/max-dependencies */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useLocation } from "react-router-dom";

import { handleExcelExport } from "./helpers";

import ExcelIcon from "~/src/ui/icons/excel-icon";
import useUserSettings from "~/src/hooks/use-user-settings";
import useProjectList from "~/src/hooks/use-project-list";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useProjectsStats from "~/src/hooks/use-projects-stats";
import useProjectsDetailStats from "~/src/hooks/use-projects-detail-stats";
import useCount from "~/src/hooks/use-count";
import API from "~/src/modules/api";

const MAX_EXPORT_SIZE = 25;

/**
 *
 */
const ExcelExportProjectsButton = () => {
  const location = useLocation();

  const swrOptions = {
    revalidateOnFocus: false
  };

  const [
    shouldFetch,
    setShouldFetch
  ] = useState(false);
  const [
    mutated,
    setMutated
  ] = useState(false);

  const [
    exporting,
    setExporting
  ] = useState(false);

  const { userSettings } = useUserSettings();
  const { query } = useProjectQueryParams();

  const { mutate: mutateCounts, ...counts } = useCount({
    query,
    shouldFetch
  }, swrOptions);

  const { stats, mutate: mutateStats } = useProjectsStats({
    query,
    shouldFetch,
    filtersetting: userSettings?.filtersetting
  }, swrOptions);

  const { projects, mutate: mutateProjectListForExport } = useProjectList(
    {
      shouldFetch,
      query: {
        ...query,
        forExport: true,
        page: location.pathname === "/liste" ? query.page : 1
      }
    },
    swrOptions
  );

  const {
    detailStats,
    isLoadingDetailStats,
    mutate: mutateDetailStats
  } = useProjectsDetailStats({
    query,
    shouldFetch
  }, swrOptions);

  const handleClick = function handleClick() {
    setExporting(true);
    setShouldFetch(true);
  };

  useEffect(
    () => {
      if (shouldFetch) {
        const mutating = async () => {
          await mutateProjectListForExport();
          await mutateCounts();
          await mutateStats();
          await mutateDetailStats();

          setMutated(true);
        };

        mutating();
      }
    },
    [shouldFetch]
  );

  useEffect(() => {
    if (mutated) {
      if (!shouldFetch || !stats || !counts) {
        return;
      }

      setShouldFetch(false);
      const projectIds = projects.map((project) => project.id);

      API
        .post("/units", projectIds)
        .then(
          ({ data: units }) => handleExcelExport(userSettings, projects, units, stats, counts, detailStats)
        );

      // eslint-disable-next-line no-warning-comments
      // TODO: return destroy function to cancel tasks

      setMutated(false);

      setExporting(false);
    }
  }, [mutated]);

  return (
    <>
      <button
        data-tooltip-id="tooltip-excel-export"
        data-tooltip-content="Excel-Export: bis zu 25 Projekte"
        onClick={handleClick}
        className="flex items-center justify-center w-8 h-8 p-1 rounded-full cursor-pointer bg-excel-green disabled:grayscale disabled:cursor-wait hover:bg-excel-green-dark"
        disabled={exporting}
      >
        <ExcelIcon />
      </button>
      <Tooltip
        id="tooltip-excel-export"
        place="bottom"
        className="z-50"
        delayShow={500}
      />
    </>
  );
};

export default ExcelExportProjectsButton;
