import { useParams } from "react-router-dom";

import ContentSection from "~/src/ui/sections/content-section";
import useProject from "~/src/hooks/use-project";

/**
 *
 */
const ProjectOverviewDescription = () => {
  const { id } = useParams();
  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    description
  } = project;

  return (
    <ContentSection id="description" title="Kurzbeschreibung">
      <p className="text-justify hyphens-auto">
        {(description)
          ? description
          : "-"
        }
      </p>
    </ContentSection>
  );
};

export default ProjectOverviewDescription;
