/* eslint-disable max-len */
import { Dialog, Transition } from "@headlessui/react";
import {
  Fragment, useEffect, useRef, useState
} from "react";

import RegionFilterState from "./region-filter-state";

import CloseIcon from "~/src/ui/icons/close-icon";
import useAccountRegions from "~/src/hooks/use-account-regions";

/**
 *
 * @param props
 * @param props.active
 * @param props.hide
 * @param props.queryRegion
 * @param props.update
 * @param props.submit
 * @param props.cancelRegionFilter
 * @param props.tempQuery
 * @param props.queryKey
 */
// eslint-disable-next-line max-lines-per-function
export default function RegionFilterModal({
  active, hide, queryRegion, update, submit, cancelRegionFilter, tempQuery, queryKey = "region"
}) {
  const { account_regions: accountRegions = {}, mutate } = useAccountRegions();

  const cancelButtonRef = useRef();

  const [
    states,
    setStates
  ] = useState([]);

  useEffect(() => {
    mutate();
  }, []);

  const accountRegionsArray = [...Object.values(accountRegions)];

  const sortStates = (unsortedStates) => [...unsortedStates].sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB)).reverse();

  const updateStateRegions = (id, regions) => {
    const stateIndex = states.map(({ id: stateId }) => stateId).indexOf(id);

    const newStates = [...states];

    const checked = regions.every(({ checked: regionChecked }) => regionChecked);
    const indeterminate = regions.some(({ checked: regionChecked }) => regionChecked) && !checked;

    const newState = {
      ...newStates[stateIndex],
      regions,
      checked,
      indeterminate
    };

    newStates[stateIndex] = newState;

    setStates(sortStates(newStates));
  };

  const setInitialStates = (regionIds) => {
    const initialStates = accountRegionsArray
      .map(({
        stateData,
        regions
      }) => {
        const updatedRegions = regions.map(({ id, ...region }) => ({
          id,
          ...region,
          checked: regionIds.includes(id)
        }));

        const checked = updatedRegions.every(({ checked: regionChecked }) => regionChecked);
        const indeterminate = updatedRegions.some(({ checked: regionChecked }) => regionChecked) && !checked;

        return {
          ...stateData,
          checked,
          indeterminate,
          regions: updatedRegions
        };
      });

    setStates(sortStates(initialStates));
  };

  useEffect(() => {
    if (accountRegionsArray?.length > 0 && tempQuery?.[queryKey]) {
      setInitialStates(tempQuery[queryKey]);
    }
  }, [tempQuery]);

  useEffect(() => {
    if (accountRegionsArray?.length > 0) {
      setInitialStates(queryRegion);
    }
  }, [
    accountRegions,
    queryRegion
  ]);

  useEffect(() => {
    const newRegions = states
      .map(({ regions }) => regions.filter(({ checked }) => checked))
      .flat()
      .map(({ id }) => id);

    if (
      (
        !tempQuery?.[queryKey] ||
        tempQuery[queryKey].some((id, index) => id !== newRegions[index]) ||
        (tempQuery[queryKey].length !== newRegions.length)
      )
    ) {
      update(
        queryKey,
        newRegions
      );
    }
  }, [states]);

  useEffect(() => {
    update(
      queryKey,
      queryRegion
    );
  }, []);

  const handleSubmit = function () {
    submit();
    hide();
  };

  const handleCancel = () => {
    setInitialStates(queryRegion);
    cancelRegionFilter();
    hide();
  };

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-[150] overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={active}
        onClose={handleCancel}
      >
        <div className="flex min-h-full w-screen items-end justify-center text-center sm:block sm:w-auto sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all sm:my-8 sm:max-w-3xl">
              <div className="relative border-b border-gray-200 px-4 py-4 sm:px-6">
                <p className="text-center text-lg font-medium">Regionen filtern</p>
                <div className="absolute right-4 top-0 hidden h-full items-center sm:flex">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={handleCancel}
                  >
                    <CloseIcon className="h-5 w-5 text-black" />
                  </button>
                </div>
              </div>

              <div className="max-h-screen-66 min-h-0 divide-y divide-gray-300 overflow-y-scroll bg-white px-4 sm:max-h-screen-80 sm:divide-gray-200 sm:px-12">

                {
                  states.map(({
                    id,
                    name,
                    code,
                    checked,
                    indeterminate,
                    regions
                  }) => (
                    <RegionFilterState key={id} {...{
                      id,
                      name,
                      checked,
                      indeterminate,
                      regions,
                      updateStateRegions
                    }} />
                  ))
                }

              </div>

              <div className="space-y-4 border-t border-gray-200 px-4 py-4 text-center sm:flex sm:flex-row-reverse sm:items-center sm:justify-between sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none sm:ml-3 sm:w-auto"
                  onClick={handleSubmit}
                >
                  Suchen
                </button>
                <button
                  type="button"
                  className="font-medium text-gray-700 underline hover:bg-gray-50 focus:outline-none"
                  onClick={handleCancel}
                  ref={cancelButtonRef}
                >
                  zurück
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
