import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param props
 * @param props.queryItems
 */
const HistoricFilterTags = ({ queryItems: historic }) => {
  if (!historic) {
    return null;
  }

  return (
    <DefaultFilterTag>
      <span>Historische Projekte</span>
    </DefaultFilterTag>
  );
};

export default HistoricFilterTags;
