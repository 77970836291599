/* eslint-disable max-lines */
/* eslint-disable no-undefined */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-empty-function */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable import/max-dependencies */
import {
  Circle,
  FeatureGroup,
  MapContainer,
  WMSTileLayer,
  ZoomControl
} from "react-leaflet";
import Leaflet, { LatLngBounds, Polygon } from "leaflet";
import {
  useEffect,
  useRef,
  useState
} from "react";
import drawLocales from "leaflet-draw-locales";

import EditControl from "./edit-control.js";
import MapEvents from "./map-events.js";

import ProjectMarkerClusterGroup from "~/src/features/project-marker-cluster-group/index.jsx";
import { parsePolygon } from "~/src/modules/helpers.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useProjectsMap from "~/src/hooks/use-projects-map.js";
import useStore from "~/src/hooks/use-store.js";
import useMediaQuery from "~/src/hooks/use-media-query.js";

drawLocales("de-at");

const maxZoom = 18;
const minZoom = 6;

// eslint-disable-next-line max-lines-per-function, max-statements
/**
 *
 * @param root0
 * @param root0.searchLocation
 * @param root0.setIsStreetView
 * @param root0.isSimple
 * @param root0.projectSlugs
 * @param root0.automated
 * @param root0.className
 * @param root0.forExport
 */
const ProjectsMap = ({
  searchLocation,
  setIsStreetView = () => { },
  isSimple,
  projectSlugs,
  automated,
  className,
  forExport = false
}) => {
  const [
    map,
    setMap
  ] = useState(null);

  const isPrint = useMediaQuery("print");
  const mapRef = useRef(null);
  const groupRef = useRef(null);
  const featureRef = useRef(null);
  const gridRef = useRef(null);
  const searchLocationRef = useRef(null);

  const { query, setQuery } = useProjectQueryParams();

  const [
    polygonState,
    setPolygonState
  ] = useState(null);
  const [
    controlMounted,
    setControlMounted
  ] = useState(false);
  const [
    controlState,
    setControlState
  ] = useState(null);
  const [
    pageLocked,
    setPageLocked
  ] = useState(true);

  const mapViewState = useStore((state) => state.mapViewState);
  const setMapViewState = useStore((state) => state.setMapViewState);
  const readPolygonsFromQuery = useStore((state) => state.readPolygonsFromQuery);
  const setReadPolygonsFromQuery = useStore((state) => state.setReadPolygonsFromQuery);

  const clusterState = useStore((state) => state.clusterState);
  const setClusterState = useStore((state) => state.setClusterState);

  const autoZoomed = useStore((state) => state.autoZoomed);
  const setAutoZoomed = useStore((state) => state.setAutoZoomed);

  const [
    queryChanged,
    setQueryChanged
  ] = useState(false);

  const prevQuery = useStore((state) => state.prevQuery);
  const setPrevQuery = useStore((state) => state.setPrevQuery);

  const [
    currentPoints,
    setCurrentPoints
  ] = useState([]);
  const [
    clustersSpiderfied,
    setClustersSpiderfied
  ] = useState(false);

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const setCurrentSelectionStatus = useStore((state) => state.setCurrentSelectionStatus);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);

  const statsBoxCollapsed = useStore((state) => state.statsBoxCollapsed);
  const filterTileCollapsed = useStore((state) => state.filterTileCollapsed);

  // TODO: add miniListTileCollapsed when implemented

  const hasBounds = (bounds) => bounds.every((value) => value !== null);

  const {
    points,
    clusterExpansionZooms,
    bounds,
    leaves,
    // eslint-disable-next-line unused-imports/no-unused-vars
    grid,
    mutate,
    isLoading,
    selectionStatus,
    selectionType
  } = useProjectsMap({
    query: {
      ...query,
      // eslint-disable-next-line camelcase
      cluster_state: clusterState,
      isSimple,
      forExport
    }
  });

  useEffect(() => {
    if (!isPrint) {
      setIsStreetView(false);
    }
  }, []);

  useEffect(() => {
    if (selectionMutate) {
      const mutating = async () => {
        await mutate();
        setSelectionMutate(false);
      };

      mutating();
    }
  }, [selectionMutate]);

  useEffect(() => {
    if (isSimple && !isPrint) {
      setQuery(
        {
          slugs: projectSlugs,
          automated
        }
      );
    }
  }, []);

  useEffect(() => {
    if (selectionStatus && !isPrint) {
      setCurrentSelectionStatus(selectionStatus);
    }
  }, [selectionStatus]);

  useEffect(() => {
    if (selectionType && !isPrint) {
      setMainSelectionType(selectionType);
    }
  }, [selectionType]);

  useEffect(() => {
    if (JSON.stringify(query) !== JSON.stringify(prevQuery)) {
      setPrevQuery(query);
      if (!queryChanged) {
        setQueryChanged(true);
      }
    }
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (mapRef.current && queryChanged) {
      setAutoZoomed(false);
      setQueryChanged(false);
    }
  }, [currentPoints]);

  useEffect(() => {
    if (!isLoading) {
      setCurrentPoints(points);
      if (selectionLoading) {
        setSelectionLoading(false);
      }
    }
  }, [points]);

  useEffect(() => {
    if (mapRef.current && !isPrint) {
      mapRef.current.invalidateSize();
    }
    if (mapRef.current) {
      mapRef.current.attributionControl.setPrefix("");
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (mapRef.current && !isPrint) {
      mapRef.current.invalidateSize();
    }
  }, [statsBoxCollapsed, filterTileCollapsed]);

  useEffect(() => {
    if (!readPolygonsFromQuery) {
      setReadPolygonsFromQuery(false);
      if (polygonState) {
        if (polygonState.length === 0) {
          setPolygonState(null);

          setQuery({
            page: pageLocked && query.page ? query.page : 1,
            polygon: undefined
          });
        }
        else {
          setQuery({
            page: pageLocked && query.page ? query.page : 1,
            polygon: polygonState.map(({ value }) => value)
          });
        }
      }
    }
  }, [polygonState]);

  useEffect(() => {
    if ((!query?.polygon || query.polygon.length === 0) && featureRef.current) {
      setPolygonState([]);

      featureRef.current.eachLayer((layer) => layer.remove());
    }

    if (readPolygonsFromQuery) {
      const queryPolygons = [];

      featureRef?.current?.eachLayer((layer) => layer.remove());

      query.polygon.forEach((positions, index) => {
        const polygon = new Polygon(
          positions.split("-")
            .map((position) => position.split("~"))
            .map(([
              lng,
              lat
            ]) => ({
              lng,
              lat
            })),
          {
            color: "#822c42",
            fillColor: "#822c42",
            weight: 2
          }
        );

        polygon.bindTooltip(`Polygon ${index + 1}`, {
          permanent: true,
          opacity: 0.8
        });

        polygon.openTooltip();

        // eslint-disable-next-line no-unused-expressions
        controlState?.options?.edit?.featureGroup && polygon.addTo(controlState.options.edit.featureGroup);

        queryPolygons.push({
          value: positions,
          // eslint-disable-next-line no-underscore-dangle
          id: polygon._leaflet_id
        });
      });

      setPolygonState(queryPolygons);
    }
  }, [query]);

  useEffect(() => {
    setReadPolygonsFromQuery(false);

    if (controlState) {
      const queryPolygons = [];

      query.polygon.forEach((positions, index) => {
        const polygon = new Polygon(positions.split("-").map((position) => position.split("~")).map(([
          lng,
          lat
        ]) => ({
          lng,
          lat
        })), {
          color: "#822c42",
          fillColor: "#822c42",
          weight: 2
        });

        polygon.bindTooltip(`Polygon ${index + 1}`, {
          permanent: true,
          // eslint-disable-next-line no-magic-numbers
          opacity: 0.8
        });

        polygon.openTooltip();

        polygon.addTo(controlState.options.edit.featureGroup);

        queryPolygons.push({
          value: positions,
          // eslint-disable-next-line no-underscore-dangle
          id: polygon._leaflet_id
        });
      });

      setPolygonState(queryPolygons);
    }
  }, [controlState]);

  useEffect(() => {
    const currentMapRef = mapRef.current;

    if (!currentMapRef) {
      return;
    }

    if (isLoading) {
      currentMapRef.fireEvent("dataloading");
    }
    else {
      currentMapRef.fireEvent("dataload");
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isPrint) {
      if (searchLocation === null || searchLocationRef?.current) {
        searchLocationRef?.current?.remove();
      }

      if (searchLocation) {
        const currentMapRef = mapRef.current;

        if (currentMapRef) {
          const searchCoords = [
            searchLocation?.coords?.lat,
            searchLocation?.coords?.lng
          ];

          currentMapRef.fitBounds([searchCoords]);

          const iconSize = 16;

          const icon = Leaflet.divIcon({
            className: "search-location-marker",
            html: "",
            iconSize: [
              iconSize,
              iconSize
            ],
            iconAnchor: [
              iconSize / 2,
              iconSize / 2
            ]
          });

          searchLocationRef.current = Leaflet.marker(searchCoords, { icon }).addTo(currentMapRef);
        }
      }
    }
  }, [
    searchLocation,
    mapRef.current
  ]);

  const handleCreate = function (event) {
    setPageLocked(false);
    setReadPolygonsFromQuery(false);

    const polyLayer = event.layer;

    polyLayer.bindTooltip(`Polygon ${(polygonState?.length || 0) + 1}`, {
      permanent: true,
      opacity: 0.8
    });

    polyLayer.openTooltip();

    const polygon = parsePolygon(polyLayer?.getLatLngs());

    setPolygonState([
      ...(polygonState || []),
      {
        value: polygon,
        id: polyLayer._leaflet_id
      }
    ]);
  };

  const handleEdit = function (event) {
    setPageLocked(false);
    setReadPolygonsFromQuery(false);

    const polygons = [];

    event.layers.eachLayer((layer) => {
      const polyLayer = layer;

      const polygon = parsePolygon(polyLayer?.getLatLngs());

      polygons.push({
        value: polygon,
        id: polyLayer._leaflet_id
      });
    });

    setPolygonState([
      ...polygonState.filter(({ id }) => !polygons.some(({ id: innerId }) => innerId === id)),
      ...polygons
    ]);
  };

  const handleDelete = function (event) {
    setPageLocked(false);
    setReadPolygonsFromQuery(false);

    const deletedPolygons = [];

    event.layers.eachLayer((layer) => {
      const polyLayer = layer;

      const polygon = parsePolygon(polyLayer?.getLatLngs());

      deletedPolygons.push({
        value: polygon,
        id: polyLayer._leaflet_id
      });

      event.layers.removeLayer(layer);
    });

    for (const [
      index,
      layer
    ] of controlState.options.edit.featureGroup.getLayers().entries()) {
      layer.setTooltipContent(`Polygon ${index + 1}`);
    }

    setPolygonState([...(polygonState || []).filter(({ id }) => !deletedPolygons.find(({ id: innerId }) => innerId === id))]);
  };

  const handleMounted = function (control) {
    if (!controlMounted) {
      setControlMounted(true);
      setControlState(control);
    }
  };

  useEffect(() => {
    if (mapRef.current && !autoZoomed && !isSimple && bounds && hasBounds(bounds)) {
      const currentMapRef = mapRef.current;

      currentMapRef.flyToBounds(new LatLngBounds([
        bounds[1],
        bounds[0]
      ], [
        bounds[3],
        bounds[2]
      ]), { duration: 1.5 });

      setAutoZoomed(true);
    }
    else if (mapRef.current && isSimple && bounds && hasBounds(bounds)) {
      const currentMapRef = mapRef.current;

      currentMapRef.fitBounds(new LatLngBounds([
        bounds[1],
        bounds[0]
      ], [
        bounds[3],
        bounds[2]
      ]));

      setAutoZoomed(true);
    }
  }, [
    autoZoomed,
    mapRef.current,
    bounds
  ]);

  return (
    <>
      <MapContainer
        zoomControl={false}
        scrollWheelZoom={true}
        center={[
          mapViewState.lat,
          mapViewState.lng
        ]}
        bounds={
          points
            ?.map(({
              geometry: {
                coordinates: [
                  lng,
                  lat
                ]
              }
            }) => [lat, lng])
        }
        zoom={mapViewState.zoom}
        maxZoom={maxZoom}
        minZoom={minZoom}
        zoomSnap={1}
        zoomDelta={1}
        tap={false}
        dragging={true}
        style={{
          backgroundColor: "white"
        }}
        ref={mapRef}
        preferCanvas={true}
        whenReady={setMap}
        className={className}
      >
        <MapEvents {...{
          setClusterState,
          setMapViewState,
          isSimple
        }} />

        <WMSTileLayer
          key="project-map"
          attribution={"&copy; <a href=\" http://basemap.at\">Basemap.at</a>"}
          url="https://mapsneu.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png"
          subdomains={[
            "maps",
            "maps1",
            "maps2",
            "maps3",
            "maps4"
          ]}
          maxZoom={34}
          format="image/png"
          version="1.0.0"
          updateInterval={50}
          maxNativeZoom={18}
          minNativeZoom={6}
          noWrap={true}
          bounds={new LatLngBounds([
            46.4318173285,
            9.47996951665
          ], [
            49.0390742051,
            16.9796667823
          ])}
        />

        {(!isSimple && !isPrint) && <ZoomControl position="topright" />}

        {(!isSimple || isPrint) && (
          // eslint-disable-next-line max-lines
          <FeatureGroup ref={featureRef}>
            <EditControl
              position="topright"
              onCreated={handleCreate}
              onEdited={handleEdit}
              onDeleted={handleDelete}
              onMounted={handleMounted}
              draw={{
                circle: false,
                polyline: false,
                rectangle: false,
                marker: false,
                circlemarker: false,
                polygon: {
                  allowIntersection: true,
                  shapeOptions: {
                    color: "#822c42",
                    opacity: 1,
                    weight: 2
                  }
                }
              }}
            />
          </FeatureGroup>
        )
        }

        {
          query.street && <Circle
            center={query.street.split(",").map(Number)}
            radius={query.radius * 1000 || 2000}
          />
        }

        <FeatureGroup
          ref={groupRef}
        >
          {
            <ProjectMarkerClusterGroup
              // eslint-disable-next-line max-lines
              {...{
                points: currentPoints,
                map,
                mapRef,
                groupRef,
                clusterExpansionZooms,
                leaves,
                clustersSpiderfied,
                setClustersSpiderfied,
                isSimple
              }}
            />
          }
        </FeatureGroup>

      </MapContainer>
    </>
  );
};

export default ProjectsMap;
