/* eslint-disable max-len */
/* eslint-disable no-empty-function */
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

import { sortProjectLabels } from "~/src/modules/labels";
import useMediaQuery from "~/src/hooks/use-media-query";

/**
 *
 * @param props
 * @param props.submit
 * @param props.sort
 * @param props.onSortChange
 * @param props.hasStreet
 * @param props.isHandleItemClickDisabled
 */
const SortProjectsDropdown = ({
  sort = "name",
  hasStreet = false,
  onSortChange = () => {},
  isHandleItemClickDisabled
}) => {
  const isHybrid = useMediaQuery("(max-width: 1150px)");

  return (
    <Menu as="div" className="relative">
      {(isHybrid)
        ? <Menu.Button className="flex items-center justify-center w-[2rem] h-[1.5rem] p-[0.2rem] lg:w-[2.25rem] lg:h-[1.875rem] lg:p-[0.3rem] text-gray-500 bg-white border border-gray-200 rounded-sm cursor-pointer hover:bg-gray-50">
          <Cog6ToothIcon className="block w-5 h-full text-gray-500" aria-hidden="true" />
        </Menu.Button>
        : <Menu.Button className="lg:flex items-center w-full h-full gap-1 font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
          <span>{sortProjectLabels.get(sort)}</span>
          <ChevronDownIcon className="w-5 h-full text-gray-500" aria-hidden="true" />
        </Menu.Button>
      }

      <Menu.Items className="origin-top-right z-10 absolute right-0 -mt-0.5 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col items-stretch overflow-hidden">
        {
          Array.from(sortProjectLabels)
            .filter(([key]) => (key === "radius" && hasStreet) || (key !== "radius"))
            .map(([
              key,
              label
            ]) => (
              <Menu.Item key={key}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => onSortChange(key)}
                    disabled={isHandleItemClickDisabled}
                    className="w-full px-4 py-2 text-left text-gray-700 bg-white ui-active:bg-gray-100 ui-active:text-gray-900"
                  >
                    {label}
                  </button>
                )}
              </Menu.Item>
            ))
        }
      </Menu.Items>
    </Menu>
  );
};

export default SortProjectsDropdown;
