import { useEffect, useState } from "react";
import cn from "classnames";

import { formatAreaInt, formatDate } from "~/src/modules/formatters";
import InsolvencyPropertySlide from "~/src/features/insolvency-property-slide";
import useTracking from "~/src/hooks/use-tracking";

/**
 *
 * @param root0
 * @param root0.isLoading
 * @param root0.properties
 * @param root0.loadingItems
 * @param root0.query
 */
export default function InsolvencyPropertyList({
  isLoading, properties = [], loadingItems = 10, query
}) {
  const { trackPageView } = useTracking();
  const [
    showInsolvencyDetails,
    setShowInsolvencyDetails
  ] = useState(false);
  const [
    activeProperty,
    setActiveProperty
  ] = useState(false);
  const [
    activeColumn,
    setActiveColumn
  ] = useState("dateLastChange");

  function handleRowClick(item) {
    setActiveProperty(item);
    setShowInsolvencyDetails(true);
    trackPageView(item.id, "Insolvenz Liegenschaft");
  }

  useEffect(() => {
    if (query?.sortByProperty) {
      setActiveColumn(query.sortByProperty);
    }
  }, [query.sortByProperty]);

  return (
    <div>

      <InsolvencyPropertySlide active={showInsolvencyDetails} hide={() => setShowInsolvencyDetails(false)} property={activeProperty} />

      <div className="ml-4 sm:ml-0 mb-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow-sm overflow-hidden border border-b border-gray-200 sm:rounded">

              <div className="min-w-full divide-y divide-gray-200">

                <div className="flex lg:grid grid-cols-12 items-center bg-gray-100">
                  <div className="col-span-3 grid grid-cols-2">
                    <div className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider whitespace-nowrap">
                      Öffentlich
                    </div>
                    <div className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider whitespace-nowrap">
                      Änderung
                    </div>
                  </div>
                  <div className="col-span-1 w-20 lg:w-auto px-6 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    KG
                  </div>
                  <div className="col-span-1 w-20 lg:w-auto px-6 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    EZ
                  </div>
                  <div className="col-span-1 w-28 lg:w-auto pr-6 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Fläche EZ
                  </div>
                  <div className="col-span-3 w-64 lg:w-auto px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Adresse (Liegenschaft)
                  </div>
                  <div className="col-span-3 w-64 lg:w-auto pr-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Schuldner
                  </div>
                </div>

                <div className="bg-white divide-y divide-gray-200">
                  {isLoading &&
                    [...Array(loadingItems)].map((e, i) => (
                      <div key={`share_deal_properties_skeleton_${i}`} className="h-20 flex lg:grid grid-cols-12 items-center hover:bg-gray-50 cursor-pointer">
                        <div className="w-20 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="w-20 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="w-20 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-3 w-64 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-3 w-64 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="w-28 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-2 w-64 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                      </div>
                    ))
                  }

                  {!isLoading && properties?.length === 0 &&

                    <div className="bg-white">
                      <p className="px-4 py-4 whitespace-nowrap text-center font-light text-gray-900">keine Liegenschaften für die ausgewählten Kriterien gefunden</p>
                    </div>
                  }

                  {properties?.map((property) => (
                    <div key={property.id} className="flex lg:grid grid-cols-12 items-center hover:bg-gray-50 cursor-pointer text-gray-600" onClick={() => handleRowClick(property)}>

                      <div className="col-span-3 grid grid-cols-2">
                        <div className={cn("px-6 py-4 whitespace-nowrap text-sm", {
                          "text-primary font-bold": activeColumn === "datePublished",
                          "text-gray-900 font-medium": activeColumn !== "datePublished"
                        })}>
                          {formatDate({ date: property.insolvency.published_date })}
                        </div>

                        <div className={cn("px-6 py-4 whitespace-nowrap text-sm", {
                          "text-primary font-bold": activeColumn === "dateLastChange",
                          "text-gray-900 font-medium": activeColumn !== "dateLastChange"
                        })}>
                          {formatDate({ date: property.insolvency.last_change_date })}
                        </div>
                      </div>

                      <p className={cn("w-20 lg:w-auto px-6 py-4 whitespace-nowrap text-sm", {
                        "text-primary font-bold": activeColumn === "kg"
                      })}>{property.kgnr}</p>

                      <p className={cn("w-20 lg:w-auto px-6 py-4 whitespace-nowrap text-sm", {
                        "text-primary font-bold": activeColumn === "ez"
                      })}>{property.ez}</p>

                      <div className={cn("w-28 lg:w-auto pr-6 py-4 whitespace-nowrap text-sm", {
                        "text-primary font-bold": activeColumn === "area"
                      })}>
                        <p>{formatAreaInt(property.ez_area)}</p>
                      </div>

                      <div className="w-64 lg:w-auto col-span-3 px-6 py-4 whitespace-nowrap text-smtruncate">
                        <span className={cn({
                          "text-primary font-bold": activeColumn === "street"
                        })}>
                          {property.property_street} {property.property_house_number}</span><br />{property.property_state}, <span className={cn({
                          "text-primary font-bold": activeColumn === "zipcode"
                        })}>
                          {property.property_zipcode} {property.property_city.replace(",", ", ")}
                        </span>
                      </div>

                      <div className="col-span-3 w-64 lg:w-auto pr-6 py-4 whitespace-nowrap text-smtruncate">
                        <p className="truncate">
                          <span className={cn({
                            "text-primary font-bold": activeColumn === "fbnr"
                          })}>{property.insolvency.company_fbnr}
                          </span>
                          <span className="mx-1">-</span>
                          <span className={cn({
                            "text-primary font-bold": activeColumn === "company"
                          })}>{property.insolvency.company_name}
                          </span>
                        </p>
                        <p className="truncate">{property.insolvency.company_city}, {property.insolvency.company_street}</p>
                      </div>

                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
