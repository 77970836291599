/* eslint-disable no-magic-numbers */
/* eslint-disable no-warning-comments */
/* eslint-disable max-len */
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cn from "classnames";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import ImmoUnitedDropdown from "../dropdowns/immo-united-dropdown";
import SettingsDropdown from "../dropdowns/settings-dropdown";

import useStore from "~/src/hooks/use-store";

const tabsLeft = [
  {
    name: "Karte",
    href: "/karte",
    current: false,
    pageKey: "karte"
  },
  {
    name: "Liste",
    href: "/liste",
    current: false,
    pageKey: "liste"
  },
  {
    name: "Statistik",
    href: "/statistik",
    current: false,
    pageKey: "statistik"
  }
];

const tabsRight = [
  // { name: "Dashboard", href: "/", pageKey: "dashboard" },
  {
    name: "Transaktionen",
    href: "/transaktionen",
    current: false,
    pageKey: "transactions"
  },
  {
    name: "Share Deals",
    href: "/share-deals",
    current: false,
    pageKey: "sharedeals"
  },
  {
    name: "Insolvenzen",
    href: "/insolvenzen",
    current: false,
    pageKey: "insolvencies"
  }
  // { name: "Merklisten", href: "/merklisten", pageKey: "watchlist" },
];

const tabs = [
  ...tabsLeft,
  ...tabsRight
];

// TODO: Add project list export
// const PdfExportProjectListButton = lazy(() => import("~/src/features/pdf-export-project-list-button"));

/**
 *
 * @param props
 * @param props.pageKey
 * @param props.hideMobile
 * @param props.projectList
 * @param props.projectDetail
 * @param props.showMenu
 * @param props.showReduced
 * @param props.onlyMenu
 * @param props.searchKey
 */
// eslint-disable-next-line max-lines-per-function
export default function SubNav({
  pageKey = false,
  showReduced = false,
  projectList = false,
  projectDetail = false,
  searchKey = "",
  onlyMenu
}) {
  const history = useHistory();

  const [
    showScrollTop,
    setShowScrollTop
  ] = useState(false);

  const toggleProjectsFiltersTile = useStore((state) => state.toggleProjectsFiltersTile);

  const toggleAreaModal = useStore((state) => state.toggleAreaModal);
  const toggleProjectSuggestionModal = useStore((state) => state.toggleProjectSuggestionModal);

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = Math.abs(currPos.y) > 800;

    if (isShow !== showScrollTop) {
      setShowScrollTop(isShow);
    }
  }, [showScrollTop]);

  const handleChange = (event) => {
    const { value } = event.target;

    history.push(tabs.find((tab) => tab.pageKey === value).href);
  };

  const buttonNavigationLeft = ({ className }) => (
    <nav className={className}>
      {tabsLeft.map((tab) => (<Link
        key={tab.name}
        to={`${tab.href}${[
          "/",
          "/karte",
          "/liste",
          "/statistik"
        ].includes(pageKey)
          ? searchKey
          : ""}`}
        title={tab.name}
        className={cn(
          "text-center text-white break-keep whitespace-nowrap text-sm select-none px-2 py-0.5 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white before:h-0 before:block before:content-[attr(title)] before:font-bold before:overflow-hidden before:visibility-hidden",
          {
            "bg-white !text-primary font-bold": ((tab?.href === pageKey) || (pageKey === "/" && tab?.href === "/karte")),
            "border-transparent hover:text-primary hover:bg-white": tab?.href !== pageKey
          }
        )}
      >
        {tab.name}
      </Link>))}
    </nav>
  );

  const buttonNavigationRight = ({ className }) => (
    <nav className={`${className} mr-[0.05rem]`}>
      {tabsRight.map((tab) => <Link
        key={tab.name}
        to={tab.href}
        title={tab.name}
        className={cn(
          "text-center break-keep whitespace-nowrap text-sm select-none px-2 py-0.5 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white before:h-0 before:block before:content-[attr(title)] before:font-bold before:overflow-hidden before:visibility-hidden",
          {
            "bg-white text-primary font-bold": tab?.href === pageKey,
            "text-white border-transparent hover:text-primary hover:bg-white": tab?.href !== pageKey
          }
        )}
      >
        {tab.name}
      </Link>)}
      <div className="flex flex-row gap-4 pl-3">
        <ImmoUnitedDropdown/>
        <SettingsDropdown />
      </div>

    </nav>
  );

  const buttonNavigation = ({ className }) => <div className="flex flex-row justify-between w-full">
    {buttonNavigationLeft({ className: `${className} justify-start` })}
    {buttonNavigationRight({ className: `${className} justify-end` })}
  </div>;

  const dropdownNavigation = ({ className }) => (
    <div className={className}>
      <select
        id="current-tab"
        name="current-tab"
        className="block w-full h-full pl-3 pr-10 text-sm text-gray-500 border-gray-300 rounded focus:outline-none focus:ring-primary focus:border-primary"
        onChange={handleChange}
        value={tabs.find((tab) => tab?.href === pageKey)?.pageKey || ""}
      >
        {tabsLeft.map((tab) => (
          <option key={tab.pageKey} value={tab.pageKey || null}>{tab.name}</option>
        ))}
        {tabsRight.map((tab) => (
          <option key={tab.pageKey} value={tab.pageKey || null}>{tab.name}</option>
        ))}
      </select>
    </div>
  );

  if (onlyMenu) {
    return (
      <div className="flex justify-end z-10 sticky top-0 max-h-[36px] h-full w-full">
        <div className="flex flex-row pl-3 gap-4 max-h-[36px]">
          <ImmoUnitedDropdown/>
          <SettingsDropdown />
        </div>
      </div>
    );
  }

  return (
    <div className="sticky top-0 z-10 w-full h-full">
      <div className="flex items-center max-h-[36px] h-full w-full">
        {(showReduced)
          ? <>
            {buttonNavigation({ className: "hidden xl:flex justify-between items-center gap-x-3 w-full" })}
            {dropdownNavigation({ className: "flex xl:hidden bg-white rounded h-full" })}
          </>
          : <>
            {buttonNavigation({ className: "hidden md:flex items-center gap-x-3 w-full" })}
            {dropdownNavigation({ className: "flex md:hidden bg-white rounded h-full" })}
          </>

        }
        {

          /*
        <div className="items-center justify-between hidden h-full sm:flex">

          <div className="items-center justify-end hidden space-x-10 lg:flex">
            <Transition
              show={showScrollTop}
              enter="transition-opacity duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <button onClick={handleScrollTop} type="button" className="flex flex-col items-center justify-center -mt-2 text-xs font-bold text-primary hover:text-primary-dark">
                <ChevronUpIcon className="w-7 h-7" />
                <span className="leading-none uppercase">zurück nach oben</span>
              </button>
            </Transition>

            <div className="items-center justify-end space-x-5 md:flex">
              {/* projectList &&
                  <div className="flex items-center space-x-3">
                    { /* TODO: Add project list export }
                    { /* <Suspense fallback={<Spinner />}><PdfExportProjectListButton /></Suspense> }
                    <div className="w-10-h-10">
                      <span data-tooltip-id="tooltip-suggestion" data-tooltip-content="Neues Projekt vorschlagen" onClick={handleProjectSuggestionModalClick} className="outline-none">
                        <MegaphoneIcon className="-mt-0.5 p-1 w-8 h-8 rounded-full cursor-pointer bg-secondary-darker hover:bg-secondary-darker2 text-secondary-darkest" />
                      </span>
                      <Tooltip
                        id="tooltip-suggestion"
                        place="bottom"
                        className="z-50"
                        delayShow={500}
                      />
                    </div>
                  </div>
                }

{projectDetail &&
                  <div className="flex items-center space-x-3">
                    {/* <PdfExportProjectButton /> }
                    <ExcelExportProjectButton />
                    <div className="w-10-h-10">
                      <span data-tooltip-id="tooltip-gewichtung" data-tooltip-content="Flächengewichtung" onClick={handleAreaModalButtonClick} className="outline-none">
                        <ScaleIcon className="-mt-0.5 p-1 w-8 h-8 rounded-full cursor-pointer bg-primary hover:bg-primary-dark text-white" />
                      </span>
                      <Tooltip
                        id="tooltip-gewichtung"
                        place="bottom"
                        className="z-50"
                        delayShow={500}
                      />
                    </div>
                  </div>
              }

              {/* projectList &&
                  <button
                    type="button"
                    onClick={toggleProjectsFiltersTile}
                    className="px-2 py-1.5 relative justify-center rounded-md items-center border bg-white hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0 focus:bg-gray-50 hidden md:flex">
                    <span className="ml-1 font-medium text-gray-800">...</span>
                  </button>
              }
            </div>

          </div>

        </div>
          */
        }

      </div>
    </div>
  );
}
