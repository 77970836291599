import cn from "classnames";

import PopupContent from "../../../popup-content/index.jsx";

import CloseIcon from "~/src/ui/icons/close-icon/index.jsx";

/**
 *
 * @param {object} props - The props.
 * @param {null|object} props.activePopup - The active popup.
 * @param {boolean} props.checkboxDisabled - If the selection checkbox is disabled.
 * @param {Function} props.handleCheckbox - Checkbox handler.
 * @param {object} props.project - The project.
 * @param {boolean} props.selected - If the project is selected.
 * @param {Function} props.setActivePopup - The active popup setter.
 * @param {Function} props.setActiveProject - The active project setter.
 * @example
 */
const Popup = ({
  activePopup,
  checkboxDisabled,
  handleCheckbox,
  project,
  selected,
  setActivePopup,
  setActiveProject
}) => {
  const location = project?.locations?.find((innerLocation) => innerLocation.default_location) ??
    project?.locations?.[0];

  if (project === undefined) {
    return null;
  }

  const displayPicture = (project?.show_image_public && project?.image_file_name);

  return (
    <div className={cn("rounded-lg bg-white w-[280px]")}>
      <PopupContent
        checkboxDisabled={checkboxDisabled}
        displayPicture={displayPicture}
        handleCheckbox={handleCheckbox}
        location={location}
        project={project}
        selected={selected}
      />

      <button
        className="absolute right-[4px] top-[4px] flex size-4 items-center justify-center rounded-[50%] bg-white leading-none"
        onClick={() => {
          setActiveProject(null);
          activePopup.setMap(null);
          setActivePopup(null);
        }}
      >
        <CloseIcon className="size-3" />
      </button>
    </div>
  );
};

export default Popup;
