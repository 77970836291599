import { Menu, Transition } from "@headlessui/react";
import {
  EnvelopeIcon, PhoneIcon
} from "@heroicons/react/24/solid";

import MobileItemMenu from "~/src/ui/icons/mobile-item-menu-icon";
import {
  ImabisLogo, IMMObaseLogo, IMMOdeveloperLogo, IMMOfarmingLogo, IMMOmappingLogo, IMMOstatsLogo, IMMOvaluationLogo, WirtschaftsCompassLogo
} from "~/src/ui/logos/immo-united-products";

const itemClassName = "flex w-full flex-col justify-start gap-1 rounded-lg p-2 hover:bg-gray-50";

/**
 *
 */
const ImmoUnitedDropdown = () => (
  <div className="relative z-10 flex items-center">
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button className="inline-flex items-center justify-center rounded-md text-white hover:text-gray-200 focus:text-gray-100 focus:outline-none">
            <div className="group flex flex-col items-center justify-center gap-1 hover:text-primary-light">
              <div className="flex h-5 w-5 items-center justify-center">
                <MobileItemMenu className="flex h-[1.1rem] w-[1.1rem] text-white group-hover:text-primary-light" />
              </div>

              <span className="text-[0.65rem] leading-none text-white group-hover:text-primary-light">IMMOunited</span>
            </div>
          </Menu.Button>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items static>
              <div className="absolute right-3 mt-4 max-h-[32rem] origin-top-right overflow-y-auto rounded-md bg-white pt-3 shadow-lg ring-1 ring-black ring-opacity-5 md:max-h-[38rem]">
                <Menu.Item>
                  <div className="flex flex-col gap-2 px-3 pb-3 md:flex-row">
                    <div className="flex flex-col gap-y-2">
                      <a target="_blank" rel="noreferrer" className={itemClassName} href="https://immobase.immounited.com" role="none">
                        <div className="flex h-7 w-7" role="none">
                          <IMMObaseLogo />
                        </div>
                        <div className="flex flex-col gap-0 pb-[0.1] pt-[0.1]">
                          <p className="text-xs font-semibold text-gray-900" role="none">IMMObase</p>
                          <p style={{ whiteSpace: "nowrap" }} className="text-xs text-gray-500" role="none">Grundbuch- und Firmenbuchauszüge</p>
                        </div>
                      </a>

                      <a target="_blank" rel="noreferrer" className={itemClassName} href="https://www.immomapping.com/" role="none">
                        <div className="block h-7 w-7" role="none">
                          <IMMOmappingLogo />
                        </div>
                        <div className="flex flex-col gap-0 pb-[0.1] pt-[0.1]">
                          <p className="text-xs font-semibold text-gray-900" role="none">IMMOmapping</p>
                          <p style={{ whiteSpace: "nowrap" }} className="text-xs text-gray-500" role="none">Kaufvertragsinformationen auf der Karte analysieren</p>
                        </div>
                      </a>

                      <a target="_blank" rel="noreferrer" className={itemClassName} href="https://www.immovaluation.com/" role="none">
                        <div className="block h-7 w-7" role="none">
                          <IMMOvaluationLogo />
                        </div>
                        <div className="flex flex-col gap-0 pb-[0.1] pt-[0.1]">
                          <p className="text-xs font-semibold text-gray-900" role="none">IMMOvaluation</p>
                          <p style={{ whiteSpace: "nowrap" }} className="text-xs text-gray-500" role="none">Bewertung auf Basis von Kaufvertragsdaten</p>
                        </div>
                      </a>

                      <a target="_blank" rel="noreferrer" className={itemClassName} href="https://imabis.com/" role="none">
                        <div className="block h-7 w-7" role="none">
                          <ImabisLogo />
                        </div>
                        <div className="flex flex-col gap-0 pb-[0.1] pt-[0.1]">
                          <p className="text-xs font-semibold text-gray-900" role="none">Imabis</p>
                          <p style={{ whiteSpace: "nowrap" }} className="text-xs text-gray-500" role="none">Immobilieninserate auf Onlineplattformen</p>
                        </div>
                      </a>
                    </div>

                    <div className="flex flex-col gap-y-2">
                      <a target="_blank" rel="noreferrer" className={itemClassName} href="https://immostats.immounited.com/" role="none">
                        <div className="block h-7 w-7" role="none">
                          <IMMOstatsLogo />
                        </div>
                        <div className="flex flex-col gap-0 pb-[0.1] pt-[0.1]">
                          <p className="text-xs font-semibold text-gray-900" role="none">IMMOstats</p>
                          <p style={{ whiteSpace: "nowrap" }} className="text-xs text-gray-500" role="none">Statistik-Tool für Kaufvertragsdaten</p>
                        </div>
                      </a>

                      <a target="_blank" rel="noreferrer" className={itemClassName} href="https://www.immofarming.com/" role="none">
                        <div className="block h-7 w-7" role="none">
                          <IMMOfarmingLogo />
                        </div>
                        <div className="flex flex-col gap-0 pb-[0.1] pt-[0.1]">
                          <p className="text-xs font-semibold text-gray-900" role="none">IMMOfarming</p>
                          <p style={{ whiteSpace: "nowrap" }} className="text-xs text-gray-500" role="none">Eigentümerstrukturen, Altersgruppen, Gebäudearten</p>
                        </div>
                      </a>

                      <a target="_blank" rel="noreferrer" className="flex w-full flex-col justify-start gap-1 rounded-lg bg-gray-100 p-2" href="https://app.immodeveloper.com" role="none">
                        <div className="block h-7 w-7" role="none">
                          <IMMOdeveloperLogo />
                        </div>
                        <div className="flex flex-col gap-0 pb-[0.1] pt-[0.1]">
                          <p className="text-xs font-semibold text-gray-900" role="none">IMMOdeveloper</p>
                          <p style={{ whiteSpace: "nowrap" }} className="text-xs text-gray-500" role="none">Bauträger- bzw. Neubauprojekte</p>
                        </div>
                      </a>

                      <a target="_blank" rel="noreferrer" className={itemClassName} href="https://daten.compass.at/WirtschaftsCompass/?PageID=8FE184" role="none">
                        <div className="block h-7 w-7" role="none">
                          <WirtschaftsCompassLogo />
                        </div>
                        <div className="flex flex-col gap-0 pb-[0.1] pt-[0.1]">
                          <p className="text-xs font-semibold text-gray-900" role="none">Wirtschafts-Compass</p>
                          <p style={{ whiteSpace: "nowrap" }} className="text-xs text-gray-500" role="none">Wirtschaftsinformation über österr. Unternehmen</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </Menu.Item>

                <Menu.Item className="border-t border-gray-300">
                  <div className="flex flex-col gap-x-[6.35rem] gap-y-2 bg-gray-50 pb-3 pl-[1.2rem] pt-3 md:flex-row">
                    <div className="flex items-center gap-x-2 whitespace-nowrap" role="none">
                      <PhoneIcon className="h-5 w-5 text-gray-500" />
                      <span className="text-xs font-normal" role="none">
                        <span className="text-xs">Support-Hotline: </span>
                        0800 - 80 90 80
                      </span>
                    </div>

                    <div className="flex items-center gap-x-2 whitespace-nowrap" role="none">
                      <EnvelopeIcon className="h-5 w-5 text-gray-500" />
                      <span className="text-xs" role="none">
                        <span>Email: </span>
                        <a href="mailto:support@immounited.com" className="text-xs font-normal hover:underline" role="none">
                          support@immounited.com
                        </a>
                      </span>
                    </div>
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  </div>
);

export default ImmoUnitedDropdown;
