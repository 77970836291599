/* eslint-disable max-len */
/* eslint-disable import/max-dependencies */
import {
  Bars3Icon,
  FunnelIcon,
  MapIcon,
  PresentationChartLineIcon,
  QueueListIcon
} from "@heroicons/react/24/outline";
import {
  FunnelIcon as FunnelIconSolid,
  XMarkIcon
} from "@heroicons/react/24/solid";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import cn from "classnames";
import { kebabCase } from "lodash-es";

import SettingsDropdown from "../dropdowns/settings-dropdown";

import LogoIcon from "~/src/ui/logos/logo-icon";
import LogoColor from "~/src/ui/logos/logo-color";
import useAuth from "~/src/hooks/use-auth";
import useStore from "~/src/hooks/use-store";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import { parseActiveFilters } from "~/src/modules/search-filter-helper";

/**
 *
 * @param props
 * @param props.fixed
 */
// eslint-disable-next-line max-lines-per-function
const NavMobile = ({ fixed = false }) => {
  const location = useLocation();
  const activeSite = `${location.pathname.split("/")[1]}`;

  const { query } = useProjectQueryParams();

  const { user } = useAuth();

  const [
    sideBarOpen,
    setSideBarOpen
  ] = useState(false);
  const [
    onMainSite,
    setOnMainSite
  ] = useState(true);
  const [
    filtersActive,
    setFiltersActive
  ] = useState(false);

  const filterOpen = useStore((state) => state.filterOpen);
  const setFilterOpen = useStore((state) => state.setFilterOpen);

  const searchKey = location.search.includes("slugs") ? "" : location.search;

  const faviconUrl = new URL(
    "../../../public/favicon.png",
    import.meta.url
  );

  const openSideBar = () => {
    setSideBarOpen(true);
  };

  const closeSideBar = () => {
    setSideBarOpen(false);
  };

  const openFilter = () => {
    setFilterOpen(true);
  };

  const closeFilter = () => {
    setFilterOpen(false);
  };

  useEffect(() => {
    if ([
      "",
      "karte",
      "liste",
      "statistik"
    ].includes(activeSite)) {
      setOnMainSite(true);
    }
    else {
      setOnMainSite(false);
    }
  }, [activeSite]);

  useEffect(() => {
    if (parseActiveFilters(query).length > 0) {
      setFiltersActive(true);
    }
    else {
      setFiltersActive(false);
    }
  }, [query]);

  if (!user) {
    return null;
  }

  return (
    <>
      <nav className="flex justify-between p-3 h-14 bg-primary">
        <div className="flex h-full gap-2">
          <button className="h-full" onClick={openSideBar}>
            <Bars3Icon className="h-full text-white" />
          </button>
          <Link to="/" className="h-full">
            <LogoIcon className="h-full" />
          </Link>
        </div>

        <SettingsDropdown />
      </nav>
      <Transition
        show={sideBarOpen}
      >
        <Transition.Child
          enter="transition ease-in-out duration-200 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-200 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className="fixed inset-0 z-50 h-full max-w-full bg-secondary-light w-80"
        >
          <div className="flex justify-between w-full gap-8 p-3 h-14">
            <LogoColor className="w-auto h-full" />
            <button className="h-full" onClick={closeSideBar}>
              <XMarkIcon className="h-full text-gray-500" />
            </button>
          </div>
          <ul className="flex flex-col">
            {
              [
                [
                  "Bauprojekte",
                  [
                    "Karte",
                    "Liste",
                    "Statistik"
                  ]
                ],
                [
                  "Weitere Funktionen",
                  [
                    "Transaktionen",
                    "Share Deals",
                    "Insolvenzen"
                  ]
                ]
              ]
                .map(([
                  title,
                  links
                ]) => (
                  <li className="flex flex-col group" key={title}>
                    <span className="px-2 py-1 text-xs font-semibold text-gray-500 border-gray-200 border-y-2">{title}</span>
                    <ul className="flex flex-col">
                      {
                        links.map((label) => {
                          const path = ([
                            "karte",
                            "liste",
                            "statistik"
                          ].includes(kebabCase(label)))
                            ? `${kebabCase(label)}${[
                              "",
                              "karte",
                              "liste",
                              "statistik"
                            ].includes(activeSite)
                              ? searchKey
                              : ""}`
                            : `${kebabCase(label)}`;

                          const active = (activeSite.includes(kebabCase(label)) || (activeSite === "" && path === "karte")) && activeSite !== "merklisten";

                          return (
                            <li className="flex" key={label}>
                              <Link
                                to={`/${path}`}
                                className="flex w-full px-6 py-2 text-sm text-gray-700 hover:bg-black hover:bg-opacity-5 active:bg-black active:bg-opacity-5 focus:bg-black focus:bg-opacity-5"
                                onClick={closeSideBar}
                              >
                                <span
                                  title={label}
                                  className={cn(
                                    "text-center text-gray-700 break-keep whitespace-nowrap text-sm select-none px-2 py-0.5 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white before:h-0 before:block before:content-[attr(title)] before:font-bold before:overflow-hidden before:visibility-hidden",
                                    {
                                      "bg-primary text-white font-bold": active,
                                      "border-transparent hover:text-white hover:bg-primary font-semibold": !active
                                    }
                                  )}
                                >
                                  {label}
                                </span>
                              </Link>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </li>
                ))
            }
          </ul>
        </Transition.Child>

        <Transition.Child
          enter="transition-opacity ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-50"
          leave="transition-opacity ease-in-out duration-200"
          leaveFrom="opacity-50"
          leaveTo="opacity-0"
          className="fixed inset-0 z-40 bg-black opacity-50"
          onClick={closeSideBar}
        />
      </Transition>

      <Transition
        show={!filterOpen}
        enter="transition ease-in-out duration-500 transform"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
        className="fixed bottom-0 z-30 w-full"
      >
        <Transition
          show={onMainSite}
          enter="transition ease-in-out duration-200 transform"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-200 transform"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
          className="flex items-center w-full bg-white drop-shadow-[0px_0px_8px_#00000040] h-28 divide-x divide-gray-300 divide-dashed"
          as="ul"
        >
          {
            [
              [
                "Karte",
                MapIcon
              ],
              [
                "Liste",
                QueueListIcon
              ],
              [
                "Statistik",
                PresentationChartLineIcon
              ],
              [
                "Filter",
                filtersActive ? FunnelIconSolid : FunnelIcon,
                openFilter
              ]
            ]
              .map(([
                label,
                Icon,
                onClick
              ]) => {
                const path = ([
                  "karte",
                  "liste",
                  "statistik"
                ].includes(kebabCase(label)))
                  ? `${kebabCase(label)}${[
                    "",
                    "karte",
                    "liste",
                    "statistik"
                  ].includes(activeSite)
                    ? searchKey
                    : ""}`
                  : `${kebabCase(label)}`;
                const active = activeSite.includes(kebabCase(label)) || (activeSite === "" && path === "karte");

                const content = (
                  <>
                    <Icon className="w-8 h-8" />
                    <span className="text-gray-500">{label}</span>
                  </>
                );

                return (
                  <li className="flex justify-center w-full" key={label}>
                    {
                      onClick
                        ? (
                          <button
                            className={cn(
                              "flex flex-col items-center justify-center w-full gap-2 text-sm  transition-all duration-200",
                              {
                                "text-primary": filtersActive,
                                "text-gray-500": !filtersActive
                              }
                            )}
                            onClick={onClick}>
                            {content}
                          </button>
                        )
                        : <Link
                          to={`/${path}`}
                          className={cn(
                            "w-full flex flex-col gap-2 text-sm text-gray-500 items-center justify-center transition-all duration-200",
                            {
                              "font-semibold text-gray-700": active
                            }
                          )}
                        >
                          {content}
                        </Link>
                    }
                  </li>
                );
              })
          }
        </Transition>

      </Transition>
    </>
  );
};

export default NavMobile;
