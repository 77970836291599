import cn from "classnames";

const maxNumberOfColumns = 12;

const getDefaultColumnWidths = (numberOfColumns) => {
  const part = Math.floor(maxNumberOfColumns / numberOfColumns);

  return [
    part + maxNumberOfColumns % numberOfColumns,
    ...Array(numberOfColumns).fill(part)
  ]
    .map((width) => `${width}/${maxNumberOfColumns}`);
};

const getColumnWidthClassName = (width) => [
  "w-1/12",
  "w-2/12",
  "w-3/12",
  "w-4/12",
  "w-5/12",
  "w-6/12",
  "w-7/12",
  "w-8/12",
  "w-9/12",
  "w-10/12",
  "w-11/12",
  "w-12/12"
]
  .find((className) => className.endsWith(width)) || "w-1/12";

/**
 *
 * @param root0
 * @param root0.columnTitles
 * @param root0.rows
 * @param root0.columnWidths
 * @param root0.emptyText
 * @param root0.className
 */
export default function Table({
  columnTitles,
  rows,
  columnWidths = getDefaultColumnWidths(columnTitles.length),
  emptyText = "Keine Daten vorhanden",
  className
}) {
  if (rows.length === 0) {
    return <p>{emptyText}</p>;
  }

  return (
    <table className={cn("w-full table-fixed break-inside-avoid", className)}>
      <colgroup>
        {
          columnTitles.map((title, titleIndex) => (
            <col className={getColumnWidthClassName(columnWidths[titleIndex])} key={title} />
          ))
        }
      </colgroup>
      <thead>
        <tr>
          {
            columnTitles.map((title, titleIndex) => (
              <th className="text-left" key={title}>{title}</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map((row, rowIndex) => (
            <tr className="odd:bg-gray-200" key={rowIndex}>
              {
                row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{(typeof cell === "string" && cell !== "") || cell ? cell : "-"}</td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}
