import { useEffect, useState } from "react";
import cn from "classnames";

import { formatAreaInt, formatMonth } from "~/src/modules/formatters";
import ShareDealPropertySlide from "~/src/features/share-deal-property-slide";
import useTracking from "~/src/hooks/use-tracking";

/**
 *
 * @param root0
 * @param root0.isLoading
 * @param root0.properties
 * @param root0.loadingItems
 * @param root0.query
 */
export default function ShareDealsPropertyList({
  isLoading, properties = [], loadingItems = 10, query
}) {
  const { trackPageView } = useTracking();
  const [
    showShareDealDetails,
    setShowShareDealDetails
  ] = useState(false);
  const [
    activeProperty,
    setActiveProperty
  ] = useState(false);
  const [
    activeColumn,
    setActiveColumn
  ] = useState("date");

  function handleShareDealClick(shareDeal) {
    setActiveProperty(shareDeal);
    setShowShareDealDetails(true);
    trackPageView(shareDeal.doc_id, "ShareDeal Liegenschaft");
  }

  function hideShareDealDetails() {
    setShowShareDealDetails(false);
  }

  useEffect(() => {
    if (query.sortBy) {
      setActiveColumn(query.sortBy);
    }
  }, [query.sortBy]);

  return (
    <div>
      <ShareDealPropertySlide active={showShareDealDetails} hide={hideShareDealDetails} property={activeProperty} />

      <div className="ml-4 sm:ml-0 mb-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow-sm overflow-hidden border border-b border-gray-200 sm:rounded">

              <div className="min-w-full divide-y divide-gray-200">

                <div className="flex lg:grid grid-cols-12 items-center bg-gray-100">
                  <div className="col-span-1 w-20 lg:w-auto px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Datum
                  </div>
                  <div className="col-span-1 w-20 lg:w-auto px-4 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    KG
                  </div>
                  <div className="col-span-1 w-20 lg:w-auto px-4 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    EZ
                  </div>
                  <div className="col-span-3 w-64 lg:w-auto px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Adresse (Liegenschaft)
                  </div>
                  <div className="col-span-3 w-64 lg:w-auto px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Eigentümer
                  </div>
                  <div className="col-span-1 w-28 lg:w-auto px-4 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Fläche EZ
                  </div>
                  <div className="col-span-2 w-64 lg:w-auto px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Änderung
                  </div>
                </div>

                <div className="bg-white divide-y divide-gray-200">
                  {isLoading &&
                    [...Array(loadingItems)].map((e, i) => (
                      <div key={`share_deal_properties_skeleton_${i}`} className="h-20 flex lg:grid grid-cols-12 items-center hover:bg-gray-50 cursor-pointer">
                        <div className="w-20 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="w-20 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="w-20 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-3 w-64 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-3 w-64 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="w-28 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                        <div className="col-span-2 w-64 lg:w-auto px-4 h-12 animate-pulse flex flex-col justify-center space-y-2">
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                          <span className="block w-full h-4 bg-gray-300 rounded" />
                        </div>
                      </div>
                    ))
                  }

                  {!isLoading && properties?.length === 0 &&

                    <div className="bg-white">
                      <p className="px-4 py-4 whitespace-nowrap text-center font-light text-gray-900">keine Liegenschaften für die ausgewählten Kriterien gefunden</p>
                    </div>
                  }

                  {properties?.map((property) => (
                    <div key={property.id} className="flex lg:grid grid-cols-12 items-center hover:bg-gray-50 cursor-pointer text-sm" onClick={() => handleShareDealClick(property)}>

                      <p className={cn("w-20 lg:w-auto px-4 py-4 whitespace-nowrap text-sm", {
                        "text-primary font-bold": activeColumn === "date",
                        "text-gray-900 font-medium": activeColumn !== "date"
                      })}>{formatMonth(property.change_date)}</p>

                      <p className={cn("w-20 lg:w-auto px-4 py-4 whitespace-nowrap", {
                        "text-primary font-bold": activeColumn === "kg",
                        "text-gray-600": activeColumn !== "kg"
                      })}>{property.kgnr}</p>

                      <p className={cn("w-20 lg:w-auto px-4 py-4 whitespace-nowrap ", {
                        "text-primary font-bold": activeColumn === "ez",
                        "text-gray-600": activeColumn !== "ez"
                      })}>{property.ez}</p>

                      <p className="w-64 lg:w-auto col-span-3 px-4 py-4 whitespace-nowrap truncate">
                        <span className={cn({
                          "text-primary font-bold": activeColumn === "street",
                          "text-gray-600": activeColumn !== "street"
                        })}>{property.property_street} {property.property_house_number}</span><br /><span className="text-gray-600">{property.property_state}, </span><span className={cn({
                          "text-primary font-bold": activeColumn === "zipcode",
                          "text-gray-600": activeColumn !== "zipcode"
                        })}>{property.property_zipcode} {property.property_city?.replace(",", ", ")}</span></p>

                      <p className="w-64 lg:w-auto col-span-3  px-4 py-4 whitespace-nowrap truncate">
                        <span className={cn({
                          "text-primary font-bold": activeColumn === "company",
                          "text-gray-600": activeColumn !== "company"
                        })}>{property.new_owner_name}</span><br />
                        <span className={cn({
                          "text-primary font-bold": activeColumn === "fbnr",
                          "text-gray-600": activeColumn !== "fbnr"
                        })}>
                          {property.new_owner_fbnr}
                        </span>
                      </p>

                      <p className={cn("w-28 lg:w-auto px-4 py-4 whitespace-nowrap", {
                        "text-primary font-bold": activeColumn === "area",
                        "text-gray-600": activeColumn !== "area"
                      })}>{formatAreaInt(property.ez_area)}</p>

                      <p className="w-64 lg:w-auto col-span-2 px-4 py-4 text-gray-600">{property.category?.name}</p>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
