/* eslint-disable max-len */
import { ScaleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

import { getPricePerSqm, NoValueDefinedLine } from "./_common/_exports.js";
import { SqmSalePrice } from "./sqm-sale-prices/_exports.js";

import priceTerms from "~/src/lib/price-terms.js";

const {
  investment: investmentTerm,
  net: netTerm,
  gross: grossTerm,
  normal: normalTerm
} = priceTerms;

/**
 *
 * @param root0
 * @param root0.unit
 * @param root0.showCustom
 * @param root0.customArea
 */
export default function SqmSalePrices({
  unit, showCustom, customArea
}) {
  const area = Number.isFinite(customArea) ? customArea : unit.sale_area;

  const prices = [];

  const sqmSalePriceExclUst = getPricePerSqm(unit.sale_price_net, area);
  const sqmSalePriceInclUst = getPricePerSqm(unit.sale_price_gross, area);

  if (sqmSalePriceExclUst !== null && sqmSalePriceInclUst !== null) {
    prices.push(
      <SqmSalePrice key="netto" value={sqmSalePriceExclUst} label={`${investmentTerm} ${netTerm}`} />,
      <SqmSalePrice key="brutto" value={sqmSalePriceInclUst} label={`${investmentTerm} ${grossTerm}`} />
    );
  }
  else if (sqmSalePriceExclUst !== null) {
    prices.push(
      <SqmSalePrice key="eigen" value={sqmSalePriceExclUst} label={normalTerm} />
    );
  }

  if (!prices.length) {
    prices.push(<NoValueDefinedLine />);
  }

  return (
    <div className="relative p-2 text-sm text-center text-gray-500 bg-gray-200">
      {showCustom && (parseFloat(customArea).toFixed(2).toString() !== unit.sale_area) &&
        <div className="absolute top-2 right-2">
          <ScaleIcon
            data-tooltip-id="tooltip-unit-sale-avg-price"
            data-tooltip-content="Preis / m² aus individueller Gewichtung berechnet" className="-mt-0.5 p-0.5 w-6 h-6 rounded-full bg-primary text-white" />
          <Tooltip
            id="tooltip-unit-sale-avg-price"
            place="bottom"
            className="z-50"
            delayShow={500}
          />
        </div>
      }
      <p>Preis / m²</p>
      <div className="mt-0.5 space-y-3">
        { prices }
      </div>
    </div>
  );
}
