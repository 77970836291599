import { constructionTypeLabels } from "~/src/modules/labels";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function ConstructionTypeFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const construction_types = queryItems.split(",");

  function handleClick(ct) {
    const newCT = construction_types.filter((item) => item !== ct).join(",");

    submit("construction_type", newCT);
  }

  return construction_types.map((ct, index) => (
    <DefaultFilterTag key={`ct_tag_${index}`} handleClick={() => handleClick(ct)}>
      <span>Bautyp: {constructionTypeLabels[ct]}</span>
    </DefaultFilterTag>
  ));
}
