/* eslint-disable array-element-newline */
/* eslint-disable array-bracket-newline */
import { useMemo, useState } from "react";

import EmptyChartContainer from "../empty-chart-container";

import { generateLineData } from "./tools";
import { MarketTrendSaleChartForPrint } from "./for-print";

import ChartLegend from "~/src/ui/charts/legend";
import LineChart from "~/src/ui/charts/line-chart";
import PriceSelect from "~/src/ui/selects/price-select";
import useMediaQuery from "~/src/hooks/use-media-query";
import DetailStatsTools from "~/src/lib/detail-stats-tools";
import { priceLabels } from "~/src/modules/labels.js";

/**
 *
 * @param props
 * @param props.statistic
 * @param props.isError
 */
export default function MarketTrendSaleChart({ statistic }) {
  const isPrint = useMediaQuery("print");

  const [selectedGraphLabel, setSelectedGraphLabel] = useState(null);

  const allGraphLabels = Object.keys(statistic);

  const labelsOfNonemptyGraphs = DetailStatsTools
    .getVariablesWithData(statistic);

  if (isPrint) {
    return <MarketTrendSaleChartForPrint
      graphLabels={labelsOfNonemptyGraphs}
      statistic={statistic} />;
  }

  const activeGraphLabel = selectedGraphLabel ?? labelsOfNonemptyGraphs?.[0];

  const lineData = useMemo(
    () => (activeGraphLabel
      ? generateLineData(activeGraphLabel, statistic)
      : null),
    [statistic, activeGraphLabel]
  );

  if (!labelsOfNonemptyGraphs?.[0] || !lineData) {
    return <EmptyChartContainer
      chartLabel={`${priceLabels.salePricePerSquareMeter} Trend`}
      allGraphLabels={allGraphLabels}
    />;
  }

  return (
    <div className="flex flex-col gap-4 p-2 bg-white border border-gray-200 rounded lg:p-4">
      <div className="flex flex-col justify-between gap-2 lg:flex-row">
        <div className="flex items-center gap-2">
          <h2 className="font-medium text-gray-900 text-md">{priceLabels.salePricePerSquareMeter} Trend</h2>
        </div>

        <PriceSelect
          value={activeGraphLabel}
          options={allGraphLabels}
          enabledOptions={labelsOfNonemptyGraphs}
          onChange={setSelectedGraphLabel}
          className="lg:self-end w-80"
          buttonClassName="w-80"
        />
      </div>

      <div className="w-full h-[450px] relative">
        <div className="absolute w-full h-[450px]">
          <LineChart
            data={lineData.chartData}
            withArea
            x={{ type: "month" }}
            y={{
              min: lineData.chartData[0].min,
              max: lineData.chartData[0].max
            }}
          />
        </div>
      </div>
      <div className="w-full">
        <ChartLegend data={lineData.legendData} simple />
      </div>
    </div>
  );
}
