/**
 *
 * @param root0
 * @param root0.styles
 * @param root0.className
 */
export default function CheckmarkIcon({ styles, className = `w-7 h-7 text-white ${styles}` }) {
  return (
    <svg className={className} width={0} height={0} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
}
