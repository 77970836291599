import { formatAreaInt } from "~/src/modules/formatters";
import { propertyTypeLabels } from "~/src/modules/labels";

function renderPropertyTypes(property) {
  let hasNoPropertyTypes = true;

  const types = Array(9).fill(1).map((_, i) => {
    const availableCount = property[`property_type_${i}_count`];

    if (availableCount) {
      hasNoPropertyTypes = false;

      return (
        <p key={`property_type_${i}`}>{availableCount} x {propertyTypeLabels[`type_${i}`]}</p>
      );
    }

    return null;
  });

  if (hasNoPropertyTypes) {
    return <p>keine Angabe</p>;
  }

  return types;
}

/**
 *
 * @param root0
 * @param root0.property
 */
export default function ShareDealPropertyCard({ property }) {
  return (
    <div className="bg-gray-100 text-gray-800 shadow overflow-hidden px-3 py-3 sm:rounded-md text-sm">

      <div className="flex justify-between">

        <div className="w-1/2">
          <dd className="mt-1 text-gray-900 sm:mt-0">{property.property_street} {property.property_house_number}<br />{property.property_state}, {property.property_zipcode} {property.property_city.replace(",", ", ")}</dd>
          <div className="mt-3">
            <dt className="font-semibold text-gray-800">Gebäudeart</dt>
            <dd className="mt-1 text-gray-900 sm:mt-0">{renderPropertyTypes(property)}</dd>
          </div>
        </div>

        <div className="w-1/2">

          <div className="flex justify-between">
            <div className="w-32">
              <dt className="font-semibold text-gray-800">KG</dt>
              <dd className="mt-1 text-gray-900 sm:mt-0">{property.kgnr}</dd>
            </div>
            <div className="w-32">
              <dt className="font-semibold text-gray-800">EZ</dt>
              <dd className="mt-1 text-gray-900 sm:mt-0">{property.ez}</dd>
            </div>
            <div className="w-48">
              <dt className="font-semibold text-gray-800">Fläche EZ</dt>
              <dd className="mt-1 text-gray-900 sm:mt-0">{formatAreaInt(property.ez_area)}</dd>
            </div>
          </div>

          <div className="mt-3">
            <dt className="font-semibold text-gray-800">GST-Nr.</dt>
            <dd className="mt-1 text-gray-900 sm:mt-0">{property.gst_numbers?.join("; ")}</dd>
          </div>

        </div>
      </div>

    </div>
  );
}
