import { forwardRef } from "react";
import dayjs from "dayjs";
import "dayjs/locale/de";
import cn from "classnames";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";

import * as styles from "./styles.module.css";

dayjs.locale("de");
registerLocale("de", de);

/**
 *
 * @param root0
 * @param root0.id
 * @param root0.name
 * @param root0.value
 * @param root0.touched
 * @param root0.error
 * @param root0.onChange
 * @param root0.onBlur
 * @param root0.isSubmitting
 * @param root0.placeholder
 * @param root0.autoFocus
 * @param root0.format
 * @param root0.minDate
 * @param root0.maxDate
 * @param root0.hasOtherDate
 */
export default function DateMonthInput({
  id,
  name,
  value,
  touched,
  error,
  onChange,
  onBlur,
  isSubmitting,
  placeholder,
  autoFocus = false,
  format,
  minDate = new Date("2021-01-01"),
  maxDate = new Date("2121-01-01"),
  hasOtherDate = false
}) {
  const touchedAndNotEmpty = (Boolean(touched) === true) && value !== "";
  const showError = error && (isSubmitting || touchedAndNotEmpty);

  function handleChange(date) {
    let direction = "asc";

    if (!hasOtherDate && name === "dateTo") {
      direction = "desc";
    }

    onChange({
      [name]: dayjs(date).format("YYYY-MM-DD"),
      direction
    });
  }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref} type="button" className="w-32 py-0.5 text-center bg-secondary-dark hover:bg-secondary-darker border border-secondary-darker rounded focus:outline-none text-gray-600">
      <span className="text-sm font-medium">{placeholder}</span>
    </button>
  ));

  return (
    <>
      <div className="relative">
        <DatePicker
          popperClassName="month-picker"
          customInput={<CustomInput />}
          minDate={minDate}
          maxDate={maxDate}
          autoFocus={autoFocus}
          id={id}
          name={name}
          selected={value}
          onChange={handleChange}
          onBlur={onBlur}
          placeholderText={placeholder}
          locale="de"
          dateFormat="MM/yyyy"
          dropdownMode="select"
          showMonthYearPicker
          className={cn(styles.inputStyle, {
            "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError
          })}
        />
      </div>
    </>
  );
}
