import useSWR from "swr";

import { fetcher } from "~/src/modules/api";
import { getPolygons } from "~/src/schema/filters";

/**
 *
 * @param query.query
 * @param query
 * @param query.shouldFetch
 * @param query.subtract
 * @param swrOptions
 * @param query.filtersetting
 */
export default function useProjectsStats({
  query,
  shouldFetch = true,
  filtersetting = "standard"
}, swrOptions) {
  const {
    polygon,
    street,
    radius,
    stats,
    page,
    layout,
    sort,
    direction,
    marked,
    ...restQuery
  } = query;

  const subtract = (filtersetting !== "standard");

  const newQuery = {
    ...restQuery,
    polygon: getPolygons(polygon),
    street,
    ...(street && {
      radius
    }),
    ...(subtract && {
      stats
    })
  };

  const params = new URLSearchParams(
    Object.fromEntries(
      Object.entries(newQuery).filter(([
        key,
        value
      ]) => typeof value !== "undefined")
    )
  );

  const {
    data, mutate, error
  } = useSWR(() => (shouldFetch ? `/projects/stats?${params}` : null), fetcher, swrOptions);

  return {
    stats: data?.payload?.stats,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}
