/* eslint-disable no-invalid-this */
/* eslint-disable no-underscore-dangle */
import {
  Popup
} from "leaflet";

import {
  animateZoom,
  updatePosition,
  initLayout
} from "./custom-popup/_exports.js";

// don't switch to arrow functions, because of leaflet's rotten class system

const CustomPopup = Popup.extend({

  _initLayout: function () {
    initLayout.call(this);
  },

  _updatePosition: function () {
    updatePosition.call(this);
  },

  _animateZoom: function (event) {
    animateZoom.call(this, event);
  }

});

export default CustomPopup;
