import { useParams } from "react-router-dom";

import ProjectDocuments from "../project-documents";

import ContentSection from "~/src/ui/sections/content-section";
import useProject from "~/src/hooks/use-project";

/**
 *
 */
const ProjectOverviewDocuments = () => {
  const { id } = useParams();
  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    documents
  } = project;

  return (
    <ContentSection id="documents" title="Dokumente">
      <ProjectDocuments documents={documents} />
    </ContentSection>
  );
};

export default ProjectOverviewDocuments;
