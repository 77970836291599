import { useParams } from "react-router-dom";

import useProject from "~/src/hooks/use-project";
import dateFormatter from "~/src/modules/date-formatter";
import DetailSkeleton from "~/src/ui/loading/detail-skeleton";
import Container from "~/src/ui/containers/content-container";
import VermarktungIcon from "~/src/ui/icons/vermarktung-icon";
import BauIcon from "~/src/ui/icons/bau-icon";
import CheckmarkIcon from "~/src/ui/icons/checkmark-icon";
import { constructionPhaseLabels, marketingPhaseLabels } from "~/src/modules/labels";

/**
 *
 */
export default function Timeline() {
  const { id } = useParams();
  const {
    project = {}, isLoading, isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    timeline = {},
    timeline: {
      construction_phase_purchase,
      construction_phase_purchase_date,
      construction_phase_purchase_date_format,
      construction_phase_active,
      construction_phase_active_date,
      construction_phase_active_date_format,
      construction_phase_completed,
      construction_phase_completed_date,
      construction_phase_completed_date_format,
      marketing_phase_coming_soon,
      marketing_phase_coming_soon_date,
      marketing_phase_coming_soon_date_format,
      marketing_phase_active,
      marketing_phase_active_date,
      marketing_phase_active_date_format,
      marketing_phase_project_finished,
      marketing_phase_project_finished_date,
      marketing_phase_project_finished_date_format,
      current_construction_phase,
      current_marketing_phase,
      first_contract_date,
      last_contract_date,
      automated
    } = {}
  } = project;

  const columns = automated
    ? [
      {
        label: "Erster Kaufvertrag",
        dateText: "Datum",
        dateValue: first_contract_date
      },
      {
        label: "Letzter Kaufvertrag",
        dateText: "Datum",
        dateValue: last_contract_date
      }
    ]
    : [
      {
        label: "Bauphase",
        type: "construction",
        icon: <BauIcon className="w-full h-full" />,
        phases: [
          [
            "construction_phase_purchase",
            "construction_phase_active",
            "construction_phase_completed"
          ].includes(current_construction_phase) && "purchase",
          [
            "construction_phase_active",
            "construction_phase_completed"
          ].includes(current_construction_phase) && "active",
          current_construction_phase === "construction_phase_completed" && "completed"
        ]
      },
      {
        label: "Vermarktungsphase",
        type: "marketing",
        icon: <VermarktungIcon className="w-full h-full" />,
        phases: [
          (current_marketing_phase === "marketing_phase_coming_soon" || marketing_phase_coming_soon_date) && "coming_soon",
          [
            "marketing_phase_active",
            "marketing_phase_project_finished"
          ].includes(current_marketing_phase) && "active",
          current_marketing_phase === "marketing_phase_project_finished" && "project_finished"
        ]
      }
    ]
      .map(({ phases, ...column }) => ({
        phases: phases.filter(Boolean),
        ...column
      }));

  return (
    <div className="w-full">
      <h2 className="px-4 text-xl font-medium sm:px-0">Timeline</h2>

      {isLoading && <DetailSkeleton />}

      {!isLoading &&
        <Container>
          <div className="flex w-full gap-5">
            {
              columns.map(({
                label, type, icon, phases, dateText, dateValue
              }) => (
                <div className="w-6/12 space-y-4 text-gray-800" key={label}>
                  <div className="flex flex-col items-center justify-center gap-4 text-xs sm:text-lg md:flex-row">
                    {!automated &&
                    <div className="w-10 h-10 lg:w-20 lg:h-20">{icon}</div>
                    }
                    {label}
                  </div>
                  <div className="space-y-4 text-center">
                    {
                      dateText
                        ? (
                          <div className="relative flex flex-col gap-2 py-4 bg-gray-100">
                            <span className="block font-medium text-center text-gray-500">
                              {dateText}
                            </span>

                            <span className="block text-center">
                              {
                                dateValue
                                  ? dateFormatter(dateValue)
                                  : "Datum nicht bekannt"
                              }
                            </span>
                          </div>
                        )
                        : (
                          <>
                            {
                              phases.length > 0
                                ? phases.map((phase, index) => (
                                  <div className="relative flex flex-col gap-2 px-8 py-4 bg-gray-100 sm:px-0" key={phase}>
                                    {!(phase === "active" && index === phases.length - 1) && <CheckmarkIcon className="absolute top-2 left-2 w-5 h-5 sm:w-7 sm:h-7 text-gray-500 !mt-0" />}
                                    <span className="block font-medium text-center text-gray-500">
                                      {{
                                        constructionPhaseLabels,
                                        marketingPhaseLabels
                                      }[`${type}PhaseLabels`][`${type}_phase_${phase}`]}
                                    </span>

                                    <span className="block text-center">
                                      {
                                        timeline[`${type}_phase_${phase}_date`]
                                          ? dateFormatter(timeline[`${type}_phase_${phase}_date`], timeline[`${type}_phase_${phase}_date_format`])
                                          : "Datum nicht bekannt"
                                      }
                                    </span>
                                  </div>
                                ))
                                : "Keine Daten vorhanden"
                            }
                          </>
                        )
                    }

                  </div>
                </div>
              ))
            }
          </div>
        </Container>
      }
    </div>
  );
}
