/* eslint-disable no-magic-numbers */
/* eslint-disable camelcase */
import { useEffect } from "react";

import { destructQuery } from "../../esg-certificate-filter-modal/_common/_exports.js";

import useEsgCertificates from "~/src/hooks/use-esg-certificates.js";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag/index.jsx";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 * @param root0.onClick
 */
export default function CertificateFilterTag({
  queryItems, submit, onClick
}) {
  const {
    certificates,
    isLoading,
    mutate
  } = useEsgCertificates();

  useEffect(() => {
    mutate();
  }, [queryItems]);

  if (!queryItems || isLoading) {
    return null;
  }

  const queryItemsAsObject = destructQuery(queryItems);

  const handleClick = (id) => {
    const newCertificateQueryItems = queryItems
      .filter((item) => !String(item).startsWith(String(id)));

    submit("certificates", newCertificateQueryItems);
  };

  const handleModal = () => {
    onClick();
  };

  const activeIssuerFilters = Object.entries(queryItemsAsObject).map(([
    issuerId,
    certificateIds
  ]) => {
    const issuerIdAsNumber = Number(issuerId);
    const certificateIdsAsNumber = certificateIds.map((certId) => Number(certId));

    const issuerAndCertificateIndex = certificates
      .map(({ id: issuerAndCertificateId }) => issuerAndCertificateId)
      .indexOf(issuerIdAsNumber);

    const activeIssuer = certificates[issuerAndCertificateIndex];

    if (activeIssuer.issuer_certificates.length) {
      const issuerWithCertificates = {
        ...activeIssuer,
        issuer_certificates: activeIssuer.issuer_certificates
          .map((cert) => ((certificateIdsAsNumber.includes(cert.id))
            ? cert
            : null))
      };

      return issuerWithCertificates;
    }

    return activeIssuer;
  });

  const displayedTags = activeIssuerFilters
    .map((issuer) => {
      if (!issuer.issuer_certificates.length) {
        return {
          id: issuer.id,
          name: issuer.name
        };
      }

      if (issuer.issuer_certificates.every((tag) => tag !== null)) {
        return {
          id: issuer.id,
          name: issuer.name
        };
      }

      const certTags = issuer.issuer_certificates.map((tag) => {
        if (tag !== null) {
          return {
            id: `${issuer.id}-${tag.id}`,
            name: tag.name
          };
        }

        return null;
      })
        .filter((value) => value !== null);

      return certTags;
    })
    .flat();

  const tags = (displayedTags.length > 10)
    ? <DefaultFilterTag key={"filter_certificates_more_than"} handleClick={() => handleModal()}>
      <span>Gebäudezertifikate: mehr als 10</span>
    </DefaultFilterTag>
    : displayedTags.map((tag, index) => (
      <DefaultFilterTag key={tag.id} handleClick={() => handleClick(tag.id)}>
        <span>Gebäudezertifikat: {tag.name}</span>
      </DefaultFilterTag>
    ));

  return (
    <>
      {tags}
    </>
  );
}
