/* eslint-disable import/prefer-default-export */
/* eslint-disable func-style */
import { generateLineData } from "./tools";

import LineChart from "~/src/ui/charts/line-chart";
import { priceLabels } from "~/src/modules/labels";
import ChartLegend from "~/src/ui/charts/legend";

/**
 *
 * @param root0
 * @param root0.graphLabels
 * @param root0.statistic
 */
function MarketTrendOfferChartForPrint({ graphLabels, statistic }) {
  return graphLabels
    .map((graphLabel) => {
      const lineData = generateLineData(graphLabel, statistic);

      return (
        <div key={graphLabel} className="w-full space-y-4 bg-white break-inside-avoid">
          <h2 className="font-medium text-gray-900 text-md">{priceLabels[graphLabel]} Trend</h2>
          <div className="h-[19rem]">
            <LineChart
              data={lineData.chartData}
              x={{ type: "month" }}
            />
          </div>
          <ChartLegend data={lineData.legendData} simple />
        </div>
      );
    });
}

export { MarketTrendOfferChartForPrint };
