import animatedScrollTo from "animated-scroll-to";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  NumberParam, StringParam, useQueryParams
} from "use-query-params";

import UnitListTitle from "./UnitListTitle";

import Pagination from "~/src/features/pagination";
import ProjectUnitItem from "~/src/features/project-unit-item.jsx";
import SortPurchasesDirection from "~/src/features/sort-purchases-direction";
import SortUnitsDropdown from "~/src/features/sort-units-dropdown";
import UnitFilters from "~/src/features/unit-filters";
import useProject from "~/src/hooks/use-project";
import useUnitCategories from "~/src/hooks/use-unit-categories";
import useUnits from "~/src/hooks/use-units";
import useUserSettings from "~/src/hooks/use-user-settings";
import UnitStatsSkeleton from "~/src/ui/loading/unit-stats-skeleton";
import UnitsSkeleton from "~/src/ui/loading/units-skeleton";

/**
 *
 */
export default function Units() {
  const [
    query,
    setQuery
  ] = useQueryParams({
    sortBy: StringParam,
    direction: StringParam,
    page: NumberParam,
    category: StringParam
  });
  const { id } = useParams();

  const {
    project, isLoading: projectLoading, isError
  } = useProject(id);
  const { unit_categories } = useUnitCategories();
  const { userSettings } = useUserSettings();
  const {
    units, total, isLoading, sortDirection
  } = useUnits({
    project,
    query
  });

  const [
    selectedCategory,
    setSelectedCategory
  ] = useState(null);

  if (isError) {
    return null;
  }

  function handleSubmit(val) {
    setQuery({
      ...val,
      page: 1
    });
  }

  function handlePageChange(newPage) {
    setQuery({ page: newPage });
    setTimeout(() => {
      animatedScrollTo(0, {
        speed: 100,
        minDuration: 350
      });
    }, 120);
  }

  const categories = project?.unit_stats && unit_categories
    ? Object.values(project?.unit_stats).sort(({ id: idA }, { id: idB }) => unit_categories.find(({ id }) => id === idA).sorting - unit_categories.find(({ id }) => id === idB).sorting)
    : [];

  return (
    <div className="w-full">
      <h2 className="px-4 text-xl font-medium sm:px-0">Erfasste Objekte</h2>

      <div>

        {projectLoading &&
          <UnitStatsSkeleton />
        }

        {!projectLoading &&
          <UnitFilters categories={categories} selectedCategory={query?.category ?? categories?.[0]?.code} submit={handleSubmit} />
        }

        {units?.length !== 0 &&
          <div className="items-center justify-between mt-12 xl:flex">
            <div className="items-center xl:flex">
              {!isLoading &&
                <UnitListTitle categoryName={query?.category ?? categories?.[0]?.code} totalRecords={total?.totalRecords} />
              }
              {isLoading &&
                <div className="h-6 mt-3 bg-gray-300 rounded w-80 animate-pulse" />
              }
            </div>

            {!isLoading &&
              <div className="items-center justify-end xl:flex">
                <div className="flex justify-center">
                  <SortUnitsDropdown submit={handleSubmit} sortBy={query.sortBy} />
                </div>
                <div className="mb-2 xl:ml-6 xl:mb-0 xl:mr-1">
                  <Pagination pagination={{
                    totalRecords: total?.totalProjects,
                    totalPages: total?.totalPages
                  }} page={query.page} onPageChange={handlePageChange} />
                </div>
                <div className="flex justify-center xl:block">
                  <SortPurchasesDirection isLoading={isLoading} direction={sortDirection} onSubmit={handleSubmit} />
                </div>
              </div>
            }

            {isLoading &&
              <div className="h-6 mt-3 bg-gray-300 rounded w-80 animate-pulse" />
            }
          </div>
        }

        <div className="mt-2">
          {isLoading && <UnitsSkeleton />}

          {units?.map((unit) => <ProjectUnitItem key={`units_${unit.id}`} unit={unit} userSettings={userSettings} />)}

          {
            (units && units.length === 0) &&
            <p className="items-center text-xl font-light text-gray-600 lg:flex">Keine Objektdaten vorhanden…</p>
          }

        </div>

        <div className="-mt-9">
          <Pagination pagination={{
            totalRecords: total?.totalProjects,
            totalPages: total?.totalPages
          }} page={query.page} onPageChange={handlePageChange} />
        </div>
      </div>

    </div>
  );
}
