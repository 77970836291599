/* eslint-disable no-magic-numbers */
/* eslint-disable no-empty-function */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */
import cn from "classnames";
import { range } from "lodash-es";

import {
  getButtonPage,
  getButtonContent,
  getStartEllipsis,
  getRangeCount,
  getEndEllipsis
} from "./pagination-helper";

/**
 *
 * @param props
 * @param props.page
 * @param props.totalPages
 * @param props.numberOfNeighbors
 * @param props.padding
 * @param props.siblingCount
 * @param props.boundaryCount
 * @param props.count
 * @param props.onPageChange
 * @param props.isLoading
 * @param props.isHandleItemClickDisabled
 * @param props.reducedPagination
 */
const Pagination = ({
  reducedPagination,
  page: currentPage = 1,
  count = 1,
  siblingCount = 1,
  boundaryCount = 1,
  onPageChange = () => {},
  isLoading = false,
  isHandleItemClickDisabled
}) => {
  if (isLoading) {
    return (
      <div
        className="flex gap-1 animate-pulse"
      >
        {
          Array(reducedPagination ? 7 : 9)
            .fill()
            .map((empty, index) => (
              <div key={`skeleton_${index}`} className="w-8 lg:w-[2.25rem] h-6 lg:h-[1.875rem] bg-gray-300 rounded"/>
            ))
        }
      </div>
    );
  }
  const startPages = range(1, Math.min(boundaryCount, count) + 1);
  const endPages = range(Math.max(count - boundaryCount + 1, boundaryCount + 1), count + 1);

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      currentPage - siblingCount,
      // Lower boundary when page is high
      count - boundaryCount - (siblingCount * 2) - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      currentPage + siblingCount,
      // Upper boundary when page is low
      boundaryCount + (siblingCount * 2) + 2
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : count - 1
  );

  const startEllipsis = getStartEllipsis({
    siblingsStart,
    boundaryCount,
    count
  });

  const rangeCount = getRangeCount({
    siblingsStart,
    siblingsEnd
  });

  const endEllipsis = getEndEllipsis({
    siblingsEnd,
    count,
    boundaryCount
  });

  const itemList = [
    "previous",
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...startEllipsis,

    // Sibling pages
    ...rangeCount,

    // End ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...endEllipsis,

    ...endPages,
    "next"
  ]
    .map((type) => ({
      type,
      page: getButtonPage({
        type,
        currentPage,
        count
      })
    }));

  return (
    <nav className="flex items-center gap-1">
      {
        itemList.map(({ type, page }, index) => {
          switch (type) {
            case "start-ellipsis":
            case "end-ellipsis":
              return (
                <span
                  key={index}
                  className="flex items-center justify-center w-8 lg:w-[2.25rem] h-6 lg:h-[1.875rem] p-2">
                  …
                </span>
              );

            default:
              return (
                <button
                  key={index}
                  onClick={() => onPageChange(page)}
                  disabled={isHandleItemClickDisabled}
                  className={cn(
                    "w-8 lg:w-[2.25rem] h-6 lg:h-[1.875rem] flex items-center justify-center p-2 border border-gray-200 rounded-sm disabled:opacity-50",
                    {
                      "bg-primary text-white cursor-default pointer-events-none": page === currentPage,
                      "bg-white text-gray-500 hover:bg-gray-50": page !== currentPage
                    }
                  )}
                  disabled={page < 1 || page > count}
                >
                  {
                    getButtonContent(type)
                  }
                </button>
              );
          }
        })

      }
    </nav>
  );
};

export default Pagination;
