/* eslint-disable no-undefined */
/* eslint-disable consistent-return */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from "react";
import { Line, ResponsiveLine } from "@nivo/line";
import { Temporal } from "@js-temporal/polyfill";

import { getActivatedColor } from "../helpers";

import useMediaQuery from "~/src/hooks/use-media-query";

const {
  PlainYearMonth
} = Temporal;

const numberFormatter = new Intl.NumberFormat("de-AT");

const monthFormatterShort = new Intl.DateTimeFormat("de-AT", {
  calendar: "iso8601",
  year: "numeric",
  month: "short"
});

const monthFormatterLong = new Intl.DateTimeFormat("de-AT", {
  calendar: "iso8601",
  year: "numeric",
  month: "long"
});

const meterFormatter = new Intl.NumberFormat("de-AT", {
  style: "unit",
  unit: "meter"
});

const currencyFormatter = new Intl.NumberFormat("de-AT", {
  style: "currency",
  currency: "EUR"
});

const formatNumber = (number) => numberFormatter.format(number).replaceAll("\u00A0", ".");

const formatMonthShort = (date) => monthFormatterShort.format(date);
const formatMonthLong = (date) => monthFormatterLong.format(date);

const formatSquareMeters = (number) => `${meterFormatter.format(number)}²`;
const formatSquareMetersRange = (numberA, numberB) => `${meterFormatter.formatRange(numberA, numberB)}²`;

const formatEuro = (number) => currencyFormatter.format(number);

const formatEuroPerSquareMeter = (number) => `${formatEuro(number)}/m²`;

const PointsDetailLayer = ({
  currentSlice,
  xScale,
  yScale
}) => {
  if (currentSlice !== null) {
    const currentXPosition = currentSlice.points[0].data.x;
    const currentYPosition = currentSlice.points.map((point) => ({
      y: point.data.y
    }));

    if (currentXPosition && Object.keys(currentYPosition).length) {
      const circles = Object.entries(currentYPosition).map(([
        key,
        yPosition
      ], index) => (
        <React.Fragment key={`circle_${key}_${index}`}>
          <circle key={`circle_out_${key}_${index}`} cx={xScale(currentXPosition)} cy={yScale(yPosition.y)} r="5"/>
          <circle key={`circle_in_${key}_${index}`} fill="#FFFFFF" cx={xScale(currentXPosition)} cy={yScale(yPosition.y)} r="3"/>
        </React.Fragment>
      ));

      return circles;
    }
  }

  return null;
};

const getTooltip = ({
  slice: {
    points
  }
}) => {
  const xValue = points.find((point) => point.data.x);

  return (
    <div className="flex flex-col gap-1 p-2 text-sm bg-white border rounded">
      <span className="text-xs font-bold text-gray-600">{formatMonthLong(xValue.data.x)}</span>
      {
        points.map((point, index) => (
          <div key={index} className="flex items-center gap-2">
            <div
              className="w-4 h-4 rounded-full"
              style={{
                backgroundColor: point.serieColor
              }}
            />

            <span>
              {`${point.serieId}m²: ${formatEuroPerSquareMeter(point.data.y)} Einheiten: ${formatNumber(point.data.count)}`}
            </span>
          </div>
        ))

      }
    </div>
  );
};

const getXScale = ({
  type: xType,
  min: xMin = "auto",
  max: xMax = "auto"
}) => {
  switch (xType) {
    case "month":
      return {
        type: "time",
        format: "%Y-%m",
        precision: "month",
        useUTC: false,
        tickValues: "every month"
      };
    case "squareMeters":
      return {
        type: "linear",
        min: xMin,
        max: xMax,
        tickSize: 5
      };

    default:
      return {
        type: "linear",
        min: xMin,
        max: xMax,
        tickSize: 5
      };
  }
};

const getAxisBottom = (xType, isMobile) => {
  switch (xType) {
    case "month":
      return {
        format: (date) => {
          const monthNumber = date.toTemporalInstant().toZonedDateTimeISO("Europe/Vienna").month;

          const interval = isMobile ? 6 : 3;

          return (monthNumber - 1) % interval === 0
            ? formatMonthShort(date)
            : "";
        },
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        legend: "Monat",
        legendOffset: 65,
        legendPosition: "middle",
        tickValues: "every month"
      };
    case "squareMeters":
      return {
        format: (number) => {
          const interval = isMobile ? 50 : 10;

          return number % interval === 0
            ? formatSquareMeters(number)
            : "";
        },
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Fläche",
        legendOffset: 45,
        legendPosition: "middle",
        tickValues: Array(41)
          .fill()
          .map((empty, index) => index * 5),
        renderTick: ({
          x, y, value
        }) => {
          const interval = isMobile ? 50 : 10;

          const isStrong = value % interval === 0;

          return (
            <g transform={`translate(${x},${y})`}>
              <line y1={0}
                y2={isStrong
                  ? 6
                  : 4}
                stroke="currentColor"
                strokeWidth={isStrong
                  ? 1
                  : 0.5}
                opacity={0.75} />
              <text y={20} textAnchor="middle" fontSize={10}>
                {
                  value % interval === 0
                    ? formatSquareMeters(value)
                    : ""
                }
              </text>
            </g>
          );
        }
      };
    default:
      return {
      };
  }
};

const getXFormat = (xType) => {
  switch (xType) {
    case "month":
      return "time:%Y-%m";

    default:
      return undefined;
  }
};

// eslint-disable-next-line max-lines-per-function
const getProps = ({
  data,
  withArea,
  withCategories,
  x,
  x: {
    type: xType
  },
  y: {
    min: yMin = "auto",
    max: yMax = "auto"
  } = {
    min: "auto",
    max: "auto"
  },
  isMobile,
  activeAreaGroups,
  theme
}) => {
  const commonProps = {
    animate: true,
    axisBottom: getAxisBottom(xType, isMobile),
    axisLeft: {
      format: (value) => `${value}`,
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -45,
      legend: data?.[0]?.label,
      legendOffset: -50,
      legendPosition: "middle"
    },
    colors: (dataPoint) => {
      if (activeAreaGroups) {
        return getActivatedColor(dataPoint, activeAreaGroups);
      }

      return dataPoint.color;
    },
    data,
    enableSlices: "x",
    isInteractive: true,
    margin: {
      top: 10,
      right: 48,
      bottom: 80,
      left: 60
    },
    pointBorderColor: { from: "serieColor" },
    pointBorderWidth: 0,
    pointColor: (dataPoint) => {
      if (activeAreaGroups) {
        return getActivatedColor(dataPoint, activeAreaGroups);
      }

      return dataPoint.color;
    },
    pointLabelYOffset: -12,
    pointSize: 4,
    theme,
    xFormat: getXFormat(xType),
    xScale: getXScale(x),
    yScale: {
      type: "linear",
      min: yMin,
      max: yMax,
      stacked: false,
      reverse: false
    }
  };

  const backgroundLayers = [
    "grid",
    "markers",
    "axes",
    "areas"
  ];

  const middleLayers = [
    "crosshair",
    "points",
    "lines"
  ];

  const foregroundLayers = [
    "slices",
    "mesh",
    "legends"
  ];

  commonProps.layers = [
    ...backgroundLayers,
    ...middleLayers,
    PointsDetailLayer,
    ...foregroundLayers
  ];
  commonProps.sliceTooltip = getTooltip;

  return commonProps;
};

// eslint-disable-next-line max-lines-per-function
/**
 *
 * @param props
 * @param props.data
 * @param props.withArea
 * @param props.withCategories
 * @param props.xType
 * @param props.x
 * @param props.y
 * @param props.activeAreaGroups
 */
export default function MultiLineChart({
  data = [],
  withArea = false,
  withCategories = false,
  x,
  y,
  activeAreaGroups,
  ...props
}) {
  const isPrint = useMediaQuery("print");
  const isMobile = useMediaQuery("(max-width: 1023px)") || isPrint;

  const theme = {
    axis: {
      legend: {
        text: {
          fontSize: isPrint ? 12 : 16,
          fill: "black"
        }
      }
    }
  };

  const ChartComponent = isPrint ? Line : ResponsiveLine;

  const [
    animatedData,
    setAnimatedData
  ] = useState([]);

  useEffect(() => {
    if (!isPrint) {
      // Delay setting the animated data to create the initial animation
      const timeoutId = setTimeout(() => {
        setAnimatedData(data);
      }, 1);

      // Cleanup function
      return () => clearTimeout(timeoutId);
    }
  }, [data]);

  const chartProps = getProps({
    data: isPrint ? data : animatedData,
    animate: !isPrint,
    withArea,
    withCategories,
    x,
    y,
    isMobile,
    activeAreaGroups,
    theme
  });

  if (isPrint ? !data.length : !animatedData.length) {
    return null;
  }

  return (
    <ChartComponent
      {...chartProps}
      {...(isPrint && {
        width: 755.9055,
        height: 320
      })}
    />
  );
}
