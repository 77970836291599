/* eslint-disable max-len */
import ProjectLink from "~/src/ui/links/project-link/index.jsx";
import EsgIcon from "~/src/ui/icons/esg-icon/index.jsx";

const PictureContent = ({ project }) => <div className="w-full">
  <ProjectLink
    project={project}
    backlink="projects"
    target="_blank"
    className="h-full"
  >
    <div className="pb-2/3 relative w-full overflow-hidden rounded-t-lg h-[175px]">
      <img
        className="h-full w-full scale-100 transform object-cover transition duration-500 hover:scale-110"
        src={`https://immodeveloper.fra1.digitaloceanspaces.com/${project?.image_file_name}`}
        alt={project?.name} />
    </div>
  </ProjectLink>
</div>;

const ProjectContent = ({
  project, handleCheckbox, selected, checkboxDisabled, location, className = ""
}) => (
  <div className={`flex justify-between w-full font-sans  gap-3 ${className}`}>
    <div className="flex w-[10%] pt-[3px]">
      {
        handleCheckbox
          ? (
            <input
              checked={selected}
              disabled={checkboxDisabled}
              onChange={(event) => handleCheckbox(event.target.checked, project.slug)}
              type="checkbox"
              className="h-4 w-4 cursor-pointer rounded border border-gray-300 text-gray-500 shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:cursor-default disabled:opacity-50"
            />
          )
          : null
      }
    </div>
    <div className="flex h-full w-full max-w-[75%] flex-col justify-between gap-1.5">
      <ProjectLink project={project} backlink="projects" target="_blank" className="group flex pb-0.5">
        <p className="inline-block text-sm font-medium text-primary group-hover:text-primary-dark">
          <span className="whitespace-pre-line break-words border-b-2 border-primary group-hover:border-primary-dark">
            {project.name}
          </span>
        </p>
      </ProjectLink>
      <div className="flex flex-col gap-[1px]">
        <p className="text-sm leading-none text-gray-500">
          {location.city?.zipcode} {location.city?.name}
        </p>
        <p className="text-sm leading-none text-gray-500">
          {location.street.name} {location.housenumber}
        </p>
      </div>
      {project.count_wohnungen === 0
        ? null
        : (
          <p className="text-sm leading-none text-gray-800">
            {project.count_wohnungen} Wohneinheiten
          </p>
        )
      }
    </div>
    <div className="flex w-[10%] pt-[3px]">
      {
        (project?.project_certificates && project?.project_certificates.length > 0)
          ? <EsgIcon
            className="max-h-[18px] max-w-[18px]"
            innerClassName="max-h-3 max-w-3"
          />
          : null
      }
    </div>
  </div>
);

/**
 *
 * @param root0
 * @param root0.project
 * @param root0.handleCheckbox
 * @param root0.selected
 * @param root0.checkboxDisabled
 * @param root0.location
 * @param root0.displayImage
 * @param root0.displayPicture
 * @param root0.className
 */
export default function PopupContent({
  project,
  handleCheckbox,
  selected,
  checkboxDisabled,
  location,
  displayPicture
}) {
  return displayPicture
    ? (
      <div className="flex w-full flex-col items-center justify-between">
        <PictureContent project={project} />
        <ProjectContent
          project={project}
          handleCheckbox={handleCheckbox}
          selected={selected}
          checkboxDisabled={checkboxDisabled}
          location={location}
          className="p-3"
        />
      </div>
    )
    : (<ProjectContent
      project={project}
      handleCheckbox={handleCheckbox}
      selected={selected}
      checkboxDisabled={checkboxDisabled}
      location={location}
      className="p-3"
    />);
}
