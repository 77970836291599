/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import PriceSelect from "~/src/ui/selects/price-select";
import AlertIcon from "~/src/ui/icons/alert-icon";
import { errorMessage } from "~/src/modules/labels";

export default function EmptyChartContainer(
  { chartLabel, allGraphLabels }) {
  return (
    <div className="flex flex-col gap-4 p-2 bg-white border border-gray-200 rounded lg:p-4 w-full">
      <div className="flex flex-col justify-between gap-2 lg:flex-row">
        <div className="flex items-center gap-2">
          <h2 className="font-medium text-gray-900 text-md">{chartLabel}</h2>
        </div>

        <PriceSelect
          value={allGraphLabels?.[0]}
          options={allGraphLabels ?? []}
          enabledOptions={[]}
          className="lg:self-end w-80"
          buttonClassName="w-80"
        />
      </div>
      <div className="flex justify-center gap-2">
        <AlertIcon className="w-5 h-5 text-gray-500" color="text-gray-400" />
        <h2 className="">{errorMessage.statsChart}</h2>
      </div>
    </div>
  );
}
