/* eslint-disable max-params */
/* eslint-disable no-magic-numbers */
/* eslint-disable camelcase */
import API from "~/src/modules/api.js";

/**
 *
 * @param uuid
 * @param slug
 * @param handleSuccess
 * @param handleError
 */
const createItem = (uuid, slug, handleSuccess, handleError) => {
  API.post("/watch-items", {
    watch_item: {
      watchlist_uuid: uuid,
      project_slug: slug
    }
  }).then((res) => {
    handleSuccess(res.data.payload, "create");
  }).catch((error) => {
    handleError(error?.response?.status || 500, uuid);
  });
};

/**
 *
 * @param uuid
 * @param slug
 * @param handleSuccess
 * @param handleError
 */
const deleteItem = (uuid, slug, handleSuccess, handleError) => {
  API.delete("/watch-items", {
    watch_item: {
      watchlist_uuid: uuid,
      project_slug: slug
    }
  }).then((res) => {
    handleSuccess(res.data.payload, "delete");
  }).catch((error) => {
    handleError(error?.response?.status || 500, uuid);
  });
};

/**
 *
 * @param name
 * @param slug
 * @param closePopover
 * @param handleSuccess
 * @param handleError
 */
const createWatchlist = (name, slug, closePopover, handleSuccess, handleError) => {
  API.post("/watchlists", {
    watchlist: {
      name
    },
    watch_item: {
      project_slug: slug
    }
  }).then((res) => {
    handleSuccess(res.data.payload.watchlist, closePopover);
  }).catch((error) => {
    handleError(error?.response?.status);
  });
};

export {
  createWatchlist, deleteItem, createItem
};
