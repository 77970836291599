/* eslint-disable max-len */
import { forwardRef } from "react";

const SelectAllProjectsFilter = forwardRef(({
  handleCheck, selectionStatus, disabled
}, forwardedRef) => {
  const checked = selectionStatus === "all";
  const indeterminate = selectionStatus === "some";

  const handleCheckbox = function (event) {
    handleCheck(event.target.checked);
  };

  return (
    <label
      htmlFor="statsAll"
      className="pl-0.5 flex items-start gap-2 cursor-pointer"
      ref={forwardedRef}>
      <input
        id="statsAll"
        checked={checked}
        ref={(el) => el && (el.indeterminate = indeterminate)}
        onChange={handleCheckbox}
        type="checkbox"
        disabled={disabled}
        className="w-4 h-4 mt-0.5 text-gray-500 border border-gray-300 rounded shadow-sm cursor-pointer focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-default"
      />
      <span className="font-medium text-gray-500">Alle markieren</span>
    </label>
  );
});

export default SelectAllProjectsFilter;
