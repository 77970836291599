export const austriaBorderGeoJson = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "AUT",
      properties: { name: "Austria" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [
              0,
              90
            ],
            [
              180,
              90
            ],
            [
              180,
              -90
            ],
            [
              0,
              -90
            ],
            [
              -180,
              -90
            ],
            [
              -180,
              0
            ],
            [
              -180,
              90
            ],
            [
              0,
              90
            ]
          ],
          [
            [
              16.945043,
              48.604166
            ],
            [
              16.954345,
              48.557399
            ],
            [
              16.949055,
              48.544836
            ],
            [
              16.946903,
              48.539726
            ],
            [
              16.93047,
              48.528202
            ],
            [
              16.913934,
              48.519339
            ],
            [
              16.906492,
              48.509908
            ],
            [
              16.901221,
              48.496602
            ],
            [
              16.875073,
              48.471539
            ],
            [
              16.864944,
              48.458077
            ],
            [
              16.86081,
              48.443788
            ],
            [
              16.851922,
              48.390407
            ],
            [
              16.849338,
              48.384102
            ],
            [
              16.846858,
              48.381131
            ],
            [
              16.845101,
              48.376583
            ],
            [
              16.84448,
              48.365602
            ],
            [
              16.847581,
              48.359582
            ],
            [
              16.855332,
              48.356455
            ],
            [
              16.875486,
              48.355034
            ],
            [
              16.881171,
              48.352812
            ],
            [
              16.891713,
              48.347024
            ],
            [
              16.901945,
              48.339402
            ],
            [
              16.906492,
              48.33147
            ],
            [
              16.904218,
              48.327025
            ],
            [
              16.900291,
              48.321238
            ],
            [
              16.898431,
              48.316173
            ],
            [
              16.902771,
              48.314106
            ],
            [
              16.905459,
              48.311988
            ],
            [
              16.908869,
              48.306975
            ],
            [
              16.912073,
              48.301239
            ],
            [
              16.913313,
              48.296691
            ],
            [
              16.916621,
              48.2908
            ],
            [
              16.924269,
              48.287519
            ],
            [
              16.933364,
              48.284728
            ],
            [
              16.944423,
              48.27801
            ],
            [
              16.950624,
              48.276589
            ],
            [
              16.954345,
              48.273127
            ],
            [
              16.955275,
              48.268786
            ],
            [
              16.953931,
              48.25734
            ],
            [
              16.954345,
              48.25256
            ],
            [
              16.969951,
              48.216645
            ],
            [
              16.974705,
              48.198558
            ],
            [
              16.974808,
              48.17688
            ],
            [
              16.98194,
              48.161299
            ],
            [
              17.007158,
              48.142799
            ],
            [
              17.020904,
              48.137166
            ],
            [
              17.036923,
              48.135513
            ],
            [
              17.047465,
              48.130991
            ],
            [
              17.062455,
              48.112724
            ],
            [
              17.067206,
              48.106936
            ],
            [
              17.080228,
              48.097608
            ],
            [
              17.069996,
              48.089159
            ],
            [
              17.064829,
              48.07903
            ],
            [
              17.059144,
              48.060427
            ],
            [
              17.063072,
              48.058773
            ],
            [
              17.075061,
              48.052081
            ],
            [
              17.069686,
              48.035674
            ],
            [
              17.092631,
              48.02725
            ],
            [
              17.12498,
              48.019525
            ],
            [
              17.148338,
              48.005443
            ],
            [
              17.085603,
              47.970148
            ],
            [
              17.096145,
              47.961931
            ],
            [
              17.095731,
              47.95573
            ],
            [
              17.090874,
              47.949477
            ],
            [
              17.088083,
              47.940899
            ],
            [
              17.086119,
              47.93922
            ],
            [
              17.076818,
              47.934956
            ],
            [
              17.07413,
              47.932114
            ],
            [
              17.075164,
              47.927773
            ],
            [
              17.080125,
              47.925758
            ],
            [
              17.085396,
              47.924621
            ],
            [
              17.087463,
              47.922632
            ],
            [
              17.083329,
              47.904829
            ],
            [
              17.077541,
              47.891729
            ],
            [
              17.067619,
              47.881626
            ],
            [
              17.051186,
              47.872893
            ],
            [
              17.016563,
              47.867699
            ],
            [
              17.004057,
              47.863281
            ],
            [
              17.004367,
              47.852377
            ],
            [
              17.010672,
              47.847882
            ],
            [
              17.031652,
              47.841345
            ],
            [
              17.039507,
              47.837365
            ],
            [
              17.049119,
              47.818684
            ],
            [
              17.05563,
              47.812354
            ],
            [
              17.040644,
              47.801114
            ],
            [
              17.041988,
              47.783906
            ],
            [
              17.048706,
              47.763649
            ],
            [
              17.050566,
              47.730989
            ],
            [
              17.055527,
              47.720913
            ],
            [
              17.064002,
              47.71329
            ],
            [
              17.075371,
              47.708484
            ],
            [
              17.054804,
              47.702025
            ],
            [
              16.98194,
              47.695436
            ],
            [
              16.902668,
              47.682026
            ],
            [
              16.864738,
              47.686729
            ],
            [
              16.850165,
              47.712929
            ],
            [
              16.836936,
              47.705358
            ],
            [
              16.817299,
              47.684248
            ],
            [
              16.80624,
              47.676884
            ],
            [
              16.797455,
              47.675463
            ],
            [
              16.741128,
              47.681458
            ],
            [
              16.730379,
              47.685902
            ],
            [
              16.719217,
              47.693731
            ],
            [
              16.711569,
              47.704066
            ],
            [
              16.707848,
              47.714608
            ],
            [
              16.702474,
              47.7236
            ],
            [
              16.689865,
              47.729568
            ],
            [
              16.609766,
              47.750627
            ],
            [
              16.567805,
              47.754192
            ],
            [
              16.531115,
              47.742978
            ],
            [
              16.52512,
              47.733315
            ],
            [
              16.526877,
              47.720137
            ],
            [
              16.521399,
              47.711533
            ],
            [
              16.512924,
              47.706004
            ],
            [
              16.47303,
              47.691767
            ],
            [
              16.461145,
              47.684532
            ],
            [
              16.45453,
              47.681768
            ],
            [
              16.449879,
              47.682414
            ],
            [
              16.444918,
              47.68554
            ],
            [
              16.43913,
              47.690475
            ],
            [
              16.431792,
              47.685463
            ],
            [
              16.4166,
              47.668823
            ],
            [
              16.407815,
              47.66133
            ],
            [
              16.425901,
              47.654276
            ],
            [
              16.481919,
              47.638954
            ],
            [
              16.509617,
              47.64314
            ],
            [
              16.575453,
              47.624949
            ],
            [
              16.608422,
              47.628773
            ],
            [
              16.63023,
              47.622004
            ],
            [
              16.647697,
              47.606139
            ],
            [
              16.656172,
              47.585934
            ],
            [
              16.65059,
              47.566555
            ],
            [
              16.66754,
              47.560096
            ],
            [
              16.68139,
              47.550561
            ],
            [
              16.689141,
              47.53803
            ],
            [
              16.688004,
              47.52263
            ],
            [
              16.677359,
              47.509866
            ],
            [
              16.648213,
              47.501546
            ],
            [
              16.636845,
              47.4932
            ],
            [
              16.640875,
              47.452919
            ],
            [
              16.626973,
              47.445549
            ],
            [
              16.589406,
              47.425633
            ],
            [
              16.481919,
              47.392302
            ],
            [
              16.46993,
              47.405531
            ],
            [
              16.456597,
              47.411836
            ],
            [
              16.444091,
              47.40951
            ],
            [
              16.433963,
              47.39685
            ],
            [
              16.436443,
              47.358506
            ],
            [
              16.434273,
              47.35556
            ],
            [
              16.429312,
              47.353803
            ],
            [
              16.424971,
              47.351116
            ],
            [
              16.424351,
              47.345199
            ],
            [
              16.427142,
              47.341375
            ],
            [
              16.431689,
              47.339721
            ],
            [
              16.436133,
              47.338559
            ],
            [
              16.43882,
              47.336569
            ],
            [
              16.469206,
              47.293238
            ],
            [
              16.473237,
              47.276805
            ],
            [
              16.466622,
              47.263421
            ],
            [
              16.452463,
              47.254584
            ],
            [
              16.421354,
              47.243138
            ],
            [
              16.421354,
              47.243035
            ],
            [
              16.424661,
              47.225594
            ],
            [
              16.409262,
              47.203683
            ],
            [
              16.412776,
              47.187173
            ],
            [
              16.41877,
              47.183659
            ],
            [
              16.426728,
              47.184124
            ],
            [
              16.4351,
              47.183581
            ],
            [
              16.441818,
              47.177121
            ],
            [
              16.442128,
              47.168336
            ],
            [
              16.433963,
              47.151283
            ],
            [
              16.433653,
              47.145754
            ],
            [
              16.447089,
              47.139708
            ],
            [
              16.480885,
              47.150767
            ],
            [
              16.497318,
              47.149681
            ],
            [
              16.509514,
              47.137537
            ],
            [
              16.50476,
              47.125833
            ],
            [
              16.481919,
              47.10524
            ],
            [
              16.460835,
              47.0963
            ],
            [
              16.454323,
              47.081701
            ],
            [
              16.461765,
              47.068498
            ],
            [
              16.481919,
              47.063898
            ],
            [
              16.493701,
              47.059816
            ],
            [
              16.497215,
              47.054622
            ],
            [
              16.493184,
              47.049145
            ],
            [
              16.481919,
              47.04421
            ],
            [
              16.437167,
              47.031782
            ],
            [
              16.424661,
              47.024082
            ],
            [
              16.45329,
              47.021679
            ],
            [
              16.467553,
              47.018423
            ],
            [
              16.481919,
              47.00938
            ],
            [
              16.486363,
              46.998554
            ],
            [
              16.467449,
              46.995427
            ],
            [
              16.441404,
              46.99522
            ],
            [
              16.424764,
              46.993102
            ],
            [
              16.424764,
              46.992998
            ],
            [
              16.420424,
              46.99026
            ],
            [
              16.415566,
              46.989433
            ],
            [
              16.410398,
              46.990415
            ],
            [
              16.405024,
              46.993153
            ],
            [
              16.387764,
              47.002042
            ],
            [
              16.366577,
              47.003825
            ],
            [
              16.325856,
              47.00044
            ],
            [
              16.288545,
              47.005582
            ],
            [
              16.274903,
              47.004315
            ],
            [
              16.265394,
              46.993257
            ],
            [
              16.261054,
              46.97809
            ],
            [
              16.252992,
              46.973516
            ],
            [
              16.24307,
              46.972018
            ],
            [
              16.232942,
              46.966075
            ],
            [
              16.230358,
              46.959977
            ],
            [
              16.231185,
              46.954422
            ],
            [
              16.230668,
              46.94835
            ],
            [
              16.22395,
              46.941064
            ],
            [
              16.217025,
              46.937395
            ],
            [
              16.196148,
              46.931297
            ],
            [
              16.170723,
              46.918533
            ],
            [
              16.159148,
              46.910316
            ],
            [
              16.122871,
              46.876365
            ],
            [
              16.110055,
              46.867916
            ],
            [
              16.094035,
              46.862774
            ],
            [
              16.052936,
              46.84606
            ],
            [
              16.032024,
              46.837556
            ],
            [
              16.028441,
              46.836947
            ],
            [
              15.987582,
              46.830011
            ],
            [
              15.981691,
              46.827685
            ],
            [
              15.971976,
              46.820632
            ],
            [
              15.972906,
              46.818435
            ],
            [
              15.97735,
              46.816213
            ],
            [
              15.978177,
              46.809134
            ],
            [
              15.971252,
              46.778076
            ],
            [
              15.970985,
              46.77505
            ],
            [
              15.969702,
              46.760532
            ],
            [
              15.970529,
              46.743014
            ],
            [
              15.982001,
              46.718545
            ],
            [
              16.003291,
              46.709191
            ],
            [
              16.014557,
              46.693714
            ],
            [
              16.016723,
              46.670691
            ],
            [
              16.016593,
              46.670757
            ],
            [
              15.997917,
              46.686919
            ],
            [
              15.986962,
              46.69219
            ],
            [
              15.946344,
              46.697151
            ],
            [
              15.878545,
              46.720715
            ],
            [
              15.850743,
              46.724488
            ],
            [
              15.822941,
              46.722834
            ],
            [
              15.78461,
              46.7122
            ],
            [
              15.755141,
              46.704024
            ],
            [
              15.728683,
              46.70299
            ],
            [
              15.652098,
              46.710819
            ],
            [
              15.635975,
              46.717563
            ],
            [
              15.632875,
              46.702473
            ],
            [
              15.632978,
              46.689632
            ],
            [
              15.626984,
              46.680873
            ],
            [
              15.621745,
              46.678175
            ],
            [
              15.616648,
              46.67555
            ],
            [
              15.603729,
              46.673018
            ],
            [
              15.58864,
              46.675963
            ],
            [
              15.567349,
              46.675757
            ],
            [
              15.545955,
              46.671881
            ],
            [
              15.530659,
              46.663845
            ],
            [
              15.52084,
              46.649608
            ],
            [
              15.520308,
              46.64772
            ],
            [
              15.517636,
              46.63824
            ],
            [
              15.513939,
              46.632545
            ],
            [
              15.511228,
              46.628369
            ],
            [
              15.492625,
              46.618293
            ],
            [
              15.462653,
              46.614649
            ],
            [
              15.440018,
              46.62439
            ],
            [
              15.435381,
              46.627195
            ],
            [
              15.417591,
              46.637955
            ],
            [
              15.388135,
              46.645578
            ],
            [
              15.332784,
              46.64358
            ],
            [
              15.204891,
              46.638963
            ],
            [
              15.172557,
              46.64136
            ],
            [
              15.105591,
              46.646323
            ],
            [
              15.085723,
              46.647795
            ],
            [
              15.061954,
              46.649557
            ],
            [
              15.004386,
              46.636844
            ],
            [
              14.967179,
              46.600257
            ],
            [
              14.947955,
              46.619274
            ],
            [
              14.933589,
              46.621135
            ],
            [
              14.919507,
              46.615017
            ],
            [
              14.897726,
              46.605554
            ],
            [
              14.894063,
              46.605215
            ],
            [
              14.877055,
              46.603642
            ],
            [
              14.862999,
              46.604831
            ],
            [
              14.85039,
              46.601136
            ],
            [
              14.833647,
              46.584393
            ],
            [
              14.822278,
              46.567546
            ],
            [
              14.814519,
              46.551337
            ],
            [
              14.807189,
              46.536024
            ],
            [
              14.796663,
              46.519401
            ],
            [
              14.788585,
              46.506646
            ],
            [
              14.783004,
              46.503261
            ],
            [
              14.760887,
              46.496284
            ],
            [
              14.735255,
              46.493339
            ],
            [
              14.726367,
              46.497706
            ],
            [
              14.709727,
              46.492512
            ],
            [
              14.703733,
              46.487758
            ],
            [
              14.698772,
              46.480962
            ],
            [
              14.687093,
              46.471221
            ],
            [
              14.679961,
              46.458871
            ],
            [
              14.672546,
              46.459791
            ],
            [
              14.666629,
              46.460524
            ],
            [
              14.662081,
              46.459698
            ],
            [
              14.642031,
              46.445228
            ],
            [
              14.631696,
              46.440577
            ],
            [
              14.621567,
              46.43851
            ],
            [
              14.599863,
              46.437167
            ],
            [
              14.590148,
              46.434428
            ],
            [
              14.575368,
              46.419726
            ],
            [
              14.566997,
              46.400373
            ],
            [
              14.562108,
              46.391737
            ],
            [
              14.557695,
              46.38394
            ],
            [
              14.540332,
              46.378643
            ],
            [
              14.527102,
              46.388229
            ],
            [
              14.515837,
              46.40536
            ],
            [
              14.502194,
              46.418356
            ],
            [
              14.467675,
              46.412672
            ],
            [
              14.450931,
              46.414481
            ],
            [
              14.437145,
              46.418884
            ],
            [
              14.420029,
              46.424351
            ],
            [
              14.414448,
              46.429002
            ],
            [
              14.411244,
              46.434635
            ],
            [
              14.40649,
              46.439337
            ],
            [
              14.395844,
              46.440991
            ],
            [
              14.362151,
              46.435875
            ],
            [
              14.242323,
              46.438237
            ],
            [
              14.149865,
              46.440061
            ],
            [
              14.147933,
              46.440425
            ],
            [
              14.137255,
              46.442438
            ],
            [
              14.081032,
              46.47595
            ],
            [
              14.066355,
              46.48104
            ],
            [
              14.050026,
              46.484399
            ],
            [
              14.032456,
              46.484709
            ],
            [
              14.014922,
              46.482531
            ],
            [
              13.998763,
              46.480523
            ],
            [
              13.98233,
              46.481918
            ],
            [
              13.890862,
              46.511787
            ],
            [
              13.860683,
              46.51525
            ],
            [
              13.795778,
              46.507886
            ],
            [
              13.782135,
              46.507782
            ],
            [
              13.716093,
              46.518867
            ],
            [
              13.700951,
              46.519746
            ],
            [
              13.685345,
              46.517627
            ],
            [
              13.670256,
              46.518712
            ],
            [
              13.549229,
              46.545842
            ],
            [
              13.506751,
              46.546927
            ],
            [
              13.499103,
              46.550622
            ],
            [
              13.484944,
              46.561733
            ],
            [
              13.478019,
              46.563567
            ],
            [
              13.417041,
              46.560492
            ],
            [
              13.373323,
              46.565789
            ],
            [
              13.271417,
              46.550777
            ],
            [
              13.231109,
              46.552173
            ],
            [
              13.210025,
              46.558012
            ],
            [
              13.146463,
              46.584961
            ],
            [
              13.064504,
              46.598035
            ],
            [
              12.83041,
              46.609637
            ],
            [
              12.773566,
              46.635191
            ],
            [
              12.7485,
              46.640996
            ],
            [
              12.739873,
              46.642994
            ],
            [
              12.732018,
              46.64227
            ],
            [
              12.715999,
              46.637955
            ],
            [
              12.706593,
              46.637749
            ],
            [
              12.697395,
              46.640539
            ],
            [
              12.679205,
              46.650022
            ],
            [
              12.671368,
              46.652466
            ],
            [
              12.669593,
              46.653019
            ],
            [
              12.62019,
              46.656481
            ],
            [
              12.562003,
              46.65121
            ],
            [
              12.547327,
              46.652192
            ],
            [
              12.53079,
              46.65767
            ],
            [
              12.499888,
              46.672139
            ],
            [
              12.469632,
              46.675799
            ],
            [
              12.445627,
              46.678702
            ],
            [
              12.40501,
              46.690123
            ],
            [
              12.370387,
              46.711155
            ],
            [
              12.351473,
              46.743246
            ],
            [
              12.342688,
              46.765131
            ],
            [
              12.326048,
              46.772495
            ],
            [
              12.305274,
              46.774459
            ],
            [
              12.284397,
              46.779988
            ],
            [
              12.274785,
              46.784639
            ],
            [
              12.269101,
              46.788566
            ],
            [
              12.266724,
              46.795336
            ],
            [
              12.26693,
              46.808255
            ],
            [
              12.269618,
              46.819831
            ],
            [
              12.273338,
              46.826755
            ],
            [
              12.276129,
              46.83399
            ],
            [
              12.275819,
              46.846289
            ],
            [
              12.26662,
              46.868148
            ],
            [
              12.250807,
              46.875615
            ],
            [
              12.208743,
              46.876623
            ],
            [
              12.1951,
              46.880085
            ],
            [
              12.189002,
              46.884943
            ],
            [
              12.183525,
              46.891222
            ],
            [
              12.172156,
              46.89918
            ],
            [
              12.160994,
              46.90303
            ],
            [
              12.137429,
              46.905924
            ],
            [
              12.126887,
              46.908869
            ],
            [
              12.141563,
              46.918843
            ],
            [
              12.141667,
              46.927989
            ],
            [
              12.134742,
              46.937446
            ],
            [
              12.128231,
              46.948582
            ],
            [
              12.122133,
              46.971656
            ],
            [
              12.117792,
              46.983076
            ],
            [
              12.111178,
              46.992998
            ],
            [
              12.12172,
              47.010517
            ],
            [
              12.182284,
              47.033616
            ],
            [
              12.203575,
              47.053331
            ],
            [
              12.203897,
              47.067012
            ],
            [
              12.204195,
              47.079686
            ],
            [
              12.180631,
              47.085215
            ],
            [
              12.133146,
              47.078832
            ],
            [
              12.116242,
              47.076559
            ],
            [
              12.014956,
              47.040489
            ],
            [
              11.943643,
              47.038164
            ],
            [
              11.899511,
              47.027725
            ],
            [
              11.857033,
              47.012015
            ],
            [
              11.822513,
              46.993257
            ],
            [
              11.777141,
              46.988296
            ],
            [
              11.766082,
              46.983438
            ],
            [
              11.746445,
              46.972457
            ],
            [
              11.73487,
              46.970622
            ],
            [
              11.716163,
              46.97548
            ],
            [
              11.683814,
              46.991913
            ],
            [
              11.664797,
              46.993257
            ],
            [
              11.657252,
              46.992533
            ],
            [
              11.649707,
              46.992998
            ],
            [
              11.648467,
              46.993257
            ],
            [
              11.64795,
              46.993257
            ],
            [
              11.59648,
              47.00044
            ],
            [
              11.572709,
              46.998915
            ],
            [
              11.543667,
              46.993102
            ],
            [
              11.543564,
              46.993102
            ],
            [
              11.543357,
              46.992998
            ],
            [
              11.543254,
              46.992998
            ],
            [
              11.533849,
              46.989536
            ],
            [
              11.524443,
              46.988347
            ],
            [
              11.515348,
              46.989484
            ],
            [
              11.506977,
              46.992998
            ],
            [
              11.501706,
              46.997856
            ],
            [
              11.495918,
              47.001602
            ],
            [
              11.489407,
              47.004212
            ],
            [
              11.471837,
              47.00708
            ],
            [
              11.461812,
              47.005504
            ],
            [
              11.452923,
              47.000879
            ],
            [
              11.445275,
              46.993102
            ],
            [
              11.445172,
              46.992998
            ],
            [
              11.411169,
              46.970493
            ],
            [
              11.380576,
              46.971553
            ],
            [
              11.349467,
              46.98194
            ],
            [
              11.31381,
              46.987262
            ],
            [
              11.243737,
              46.979252
            ],
            [
              11.174491,
              46.963853
            ],
            [
              11.156611,
              46.956515
            ],
            [
              11.091912,
              46.912435
            ],
            [
              11.083643,
              46.900136
            ],
            [
              11.073101,
              46.86497
            ],
            [
              11.054291,
              46.834145
            ],
            [
              11.053464,
              46.830321
            ],
            [
              11.054601,
              46.819934
            ],
            [
              11.052844,
              46.814921
            ],
            [
              11.048503,
              46.811692
            ],
            [
              11.037445,
              46.808255
            ],
            [
              11.033311,
              46.805568
            ],
            [
              11.010883,
              46.779213
            ],
            [
              10.99693,
              46.76911
            ],
            [
              10.982668,
              46.767741
            ],
            [
              10.965718,
              46.771642
            ],
            [
              10.930888,
              46.773994
            ],
            [
              10.913731,
              46.77234
            ],
            [
              10.880348,
              46.764589
            ],
            [
              10.870426,
              46.764072
            ],
            [
              10.860195,
              46.766991
            ],
            [
              10.843141,
              46.777043
            ],
            [
              10.83415,
              46.780246
            ],
            [
              10.823918,
              46.78035
            ],
            [
              10.804901,
              46.776629
            ],
            [
              10.795082,
              46.776888
            ],
            [
              10.76635,
              46.788101
            ],
            [
              10.754775,
              46.790763
            ],
            [
              10.733691,
              46.786189
            ],
            [
              10.722942,
              46.786448
            ],
            [
              10.716947,
              46.795207
            ],
            [
              10.721598,
              46.800142
            ],
            [
              10.743819,
              46.812518
            ],
            [
              10.748677,
              46.819443
            ],
            [
              10.738962,
              46.829546
            ],
            [
              10.662481,
              46.860965
            ],
            [
              10.647081,
              46.863756
            ],
            [
              10.629408,
              46.862412
            ],
            [
              10.527708,
              46.843214
            ],
            [
              10.486264,
              46.846366
            ],
            [
              10.453811,
              46.864427
            ],
            [
              10.451434,
              46.88577
            ],
            [
              10.463836,
              46.919747
            ],
            [
              10.458462,
              46.936619
            ],
            [
              10.449574,
              46.943906
            ],
            [
              10.415571,
              46.962406
            ],
            [
              10.394693,
              46.985402
            ],
            [
              10.384358,
              46.992998
            ],
            [
              10.384358,
              46.993153
            ],
            [
              10.384255,
              46.993153
            ],
            [
              10.378984,
              46.995505
            ],
            [
              10.373403,
              46.996254
            ],
            [
              10.367925,
              46.995505
            ],
            [
              10.338883,
              46.98411
            ],
            [
              10.313665,
              46.964318
            ],
            [
              10.296198,
              46.941374
            ],
            [
              10.295681,
              46.922693
            ],
            [
              10.270773,
              46.921892
            ],
            [
              10.251343,
              46.92538
            ],
            [
              10.235116,
              46.923313
            ],
            [
              10.219924,
              46.905769
            ],
            [
              10.215169,
              46.893108
            ],
            [
              10.214342,
              46.884685
            ],
            [
              10.211655,
              46.877036
            ],
            [
              10.201423,
              46.86683
            ],
            [
              10.157808,
              46.851612
            ],
            [
              10.13197,
              46.846573
            ],
            [
              10.125188,
              46.846751
            ],
            [
              10.1113,
              46.847116
            ],
            [
              10.068098,
              46.856624
            ],
            [
              10.045567,
              46.865564
            ],
            [
              10.006913,
              46.890757
            ],
            [
              9.899943,
              46.914398
            ],
            [
              9.875138,
              46.927421
            ],
            [
              9.862426,
              46.939772
            ],
            [
              9.860772,
              46.949151
            ],
            [
              9.863976,
              46.959925
            ],
            [
              9.866457,
              46.983387
            ],
            [
              9.870591,
              46.992947
            ],
            [
              9.870591,
              46.998838
            ],
            [
              9.866767,
              47.001938
            ],
            [
              9.860566,
              47.001602
            ],
            [
              9.856328,
              47.004083
            ],
            [
              9.857982,
              47.015478
            ],
            [
              9.669053,
              47.056199
            ],
            [
              9.65231,
              47.05793
            ],
            [
              9.59991,
              47.053486
            ],
            [
              9.581203,
              47.05687
            ],
            [
              9.608798,
              47.080771
            ],
            [
              9.615723,
              47.106764
            ],
            [
              9.605594,
              47.132266
            ],
            [
              9.581823,
              47.154901
            ],
            [
              9.552057,
              47.16689
            ],
            [
              9.551954,
              47.175571
            ],
            [
              9.561876,
              47.190609
            ],
            [
              9.562909,
              47.19774
            ],
            [
              9.554331,
              47.211615
            ],
            [
              9.544823,
              47.220323
            ],
            [
              9.540378,
              47.229108
            ],
            [
              9.547096,
              47.243035
            ],
            [
              9.53025,
              47.253654
            ],
            [
              9.521155,
              47.262801
            ],
            [
              9.553298,
              47.299853
            ],
            [
              9.587404,
              47.32781
            ],
            [
              9.591228,
              47.334683
            ],
            [
              9.596396,
              47.352305
            ],
            [
              9.601047,
              47.36127
            ],
            [
              9.639804,
              47.394524
            ],
            [
              9.649519,
              47.409717
            ],
            [
              9.650346,
              47.452092
            ],
            [
              9.621717,
              47.469197
            ],
            [
              9.58451,
              47.480721
            ],
            [
              9.554951,
              47.5109
            ],
            [
              9.553059,
              47.516891
            ],
            [
              9.547482,
              47.534547
            ],
            [
              9.549887,
              47.533534
            ],
            [
              9.554144,
              47.532743
            ],
            [
              9.612622,
              47.521881
            ],
            [
              9.676701,
              47.52263
            ],
            [
              9.703986,
              47.531415
            ],
            [
              9.718455,
              47.546711
            ],
            [
              9.730858,
              47.564901
            ],
            [
              9.752665,
              47.582058
            ],
            [
              9.767135,
              47.587277
            ],
            [
              9.782121,
              47.588414
            ],
            [
              9.79628,
              47.584952
            ],
            [
              9.807959,
              47.576322
            ],
            [
              9.811266,
              47.564488
            ],
            [
              9.809303,
              47.552318
            ],
            [
              9.813127,
              47.54175
            ],
            [
              9.833487,
              47.534619
            ],
            [
              9.841549,
              47.535032
            ],
            [
              9.853848,
              47.540407
            ],
            [
              9.858912,
              47.541337
            ],
            [
              9.888988,
              47.533792
            ],
            [
              9.918753,
              47.53219
            ],
            [
              9.933843,
              47.534206
            ],
            [
              9.945935,
              47.540769
            ],
            [
              9.948726,
              47.524232
            ],
            [
              9.959164,
              47.514879
            ],
            [
              9.97167,
              47.506404
            ],
            [
              9.980765,
              47.492942
            ],
            [
              9.980765,
              47.489919
            ],
            [
              9.981075,
              47.48687
            ],
            [
              9.982625,
              47.481031
            ],
            [
              9.993581,
              47.476586
            ],
            [
              10.002366,
              47.47917
            ],
            [
              10.011254,
              47.484441
            ],
            [
              10.022623,
              47.487955
            ],
            [
              10.071819,
              47.439121
            ],
            [
              10.080604,
              47.42739
            ],
            [
              10.075953,
              47.416874
            ],
            [
              10.053215,
              47.40486
            ],
            [
              10.064171,
              47.396178
            ],
            [
              10.066858,
              47.385791
            ],
            [
              10.067788,
              47.375042
            ],
            [
              10.073162,
              47.365482
            ],
            [
              10.082878,
              47.359074
            ],
            [
              10.089596,
              47.359281
            ],
            [
              10.097244,
              47.363131
            ],
            [
              10.11037,
              47.367394
            ],
            [
              10.11533,
              47.366955
            ],
            [
              10.125666,
              47.362692
            ],
            [
              10.130833,
              47.362666
            ],
            [
              10.135071,
              47.364888
            ],
            [
              10.146956,
              47.373802
            ],
            [
              10.190881,
              47.379228
            ],
            [
              10.209278,
              47.372484
            ],
            [
              10.208555,
              47.354475
            ],
            [
              10.190468,
              47.317475
            ],
            [
              10.192742,
              47.310912
            ],
            [
              10.193259,
              47.304917
            ],
            [
              10.190778,
              47.298251
            ],
            [
              10.18592,
              47.294995
            ],
            [
              10.172691,
              47.292308
            ],
            [
              10.168661,
              47.290422
            ],
            [
              10.159462,
              47.279131
            ],
            [
              10.155431,
              47.272878
            ],
            [
              10.159876,
              47.271121
            ],
            [
              10.174951,
              47.272376
            ],
            [
              10.239354,
              47.277735
            ],
            [
              10.261161,
              47.283497
            ],
            [
              10.305913,
              47.302178
            ],
            [
              10.325447,
              47.314322
            ],
            [
              10.34343,
              47.330756
            ],
            [
              10.365651,
              47.361115
            ],
            [
              10.371646,
              47.367394
            ],
            [
              10.381981,
              47.372122
            ],
            [
              10.403065,
              47.377109
            ],
            [
              10.411643,
              47.381037
            ],
            [
              10.428283,
              47.396049
            ],
            [
              10.442856,
              47.41628
            ],
            [
              10.451951,
              47.438759
            ],
            [
              10.451744,
              47.460205
            ],
            [
              10.44761,
              47.472581
            ],
            [
              10.442236,
              47.481392
            ],
            [
              10.433657,
              47.487852
            ],
            [
              10.419601,
              47.493045
            ],
            [
              10.42973,
              47.532914
            ],
            [
              10.43097,
              47.542164
            ],
            [
              10.424356,
              47.553352
            ],
            [
              10.415674,
              47.564385
            ],
            [
              10.414227,
              47.573015
            ],
            [
              10.429213,
              47.57702
            ],
            [
              10.445956,
              47.579061
            ],
            [
              10.453604,
              47.581102
            ],
            [
              10.457118,
              47.579242
            ],
            [
              10.461563,
              47.569914
            ],
            [
              10.460322,
              47.565987
            ],
            [
              10.455465,
              47.561181
            ],
            [
              10.451744,
              47.554825
            ],
            [
              10.453501,
              47.546246
            ],
            [
              10.458772,
              47.541647
            ],
            [
              10.466937,
              47.53772
            ],
            [
              10.482543,
              47.532862
            ],
            [
              10.525125,
              47.52847
            ],
            [
              10.536907,
              47.529865
            ],
            [
              10.549619,
              47.536789
            ],
            [
              10.569876,
              47.555936
            ],
            [
              10.583726,
              47.562473
            ],
            [
              10.60791,
              47.562266
            ],
            [
              10.739892,
              47.52847
            ],
            [
              10.743923,
              47.525317
            ],
            [
              10.74723,
              47.52015
            ],
            [
              10.752191,
              47.515395
            ],
            [
              10.760769,
              47.513638
            ],
            [
              10.790328,
              47.516067
            ],
            [
              10.830636,
              47.528573
            ],
            [
              10.844795,
              47.531312
            ],
            [
              10.858644,
              47.530666
            ],
            [
              10.86929,
              47.526583
            ],
            [
              10.892234,
              47.514879
            ],
            [
              10.883862,
              47.508006
            ],
            [
              10.851616,
              47.493097
            ],
            [
              10.858438,
              47.485165
            ],
            [
              10.909907,
              47.46899
            ],
            [
              10.959827,
              47.432506
            ],
            [
              10.962824,
              47.427959
            ],
            [
              10.963134,
              47.421241
            ],
            [
              10.96086,
              47.414316
            ],
            [
              10.957656,
              47.409717
            ],
            [
              10.955279,
              47.409614
            ],
            [
              10.965511,
              47.396075
            ],
            [
              10.979464,
              47.390545
            ],
            [
              11.083747,
              47.389512
            ],
            [
              11.10328,
              47.393568
            ],
            [
              11.168599,
              47.424264
            ],
            [
              11.193818,
              47.428941
            ],
            [
              11.215212,
              47.422998
            ],
            [
              11.213041,
              47.395816
            ],
            [
              11.237019,
              47.393956
            ],
            [
              11.258827,
              47.400725
            ],
            [
              11.273089,
              47.411112
            ],
            [
              11.286318,
              47.423205
            ],
            [
              11.305645,
              47.4354
            ],
            [
              11.324249,
              47.439431
            ],
            [
              11.367657,
              47.440129
            ],
            [
              11.383884,
              47.444857
            ],
            [
              11.392462,
              47.454779
            ],
            [
              11.388638,
              47.46222
            ],
            [
              11.377786,
              47.467052
            ],
            [
              11.365487,
              47.469326
            ],
            [
              11.412616,
              47.506094
            ],
            [
              11.435353,
              47.509711
            ],
            [
              11.459951,
              47.507437
            ],
            [
              11.482585,
              47.50258
            ],
            [
              11.529508,
              47.507851
            ],
            [
              11.551212,
              47.51369
            ],
            [
              11.569402,
              47.527384
            ],
            [
              11.574466,
              47.536634
            ],
            [
              11.582321,
              47.559785
            ],
            [
              11.589142,
              47.570379
            ],
            [
              11.617048,
              47.586761
            ],
            [
              11.620458,
              47.589654
            ],
            [
              11.638958,
              47.589241
            ],
            [
              11.682573,
              47.58335
            ],
            [
              11.764015,
              47.583117
            ],
            [
              11.820343,
              47.575288
            ],
            [
              11.830678,
              47.577614
            ],
            [
              11.840703,
              47.594615
            ],
            [
              11.851969,
              47.599163
            ],
            [
              11.935891,
              47.610738
            ],
            [
              12.165645,
              47.603969
            ],
            [
              12.173603,
              47.60508
            ],
            [
              12.202128,
              47.629833
            ],
            [
              12.205745,
              47.636215
            ],
            [
              12.206779,
              47.645568
            ],
            [
              12.205125,
              47.672285
            ],
            [
              12.202335,
              47.677789
            ],
            [
              12.197271,
              47.680088
            ],
            [
              12.189106,
              47.685566
            ],
            [
              12.181768,
              47.692129
            ],
            [
              12.178564,
              47.697658
            ],
            [
              12.1766,
              47.705823
            ],
            [
              12.192413,
              47.710164
            ],
            [
              12.216494,
              47.723703
            ],
            [
              12.225176,
              47.727372
            ],
            [
              12.239749,
              47.731687
            ],
            [
              12.242229,
              47.732023
            ],
            [
              12.239128,
              47.72745
            ],
            [
              12.236545,
              47.716778
            ],
            [
              12.233031,
              47.708872
            ],
            [
              12.226623,
              47.702878
            ],
            [
              12.223315,
              47.695901
            ],
            [
              12.228793,
              47.684868
            ],
            [
              12.238715,
              47.6789
            ],
            [
              12.249567,
              47.680011
            ],
            [
              12.271891,
              47.687891
            ],
            [
              12.293699,
              47.69001
            ],
            [
              12.351473,
              47.68169
            ],
            [
              12.368423,
              47.683551
            ],
            [
              12.407697,
              47.693731
            ],
            [
              12.42413,
              47.69156
            ],
            [
              12.428781,
              47.685798
            ],
            [
              12.43922,
              47.664482
            ],
            [
              12.444697,
              47.656291
            ],
            [
              12.452759,
              47.649754
            ],
            [
              12.482524,
              47.633579
            ],
            [
              12.49658,
              47.628903
            ],
            [
              12.517148,
              47.628541
            ],
            [
              12.538232,
              47.631331
            ],
            [
              12.553838,
              47.636008
            ],
            [
              12.563375,
              47.641761
            ],
            [
              12.598383,
              47.66288
            ],
            [
              12.6174,
              47.669339
            ],
            [
              12.653057,
              47.675127
            ],
            [
              12.688713,
              47.675127
            ],
            [
              12.744834,
              47.66536
            ],
            [
              12.761991,
              47.666859
            ],
            [
              12.751655,
              47.649392
            ],
            [
              12.767365,
              47.63637
            ],
            [
              12.793307,
              47.624794
            ],
            [
              12.81315,
              47.611824
            ],
            [
              12.785865,
              47.602677
            ],
            [
              12.773669,
              47.5795
            ],
            [
              12.77894,
              47.554825
            ],
            [
              12.803642,
              47.541337
            ],
            [
              12.819041,
              47.539528
            ],
            [
              12.828446,
              47.537255
            ],
            [
              12.836921,
              47.532655
            ],
            [
              12.882603,
              47.498575
            ],
            [
              12.931593,
              47.473512
            ],
            [
              12.942548,
              47.470489
            ],
            [
              12.951023,
              47.472478
            ],
            [
              12.959291,
              47.475527
            ],
            [
              12.96973,
              47.475734
            ],
            [
              12.979342,
              47.472246
            ],
            [
              12.984923,
              47.468267
            ],
            [
              12.991124,
              47.465683
            ],
            [
              13.001873,
              47.466019
            ],
            [
              13.008797,
              47.470101
            ],
            [
              13.037323,
              47.493045
            ],
            [
              13.037013,
              47.501365
            ],
            [
              13.034429,
              47.507437
            ],
            [
              13.031225,
              47.512553
            ],
            [
              13.028951,
              47.518031
            ],
            [
              13.028124,
              47.524232
            ],
            [
              13.028124,
              47.541647
            ],
            [
              13.030605,
              47.544257
            ],
            [
              13.040113,
              47.560457
            ],
            [
              13.04125,
              47.561904
            ],
            [
              13.03908,
              47.561336
            ],
            [
              13.038253,
              47.584022
            ],
            [
              13.04094,
              47.58335
            ],
            [
              13.057063,
              47.597664
            ],
            [
              13.057683,
              47.600429
            ],
            [
              13.069569,
              47.620247
            ],
            [
              13.072049,
              47.622417
            ],
            [
              13.07422,
              47.63451
            ],
            [
              13.07484,
              47.646654
            ],
            [
              13.072049,
              47.659469
            ],
            [
              13.064091,
              47.67399
            ],
            [
              13.044557,
              47.696573
            ],
            [
              13.032258,
              47.706624
            ],
            [
              13.019856,
              47.712929
            ],
            [
              13.004353,
              47.714608
            ],
            [
              12.979032,
              47.707089
            ],
            [
              12.964045,
              47.705358
            ],
            [
              12.908338,
              47.71236
            ],
            [
              12.892009,
              47.723548
            ],
            [
              12.910612,
              47.742927
            ],
            [
              12.910819,
              47.74303
            ],
            [
              12.917227,
              47.750161
            ],
            [
              12.918984,
              47.756776
            ],
            [
              12.919294,
              47.763184
            ],
            [
              12.921257,
              47.76954
            ],
            [
              12.926735,
              47.777395
            ],
            [
              12.991227,
              47.847106
            ],
            [
              12.986576,
              47.850259
            ],
            [
              12.964976,
              47.871963
            ],
            [
              12.931076,
              47.924879
            ],
            [
              12.914229,
              47.93599
            ],
            [
              12.906168,
              47.93891
            ],
            [
              12.886014,
              47.952888
            ],
            [
              12.862036,
              47.962552
            ],
            [
              12.853975,
              47.970484
            ],
            [
              12.8486,
              47.980948
            ],
            [
              12.848246,
              47.982389
            ],
            [
              12.845603,
              47.993144
            ],
            [
              12.830617,
              48.015468
            ],
            [
              12.760234,
              48.064871
            ],
            [
              12.750932,
              48.074741
            ],
            [
              12.742043,
              48.086782
            ],
            [
              12.736979,
              48.099959
            ],
            [
              12.738943,
              48.113447
            ],
            [
              12.745041,
              48.12063
            ],
            [
              12.751449,
              48.122103
            ],
            [
              12.75796,
              48.121896
            ],
            [
              12.764058,
              48.123756
            ],
            [
              12.77832,
              48.138717
            ],
            [
              12.782351,
              48.142024
            ],
            [
              12.822142,
              48.160679
            ],
            [
              12.835164,
              48.170704
            ],
            [
              12.853458,
              48.18998
            ],
            [
              12.862553,
              48.196646
            ],
            [
              12.877642,
              48.202046
            ],
            [
              12.93304,
              48.209255
            ],
            [
              12.931696,
              48.211632
            ],
            [
              12.947199,
              48.220004
            ],
            [
              12.979445,
              48.232018
            ],
            [
              13.016652,
              48.255893
            ],
            [
              13.032775,
              48.263567
            ],
            [
              13.136438,
              48.291059
            ],
            [
              13.274207,
              48.307104
            ],
            [
              13.307797,
              48.319636
            ],
            [
              13.405569,
              48.376583
            ],
            [
              13.419522,
              48.392164
            ],
            [
              13.425206,
              48.413377
            ],
            [
              13.42717,
              48.418544
            ],
            [
              13.431717,
              48.423712
            ],
            [
              13.436161,
              48.430585
            ],
            [
              13.438228,
              48.440998
            ],
            [
              13.436368,
              48.469239
            ],
            [
              13.438228,
              48.478541
            ],
            [
              13.442983,
              48.487713
            ],
            [
              13.456418,
              48.506653
            ],
            [
              13.459209,
              48.516394
            ],
            [
              13.457039,
              48.525179
            ],
            [
              13.447737,
              48.534816
            ],
            [
              13.443706,
              48.552851
            ],
            [
              13.440709,
              48.557347
            ],
            [
              13.440089,
              48.560965
            ],
            [
              13.445566,
              48.567915
            ],
            [
              13.454558,
              48.573445
            ],
            [
              13.487218,
              48.581584
            ],
            [
              13.520704,
              48.584581
            ],
            [
              13.624367,
              48.565357
            ],
            [
              13.641834,
              48.559104
            ],
            [
              13.65806,
              48.551094
            ],
            [
              13.673356,
              48.535488
            ],
            [
              13.714336,
              48.523215
            ],
            [
              13.716506,
              48.521691
            ],
            [
              13.725394,
              48.548381
            ],
            [
              13.733869,
              48.559776
            ],
            [
              13.73728,
              48.559259
            ],
            [
              13.758054,
              48.561481
            ],
            [
              13.766012,
              48.563755
            ],
            [
              13.77428,
              48.569207
            ],
            [
              13.779034,
              48.573935
            ],
            [
              13.796191,
              48.598585
            ],
            [
              13.802392,
              48.611711
            ],
            [
              13.805493,
              48.626129
            ],
            [
              13.806216,
              48.642923
            ],
            [
              13.800945,
              48.675221
            ],
            [
              13.803529,
              48.687159
            ],
            [
              13.816862,
              48.695582
            ],
            [
              13.804356,
              48.699561
            ],
            [
              13.799395,
              48.70261
            ],
            [
              13.78875,
              48.716511
            ],
            [
              13.783582,
              48.71527
            ],
            [
              13.785856,
              48.724779
            ],
            [
              13.815725,
              48.76643
            ],
            [
              13.855929,
              48.759299
            ],
            [
              13.874946,
              48.752426
            ],
            [
              13.893136,
              48.743021
            ],
            [
              13.915047,
              48.73098
            ],
            [
              13.98233,
              48.706485
            ],
            [
              13.991115,
              48.700181
            ],
            [
              14.007754,
              48.683283
            ],
            [
              14.014162,
              48.675066
            ],
            [
              14.019227,
              48.671914
            ],
            [
              14.026151,
              48.670054
            ],
            [
              14.032146,
              48.667263
            ],
            [
              14.034729,
              48.661165
            ],
            [
              14.032869,
              48.654912
            ],
            [
              14.028322,
              48.653982
            ],
            [
              14.023464,
              48.654602
            ],
            [
              14.020467,
              48.653155
            ],
            [
              14.013749,
              48.643802
            ],
            [
              14.008064,
              48.642407
            ],
            [
              14.006204,
              48.639513
            ],
            [
              14.010855,
              48.62587
            ],
            [
              14.015196,
              48.620393
            ],
            [
              14.032456,
              48.60613
            ],
            [
              14.040827,
              48.601169
            ],
            [
              14.07483,
              48.591712
            ],
            [
              14.216527,
              48.58117
            ],
            [
              14.315746,
              48.557916
            ],
            [
              14.325358,
              48.558639
            ],
            [
              14.333213,
              48.560706
            ],
            [
              14.353366,
              48.571429
            ],
            [
              14.405353,
              48.586286
            ],
            [
              14.421166,
              48.597061
            ],
            [
              14.443593,
              48.636516
            ],
            [
              14.458166,
              48.643182
            ],
            [
              14.482144,
              48.624475
            ],
            [
              14.522038,
              48.610626
            ],
            [
              14.53382,
              48.608714
            ],
            [
              14.548807,
              48.610936
            ],
            [
              14.578779,
              48.620496
            ],
            [
              14.594179,
              48.621323
            ],
            [
              14.600586,
              48.617576
            ],
            [
              14.601827,
              48.611194
            ],
            [
              14.60193,
              48.604528
            ],
            [
              14.605134,
              48.600316
            ],
            [
              14.612885,
              48.599748
            ],
            [
              14.628182,
              48.603288
            ],
            [
              14.634693,
              48.602332
            ],
            [
              14.645442,
              48.592901
            ],
            [
              14.651333,
              48.583496
            ],
            [
              14.659187,
              48.576959
            ],
            [
              14.675621,
              48.576235
            ],
            [
              14.69114,
              48.586535
            ],
            [
              14.695671,
              48.589542
            ],
            [
              14.700735,
              48.615535
            ],
            [
              14.700219,
              48.646102
            ],
            [
              14.703836,
              48.673103
            ],
            [
              14.722749,
              48.693411
            ],
            [
              14.775356,
              48.724004
            ],
            [
              14.78042,
              48.743124
            ],
            [
              14.790446,
              48.754855
            ],
            [
              14.794476,
              48.766999
            ],
            [
              14.800367,
              48.776507
            ],
            [
              14.815457,
              48.780331
            ],
            [
              14.86765,
              48.775577
            ],
            [
              14.919327,
              48.761573
            ],
            [
              14.939584,
              48.762813
            ],
            [
              14.951056,
              48.780331
            ],
            [
              14.948886,
              48.78214
            ],
            [
              14.944441,
              48.788599
            ],
            [
              14.940101,
              48.796506
            ],
            [
              14.938137,
              48.80281
            ],
            [
              14.93979,
              48.809787
            ],
            [
              14.947025,
              48.822396
            ],
            [
              14.968213,
              48.885028
            ],
            [
              14.968316,
              48.912674
            ],
            [
              14.964182,
              48.943267
            ],
            [
              14.964595,
              48.971741
            ],
            [
              14.978238,
              48.992825
            ],
            [
              14.977308,
              48.998251
            ],
            [
              14.977308,
              49.002902
            ],
            [
              14.978858,
              49.00626
            ],
            [
              14.982062,
              49.007914
            ],
            [
              15.003973,
              49.009774
            ],
            [
              15.059576,
              48.997217
            ],
            [
              15.137401,
              48.993031
            ],
            [
              15.144739,
              48.978924
            ],
            [
              15.148977,
              48.965333
            ],
            [
              15.148563,
              48.951742
            ],
            [
              15.141949,
              48.937479
            ],
            [
              15.161792,
              48.937221
            ],
            [
              15.238067,
              48.95076
            ],
            [
              15.243234,
              48.95293
            ],
            [
              15.257084,
              48.963886
            ],
            [
              15.260391,
              48.969157
            ],
            [
              15.262872,
              48.982593
            ],
            [
              15.266902,
              48.986675
            ],
            [
              15.27455,
              48.986727
            ],
            [
              15.279615,
              48.982593
            ],
            [
              15.283749,
              48.977632
            ],
            [
              15.28871,
              48.975358
            ],
            [
              15.335529,
              48.974996
            ],
            [
              15.357853,
              48.97081
            ],
            [
              15.405912,
              48.954687
            ],
            [
              15.450354,
              48.944817
            ],
            [
              15.471851,
              48.936704
            ],
            [
              15.521254,
              48.908489
            ],
            [
              15.544187,
              48.902612
            ],
            [
              15.603729,
              48.887353
            ],
            [
              15.681347,
              48.858466
            ],
            [
              15.703775,
              48.854952
            ],
            [
              15.726823,
              48.854952
            ],
            [
              15.743669,
              48.858466
            ],
            [
              15.779222,
              48.870868
            ],
            [
              15.787904,
              48.872263
            ],
            [
              15.818497,
              48.872315
            ],
            [
              15.824284,
              48.869421
            ],
            [
              15.828315,
              48.857122
            ],
            [
              15.833276,
              48.852006
            ],
            [
              15.840097,
              48.850094
            ],
            [
              15.859218,
              48.849164
            ],
            [
              15.870173,
              48.843893
            ],
            [
              15.877614,
              48.841671
            ],
            [
              15.885986,
              48.842033
            ],
            [
              15.875754,
              48.833093
            ],
            [
              15.888363,
              48.835056
            ],
            [
              15.899629,
              48.834746
            ],
            [
              15.90707,
              48.830251
            ],
            [
              15.908103,
              48.819709
            ],
            [
              15.925053,
              48.822602
            ],
            [
              15.930324,
              48.818313
            ],
            [
              15.930324,
              48.811389
            ],
            [
              15.931771,
              48.806428
            ],
            [
              16.032334,
              48.758059
            ],
            [
              16.085354,
              48.742866
            ],
            [
              16.177751,
              48.746535
            ],
            [
              16.317588,
              48.73284
            ],
            [
              16.318724,
              48.733512
            ],
            [
              16.339498,
              48.735579
            ],
            [
              16.352727,
              48.728448
            ],
            [
              16.358309,
              48.727259
            ],
            [
              16.374018,
              48.730308
            ],
            [
              16.384974,
              48.737078
            ],
            [
              16.43572,
              48.794542
            ],
            [
              16.453083,
              48.80219
            ],
            [
              16.481919,
              48.7994
            ],
            [
              16.492254,
              48.79971
            ],
            [
              16.510341,
              48.804774
            ],
            [
              16.519539,
              48.805601
            ],
            [
              16.528737,
              48.803534
            ],
            [
              16.545791,
              48.796299
            ],
            [
              16.605529,
              48.784827
            ],
            [
              16.624029,
              48.78338
            ],
            [
              16.643149,
              48.778264
            ],
            [
              16.651004,
              48.766223
            ],
            [
              16.654725,
              48.751857
            ],
            [
              16.661959,
              48.740023
            ],
            [
              16.675085,
              48.733926
            ],
            [
              16.691001,
              48.732065
            ],
            [
              16.729035,
              48.733099
            ],
            [
              16.744332,
              48.729637
            ],
            [
              16.780505,
              48.713772
            ],
            [
              16.798902,
              48.709224
            ],
            [
              16.818125,
              48.710775
            ],
            [
              16.856573,
              48.719818
            ],
            [
              16.873006,
              48.718991
            ],
            [
              16.896674,
              48.696977
            ],
            [
              16.910523,
              48.63078
            ],
            [
              16.945043,
              48.604166
            ]
          ]
        ]
      }
    }
  ]
};
