import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

import useSignedSource from "~/src/hooks/use-signed-source";

/**
 *
 * @param root0
 * @param root0.document
 * @param root0.className
 * @param root0.childElements
 */
export default function SignedDocumentLink({
  document,
  className,
  childElements,
  ...props
}) {
  const { src } = useSignedSource(document.file_name);

  return (
    <a
      href={src || ""}
      target="_blank"
      rel="noreferrer"
      className={cn(
        "flex items-center gap-x-1 font-medium text-primary hover:bg-gray-50 focus:outline-none pr-2",
        className
      )}
      {...props}
    >
      {(childElements) &&
       childElements
      }
      <ArrowTopRightOnSquareIcon className="shrink-0 w-5 h-5" />
    </a>
  );
}
