import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 */
export default function RechtsformSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const tempItems = tempQuery?.split(",") || [];

  const hasBuyable = items.includes("buyable");
  const hasRentable = items.includes("rentable");
  const hasBuyableRentable = items.includes("buyable_rentable");
  const hasUnknown = items.includes("unknown");

  const hasTempBuyable = tempItems.includes("buyable");
  const hasTempRentable = tempItems.includes("rentable");
  const hasTempBuyableRentable = tempItems.includes("buyable_rentable");
  const hasTempUnknown = tempItems.includes("unknown");

  function onCheck(event, key) {
    handleCheck("rechtsform", event, tempQuery, items, tempItems, key, update);
  }

  function checked(item, tempItem) {
    return handleCheckedStatus(query, item, tempQuery, tempItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Rechtsform
      </p>
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 font-light">
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasBuyable, hasTempBuyable)} onChange={(e) => onCheck(e, "buyable")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Eigentum</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasRentable, hasTempRentable)} onChange={(e) => onCheck(e, "rentable")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Miete</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasBuyableRentable, hasTempBuyableRentable)} onChange={(e) => onCheck(e, "buyable_rentable")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Eigentum & Miete</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasUnknown, hasTempUnknown)} onChange={(e) => onCheck(e, "unknown")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">nicht bekannt</span>
          </label>
        </div>
      </div>
    </div>
  );
}
