import ProjectFilterTags from "~/src/features/filter-tags/project-filter-tags";
import LegendBox from "~/src/features/legend-box";
import ProjectsMap from "~/src/features/maps/projects-map";
import ProjectCount from "~/src/features/project-count";
import StatsBox from "~/src/features/stats-box";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 */
const PrintOverviewPage = () => {
  const { query } = useProjectQueryParams();

  return (
    <>
      <style>
        {`
          *:not(html, #print, #print *) {
            all: unset !important;
          }
          *:not(html, html > body, html > body > div#root, #print, #print *) {
            display: none !important;
          }
        `}
      </style>
      <div className="space-y-16 space-y-reverse bg-white w-[200mm] my-0 mx-auto" id="print">
        <div className="flex flex-col items-center w-full gap-4 mt-2 mb-16">
          <LogoColor />
          <ProjectCount />
          <StatsBox />
          <ProjectFilterTags query={query} />
          <div className="w-full break-inside-avoid">
            <ProjectsMap
              className="h-[150mm] mb-4 border border-gray-200 rounded "
              isSimple
              forExport
            />
            <LegendBox />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintOverviewPage;
