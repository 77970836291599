import { marketingPhaseLabels } from "~/src/modules/labels";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function MarketingPhaseFilterTags({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const marketing_phase = queryItems.split(",");

  function handleClick(mp) {
    const newMP = marketing_phase.filter((item) => item !== mp).join(",");

    submit("marketing_phase", newMP);
  }

  return marketing_phase.map((mp, index) => (
    <DefaultFilterTag key={`mp_tag_${index}`} handleClick={() => handleClick(mp)}>
      <span>Vermarktungsphase: {marketingPhaseLabels[mp]}</span>
    </DefaultFilterTag>
  ));
}
