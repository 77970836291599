import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const trackingId = window.settings.REACT_APP_MATOMO_ID;

/**
 *
 */
export default function useAnaltytics() {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window._paq) {
        return;
      }
      if (!trackingId) {
        console.log(
          "Tracking not enabled, as `trackingId` was not given"
        );

        return;
      }

      window._paq.push([
        "setCustomUrl",
        location.pathname + location.search
      ]);
      window._paq.push(["trackPageView"]);
    });

    return unlisten;
  }, [listen]);
}
