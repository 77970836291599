import { rechtsformLabels } from "~/src/modules/labels";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function RechtsformFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const rechtsform = queryItems.split(",");

  function handleClick(rf) {
    const newRF = rechtsform.filter((item) => item !== rf).join(",");

    submit("rechtsform", newRF);
  }

  return rechtsform.map((rf, index) => (
    <DefaultFilterTag key={`rf_tag_${index}`} handleClick={() => handleClick(rf)}>
      <span>Rechtsform: {rechtsformLabels[rf]}</span>
    </DefaultFilterTag>
  ));
}
