import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.className
 */
export default function MarkerIconCircle({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={cn("!w-[1.8rem] !top-0", className)}>
      <circle cx="50" cy="50" r="50" fill="currentColor" />
      <circle cx="50" cy="50" r="25" fill="white" />
    </svg>
  );
}
