/* eslint-disable max-len */
/* eslint-disable import/max-dependencies */
import cn from "classnames";
import {
  useEffect, useRef, useState
} from "react";
import { MegaphoneIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import {
  camel, dash, shift
} from "radash";

import ExcelExportProjectsButton from "../excel-export-projects-button";
import PdfExportProjectsButton from "../pdf-export-projects-button";

import { formatEuro, pluralize } from "~/src/modules/formatters";
import ProjectCount from "~/src/features/project-count";
import { priceLabels } from "~/src/modules/labels";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useProjectsStats from "~/src/hooks/use-projects-stats";
import useStore from "~/src/hooks/use-store";
import useMediaQuery from "~/src/hooks/use-media-query";
import useUserSettings from "~/src/hooks/use-user-settings";
import CollapsibleContainer from "~/src/ui/containers/collapsible-container";

const StatsItem = ({
  children, statsLoaded, isSelected
}) => {
  if (statsLoaded) {
    return children;
  }

  return <div
    className={cn(
      "mt-0.5 h-[14px] w-[11rem] rounded animate-pulse hover:bg-gray-50",
      {
        "bg-gray-300": !isSelected
      }
    )}
  />;
};

const tooltipDelay = 500;

// eslint-disable-next-line max-lines-per-function
/**
 *
 * @param props
 * @param props.className
 */
// eslint-disable-next-line max-lines-per-function, max-statements
const StatsBox = ({
  className
}) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const isLaptop = useMediaQuery("(max-width: 1535px)");
  const isPrint = useMediaQuery("print");

  const { query, setQuery } = useProjectQueryParams();
  const {
    userSettings,
    mutate: mutateUsersettings,
    isLoading: userSettingsLoading,
    isError
  } = useUserSettings(isPrint, query?.userId);

  const { stats, mutate } = useProjectsStats({
    query,
    filtersetting: userSettings?.filtersetting
  });

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const toggleProjectSuggestionModal = useStore((state) => state.toggleProjectSuggestionModal);

  const toggleFilterBehaviorModal = useStore((state) => state.toggleFilterBehaviorModal);

  const [
    isHandleItemClickDisabled,
    setIsHandleItemClickDisable
  ] = useState(false);

  const handleItemClickTimeoutInterval = 1250;

  const [
    selectedItems,
    setSelectedItems
  ] = useState(query.stats ? query.stats.split(",") : []);

  const [
    tooltipOpen,
    setTooltipOpen
  ] = useState(false);

  const tooltipTimeout = useRef(null);

  const statsBoxCollapsed = useStore((state) => state.statsBoxCollapsed);
  const setStatsBoxCollapsed = useStore((state) => state.setStatsBoxCollapsed);

  const userClickedStatsBoxCollapse = useStore((state) => state.userClickedStatsBoxCollapse);
  const setUserClickedStatsBoxCollapse = useStore((state) => state.setUserClickedStatsBoxCollapse);

  // eslint-disable-next-line no-shadow
  const updateQueryFromStats = (query) => {
    setQuery(query);
  };

  useEffect(() => {
    if (selectedItems.length) {
      updateQueryFromStats({
        stats: selectedItems,
        page: 1
      });
    }
    else if (query.stats) {
      // eslint-disable-next-line no-undefined
      updateQueryFromStats({ stats: undefined });
    }
  }, [selectedItems]);

  useEffect(() => {
    if (!query.stats) {
      setSelectedItems([]);
    }
  }, [query]);

  useEffect(() => {
    if (!userClickedStatsBoxCollapse) {
      setStatsBoxCollapsed(isLaptop);
      clearTimeout(tooltipTimeout.current);
      setTooltipOpen(false);
    }
  }, [isLaptop]);

  const handleItemClick = (label) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    if (selectedItems.includes(label)) {
      setSelectedItems(selectedItems.filter((item) => item !== label));
    }
    else {
      setSelectedItems([
        ...selectedItems,
        label
      ]);
    }

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  useEffect(() => {
    if (selectionMutate) {
      const mutating = async () => {
        await mutate();
        setSelectionMutate(false);

        if (selectionLoading) {
          setSelectionLoading(false);
        }
      };

      mutating();
    }
  }, [selectionMutate]);

  useEffect(() => {
    mutateUsersettings();
  }, [
    query,
    selectionMutate,
    selectedItems
  ]);

  const statsBlocks = [
    {
      items: [
        {
          className: "w-full min-w-[13.5rem] max-w-[17rem]",
          checkboxClassName: "text-primary-lighter",
          valueKey: "offerPriceEigennutzerAverage",
          countKey: "offerPriceEigennutzerCount",
          backgroundColor: "bg-primary-lighter"
        },
        {
          className: "w-full min-w-[14.75rem] max-w-[17rem]",
          checkboxClassName: "text-primary-lighter",
          valueKey: "offerPriceInvestorAverage",
          countKey: "offerPriceInvestorCount",
          backgroundColor: "bg-primary-lighter"
        },
        {
          className: "w-full min-w-[12.5rem] max-w-[17rem]",
          checkboxClassName: "text-primary-lighter",
          valueKey: "rentAverage",
          countKey: "rentCount",
          backgroundColor: "bg-primary-lighter"
        }
      ]
    },
    {
      items: [
        {
          className: "w-full min-w-[13.5rem] max-w-[17rem]",
          checkboxClassName: "text-primary-green",
          valueKey: "salePriceEigennutzerAverage",
          countKey: "salePriceEigennutzerCount",
          backgroundColor: "bg-primary-green"
        },
        {
          className: "w-full min-w-[14.75rem] max-w-[17rem]",
          checkboxClassName: "text-primary-green",
          valueKey: "salePriceInvestorAverage",
          countKey: "salePriceInvestorCount",
          backgroundColor: "bg-primary-green"
        },
        {
          className: "w-full min-w-[12.5rem] max-w-[17rem]",
          checkboxClassName: "text-primary-yellow",
          valueKey: "gkaAverage",
          countKey: "gkaCount",
          backgroundColor: "bg-primary-yellow"
        }
      ]
    }
  ]
    .map(({ items }, index) => (
      <div className="flex h-full flex-col gap-x-3 gap-y-2 @[702px]:flex-row print:flex-row" key={index}>
        {
          items.map(({
            className: innerClassName, checkboxClassName, valueKey, countKey, backgroundColor
          }, blockIndex) => {
            const value = stats?.[valueKey];
            const count = stats?.[countKey];

            const labelKey = camel(
              shift(
                dash(valueKey)
                  .split("-")
                  .map((part) => {
                    switch (part) {
                      case "eigennutzer":
                        return "normal";
                      case "investor":
                        return "investorNet";
                      case "rent":
                        return "rentNet";
                      default:
                        return part;
                    }
                  }),
                1
              )
                .join("-")
            );

            const label = priceLabels[labelKey];

            const isSelected = Boolean(selectedItems.includes(valueKey));

            return (
              <div
                className={cn(
                  "flex justify-between hover:bg-gray-50 rounded-md group cursor-pointer",
                  {
                    "hover:bg-gray-50": !isSelected,
                    "bg-gray-100": isSelected
                    // "cursor-wait": isHandleItemClickDisabled,
                    // "cursor-pointer": !isHandleItemClickDisabled
                  },
                  innerClassName
                )}
                key={blockIndex}
                onClick={() => handleItemClick(valueKey)}
              >
                <div className="flex flex-row justify-between gap-2">
                  <div className={`self-center overflow-hidden w-1 ml-2 h-full max-h-[70%] ${backgroundColor}`} />
                  <div className="flex flex-col justify-between py-2">
                    <dt
                      className="truncate text-xs font-light text-gray-500 print:font-normal print:text-gray-700"
                    >
                      {label}
                    </dt>
                    <StatsItem
                      isSelected={isSelected}
                      statsLoaded={stats && !selectionLoading}>
                      <dd className="truncate text-xs font-semibold text-gray-900">
                        {(value)
                          ? <span>
                            <span>
                              {`${formatEuro(value)} / m²`}
                            </span>
                            <span> </span>
                            <span className="text-xs font-light">
                              ({count?.toLocaleString("de") ?? 0} {pluralize("Projekt", "Projekte", "Projekte", count)})
                            </span>
                          </span>
                          : null
                        }
                        {!value &&
                          <span className="text-xs font-light">-</span>
                        }
                      </dd>
                    </StatsItem>
                  </div>

                </div>
                <input
                  type="checkbox"
                  readOnly={true}
                  id={blockIndex}
                  checked={isSelected}
                  className={cn(
                    "w-4 h-4 mt-2 mr-2 rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 cursor-pointer",
                    {
                      hidden: !isSelected,
                      [checkboxClassName]: isSelected
                    }
                  )}
                />
              </div>
            );
          })
        }
      </div>
    ));

  const userInteractionButtons = () => <>
    <PdfExportProjectsButton />
    <ExcelExportProjectsButton />
    <button className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-400 p-1 hover:bg-gray-500"
      onClick={toggleProjectSuggestionModal}
    >
      <MegaphoneIcon
        className="w-full text-white"
        aria-hidden="true"
      />
    </button>
    <button
      className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 disabled:cursor-wait disabled:grayscale"
      onClick={toggleFilterBehaviorModal}
      disabled={userSettingsLoading}
    >
      <EllipsisVerticalIcon className="w-full self-center p-[1.5px] font-extrabold text-gray-500" />
    </button>
  </>;

  return (
    <CollapsibleContainer
      position="bottom"
      custom
      className={cn(
        "flex flex-col h-full w-full print:h-auto group relative items-center px-2.5 bg-white border border-gray-200 rounded @[1080px]:flex-row print:flex-row print:px-0 print:border-none min-h-0",
        className,
        {
          "@[1080px]:h-[130px] @[1080px]:gap-0": !(statsBoxCollapsed && !isPrint)
        }
      )}
      collapsedClassName={cn(
        "flex flex-col h-full w-full print:h-auto group relative items-center px-2.5 bg-white border border-gray-200 rounded @[1080px]:flex-row print:flex-row print:px-0 print:border-none",
        className,
        {
          "@[1080px]:h-[130px] @[1080px]:gap-0": !(statsBoxCollapsed && !isPrint)
        }
      )}
      data-collapsed={statsBoxCollapsed && !isPrint}
      collapsed={statsBoxCollapsed && !isPrint}
      onChange={(collapsed) => {
        setStatsBoxCollapsed(collapsed);

        setUserClickedStatsBoxCollapse(true);
      }}
      tooltipTexts={{
        collapsed: "Durchschnittswerte anzeigen",
        expanded: "Durchschnittswerte ausblenden"
      }}
      active={!isPrint}
    >
      <div
        className={cn(
          "flex-col h-full py-2.5 pr-2.5 @[1080px]:pr-0 justify-between w-full gap-2.5 @[1080px]:w-4/6 print:w-full @[1080px]:print:w-full print:py-0 flex",
          {
            hidden: statsBoxCollapsed && !isPrint
          }
        )}
      >
        {statsBlocks}
      </div>
      {
        !isPrint && (
          <>
            <div
              className={cn(
                "flex flex-col justify-between w-full h-full pb-3.5 pt-3.5",
                {
                  "pt-0 @[1080px]:pb-[13px] @[1080px]:pt-[18px] @[1080px]:items-end @[1080px]:w-2/6": !(statsBoxCollapsed && !isPrint)
                }
              )}
            >
              <div
                className={cn(
                  "hidden gap-2",
                  {
                    "@[1080px]:flex": !(statsBoxCollapsed && !isPrint)
                  }
                )}
              >
                {
                  (isMobile)
                    ? (
                      <button
                        className="w-[26px]"
                        onClick={toggleFilterBehaviorModal}
                        disabled={userSettingsLoading}
                      >
                        <EllipsisVerticalIcon className="h-8 w-8 font-extrabold text-gray-500" />
                      </button>
                    )
                    : (
                      <div className="flex items-center gap-2">
                        {
                          userInteractionButtons()
                        }
                      </div>
                    )
                }
              </div>
              <div
                className={cn(
                  "ml-2 flex items-center justify-between",
                  {
                    "@[1080px]:mx-0": !(statsBoxCollapsed && !isPrint)
                  }
                )}
              >
                <div className="flex items-center gap-4 pr-2">
                  <ProjectCount />
                  {
                    (statsBoxCollapsed && selectedItems.length > 0) && (
                      <div className="flex items-center gap-1 rounded bg-primary px-0.5 py-px text-white">
                        <InformationCircleIcon className="h-4 w-4" />
                        <span className="text-xs">Statistik-Buttons aktiv</span>
                      </div>
                    )
                  }

                </div>
                {
                  (isMobile)
                    ? (
                      <button
                        className="w-[26px]"
                        onClick={toggleFilterBehaviorModal}
                        disabled={userSettingsLoading}
                      >
                        <EllipsisVerticalIcon className="h-8 w-8 font-extrabold text-gray-500" />
                      </button>
                    )
                    : <div
                      className={cn(
                        "flex items-center gap-2",
                        {
                          "@[1080px]:hidden": !(statsBoxCollapsed && !isPrint)
                        }
                      )}
                    >
                      {
                        userInteractionButtons()
                      }
                    </div>
                }
              </div>
            </div>
          </>
        )
      }
    </CollapsibleContainer>
  );
};

export default StatsBox;
