import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { Link, useLocation } from "react-router-dom";

/**
 *
 */
export default function BackLink() {
  const { state } = useLocation();

  const linkOptions = {
    pathname: state?.pathname || "/karte",
    search: state?.backlink || state?.search
  };

  return (
    <Link
      to={linkOptions}
      className="flex items-center text-sm font-medium text-gray-200 hover:text-white focus:outline-none gap-2"
    >
      <ArrowLongLeftIcon className="w-5 h-5" />
      <span className="whitespace-nowrap">
        zurück zur Übersicht
      </span>
    </Link>
  );
}
