import ProjectPhaseModal from "./modals/project-phase-modal";
import FinishYearModal from "./modals/finish-year-modal";
import QuantityModal from "./modals/quantity-modal";
import RechstformModal from "./modals/rechtsform-modal";
import FundingModal from "./modals/funding-modal";
import TransactionModal from "./modals/transaction-modal";
import ConstructionTypeModal from "./modals/construction-type-modal";
import ObjectTypeModal from "./modals/object-type-modal";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 * @param root0.submit
 * @param root0.cancelSearchFilter
 * @param root0.filterModals
 */
export default function SearchFilterModals({
  query, tempQuery, update, submit, cancelSearchFilter, filterModals
}) {
  const {
    finishYearModal, setFinishYearModal,
    projectPhaseModal, setProjectPhaseModal,
    quantityModal, setQuantityModal,
    objectTypeModal, setObjectTypeModal,
    rechtsformModal, setRechtsformModal,
    transactionModal, setTransactionModal,
    fundingModal, setFundingModal,
    constructionTypeModal, setConstructionTypeModal
  } = filterModals;

  return (
    <>
      <FinishYearModal isActive={finishYearModal} hide={() => setFinishYearModal(false)} finish_year={query.finish_year} tempQuery={tempQuery} update={update} submit={submit} cancelSearchFilter={cancelSearchFilter} />
      <ProjectPhaseModal isActive={projectPhaseModal} hide={() => setProjectPhaseModal(false)} query={query} tempQuery={tempQuery} update={update} submit={submit} cancelSearchFilter={cancelSearchFilter} />
      <QuantityModal isActive={quantityModal} hide={() => setQuantityModal(false)} query={query} tempQuery={tempQuery} update={update} submit={submit} cancelSearchFilter={cancelSearchFilter} />
      <RechstformModal isActive={rechtsformModal} hide={() => setRechtsformModal(false)} query={query} tempQuery={tempQuery} update={update} submit={submit} cancelSearchFilter={cancelSearchFilter} />
      <FundingModal isActive={fundingModal} hide={() => setFundingModal(false)} query={query} tempQuery={tempQuery} update={update} submit={submit} cancelSearchFilter={cancelSearchFilter} />
      <TransactionModal isActive={transactionModal} hide={() => setTransactionModal(false)} query={query} tempQuery={tempQuery} update={update} submit={submit} cancelSearchFilter={cancelSearchFilter} />
      <ConstructionTypeModal isActive={constructionTypeModal} hide={() => setConstructionTypeModal(false)} query={query} tempQuery={tempQuery} update={update} submit={submit} cancelSearchFilter={cancelSearchFilter} />
      <ObjectTypeModal isActive={objectTypeModal} hide={() => setObjectTypeModal(false)} query={query} tempQuery={tempQuery} update={update} submit={submit} cancelSearchFilter={cancelSearchFilter} />
    </>
  );
}
