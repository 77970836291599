/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import {
  Dialog, Transition, RadioGroup
} from "@headlessui/react";
import {
  Fragment, useState
  , useEffect
} from "react";
import cn from "classnames";

import useStore from "~/src/hooks/use-store";
import useUserSettings from "~/src/hooks/use-user-settings";
import API from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.isOpen
 */
export default function FilterBehaviorModal({ isOpen = false }) {
  const { userSettings, mutate } = useUserSettings();
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const [
    selectedOption,
    setSelectedOption
  ] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setSelectedOption(userSettings.filtersetting);
      mutate();
    }
  }, [isOpen]);

  useEffect(() => {
    if (userSettings) {
      setSelectedOption(userSettings.filtersetting);
    }
  }, [userSettings]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const [
    isRequestSubmitting,
    setIsRequestSubmitting
  ] = useState(false);

  const toggleFilterBehaviorModal = useStore((state) => state.toggleFilterBehaviorModal);

  const timeOutSetting = 800;

  const handleClose = function handleClose() {
    toggleFilterBehaviorModal();
    setTimeout(() => {
      setIsRequestSubmitting(false);
    }, timeOutSetting);
  };

  const onSubmit = function onSubmit(event) {
    event.preventDefault();
    setIsRequestSubmitting(true);

    API.put("/user-settings/filter-update", {
      // eslint-disable-next-line camelcase
      user_settings: {
        filtersetting: selectedOption
      }
    })
      .finally(() => {
        handleClose();
        mutate();
        setSelectionMutate(true);
      });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-full px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <form onSubmit={onSubmit}>
                <Dialog.Title as="h3" className="flex justify-center  w-full text-lg font-medium leading-6 text-gray-900">
                  Einstellung der Statistik-Buttons
                </Dialog.Title>

                <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                  <RadioGroup.Label className="sr-only">Option Filter</RadioGroup.Label>
                  <div className="flex flex-col mt-5 sm:mt-6 gap-y-5">
                    <div className="flex flex-col gap-y-2 cursor-pointer"
                      onClick={() => handleOptionChange("standard")}
                    >
                      <RadioGroup.Option value="standard">
                        {({ checked }) => (
                          <div className="flex items-center gap-2">
                            <span
                              className={cn("h-5 w-5 flex items-center justify-center text-center rounded-full border", {
                                "bg-white border-gray-300": !checked,
                                "bg-primary border-transparent": checked
                              })}
                              aria-hidden="true"
                            >
                              <span className="w-2 h-2 bg-white rounded-full" />
                            </span>
                            <div className="font-medium" >
                            Standardauswahl
                            </div>
                          </div>
                        )}
                      </RadioGroup.Option>
                      <div className="ml-[21px] px-2 py-[5px] rounded-lg bg-gray-50 text-sm text-gray-800 text-left">
                        <>
                        Die Standardauswahl ermöglicht es Ihnen, unterschiedliche Statistik-Buttons gleichzeitig zu berücksichtigen.
                        Sie können sich beispielsweise sowohl Projekte mit einem berechneten Grundkostenanteil{" "}
                          <span className="font-bold">als auch</span> Projekte mit Verkaufspreisen anzeigen lassen.
                        </>
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2 cursor-pointer"
                      onClick={() => handleOptionChange("detail")}
                    >
                      <RadioGroup.Option value="detail">
                        {({ checked }) => (
                          <div className="flex items-center gap-2" >
                            <span
                              className={cn("h-5 w-5 flex items-center justify-center text-center rounded-full border", {
                                "bg-white border-gray-300": !checked,
                                "bg-primary border-transparent": checked
                              })}
                              aria-hidden="true"
                            >
                              <span className="w-2 h-2 bg-white rounded-full" />
                            </span>
                            <div
                              className="font-medium " >
                            Detailauswahl
                            </div>
                          </div>
                        )}
                      </RadioGroup.Option>
                      <div className="ml-[21px] px-2 py-[5px] rounded-lg bg-gray-50 text-sm text-gray-800 text-left">
                        <>
                        Mit der Detailauswahl können Sie Projekte rausfiltern, die mehrere Kriterien der Statistik-Buttons erfüllen müssen.
                        Sie können beispielsweise Projekte herausfiltern, die über einen berechneten Grundkostenanteil{" "}
                          <span className="font-bold">sowie</span> Objekte mit Verkaufspreis Eigennutzung verfügen.
                        </>
                      </div>
                    </div>

                  </div>
                </RadioGroup>

                <div className="flex justify-between w-full mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="py-2 text-sm font-medium text-gray-500 hover:text-gray-800 underline"
                  >
                    Schließen
                  </button>

                  {isRequestSubmitting &&
                    <button
                      type="submit"
                      disabled
                      className="inline-flex justify-center py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm cursor-not-allowed w-44 bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-lighter"
                    >
                      <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>wird gesendet...</span>
                    </button>
                  }
                  {!isRequestSubmitting &&
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm w-44 bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-lighter"
                    >
                      Änderungen speichern
                    </button>
                  }
                </div>

              </form>
            </div>

          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
