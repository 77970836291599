/* eslint-disable max-len */
import useMediaQuery from "~/src/hooks/use-media-query.js";
import CloseIcon from "~/src/ui/icons/close-icon/index.jsx";

/**
 *
 * @param root0
 * @param root0.label
 * @param root0.slug
 * @param root0.handleClick
 */
export default function CompanyFilterTag({
  label, slug, handleClick
}) {
  const isPrint = useMediaQuery("print");

  if (!label || !slug) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className="flex items-center gap-x-1 text-xs px-1.5 py-0.5 rounded bg-gray-100 print:bg-white text-secondary-darkest shadow hover:bg-gray-200 focus:outline-none print:shadow-none print:border print:border-gray-200">
      <span>Unternehmen: {label}</span>
      {
        !isPrint && <CloseIcon className="-mr-0.5 w-4 h-4 text-secondary-darkest" />
      }
    </button>
  );
}
