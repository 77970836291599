/* eslint-disable max-len */

import Page from "~/src/ui/page/index.jsx";
import ProjectCount from "~/src/features/project-count/index.jsx";
import ProjectsFiltersTile from "~/src/features/projects-filters-tile/index.jsx";
import ProjectsMapTile from "~/src/features/projects-map-tile/index.jsx";
import StatsBox from "~/src/features/stats-box/index.jsx";
import useMediaQuery from "~/src/hooks/use-media-query.js";

/**
 *
 */
const KartePage = () => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <Page
      title="Karte"
      className="h-[calc(100%-3.5rem)] lg:h-auto"
    >
      <div className="flex h-full max-h-full flex-row gap-4 overflow-hidden lg:h-[calc(100vh-3rem)] lg:max-h-[calc(100vh-3rem)] lg:p-4">
        <ProjectsFiltersTile />

        <div className="flex w-full flex-col justify-between lg:gap-4">
          {
            !isMobile && (
              <div className="z-20 hidden @container lg:flex min-h-0 shrink-0 grow-0">
                <StatsBox />
              </div>
            )
          }
          {
            isMobile && (
              <div className="flex w-full p-4 lg:hidden lg:p-0">
                <ProjectCount className="w-full" />
              </div>
            )
          }

          <div className="z-10 flex h-full flex-col gap-1 min-h-0 grow-0">
            <ProjectsMapTile />
          </div>

        </div>
      </div>
    </Page>
  );
};

export default KartePage;
