import RegionFilterTag from "~/src/features/filters/share-deal-region-filter-tag";
import { formatMonth } from "~/src/modules/formatters";
import SingleFilterTag from "~/src/ui/search-filter-tags/single-filter-tag";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.onSubmit
 * @param root0.handleClearFilters
 */
export default function ShareDealFilterTags({
  query, onSubmit, handleClearFilters
}) {
  const activeFilters = [];

  Object.entries(query).forEach(([
    key,
    val
  ]) => {
    if (![
      "page",
      "direction",
      "sortBy",
      "sortByTransaction",
      "type",
      "automated",
      "selection"
    ].includes(key) && val !== undefined) {
      if ([
        "company",
        "cat",
        "purchaseType",
        "dateFrom",
        "dateTo"
      ].includes(key) && val !== undefined) {
        activeFilters.push(key);
      }
    }
  });

  return (
    <div className="flex flex-wrap items-center justify-start gap-4 min-h-10">
      <SingleFilterTag label={query.cat} show={query.catId && query.cat} onClick={() => onSubmit({
        catId: undefined,
        cat: undefined
      })} />
      <SingleFilterTag label={`Unternehmen: ${query.company}`} show={query.company} onClick={() => onSubmit({ company: undefined })} />
      <SingleFilterTag label={query.purchaseType} show={query.purchaseTypeId && query.purchaseType} onClick={() => onSubmit({
        purchaseTypeId: undefined,
        purchaseType: undefined
      })} />
      <SingleFilterTag label={`Datum von: ${formatMonth(query.dateFrom)}`} show={query.dateFrom} onClick={() => onSubmit({
        dateFrom: undefined,
        direction: query.dateTo ? "desc" : undefined
      })} />
      <SingleFilterTag label={`Datum bis: ${formatMonth(query.dateTo)}`} show={query.dateTo} onClick={() => onSubmit({
        dateTo: undefined,
        direction: query.dateFrom ? "asc" : undefined
      })} />
      {query.type !== "transaktion" &&
        <RegionFilterTag queryRegions={query.regions} submit={onSubmit} />
      }

      {(activeFilters.length > 0 || (query.type !== "transaktion" && query.regions.length > 0)) &&
        <button type="button" onClick={handleClearFilters} className="mt-0.5 ml-2 focus:outline-none tracking-tight text-sm font-semibold hover:underline text-primary">
          Filter zurücksetzen
        </button>
      }
    </div>
  );
}
