import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 */
export default function ObjectTypeModal({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const tempItems = tempQuery?.split(",") || [];

  const hasWohnung = items.includes("wohnung");
  const hasEinfamilienhaus = items.includes("einfamilienhaus");
  const hasDoppelhaushaelfte = items.includes("doppelhaushaelfte");
  const hasReihenhaus = items.includes("reihenhaus");
  const hasTownHouse = items.includes("townhouse");
  const hasServicedApartment = items.includes("servicedapartment");
  const hasStudentenheimplatz = items.includes("studentenheimplatz");
  const hasBetreutesWohnen = items.includes("betreuteswohnen");

  const hasTempWohnung = tempItems.includes("wohnung");
  const hasTempEinfamilienhaus = tempItems.includes("einfamilienhaus");
  const hasTempDoppelhaushaelfte = tempItems.includes("doppelhaushaelfte");
  const hasTempReihenhaus = tempItems.includes("reihenhaus");
  const hasTempTownHouse = tempItems.includes("townhouse");
  const hasTempServicedApartment = tempItems.includes("servicedapartment");
  const hasTempStudentenheimplatz = tempItems.includes("studentenheimplatz");
  const hasTempBetreutesWohnen = tempItems.includes("betreuteswohnen");

  function onCheck(event, key) {
    handleCheck("objekttyp", event, tempQuery, items, tempItems, key, update);
  }

  function checked(item, tempItem) {
    return handleCheckedStatus(query, item, tempQuery, tempItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Objektarten
      </p>
      <div className="grid grid-cols-1 mt-6 font-light sm:grid-cols-2 gap-x-2 gap-y-4">

        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasWohnung, hasTempWohnung)} onChange={(e) => onCheck(e, "wohnung")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Wohnung</span>
          </label>
        </div>

        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasEinfamilienhaus, hasTempEinfamilienhaus)} onChange={(e) => onCheck(e, "einfamilienhaus")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Einfamilienhaus</span>
          </label>
        </div>

        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasDoppelhaushaelfte, hasTempDoppelhaushaelfte)} onChange={(e) => onCheck(e, "doppelhaushaelfte")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Doppelhaushälfte</span>
          </label>
        </div>

        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasReihenhaus, hasTempReihenhaus)} onChange={(e) => onCheck(e, "reihenhaus")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Reihenhaus</span>
          </label>
        </div>

        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasTownHouse, hasTempTownHouse)} onChange={(e) => onCheck(e, "townhouse")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Town House</span>
          </label>
        </div>

        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasServicedApartment, hasTempServicedApartment)} onChange={(e) => onCheck(e, "servicedapartment")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Serviced Apartment</span>
          </label>
        </div>

        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasStudentenheimplatz, hasTempStudentenheimplatz)} onChange={(e) => onCheck(e, "studentenheimplatz")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Studentenheimplatz</span>
          </label>
        </div>

        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasBetreutesWohnen, hasTempBetreutesWohnen)} onChange={(e) => onCheck(e, "betreuteswohnen")} type="checkbox" className="w-6 h-6 border border-gray-400 rounded text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Betreutes Wohnen</span>
          </label>
        </div>
      </div>
    </div>
  );
}
