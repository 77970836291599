import { marketingPhaseLabels } from "~/src/modules/labels";
import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 */
export default function MarketingPhaseSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const tempItems = tempQuery?.split(",") || [];

  const hasActive = items.includes("marketing_phase_active");
  const hasComingSoon = items.includes("marketing_phase_coming_soon");
  const hasProjectFinished = items.includes("marketing_phase_project_finished");
  const hasUnknown = items.includes("unknown");

  const hasTempActive = tempItems.includes("marketing_phase_active");
  const hasTempComingSoon = tempItems.includes("marketing_phase_coming_soon");
  const hasTempProjectFinished = tempItems.includes("marketing_phase_project_finished");
  const hasTempUnknown = tempItems.includes("unknown");

  function onCheck(event, key) {
    handleCheck("marketing_phase", event, tempQuery, items, tempItems, key, update);
  }

  function checked(item, tempItem) {
    return handleCheckedStatus(query, item, tempQuery, tempItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Vermarktungsphase
      </p>
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 font-light">
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasComingSoon, hasTempComingSoon)} onChange={(e) => onCheck(e, "marketing_phase_coming_soon")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{marketingPhaseLabels.marketing_phase_coming_soon}</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasActive, hasTempActive)} onChange={(e) => onCheck(e, "marketing_phase_active")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{marketingPhaseLabels.marketing_phase_active}</span>
          </label>
        </div>
        {/* <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasCompletedApartments, hasTempCompletedApartments)} onChange={(e) => onCheck(e, "marketing_phase_completed_apartments")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Wohneinheiten vermarktet</span>
          </label>
        </div> */}
        {/* <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasCompletedAll, hasTempCompletedAll)} onChange={(e) => onCheck(e, "marketing_phase_completed_all")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Alle Einheiten vermarktet</span>
          </label>
        </div> */}
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasProjectFinished, hasTempProjectFinished)} onChange={(e) => onCheck(e, "marketing_phase_project_finished")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{marketingPhaseLabels.marketing_phase_project_finished}</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasUnknown, hasTempUnknown)} onChange={(e) => onCheck(e, "unknown")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">{marketingPhaseLabels.unknown}</span>
          </label>
        </div>
      </div>
    </div>
  );
}
