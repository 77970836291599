/**
 *
 * @param props
 * @param props.children
 * @param props.image
 */
const ContentContainer = ({
  image,
  children,
  ...props
}) => (
  <article className="relative flex flex-col w-full gap-6 p-4 overflow-hidden bg-white border border-gray-200 sm:rounded" {...props}>
    {
      image && (
        <figure className="self-center md:absolute top-4 right-4">
          {image}
        </figure>
      )
    }
    {children}
  </article>
);

export default ContentContainer;
