import { useState } from "react";
import { Redirect } from "react-router";
import {
  useQueryParams, StringParam, NumberParam, DelimitedNumericArrayParam, withDefault
} from "use-query-params";
import animateScrollTo from "animated-scroll-to";

import useAccountRegions from "~/src/hooks/use-account-regions";
import useInsolvencies from "~/src/hooks/use-insolvencies";
import { pluralize } from "~/src/modules/formatters";
import Page from "~/src/ui/page";
import RegionFilterModal from "~/src/features/region-filter-modal";
import DateInput from "~/src/ui/inputs/date-input";
import FilterButton from "~/src/ui/buttons/filter-button";
import InsolvencyList from "~/src/features/insolvency-list";
import Pagination from "~/src/features/pagination";
import SortPurchasesDirection from "~/src/features/sort-purchases-direction";
import InsolvencyCategorySelect from "~/src/ui/insolvency-category-select";
import InsolvencyDateToggle from "~/src/features/insolvency-date-toggle";
import InsolvencyPropertyList from "~/src/features/insolvency-property-list";
import InsolvencyCompanySelect from "~/src/ui/selects/insolvency-company-select";
import InsolvencyFilterTags from "~/src/features/filter-tags/insolvency-filter-tags";
import SortInsolvenciesDropdown from "~/src/features/sort-insolvencies-dropdown";
import SortInsolvencyPropertiesDropdown from "~/src/features/sort-insolvency-properties-dropdown";
import { updateQueryFromTemp } from "~/src/modules/query-helper";

/**
 *
 */
export default function InsolvenciesPage() {
  const [
    query,
    setQuery
  ] = useQueryParams({
    cat: StringParam,
    catId: NumberParam,
    company: StringParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    dateType: StringParam,
    direction: StringParam,
    page: NumberParam,
    regions: withDefault(DelimitedNumericArrayParam, []),
    sortBy: StringParam,
    sortByInsolvency: StringParam,
    sortByProperty: StringParam,
    type: StringParam
  });

  const [
    tempQuery,
    setTempQuery
  ] = useState({});

  const { account_regions } = useAccountRegions();
  const {
    insolvencies, pagination, sortDirection, isLoading, isError
  } = useInsolvencies({ query });
  const [
    regionFilterModal,
    setRegionFilterModal
  ] = useState(false);

  if (isError) {
    <Redirect to="/error" />;
  }

  function handlePageChange(newPage) {
    setQuery({ page: newPage });
    setTimeout(() => {
      animateScrollTo(0, {
        speed: 120
      });
    }, 120);
  }

  function handleClearFilters() {
    setQuery({
      ...query,
      page: 1,
      direction: undefined,
      catId: undefined,
      cat: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      regions: undefined,
      company: undefined
    });
  }

  function handleSubmit(val) {
    setQuery({
      ...val,
      page: 1
    });
  }

  function updateTempQuery(key, value) {
    const q = {
      ...tempQuery,
      [key]: value
    };

    setTempQuery(q);
  }

  function handleSubmitQuery() {
    const q = updateQueryFromTemp(query, tempQuery);

    setQuery(q);
    setTempQuery({});
  }

  function cancelRegionFilter() {
    const q = {
      ...tempQuery
    };

    delete q.regions;
    setTempQuery(q);
  }

  return (
    <Page title="Insolvenzen">

      <RegionFilterModal account_regions={account_regions} active={regionFilterModal} hide={() => setRegionFilterModal(false)} queryRegion={query.regions} tempQuery={tempQuery} update={updateTempQuery} submit={handleSubmitQuery} cancelRegionFilter={cancelRegionFilter} queryKey="regions" />

      <div className="px-4 mx-auto xl:container lg:px-16">
        <h2 className="mt-8 text-3xl font-medium text-gray-700">Insolvenzen</h2>
      </div>

      <div className="px-4 py-5 mx-auto mt-1 xl:container lg:px-16">

        <div className="items-center mt-4 sm:flex">

          <p className="font-semibold text-gray-700">Aufgelistet nach:</p>

          <div className="flex items-center mt-2 ml-8 sm:mt-0">
            <input
              checked={query?.type !== "liegenschaft"}
              onChange={() => handleSubmit({
                type: "insolvenz",
                sortByProperty: undefined
              })}
              id="show-companies"
              name="show-companies"
              type="radio"
              className="w-5 h-5 border-gray-300 cursor-pointer focus:ring-0 text-primary"
            />
            <label htmlFor="show-companies" className="block ml-3 text-gray-700 cursor-pointer">
              Insolvenzen
            </label>
          </div>

          <div className="flex items-center ml-8">
            <input
              checked={query?.type === "liegenschaft"}
              onChange={() => handleSubmit({
                type: "liegenschaft",
                sortByInsolvency: undefined
              })}
              id="show-liegenschaften"
              name="show-liegenschaften"
              type="radio"
              className="w-5 h-5 border-gray-300 cursor-pointer focus:ring-0 text-primary"
            />
            <label htmlFor="show-liegenschaften" className="block ml-3 text-gray-700 cursor-pointer">
              Liegenschaften
            </label>
          </div>

        </div>

        <div className="items-center justify-between w-full mt-10 sm:flex">

          <div>
            <div className="items-center lg:flex">

              <div className="w-full sm:w-140">
                <InsolvencyCompanySelect onUpdate={(searchTerm) => handleSubmit({ company: searchTerm })} />
              </div>

              {query?.type !== "liegenschaft" &&
                <div className="w-full mt-2 lg:mt-0 lg:ml-2 lg:w-80">
                  <InsolvencyCategorySelect onUpdate={(id, label) => setQuery({
                    catId: id,
                    cat: label
                  })} />
                </div>
              }

              {query?.type === "liegenschaft" &&
                <div className="lg:ml-6 mt-4 lg:mt-0.5">
                  <FilterButton title="Regionen" onClick={() => setRegionFilterModal(true)} />
                </div>
              }
            </div>

            <div className="items-center h-full mt-5 lg:flex gap-x-3">
              <div className="flex items-center justify-start lg:justify-center gap-x-3">
                <DateInput name="dateFrom" hasOtherDate={query?.dateTo} maxDate={query?.dateTo ? new Date(query.dateTo) : new Date()} minDate={new Date("2006-01-01")} placeholder="Datum von" onChange={handleSubmit} />
                <DateInput name="dateTo" hasOtherDate={query?.dateFrom} minDate={query?.dateFrom ? new Date(query.dateFrom) : new Date("2006-01-01")} maxDate={new Date()} placeholder="Datum bis" onChange={handleSubmit} />
              </div>
              <div className="mt-8 lg:mt-0 lg:ml-2">
                <InsolvencyDateToggle searchType={query?.type} dateType={query?.dateType} onChange={handleSubmit} />
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="px-4 mx-auto mt-5 xl:container lg:px-16">

        <InsolvencyFilterTags query={query} onSubmit={handleSubmit} handleClearFilters={handleClearFilters} />

        <div className="flex flex-col items-center justify-between mt-4 lg:flex-row lg:items-center">
          {!isLoading &&
            <>
              {query?.type !== "liegenschaft" &&
                <h3 className="mb-3 text-xl font-light text-center text-gray-800 2xl:text-2xl lg:text-left lg:mb-0">{pagination?.totalRecords?.toLocaleString("de")} {pluralize("Insolvenz", "Insolvenzen", "Insolvenzen", pagination?.totalRecords)} gefiltert</h3>
              }
              {query?.type === "liegenschaft" &&
                <h3 className="mb-3 text-xl font-light text-center text-gray-800 2xl:text-2xl lg:text-left lg:mb-0">{pagination?.totalRecords?.toLocaleString("de")} {pluralize("Liegenschaft", "Liegenschaften", "Liegenschaften", pagination?.totalRecords)} gefiltert</h3>
              }
            </>
          }

          {!pagination?.totalRecords && isLoading &&
            <div className="w-64 h-5 bg-gray-300 rounded animate-pulse" />
          }

          {!isLoading &&
            <div className="items-center justify-end lg:flex">
              {query?.type === "liegenschaft" &&
                <SortInsolvencyPropertiesDropdown sortByProperty={query.sortByProperty} onSubmit={handleSubmit} />
              }
              {query?.type !== "liegenschaft" &&
                <SortInsolvenciesDropdown sortByInsolvency={query.sortByInsolvency} onSubmit={handleSubmit} />
              }

              <div className="mb-2 ml-6 mr-1 lg:mb-0">
                <Pagination pagination={pagination} page={query.page} onPageChange={handlePageChange} />
              </div>
              <div className="flex justify-center lg:block">
                <SortPurchasesDirection isLoading={isLoading} direction={sortDirection} onSubmit={handleSubmit} />
              </div>
            </div>
          }

          {isLoading &&
            <div className="h-5 mt-4 mb-1 bg-gray-300 rounded w-96 animate-pulse" />
          }
        </div>

      </div>

      <div className="px-4 pb-5 mx-auto mt-2 xl:container lg:px-16">

        {query?.type !== "liegenschaft" &&
          <InsolvencyList insolvencies={insolvencies} query={query} isLoading={isLoading} />
        }

        {query?.type === "liegenschaft" &&
          <InsolvencyPropertyList properties={insolvencies} query={query} isLoading={isLoading} />
        }

        <Pagination pagination={pagination} page={query.page} onPageChange={handlePageChange} />

      </div>

    </Page>
  );
}
