import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

/**
 *
 * @param id
 * @param message
 * @param duration
 */
export function showSuccess(id = null, message = "Erfolgreich gespeichert!", duration = 900) {
  toast.dismiss(id);
  toast.success(message, {
    toastId: id,
    autoClose: duration,
    progressClassName: "bg-emerald-500",
    bodyClassName: "text-sm font-medium text-gray-800",
    icon: <div className="-mt-0.5"><CheckCircleIcon className=" text-emerald-500 w-6 h-6" /></div>,
    pauseOnHover: false,
    pauseOnFocusLoss: false
  });
}

/**
 *
 * @param id
 * @param message
 * @param duration
 */
export function showError(id = null, message = "Fehler! Änderung nicht gespeichert.", duration = 5000) {
  toast.dismiss(id);
  toast.error(message, {
    toastId: id,
    autoClose: duration,
    progressClassName: "bg-primary",
    bodyClassName: "text-sm font-medium text-primary",
    icon: <div className="-mt-0.5"><CheckCircleIcon className=" text-primary w-6 h-6" /></div>,
    pauseOnHover: false,
    pauseOnFocusLoss: false
  });
}
