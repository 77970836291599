import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import CloseIcon from "~/src/ui/icons/close-icon";

/**
 *
 * @param root0
 * @param root0.children
 * @param root0.cancelButtonRef
 * @param root0.isActive
 * @param root0.handleCancel
 * @param root0.handleSubmit
 */
export default function SearchFilterContainer({
  children, cancelButtonRef, isActive, handleCancel, handleSubmit
}) {
  return (
    <Transition.Root show={isActive} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-[150] inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={isActive}
        onClose={handleCancel}
      >
        <div className="flex items-end justify-center w-screen min-h-full text-center sm:block sm:p-0 sm:w-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-3xl">
              <div className="relative px-4 py-4 border-b border-gray-200 sm:px-6">
                <p className="text-lg font-medium text-center">Projekte filtern nach...</p>
                <div className="absolute top-0 items-center hidden h-full sm:flex right-4">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={handleCancel}
                  >
                    <CloseIcon className="w-5 h-5 text-black" />
                  </button>
                </div>
              </div>

              <div className="px-4 overflow-y-scroll bg-white divide-y divide-gray-300 sm:divide-gray-200 sm:px-12 max-h-screen-66 sm:max-h-screen-80">

                {children}

              </div>

              <div className="px-4 py-4 space-y-4 text-center border-t border-gray-200 sm:px-6 sm:flex sm:flex-row-reverse sm:justify-between sm:items-center sm:space-y-0">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-8 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-dark focus:outline-none sm:ml-3 sm:w-auto"
                  onClick={handleSubmit}
                >
                  Suchen
                </button>
                <button
                  type="button"
                  className="-ml-2.5 px-2.5 py-1.5 rounded-md underline font-medium text-gray-700 hover:bg-gray-100 focus:outline-none"
                  onClick={handleCancel}
                  ref={cancelButtonRef}
                >
                  Schließen
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
