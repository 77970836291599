import { useRef } from "react";

import SearchFilterContainer from "~/src/features/search-filter-modals/container";
import FinishYearSearchFilter from "~/src/features/search-filter-modals/forms/finish-year-search-filter";

/**
 *
 * @param root0
 * @param root0.isActive
 * @param root0.hide
 * @param root0.finish_year
 * @param root0.tempQuery
 * @param root0.update
 * @param root0.submit
 * @param root0.cancelSearchFilter
 */
export default function FinishYearModal({
  isActive, hide, finish_year, tempQuery, update, submit, cancelSearchFilter
}) {
  const cancelButtonRef = useRef();

  function handleSubmit() {
    submit();
    hide();
  }

  function handleCancel() {
    cancelSearchFilter();
    hide();
  }

  return (
    <SearchFilterContainer
      isActive={isActive}
      cancelButtonRef={cancelButtonRef}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
    >
      <FinishYearSearchFilter query={finish_year} tempQuery={tempQuery.finish_year} update={update} />
    </SearchFilterContainer>
  );
}
