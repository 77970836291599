import cn from "classnames";
import { groupBy } from "lodash-es";
import { useParams } from "react-router-dom";

import ProjectUnit from "./project-unit";

import useProjectExport from "~/src/hooks/use-project-export";
import { pluralize } from "~/src/modules/formatters";
import { unitCategoryLabels } from "~/src/modules/labels";
import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

/**
 *
 */
export default function ProjectUnits() {
  const { id: projectId } = useParams();
  const { units } = useProjectExport(projectId);

  if (!units) {
    return null;
  }

  const groupedUnits = groupBy([...units].sort(({ unit_category: { sorting: sortingA } }, { unit_category: { sorting: sortingB } }) => sortingA - sortingB), "unit_category.code");

  const listFormatter = new Intl.ListFormat("de", {
    style: "long",
    type: "conjunction"
  });

  const columnTitles = [
    "Objektkategorie",
    "Erfasste Einheiten"
  ];

  const rows = Object.entries(groupedUnits).map(([
    categoryCode,
    units
  ]) => [
    unitCategoryLabels[categoryCode].singular,
    units.length
  ]);

  const columnWidths = [
    "2/12",
    "10/12"
  ];

  return (
    <SectionContainer title="Erfasste Objekte" className="break-before-page !mt-0" wrap>
      {
        units.length > 0
          ? (
            <>
              <Table {...{
                columnTitles,
                rows,
                columnWidths
              }} />
              {
                Object.entries(groupedUnits).map(([
                  categoryCode,
                  units
                ], index) => {
                  const categoryLabel = unitCategoryLabels[categoryCode];

                  const title = `${units.length} ${pluralize(categoryLabel.singular, categoryLabel.plural, categoryLabel.plural, units.length)}`;

                  return (
                    <SectionContainer title={`${title}`} sub key={categoryCode} className={cn({ "break-before-page": index !== 0 })} wrap>
                      <>
                        {
                          units.map((unit, index) => (
                            <ProjectUnit {...{
                              unit,
                              index
                            }} key={unit.id} />
                          ))
                        }
                      </>
                    </SectionContainer>
                  );
                })
              }
            </>
          )
          : <p>Keine Objektdaten vorhanden</p>
      }
    </SectionContainer>
  );
}
