import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Menu } from "@headlessui/react";
import cn from "classnames";

import { priceLabels, unitCategoryLabels } from "~/src/modules/labels";
import useUnitCategories from "~/src/hooks/use-unit-categories";

/**
 *
 * @param props
 * @param props.activeCategory
 * @param props.categories
 * @param props.setActiveCategory
 * @param props.className
 * @param props.isManual
 * @param props.isUnits
 */
export default function CategoryDropdown({
  activeCategory,
  categories,
  setActiveCategory,
  isUnits = true,
  className
}) {
  const { unit_categories: unitCategories } = useUnitCategories();

  const [
    categoryOptions,
    setCategoryOptions
  ] = useState(categories);

  useEffect(() => {
    if (unitCategories && isUnits) {
      setCategoryOptions(
        [...categories]
          .sort((categoryCodeA, categoryCodeB) => {
            const sortingA = unitCategories ? Number(unitCategories.find(({ code }) => code === categoryCodeA).sorting) : 0;

            const sortingB = unitCategories ? Number(unitCategories.find(({ code }) => code === categoryCodeB).sorting) : 0;

            return sortingA - sortingB;
          })
      );
    }
    else if (categories.length) {
      setCategoryOptions(categories);
    }
  }, [
    unitCategories,
    categories
  ]);

  return (
    <Menu as="div" className={cn("relative inline-flex", className)}>
      <Menu.Button className="flex items-center justify-center gap-1 font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        <span>{(isUnits) ? unitCategoryLabels?.[activeCategory]?.singular : priceLabels?.[activeCategory]}</span>
        <ChevronDownIcon className="w-5 h-5 text-gray-500" aria-hidden="true" />
      </Menu.Button>
      <Menu.Items className="absolute right-0 z-10 px-1 py-1 origin-top-right bg-white rounded-md shadow-lg min-w-52 top-8 ring-1 ring-black ring-opacity-5">
        {categoryOptions.map((categoryCode) => (<Menu.Item key={categoryCode}>
          {({ active }) => (
            <button
              id={categoryCode}
              type="button"
              onClick={() => setActiveCategory(categoryCode)}
              className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                "bg-gray-100 text-gray-900": active,
                "text-gray-700": !active
              })}
            >
              <span>{(isUnits) ? unitCategoryLabels?.[categoryCode]?.singular : priceLabels?.[categoryCode]}</span>
            </button>
          )}
        </Menu.Item>))}
      </Menu.Items>
    </Menu>

  );
}
