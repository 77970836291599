/* eslint-disable max-len */

import Page from "~/src/ui/page/index.jsx";
import ProjectCount from "~/src/features/project-count/index.jsx";
import ProjectsFiltersTile from "~/src/features/projects-filters-tile/index.jsx";
import ProjectsListTile from "~/src/features/projects-list-tile/index.jsx";
import StatsBox from "~/src/features/stats-box/index.jsx";
import useMediaQuery from "~/src/hooks/use-media-query.js";

/**
 *
 */
const ListePage = () => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <Page
      title="Liste"
      className="h-[calc(100%-10.5rem)] lg:h-auto"
    >
      <div className="flex h-full max-h-full flex-row gap-4 overflow-hidden lg:h-[calc(100vh-3rem)] lg:max-h-[calc(100vh-3rem)] lg:p-4">
        <ProjectsFiltersTile />

        <div className="flex flex-col justify-between w-full lg:gap-4">
          {
            !isMobile && (
              <div className="z-20 hidden @container lg:flex" >
                <StatsBox />
              </div>
            )
          }
          {
            isMobile && (
              <div className="flex w-full p-4 lg:hidden lg:p-0">
                <ProjectCount className="w-full" />
              </div>
            )
          }

          <div className="z-10 flex flex-col h-full min-h-0 gap-1 lg:pb-0">
            <ProjectsListTile />
          </div>

        </div>
      </div>
    </Page>
  );
};

export default ListePage;
