import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.watchlist
 * @param root0.watchlistLength
 * @param root0.index
 * @param root0.project
 * @param root0.handleCheckbox
 */
export default function WatchlistDropdownItem({
  watchlist, watchlistLength, index, project, handleCheckbox
}) {
  const watchlistProjectSlugs = watchlist.projects.map((p) => p.slug);
  const inWatchlist = watchlistProjectSlugs.includes(project?.slug);

  return (
    <label key={watchlist.uuid} htmlFor={`watchlist-${watchlist.uuid}`} className="truncate">
      <div className={cn("relative flex items-start cursor-pointer bg-secondary-light py-2 px-4 rounded-lg", {
        "mt-4": index === 0,
        "mb-4": (watchlistLength > 1 && watchlistLength === index + 1) || watchlistLength === 1
      })}>
        <div className="flex items-center h-5 cursor-pointer">
          <input
            checked={inWatchlist}
            onChange={(e) => handleCheckbox(e, watchlist.uuid)}
            id={`watchlist-${watchlist.uuid}`}
            name={`watchlist-${watchlist.uuid}`}
            type="checkbox"
            className="focus:ring-0 focus:ring-transparent h-4 w-4 text-primary border-gray-300 rounded cursor-pointer"
          />
        </div>
        <div className="ml-3 text-sm truncate">
          <p className="font-medium text-gray-600 cursor-pointer truncate">
            {watchlist.name}
          </p>
        </div>
      </div>
    </label>
  );
}
