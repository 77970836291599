import { useEffect, useState } from "react";

import { transformToPieData } from "../../helpers";

import ChartError from "~/src/ui/charts/error";
import ChartLegend from "~/src/ui/charts/legend";
import MetaTooltip from "~/src/ui/charts/meta-tooltip";
import PieChart from "~/src/ui/charts/pie-chart";

/**
 *
 * @param props
 * @param props.statistic
 * @param props.isLoading
 * @param props.isError
 */
export default function BuyersAgeChart({
  statistic, isLoading, isError
}) {
  const [
    pieData,
    setPieData
  ] = useState([]);

  useEffect(() => {
    if (typeof statistic !== "undefined") {
      setPieData(statistic && transformToPieData(statistic, "buyers"));
    }
  }, [statistic]);

  if (isError) {
    return (
      <div className="flex flex-col gap-4 rounded border border-gray-200 bg-white shadow-sm p-4">
        <div className="flex gap-2 items-center">
          <h2 className="text-xl font-medium">Altersgruppen</h2>
        </div>
        <ChartError />
      </div>
    );
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 rounded border border-gray-200 bg-white shadow-sm p-4">
      <div className="flex gap-2 items-center">
        <h2 className="text-xl font-medium">Altersgruppen</h2>
        <MetaTooltip
          {...((pieData) && {
            meta: statistic.meta
          })}
        />
      </div>
      <div className="w-full h-[450px]">
        <PieChart
          data={pieData}
          sortByValue={false}
        />
      </div>
      <div className="w-full">
        <ChartLegend data={pieData}/>
      </div>
    </div>
  );
}
