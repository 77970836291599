/* eslint-disable max-len */
import { forwardRef } from "react";

const SelectOnlyMarkedProjects = forwardRef(({
  onlyMarkedProjects, handleCheck, disabled
}, forwardedRef) => {
  const handleCheckbox = function (event) {
    handleCheck(event.target.checked);
  };

  return (
    <label htmlFor="onlyMarked"
      className="pl-0.5 flex items-start gap-2 cursor-pointer"
      ref={forwardedRef}
    >
      <input
        id="onlyMarked"
        checked={onlyMarkedProjects}
        onChange={handleCheckbox}
        type="checkbox"
        disabled={disabled}
        className="w-4 h-4 mt-0.5 text-gray-500 border border-gray-300 rounded shadow-sm cursor-pointer focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-default"
      />
      <span className="hidden lg:block font-medium text-gray-500">Nur markierte Projekte anzeigen</span>
      <span className="lg:hidden font-medium text-gray-500">Nur markierte Projekte</span>
    </label>
  );
});

export default SelectOnlyMarkedProjects;
