/**
 *
 */
export default function UnitStatsSkeleton() {
  return (
    <div className="mt-6 space-y-4 md:space-y-0 md:grid md:grid-cols-6 md:gap-4">
      {[...Array(3)].map((e, i) => (
        <div key={`stats_skeleton_${i}`} className="animate-pulse md:col-span-2 relative flex justify-between items-center rounded border border-gray-200 bg-white shadow-sm px-4 py-4">
          <div className="flex items-center">
            <p className="w-5 h-5 rounded-full bg-gray-300" />
            <p className="ml-3 w-40 h-5 bg-gray-300 rounded" />
          </div>
          <p className="w-32 h-5 bg-gray-300 rounded" />
        </div>
      ))}
    </div>
  );
}
