import cn from "classnames";

/**
 *
 * @param props
 * @param props.className
 * @param props.children
 */
export default function ChartLayout({ className, children }) {
  return (
    <div className={cn(className)}>
      {children}
    </div>
  );
}
