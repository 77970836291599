/* eslint-disable max-len */
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import ProjectsFiltersTile from "../projects-filters-tile/index.jsx";

import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useStore from "~/src/hooks/use-store.js";

/**
 *
 */
const ProjectsFiltersModal = () => {
  const { query, setQuery } = useProjectQueryParams();

  const filterOpen = useStore((state) => state.filterOpen);
  const setFilterOpen = useStore((state) => state.setFilterOpen);

  const closeFilter = () => {
    setFilterOpen(false);
  };

  return (
    <Transition
      show={filterOpen}
      enter="transition ease-in-out duration-500 transform"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leave="transition ease-in-out duration-500 transform"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-full"
      className="fixed inset-0 z-50 flex flex-col w-full gap-4 p-4 bg-white drop-shadow-[0px_0px_8px_#00000040]"
      unmount={false}
    >
      <div className="flex justify-end w-full">
        <button onClick={closeFilter}>
          <XMarkIcon className="w-8 h-8 text-gray-700" />
        </button>
      </div>
      <ProjectsFiltersTile
        queryAutomated={typeof query.automated === "boolean" ? query.automated : false}
        onChange={(automated) => {
          setQuery({
            automated,
            page: 1
          });
        }}
        className="!flex"
      />
    </Transition>
  );
};

export default ProjectsFiltersModal;
