const createHttpError = ({ relativeUrl, status, responseBody }) => {
  const description = "Cannot access HTTP resource.";
  const data = {
    response: { status, body: responseBody },
    request: { relativeUrl }
  };

  const message = JSON.stringify({
    description,
    data
  });

  return Error(message);
};

const getHttpErrorResponseBody = ({ error }) => {
  try {
    const message = JSON.parse(error?.message);

    return message?.data?.response?.body;
  }
  catch (errorInTry) {
    if (errorInTry.name === "SyntaxError") {
      return false;
    }
    throw errorInTry;
  }
};

const getHttpErrorRelativeUrl = ({ error }) => {
  try {
    const message = JSON.parse(error?.message);

    return message?.data?.request?.relativeUrl;
  }
  catch (errorInTry) {
    if (errorInTry.name === "SyntaxError") {
      return false;
    }
    throw errorInTry;
  }
};

const errorHasHttpStatus = ({ error, status }) => {
  try {
    const message = JSON.parse(error?.message);

    return message?.data?.response?.status === status;
  }
  catch (errorInTry) {
    if (errorInTry.name === "SyntaxError") {
      return false;
    }
    throw errorInTry;
  }
};

export {
 createHttpError,
 errorHasHttpStatus,
 getHttpErrorResponseBody,
 getHttpErrorRelativeUrl
};
