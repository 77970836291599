/* eslint-disable import/max-dependencies */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import {
  useEffect,
  useRef,
  useState
} from "react";
import { useSWRConfig } from "swr";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash-es";

import ProjectListItem from "./project-list-item";

import useWatchlists from "~/src/hooks/use-watchlists";
import ItemSkeleton from "~/src/ui/loading/item-skeleton";
import useProjectList from "~/src/hooks/use-project-list";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useStore from "~/src/hooks/use-store";
import { parseUrlParams } from "~/src/features/filters/filter-url-parser";

// eslint-disable-next-line max-lines-per-function, max-statements
/**
 *
 * @param setCurrentTotal.setCurrentTotal
 * @param setCurrentTotal
 * @param setIsLoading
 * @param setSelectionStatus
 * @param className
 * @param setCurrentTotal.setIsLoading
 * @param setCurrentTotal.setSelectionStatus
 * @param setCurrentTotal.className
 * @param setCurrentTotal.handlePageOnEmptyProjectList
 */
export default function ProjectList({
  setCurrentTotal,
  setIsLoading,
  setSelectionStatus,
  className
}) {
  const swrOptions = {
    revalidateOnFocus: false
  };

  const location = useLocation();

  const {
    cache: globalCache,
    mutate: mutateGlobal
  } = useSWRConfig();

  const { query, setQuery } = useProjectQueryParams();

  const {
    projects,
    total,
    selectionStatus,
    selectionType,
    isLoading,
    updateTime,
    mutate: mutateProjectList
  } = useProjectList({
    query,
    from: true
  }, swrOptions);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setSelectionStatus(selectionStatus);
  }, [isLoading]);

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const setChange = useStore((state) => state.setChange);

  const [
    fromHook,
    setFromHook
  ] = useState(false);

  const setCurrentSelectionStatus = useStore((state) => state.setCurrentSelectionStatus);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);

  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const projectListRef = useRef(null);

  const [
    projectIsLoading,
    setProjectIsLoading
  ] = useState(false);

  useEffect(() => {
    const { search } = location;

    const urlSearch = new URLSearchParams(search);

    const parsedSearch = Object.fromEntries(
      [...urlSearch.entries()].map(parseUrlParams)
    );

    mutateGlobal(
      (swrCacheKey) => {
        if (swrCacheKey.startsWith("/project-list")) {
          const cacheKeyValue = globalCache.get(swrCacheKey);

          const cleanedSwrCacheKey = swrCacheKey.replace("/project-list?", "");

          const urlParsedSwrCacheKey = new URLSearchParams(cleanedSwrCacheKey);

          const parsedSwrCacheKey = Object.fromEntries(
            [...urlParsedSwrCacheKey.entries()].map(parseUrlParams)
          );

          const relevantSwrCacheKeys = Object.fromEntries(Object.entries(parsedSwrCacheKey)
            .filter(([
              filterKey,
              _filterValue
            ]) => (Object.keys(parsedSearch).includes(filterKey))));

          if (cacheKeyValue?.data && !cacheKeyValue?.isLoading) {
            // setSelectionMutate(true);
            mutateProjectList();
          }

          return (
            !isEqual(relevantSwrCacheKeys, parsedSearch) &&
            (Object.keys(cacheKeyValue).includes("data") ||
            !cacheKeyValue?.isLoading)
          );
        }

        return false;
      },
      // eslint-disable-next-line no-undefined
      undefined,
      {
        revalidate: false
      }
    );
  }, [location]);

  useEffect(() => {
    if (projectListRef.current) {
      projectListRef.current.scrollTop = 0;
    }
    setProjectIsLoading(true);
  }, [query]);

  useEffect(() => {
    if (projectIsLoading && typeof projects !== "undefined") {
      setProjectIsLoading(false);
    }
  }, [updateTime]);

  useEffect(() => {
    if (selectionType) {
      setMainSelectionType(selectionType);
      if (!fromHook) {
        setFromHook(true);
      }
    }
  }, [selectionType]);

  useEffect(() => {
    if (selectionStatus) {
      setCurrentSelectionStatus(selectionStatus);
      if (!fromHook) {
        setFromHook(true);
      }
    }
  }, [selectionStatus]);

  const loadingTimeout = 250;

  useEffect(() => {
    if (fromHook) {
      const settingSelectionLoading = () => {
        const timeoutId = setTimeout(() => {
          setSelectionLoading(false);
          setFromHook(false);
        }, loadingTimeout);

        return () => {
          clearTimeout(timeoutId);
        };
      };

      settingSelectionLoading();
    }
  }, [fromHook]);

  useEffect(() => {
    if (typeof total !== "undefined") {
      setCurrentTotal(total.totalPages);
    }
  });

  useEffect(() => {
    if (projects && projects.length === 0) {
      setQuery({
        page: Math.max(total.totalPages, 1)
      });
    }
  }, [projects]);

  useEffect(() => {
    if (selectionMutate) {
      const mutating = async () => {
        await mutateProjectList();
        // await mutateCount();
        setSelectionMutate(false);
        if (selectionLoading) {
          setSelectionLoading(false);
        }
      };

      mutating();
    }
  }, [selectionMutate]);

  const updateSelection = (update) => {
    setChange(update);
  };

  const handleProjectSelectedCheckbox = async (value, currentSlug) => {
    if (!selectionLoading) {
      const updateType = (value)
        ? "add"
        : "remove";

      await updateSelection({
        type: updateType,
        data: [currentSlug],
        source: "list",
        sourcePage: query.page
      });
    }
  };

  const { watchlists, mutate: mutateWatchlists } = useWatchlists();

  const maxPageSize = 25;

  if (isLoading || !projects) {
    return (
      <div className="h-full overflow-y-scroll overscroll-y-contain">
        {
          Array(maxPageSize).fill().map((empty, index) => (
            <ItemSkeleton
              key={index}
              className="relative w-full border-b border-gray-200 last:border-b-0"
            />
          ))
        }
      </div>
    );
  }

  if (projects && !projects.length) {
    return (
      <span className="ml-[10px] mt-5">Keine Ergebnisse gefunden…</span>
    );
  }

  return (
    <div
      className={className}
    >
      {projects.map((project) => (
        <ProjectListItem
          key={`project_${project.slug}`}
          project={project}
          handleCheckbox={handleProjectSelectedCheckbox}
          watchlists={watchlists}
          mutateWatchlists={mutateWatchlists}
          disabled={selectionLoading}
        />
      ))}

    </div>
  );
}
