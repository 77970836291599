import cn from "classnames";

import MarkerSmall from "~/src/ui/markers/map-marker-small";
import MarkerIconPurchase from "~/src/ui/markers/icons/marker-icon-purchase";
import MarkerIconConstruction from "~/src/ui/markers/icons/marker-icon-construction";
import MarkerIconCompletion from "~/src/ui/markers/icons/marker-icon-completion";
import MarkerIconEmpty from "~/src/ui/markers/icons/marker-icon-empty";

/**
 *
 * @param root0
 * @param root0.className
 */
export default function LegendBox({ className }) {
  const marketingPhase = {
    title: "Vermarktungsphase",
    items: [
      {
        className: "text-marker-orange",
        name: "coming soon"
      },
      {
        className: "text-marker-red",
        name: "in Vermarktung"
      },
      {
        className: "text-marker-green",
        name: "abgeschlossen"
      },
      {
        className: "text-marker-gray",
        name: "nicht bekannt"
      }
    ]
  };

  const constructionPhase = {
    title: "Bauphase",
    items: [
      {
        iconComponent: MarkerIconPurchase,
        name: "Ankauf"
      },
      {
        iconComponent: MarkerIconConstruction,
        name: "in Bau"
      },
      {
        iconComponent: MarkerIconCompletion,
        name: "abgeschlossen"
      },
      {
        iconComponent: MarkerIconEmpty,
        name: "nicht bekannt"
      }
    ]
  };

  return (
    <div className={cn("flex flex-wrap pl-2 gap-2 print:gap-8 print:p-0 text-xs text-gray-500", className)}>
      <div className="flex flex-col col-span-1">
        <h3 className="leading-6 text-gray-700">{marketingPhase.title}</h3>
        <div className="grid grid-cols-1 xs:grid-cols-2 gap-x-4 gap-y-2 w-max">
          {
            marketingPhase.items.map(({ className, name }, index) => (
              <div className="flex items-center col-span-1" key={index}>
                <MarkerSmall className={cn("w-5 h-5", className)} />
                <span className="ml-2 whitespace-nowrap">{name}</span>
              </div>
            ))
          }
        </div>
      </div>
      <div className="col-span-1">
        <h3 className="leading-6 text-gray-700">{constructionPhase.title}</h3>
        <div className="grid grid-cols-1 xs:grid-cols-2 gap-x-4 gap-y-2 w-max">
          {
            constructionPhase.items.map(({ iconComponent: IconComponent, name }, index) => (
              <div className="flex items-center col-span-1" key={index}>
                <div className="flex items-center justify-center w-5 h-5 p-1 bg-white border rounded-full">
                  <IconComponent className="w-full h-full text-gray-500" />
                </div>
                <span className="ml-2 whitespace-nowrap">{name}</span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
