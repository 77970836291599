import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.children
 * @param root0.className
 * @param root0.sub
 */
export default function Header({
  children, className, sub = false
}) {
  return (
    <span className={cn("uppercase break-after-avoid", {
      "text-lg": !sub,
      "text-base": sub
    }, className)}>{children}</span>
  );
}
