import { Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

/**
 *
 * @param root0
 * @param root0.watchlist
 * @param root0.nameFilter
 * @param root0.handleEdit
 * @param root0.handleDelete
 */
export default function WatchlistItem({
  watchlist, nameFilter, handleEdit, handleDelete
}) {
  return (

    <Transition
      show={watchlist.name.toLowerCase().includes(nameFilter.toLowerCase())}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-white rounded-sm border border-gray-200">

        <div className="px-5 py-3 w-full">
          <h3 className="text-xl font-medium text-gray-800 truncate">
            <Link to={`/merklisten/${watchlist.uuid}`} className="hover:underline">{watchlist.name}</Link>
          </h3>

          <div className="mt-1 flex justify-between items-center">
            <p className="text-gray-800 font-light text-lg"><span className=" text-gray-600">{watchlist.numberOfProjects}</span> / 200 Projekte</p>

            {watchlist.name !== "Standardliste" &&
            <div className="flex items-center">
              <button onClick={() => handleEdit(watchlist)} type="button" className="focus:outline-none text-gray-500 hover:text-gray-600">
                <PencilIcon className="w-5 h-5"/>
              </button>
              <button type="button" onClick={() => handleDelete(watchlist)} className="focus:outline-none text-gray-500 hover:text-gray-600">
                <TrashIcon className="ml-3.5 w-5 h-5" />
              </button>
            </div>
            }

          </div>
        </div>
      </div>
    </Transition>
  );
}
