/* eslint-disable no-undefined */
import useSWR from "swr";

import { getFetcherWithHeaders } from "~/src/modules/api.js";

/**
 *
 * @param root0
 * @param root0.location
 */
export default function useAuthInternal({ location }) {
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");

  const headers = {
    Authorization: `Basic ${btoa(`${username}:${password}`)}`
  };

  if (window.settings.NODE_ENV === "development") {
    return {
      user: undefined,
      isLoading: false,
      isError: undefined,
      // eslint-disable-next-line no-empty-function
      mutate: () => {}
    };
  }

  const searchParams = new URLSearchParams(location.search);

  const userId = searchParams.get("userId");

  const {
    data,
    error,
    mutate
  } = useSWR(`/auth/session-internal?userId=${userId}`, getFetcherWithHeaders(headers));

  return {
    user: data?.payload,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
