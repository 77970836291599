/* eslint-disable max-len */
/* eslint-disable no-empty-function */
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon
} from "@heroicons/react/24/solid";

/**
 *
 * @param props
 * @param props.direction
 * @param props.onSortDirectionChange
 * @param props.isHandleItemClickDisabled
 */
const SortDirectionButton = ({
  direction = "asc",
  onSortDirectionChange = () => {},
  isHandleItemClickDisabled
}) => (
  <button
    onClick={() => {
      onSortDirectionChange(direction === "asc" ? "desc" : "asc");
    }}
    disabled={isHandleItemClickDisabled}
    className="flex items-center justify-center w-8 lg:w-[2.25rem] h-6 lg:h-[1.875rem] p-2 lg:p-[0.3rem] text-gray-500 bg-white border border-gray-200 rounded-sm cursor-pointer hover:bg-gray-50"
  >
    {direction === "asc" &&
        <BarsArrowUpIcon className="w-full lg:h-full" />
    }
    {direction === "desc" &&
        <BarsArrowDownIcon className="w-full lg:h-full" />
    }
  </button>
);

export default SortDirectionButton;
