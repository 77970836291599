/**
 *
 * @param spec
 * @param colGetters
 */
const getColumnsFromSpec = (spec, colGetters) => (
  Object.fromEntries(
    spec.map(
      ({ key, ...colSpec }, index) => [
        key,
        {
          ...colSpec,
          number: index + 1,
          get: colGetters?.[key] ?? ((record) => record[key])
        }
      ]
    )
  )
);

/**
 *
 * @param col
 * @param labels
 */
const addLabelsToCols = (col, labels) => {
  for (const colKey in col) {
    col[colKey].label = labels[colKey];
  }
};

export { getColumnsFromSpec, addLabelsToCols };
