import { useRef } from "react";

import SearchFilterContainer from "~/src/features/search-filter-modals/container";
import QuantitySearchFilter from "~/src/features/search-filter-modals/forms/quantity-search-filter";

/**
 *
 * @param root0
 * @param root0.isActive
 * @param root0.hide
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 * @param root0.submit
 * @param root0.cancelSearchFilter
 */
export default function QuantityModal({
  isActive, hide, query, tempQuery, update, submit, cancelSearchFilter
}) {
  const cancelButtonRef = useRef();

  function handleSubmit() {
    submit();
    hide();
  }

  function handleCancel() {
    cancelSearchFilter();
    hide();
  }

  return (
    <SearchFilterContainer
      isActive={isActive}
      cancelButtonRef={cancelButtonRef}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
    >
      <QuantitySearchFilter query={query.quantity} tempQuery={tempQuery.quantity} update={update} />
    </SearchFilterContainer>
  );
}
