import {
  createContext,
  useCallback,
  useState
} from "react";

export const DashboardContext = createContext({
  showAnimation: true,
  hideAnimation: () => {}
});

/**
 *
 * @param props
 * @param props.children
 */
const DashboardProvider = ({ children }) => {
  const [
    showAnimation,
    setShowAnimation
  ] = useState(true);

  const hideAnimation = () => setShowAnimation(false);

  const contextValue = {
    showAnimation,
    hideAnimation: useCallback(() => hideAnimation(), [])
  };

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
