import {
  Bars4Icon, BarsArrowDownIcon, BarsArrowUpIcon
} from "@heroicons/react/24/solid";

/**
 *
 * @param root0
 * @param root0.isLoading
 * @param root0.direction
 * @param root0.onSubmit
 */
export default function PurchasesSortDirection({
  isLoading, direction = "asc", onSubmit
}) {
  function handleClick() {
    onSubmit({ direction: direction === "asc" ? "desc" : "asc" });
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className="w-10 h-8 inline-flex justify-center items-center text-base leading-4 font-medium text-gray-500 bg-white rounded-sm border border-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-0"
    >
      {isLoading &&
        <Bars4Icon className="w-1/2" />
      }
      {direction === "asc" && !isLoading &&
        <BarsArrowUpIcon className="w-1/2" />
      }
      {direction === "desc" && !isLoading &&
        <BarsArrowDownIcon className="w-1/2" />
      }
    </button>
  );
}
