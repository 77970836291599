import { useParams } from "react-router-dom";

import useProjectExport from "~/src/hooks/use-project-export";
import { formatArea } from "~/src/modules/formatters";
import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

/**
 *
 */
export default function ProjectEinlagen() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    einlagen
  } = project;

  const columnTitles = [
    "BG",
    "KG",
    "EZ",
    "Fläche",
    "GST-Nr."
  ];

  const rows = einlagen.map(({
    katastralgemeinde: {
      bezirksgericht: {
        code: bgCode,
        name: bgName
      },
      code: kgCode,
      name: kgName
    },
    ez_number,
    area,
    gst_numbers
  }) => [
    `${bgCode} ${bgName}`,
    `${kgCode} ${kgName}`,
    ez_number,
    formatArea(area),
    gst_numbers.join(", ")
  ]);

  const columnWidths = [
    ...Array(4).fill("2/12"),
    "4/12"
  ];

  const emptyText = "Liegenschaften nicht bekannt";

  return (
    <SectionContainer title="Liegenschaften">
      <Table {...{
        columnTitles,
        rows,
        emptyText,
        columnWidths
      }} />
    </SectionContainer>
  );
}
