import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param query.query
 * @param query
 * @param query.shouldFetch
 * @param swrOptions
 */
export default function useProjectsDetailStats({ query, shouldFetch = true }, swrOptions) {
  const {
    polygon,
    street,
    radius,
    page,
    layout,
    sort,
    direction,
    ...restQuery
  } = query;

  const newQuery = {
    ...restQuery,
    polygon: polygon?.join("_"),
    ...(street && {
      street,
      radius
    })
  };

  const params = new URLSearchParams(
    Object.fromEntries(
      Object.entries(newQuery).filter(([
        key,
        value
      ]) => typeof value !== "undefined")
    )
  );

  const {
    data, mutate, error
  } = useSWR(() => (shouldFetch ? `/projects/detail-stats?${params}` : null), fetcher, swrOptions);

  return {
    detailStats: data?.payload?.detailStats,
    mutate,
    isLoading: !error && !data,
    isError: error
  };
}
