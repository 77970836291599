import useSWR from "swr";
import { stringify } from "query-string";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.project
 * @param root0.query
 */
export default function useUnits({ project, query }) {
  const {
    data, error, mutate
  } = useSWR(() => `/projects/${project.slug}/units?${stringify(query)}`, fetcher);
  // This request is dependent on project
  // When passing a function, SWR will use the return
  // value as `key`. If the function throws or returns
  // falsy, SWR will know that some dependencies are not
  // ready.
  // https://swr.vercel.app/docs/conditional-fetching#conditional

  return {
    units: data?.payload?.units,
    total: data?.payload?.total,
    sortDirection: data?.payload?.sortDirection,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
