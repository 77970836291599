import useSWR from "swr";

import httpStatus from "../lib/http-status";
import { errorHasHttpStatus } from "../lib/error";

import useMediaQuery from "./use-media-query";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.version
 */
export default function useAuth({ version = 0 } = { version: 0 }) {
  const isPrint = useMediaQuery("print");
  const {
    data,
    error,
    mutate
  } = useSWR(`/auth/session?v=${version}`, fetcher);

  const loggedOut = errorHasHttpStatus({
    error,
    status: httpStatus.forbidden
  });

  return {
    user: data?.payload,
    isLoading: !error && !data,
    isError: error,
    loggedOut,
    mutate
  };
}
