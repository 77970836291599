import { animated, to } from "@react-spring/web";
import { useCallback } from "react";

const generateArray = (min, max, steps) => {
  const deltaMinMax = max - min;
  const stepValue = deltaMinMax / steps;
  const powerOfSteps = Math.floor(Math.log10(stepValue));
  const stepValueRounded = Math.round(stepValue / (10 ** powerOfSteps));
  const stepSize = stepValueRounded * (10 ** powerOfSteps);
  const result = [];

  let i = 1;

  while ((i * stepSize) < max * 1.1) {
    result.push(stepSize * i);
    i++;
  }

  return result;
};

const getMinMaxWithTimeline = (min, max, timeline) => ({
  minWithTimeline: Math.min(min, ...Object.values(timeline).filter(Boolean)),
  maxWithTimeline: Math.max(max, ...Object.values(timeline).filter(Boolean))
});

const timelineSettings = {
  purchase: {
    label: "Ankauf",
    color: "#14b8a6"
  },
  constructionStart: {
    label: "Baubeginn",
    color: "#0891b2"
  },
  marketingStart: {
    label: "Vermarktungsbeginn",
    color: "#ea7600"
  },
  constructionFinish: {
    label: "Bau abgeschlossen",
    color: "#c20c36"
  }
};

/**
 *
 * @param min
 * @param max
 */
export const getYMin = (min, max) => min - ((max - min) * 0.1);

/**
 *
 * @param min
 * @param max
 */
export const getYMax = (min, max) => max + ((max - min) * 0.1);

// add respect to timelines and add + and - 3 months
/**
 *
 * @param min
 * @param max
 * @param timeline
 */
export const getXMin = (min, max, timeline) => {
  const { minWithTimeline, maxWithTimeline } = getMinMaxWithTimeline(min, max, timeline);

  return minWithTimeline - ((maxWithTimeline - minWithTimeline) * 0.1);
};

/**
 *
 * @param min
 * @param max
 * @param timeline
 */
export const getXMax = (min, max, timeline) => {
  const { minWithTimeline, maxWithTimeline } = getMinMaxWithTimeline(min, max, timeline);

  return maxWithTimeline + ((maxWithTimeline - minWithTimeline) * 0.1);
};

/**
 *
 * @param min
 * @param max
 */
export const getTickValues = (min, max) => generateArray(min, max, 5);

/**
 *
 * @param timeline
 */
export const createMarkers = (timeline) => [...Object.entries(timeline)]
  .map(([
    phase,
    date
  ], index) => ({
    axis: "x",
    value: date,
    lineStyle: {
      stroke: timelineSettings[phase].color,
      strokeWidth: 2,
      strokeDasharray: [
        4,
        4
      ],
      strokeDashoffset: index * 2,
      opacity: 1
    },
    textStyle: {
      fill: timelineSettings[phase].color,
      fontWeight: 600,
      fontSize: 10.25,
      textAnchor: "middle",
      letterSpacing: 0.25
    },
    legend: timelineSettings[phase].label,
    legendPosition: index % 2 === 0 ? "bottom" : "top",
    legendOffsetY: index === 0 ? 8 : index === 1 ? 8 : index === 2 ? 18 : 18
  }))
  .sort(({ date: dateA }, { date: dateB }) => dateA - dateB)
  .filter(({ value }) => value !== null);

/**
 *
 * @param value
 */
export const formatCurrency = (value) => `€ ${value.toLocaleString("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}`;

/**
 *
 * @param value
 */
export const formatCurrencyPerSquaremeter = (value) => `€ ${value.toLocaleString("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})} / m²`;

/**
 *
 * @param hasSquaremeter
 * @param yMax
 */
export const getAxisLeftLegendOffset = (hasSquaremeter, yMax) => {
  const offsetFromYMax = (5.5 * Math.floor(Math.log10(yMax)));

  return (hasSquaremeter)
    ? (-90 - offsetFromYMax)
    : (-75 - offsetFromYMax);
};

const interpolateRadius = (size) => size / 2;

const AnimatedCircle = ({
  style: {
    x,
    y,
    size,
    color,
    ...rest
  },
  blendMode,
  isInteractive,
  handleMouseEnter,
  handleMouseMove,
  handleMouseLeave,
  handleClick,
  ...restProp
}) => (<animated.circle
  cx={x}
  cy={y}
  r={size.to(interpolateRadius)}
  fill={color}
  style={{ mixBlendMode: blendMode }}
  onMouseEnter={isInteractive ? handleMouseEnter : undefined}
  onMouseMove={isInteractive ? handleMouseMove : undefined}
  onMouseLeave={isInteractive ? handleMouseLeave : undefined}
  onClick={isInteractive ? handleClick : undefined}
/>);

const AnimatedTriangle = ({
  style: {
    x,
    y,
    size,
    color
  },
  blendMode,
  isInteractive,
  handleMouseEnter,
  handleMouseMove,
  handleMouseLeave,
  handleClick
}) => {
  const getTrianglePointsString = (currentSize, currentX, currentY) => {
    const xNumber = currentX;
    const yNumber = currentY;

    const {
      x: xAbove,
      y: yAbove
    } = {
      x: xNumber,
      y: yNumber - (currentSize * 1.5)
    };

    const angles = [
      0,
      120,
      240
    ];

    const trianglePoints = angles
      .map((angle) => {
        const radians = (Math.PI / 180) * angle;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);

        return {
          x: (cos * (xAbove - xNumber)) + (sin * (yAbove - yNumber)) + xNumber,
          y: (cos * (yAbove - yNumber)) - (sin * (xAbove - xNumber)) + yNumber
        };
      });

    const trianglePointsString = Object.values(trianglePoints)
      .map(({ x, y }) => `${x},${y}`)
      .join(" ");

    return trianglePointsString;
  };

  return (
    <>
      <animated.polygon
        fill={color}
        points={to([
          size,
          x,
          y
        ], getTrianglePointsString)}
        style={{ mixBlendMode: blendMode }}
        onMouseEnter={isInteractive ? handleMouseEnter : undefined}
        onMouseMove={isInteractive ? handleMouseMove : undefined}
        onMouseLeave={isInteractive ? handleMouseLeave : undefined}
        onClick={isInteractive ? handleClick : undefined}
      />
    </>

  );
};

/**
 *
 * @param props
 */
export const ZKeyAwareNode = (props) => {
  const {
    node,
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
    onClick
  } = props;

  const handleMouseEnter = useCallback((event) => onMouseEnter?.(node, event), [
    node,
    onMouseEnter
  ]);
  const handleMouseMove = useCallback((event) => onMouseMove?.(node, event), [
    node,
    onMouseMove
  ]);
  const handleMouseLeave = useCallback((event) => onMouseLeave?.(node, event), [
    node,
    onMouseLeave
  ]);
  const handleClick = useCallback((event) => onClick?.(node, event), [
    node,
    onClick
  ]);

  return node.serieId === "dataWithZKey"
    ? <AnimatedCircle
      {...{
        ...props,
        handleMouseEnter,
        handleMouseMove,
        handleMouseLeave,
        handleClick
      }}
    />
    : <AnimatedTriangle
      {...{
        ...props,
        handleMouseEnter,
        handleMouseMove,
        handleMouseLeave,
        handleClick
      }}
    />;
};
