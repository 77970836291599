import LogoutButton from "~/src/ui/buttons/logout-button";
import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 */
export default function NotAuthorizedPage() {
  return (
    <div className="relative flex items-center justify-center h-screen">
      <div className="absolute top-8 right-8">
        <LogoutButton />
      </div>
      <div>
        <div className="md:hidden">
          <LogoColor />
        </div>
        <div className="hidden md:block">
          <LogoColor className="w-[416px] h-[48px]" />
        </div>
        <div className="mt-6 text-lg font-light leading-6">
          <p>Dieses Nutzerkonto ist nicht freigeschaltet.</p>
          <p>Bitte kontaktieren Sie unseren Support.</p>
        </div>
      </div>
    </div>
  );
}
