/* eslint-disable max-len */
/* eslint-disable import/max-dependencies */
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Listbox } from "@headlessui/react";
import cn from "classnames";

import { transformToBarData } from "../helpers";

import BarChart from "~/src/ui/charts/bar-chart";
import ChartError from "~/src/ui/charts/error";
import ChartLegend from "~/src/ui/charts/legend";
import MetaTooltip from "~/src/ui/charts/meta-tooltip";
import { groupModeLabels } from "~/src/modules/labels";
import useMediaQuery from "~/src/hooks/use-media-query";

/**
 *
 * @param props
 * @param props.statistic
 * @param props.isError
 */
// eslint-disable-next-line max-lines-per-function
export default function CountWohnungenByYearChart({
  statistic, isError
}) {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const isPrint = useMediaQuery("print");

  const groupModes = [
    "grouped",
    "stacked"
  ];
  const [
    groupMode,
    setGroupMode
  ] = useState(groupModes[(isMobile ? 1 : 0)]);

  const [
    barData,
    setBarData
  ] = useState(null);

  if (isError) {
    return <ChartError/>;
  }

  useEffect(() => {
    const transformedData = transformToBarData({ barData: statistic });

    transformedData.chartData = transformedData.chartData
      .map(({ year, ...rest }) => {
        let yearLabel = year;

        if (year === "2019") {
          yearLabel = `bis ${year}`;
        }
        else if (year === "2026") {
          yearLabel = `ab ${year}`;
        }

        return {
          ...rest,
          year: yearLabel
        };
      });

    setBarData(transformedData);
  }, [statistic]);

  if (barData?.chartData?.length === 0) {
    return null;
  }

  if (barData === null) {
    return <div className="h-[560px] w-full bg-white"/>;
  }

  if (isPrint && barData?.chartData?.length > 0) {
    return groupModes.map((groupModeOption) => (
      <div
        key={groupModeOption}
        className="w-full space-y-4 bg-white break-inside-avoid"
      >
        <h2 className="font-medium text-gray-900 text-md">Fertiggestellte Wohneinheiten pro Jahr ({groupModeLabels[groupModeOption]})</h2>
        <div className="h-80">
          <BarChart data={barData?.chartData} groupMode={groupModeOption} />
        </div>
        <ChartLegend data={barData?.legendData} simple />
      </div>
    ));
  }

  return (
    <div className="flex flex-col gap-4 p-2 bg-white border border-gray-200 rounded lg:p-4">
      <div className="flex flex-col gap-2 lg:items-center lg:flex-row">
        <h2 className="font-medium text-gray-900 text-md">Fertiggestellte Wohneinheiten pro Jahr</h2>
        <MetaTooltip
          {...((barData) && {
            meta: barData.meta
          })}
        />

        <Listbox value={groupMode} onChange={setGroupMode} className="relative flex-row hidden rounded cursor-pointer w-28 lg:flex" as="div">
          <Listbox.Button className="flex items-center justify-between px-2 py-1 bg-white border border-gray-300 rounded w-28">
            <span className="text-sm text-gray-900">{groupModeLabels[groupMode]}</span>
            <ChevronDownIcon className="w-5 h-5 text-gray-400" />
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 w-full py-1 mt-8 overflow-auto text-base bg-white rounded shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {groupModes.map((groupModeOption) => (
              <Listbox.Option
                key={groupModeOption}
                className={cn(
                  "cursor-pointer select-none relative px-2 py-1 text-gray-700 ui-active:text-gray-900 ui-active:bg-gray-100"
                )}
                value={groupModeOption}
              >

                {({ selected, active }) => (
                  <>
                    <span className={`${selected ? "font-medium" : "font-normal"} block truncate`}>
                      {groupModeLabels[groupModeOption]}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>

      </div>

      <div className="w-full h-[450px] relative pl-2">
        <div className="absolute w-full h-[450px]">
          <BarChart data={barData?.chartData} groupMode={groupMode} />
        </div>
      </div>
      {
        <div className="w-full">
          <ChartLegend data={barData?.legendData} simple />
        </div>
      }

    </div>
  );
}
