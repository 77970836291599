/**
 *
 * @param root0
 * @param root0.className
 */
export default function MarkerIconNone({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={className} />
  );
}
