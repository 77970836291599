import useSWR from "swr";
import { stringify } from "query-string";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.query
 */
export default function useInsolvencies({ query }) {
  const { data, error } = useSWR(query.type === "liegenschaft" ? `/insolvency-properties?${stringify(query, { arrayFormat: "comma" })}` : `/insolvencies?${stringify(query, { arrayFormat: "comma" })}`, fetcher);

  return {
    insolvencies: data?.payload?.insolvencies,
    pagination: data?.payload?.pagination,
    sortDirection: data?.payload?.sortDirection,
    isLoading: !error && !data,
    isError: error
  };
}
