import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.columns
 */
export default function ProjectTransaction({ columns }) {
  return (
    <div className="flex gap-2 break-inside-avoid">
      {
        columns.map((column, columnIndex) => (
          <div className="space-y-2 w-full" key={columnIndex}>
            {
              column.map(({ className, rows }, blockIndex) => (
                <div className={cn("space-y-2 border-t-4 p-4", { "h-full": columnIndex === 0 && blockIndex === 0 }, className)} key={`${columnIndex}-${blockIndex}`}>
                  {
                    rows.map((row, rowIndex) => (!Array.isArray(row)
                      ? (
                        <dl key={`${columnIndex}-${blockIndex}-${rowIndex}`}>
                          <dt className="uppercase">{row.label}</dt>
                          {
                            typeof row.value === "string"
                              ? <dd>{row.value}</dd>
                              : row.value
                          }
                        </dl>
                      )
                      : (
                        <div className="grid grid-cols-2 gap-4">
                          {
                            row.map(({ label, value }, cellIndex) => (
                              <dl key={`${columnIndex}-${blockIndex}-${rowIndex}-${cellIndex}`}>
                                <dt className="uppercase">{label}</dt>
                                {
                                  typeof value === "string"
                                    ? <dd>{value}</dd>
                                    : value
                                }
                              </dl>
                            ))
                          }
                        </div>
                      )))
                  }
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  );
}
