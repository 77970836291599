import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

import useNotification from "~/src/hooks/use-notification";

/**
 *
 */
const SuccessNotification = () => {
  const { success, hideSuccess } = useNotification();
  const [
    visible,
    setVisible
  ] = useState(false);

  useEffect(() => {
    if (success) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        hideSuccess(false);
      }, 10000);
    }
  }, [
    success,
    hideSuccess
  ]);

  return (
    <div className="fixed z-40 inset-0 px-4 py-6 pointer-events-none sm:p-6 max-w-[100vw]">
      <Transition
        show={visible}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex items-end justify-center px-4 py-6 sm:items-start sm:justify-end">

          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="shrink-0">
                  <svg className="h-6 w-6 text-emerald-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-800">
                    Erfolgreich gespeichert!
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Transition>
    </div>
  );
};

export default SuccessNotification;
