import { constructionPhaseLabels } from "~/src/modules/labels";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function ConstructionPhaseFilterTags({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const construction_phase = queryItems.split(",");

  function handleClick(cp) {
    const newCP = construction_phase.filter((item) => item !== cp).join(",");

    submit("construction_phase", newCP);
  }

  return construction_phase.map((cp, index) => (
    <DefaultFilterTag key={`cp_tag_${index}`} handleClick={() => handleClick(cp)}>
      <span>Bauphase: {constructionPhaseLabels[cp]}</span>
    </DefaultFilterTag>
  ));
}
