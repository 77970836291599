import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon, MegaphoneIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import {
  Fragment, useRef, useState
} from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import useAuth from "~/src/hooks/use-auth";
import useStore from "~/src/hooks/use-store";
import API from "~/src/modules/api";

const schema = yup.object({
  email: yup.string().email("Kein korrektes Email Format!").required("Pflichtfeld"),
  phone: yup.string().max(25, "Eingabe zu lang!").notRequired(),
  comment: yup.string().min(1, "Eingabe zu kurz!").max(2500, "Eingabe zu lang!")
    .required("Pflichtfeld")
}).required();

/**
 *
 * @param root0
 * @param root0.isOpen
 */
export default function ProjectSuggestionModal({ isOpen = false }) {
  const { user } = useAuth();
  const [
    isRequestSubmitting,
    setIsRequestSubmitting
  ] = useState(false);
  const [
    showSuccess,
    setShowSuccess
  ] = useState(false);
  const {
    register, handleSubmit, reset, formState: { errors, isSubmitted }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const toggleProjectSuggestionModal = useStore((state) => state.toggleProjectSuggestionModal);

  const initialInputRef = useRef(null);
  const { ref: initialHookFormRef, ...rest } = register("phone");

  function handleClose() {
    toggleProjectSuggestionModal();
    setTimeout(() => {
      reset();
      setShowSuccess(false);
      setIsRequestSubmitting(false);
    }, 400);
  }

  function onSubmit(data) {
    setIsRequestSubmitting(true);

    const suggestion = {
      ...data
    };

    API.post("/project-suggestion", {
      suggestion
    })
      .then((res) => {
        setShowSuccess(true);
      }).catch((e) => {
      })
      .finally(() => {
        setIsRequestSubmitting(false);
        handleClose();
      });
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog initialFocus={initialInputRef} as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-full px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >

            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">

              {showSuccess &&
                <>
                  <h4 className="text-lg font-medium text-gray-600">Vielen Dank für Ihren wichtigen Vorschlag!</h4>

                  <p className="mt-6 text-sm text-gray-600">Wir werden das Projekt prüfen und gegebenenfalls <br /> in die IMMOdeveloper-Datenbank aufnehmen.<br /><br />Bei Rückfragen werden wir uns erlauben, Sie direkt zu kontaktieren.</p>

                  <p className="mt-4 text-sm text-gray-600">
                    Mit freundlichen Grüßen, Ihr <strong><span style={{ color: "#006b55" }}>IMMO</span><span style={{ color: "#000" }}>united</span></strong><br />
                    Research-Team<br />
                    Kostenlose Infohotline: 0800 80 90 80
                  </p>

                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Schließen
                    </button>
                  </div>
                </>
              }

              {!showSuccess &&
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="sm:grid sm:grid-cols-5 sm:gap-4 sm:items-start">
                    <div className="items-center justify-center hidden w-12 h-12 rounded-full md:flex bg-secondary-darker">
                      <MegaphoneIcon className="w-6 h-6 text-secondary-darkest" aria-hidden="true" />
                    </div>
                    <div className="mt-1 sm:mt-2 sm:col-span-4">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Neues Projekt vorschlagen
                      </Dialog.Title>
                      <div className="mt-1 space-y-1">
                        <p className="text-sm text-gray-500">Sie möchten uns ein neues oder fehlendes Projekt melden?</p>
                        <p className="text-sm text-gray-500">Unser Research-Team wird umgehend die Erfassung Ihres vorgeschlagenen Projektes prüfen.</p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-10 space-y-6 sm:space-y-4">

                    <div className="sm:grid sm:grid-cols-5 sm:gap-4 sm:items-center">
                      <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                        Benutzer
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-4">
                        <p className="pl-1 text-sm font-semibold text-gray-900">{user?.username}</p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-5 sm:gap-4 sm:items-start">
                      <label htmlFor="email" className="block mt-2 text-sm font-medium text-gray-700">
                        E-Mail *
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-4">
                        <div className="relative rounded-md shadow-sm">
                          <input
                            disabled={isRequestSubmitting}
                            defaultValue={user?.email || ""} {...register("email")}
                            type="text"
                            name="email"
                            id="email"
                            autoComplete="benutzer-email"
                            className={cn("block w-full shadow-sm  sm:text-sm rounded-md", {
                              "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500": errors.email,
                              "border-gray-300 focus:ring-gray-500 focus:border-gray-500": !errors?.email
                            })}
                          />
                          {errors?.email &&
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                              <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-5 sm:gap-4 sm:items-center">
                      <label htmlFor="phone" className="block -mt-1 text-sm font-medium text-gray-700">
                        Telefon
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-4">
                        <div className="relative rounded-md shadow-sm">
                          <input
                            disabled={isRequestSubmitting}
                            defaultValue={""} {...register("phone")}
                            {...rest}
                            ref={(e) => {
                              initialHookFormRef(e);
                              initialInputRef.current = e;
                            }}
                            type="text"
                            name="phone"
                            id="phone"
                            autoComplete="benutzer-telefon"
                            className={cn("block w-full shadow-sm sm:text-sm rounded-md", {
                              "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500": errors.phone,
                              "border-gray-300 focus:ring-gray-500 focus:border-gray-500": !errors?.phone
                            })}
                          />
                          {errors?.phone &&
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                              <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-5 sm:gap-4 sm:items-start">
                      <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                        Projekt *
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-4">
                        <div className="relative rounded-md shadow-sm">
                          <textarea
                            disabled={isRequestSubmitting}
                            defaultValue={""} {...register("comment")}
                            rows={6}
                            id="comment"
                            placeholder="Bitte geben Sie uns die Ihnen bekannten Eckdaten wie Projektname, Adresse, Bauträger, Vermarkter an."
                            className={cn("block w-full shadow-sm  sm:text-sm rounded-md", {
                              "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500": errors.comment,
                              "border-gray-300 focus:ring-gray-500 focus:border-gray-500": !errors?.comment
                            })}
                          />
                          {errors?.comment &&
                            <div className="absolute right-0 flex items-center pr-3 pointer-events-none top-3">
                              <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="items-start justify-between md:flex">
                      <p htmlFor="comment" className="block text-xs text-gray-700">
                        Hinweis: * Pflichtfelder
                      </p>
                      <p className="h-4 mt-4 text-sm text-red-500 md:mt-0">
                        {isSubmitted && Object.keys(errors).length > 0 &&
                          <span>Bitte überprüfen Sie ihre Eingaben!</span>
                        }
                      </p>
                    </div>

                  </div>

                  <div className="mt-5 sm:mt-6">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={handleClose}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      >
                        Abbrechen
                      </button>

                      {isRequestSubmitting &&
                        <button
                          type="submit"
                          disabled
                          className="inline-flex justify-center py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm cursor-not-allowed w-44 bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-lighter"
                        >
                          <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <span>wird gesendet...</span>
                        </button>
                      }
                      {!isRequestSubmitting &&
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm w-44 bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-lighter"
                        >
                          Abschicken
                        </button>
                      }
                    </div>
                  </div>

                </form>
              }
            </div>

          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
