import { camelCase } from "lodash-es";

import HoverTooltip from "~/src/ui/charts/tooltip";
import collator from "~/src/modules/collator";
import {
  unitCategoryLabels, countWohnungenByYearLabels as legendPickerWohnungenByYear
} from "~/src/modules/labels";
import {
  colorPaletteWohnungenByYear
} from "~/src/modules/color-palletes";

const colorPalette = [
  // "hsl(168, 100%, 21%)",
  // "hsl(146, 47%, 62%)",
  // "hsl(99, 52%, 68%)",
  // "hsl(187, 38%, 45%)",
  // "hsl(206, 61%, 35%)"

  "hsl(345, 49%, 34%)",
  "hsl(325, 39%, 33%)",
  "hsl(290, 25%, 32%)",
  "hsl(245, 22%, 34%)",
  "hsl(217, 30%, 30%)",
  "hsl(203, 30%, 26%)"

];

/*
  #9B5668 344°, 29%, 47%
  #A86B7B 344°, 26%, 54%
  #B4808E 344°, 26%, 60%
  #C196A1 345°, 26%, 67%
  #CDABB3 346°, 25%, 74%
*/

const tooltipStructure = {
  buyers: [
    "label",
    "amount",
    "totalPercentage"
  ],
  rooms: [
    "label",
    "percentage",
    "averageSquareMeterPrice"
  ],
  scatter: [
    "top",
    "area",
    "price",
    "date"
  ],
  default: [
    "label",
    "amount",
    "percentage"
  ]
};

const chartLabels = {
  unitsBuyersAge: new Proxy(
    {},
    {
      get: (target, name) => {
        if (name === "unknown") {
          return "unbekannt";
        }
        else if ([
          "from",
          "to"
        ].some((rangeString) => name.includes(rangeString))) {
          const firstSplit = name.split("from");

          if (firstSplit.length === 1) {
            const [
              empty,
              to
            ] = firstSplit[0].split("to");

            return `< ${to} Jahre`;
          }

          const secondSplit = firstSplit[1].split("to");

          if (secondSplit.length === 1) {
            const [from] = secondSplit;

            return `${Number(from) - 1}+ Jahre`;
          }

          const [
            from,
            to
          ] = secondSplit;

          return `${from} - ${to} Jahre`;
        }

        // should never happen
        return "";
      }
    }
  ),
  unitsBuyersGender: {
    male: "männlich",
    female: "weiblich",
    unassignable: "nicht zuordenbar"
  },
  unitsBuyersPartnership: {
    yes: "Eigentümerpartnerschaft",
    no: "Keine Eigentümerpartnerschaft",
    unknown: "unbekannt"
  },
  unitsBuyersGraduated: {
    yes: "Akademiker",
    no: "Nicht Akademiker"
  },
  unitsBuyersProvenance: {
    national: "Österreich",
    foreign: "Ausland",
    unknown: "unbekannt"
  },
  unitsBuyersType: {
    private: "Privatperson",
    company: "Unternehmen",
    unknown: "unbekannt"
  },
  unitsMainCategory: Object.fromEntries(Object.entries(unitCategoryLabels).map(([
    key,
    { singular }
  ]) => [
    key,
    singular
  ])),
  unitsOfferRooms: {
    unknown: "unbekannt",
    ...Object.fromEntries(Array(5).fill().map((empty, index) => [
      `${index + 1}`,
      `${index + 1} Zimmer`
    ])),
    "6+": "6+ Zimmer"
  },
  unitsSaleRooms: {
    unknown: "unbekannt",
    ...Object.fromEntries(Array(5).fill().map((empty, index) => [
      `${index + 1}`,
      `${index + 1} Zimmer`
    ])),
    "6+": "6+ Zimmer"
  }
};

const unitsBuyersAgeSorting = [
  "to",
  "fromto",
  "from",
  "unknown"
];

const unitsBuyersGenderSorting = [
  "male",
  "female",
  "unassignable"
];

const unitsOfferRoomsSorting = [
  ...Array(5).fill().map((empty, index) => `${index + 1}`),
  "6+",
  "unknown"
];

const unitsSaleRoomsSorting = [
  ...Array(5).fill().map((empty, index) => `${index + 1}`),
  "6+",
  "unknown"
];

/**
 *
 * @param statistics
 * @param statistics.meta
 * @param statistics.meta.types
 * @param statistics.data
 * @param options
 * @param options.fancyLabels
 * @param statistics.tooltipType
 * @param tooltipType
 * @param statistics.meta.types.fancyLabels
 * @param tooltipType.fancyLabels
 * @param tooltipType.useTotalPercentage
 * @param tooltipType.averageSquareMeterPriceKey
 */

/**
 *
 * @param root0
 * @param root0.barData
 */

const barDataToColor = (barData) => barData.map((singleDataEntry) => {
  const colorForBar = Object.keys(singleDataEntry)
    .filter((entryKey) => entryKey !== "year" && !entryKey.endsWith("_project"))
    .map((entryKey) => colorPaletteWohnungenByYear[entryKey]);

  return {
    ...singleDataEntry,
    ...Object.assign({}, ...colorForBar)
  };
});

const barDataToLegend = (barData) => {
  const totalLegendKeys = barData.reduce((legendPairs, singleDataEntry) => {
    const legendData = Object.entries(singleDataEntry)
      .filter(([
        entryKey,
        value
      ]) => entryKey !== "year" && !entryKey.endsWith("_project") && value > 0)
      .map(([
        entryKey,
        value
      ]) => ({
        color: colorPaletteWohnungenByYear[entryKey][`${entryKey}Color`],
        label: legendPickerWohnungenByYear[entryKey]
      }))
      .toSorted(({ label: labelA }, { label: labelB }) => collator.compare(labelA, labelB));

    legendData.forEach((legendDataPoint) => {
      if (!legendPairs.some((legendPair) => legendPair.label === legendDataPoint.label)) {
        legendPairs.push(legendDataPoint);
      }
    });

    return legendPairs;
  }, []);

  return totalLegendKeys;
};

/**
 *
 * @param root0
 * @param root0.barData
 */
const transformToBarData = ({
  barData
}) => {
  const chartData = [...barDataToColor(barData)];
  const legendData = barDataToLegend(barData);

  return {
    chartData,
    legendData
  };
};

/**
 *
 * @param root0
 * @param root0.meta
 * @param root0.meta.types
 * @param root0.data
 * @param tooltipType
 * @param root1
 * @param root1.fancyLabels
 * @param root1.useTotalPercentage
 * @param root1.averageSquareMeterPriceKey
 */
const transformToPieData = (
  {
    meta: { types },
    data
  },
  tooltipType = "default",
  {
    fancyLabels = true,
    useTotalPercentage = false,
    averageSquareMeterPriceKey
  } = {
    fancyLabels: true,
    useTotalPercentage: false
  }
) => {
  let colorOffset = 0;
  const [
    mainType,
    subType
  ] = types;
  const camelCaseMainType = camelCase(mainType);

  const sortedDataEntries = [...Object.entries(data)]
    .sort(([categoryKeyA], [categoryKeyB]) => {
      switch (camelCaseMainType) {
        case "unitsBuyersAge": {
          const [
            sortingIndexA,
            sortingIndexB
          ] = [
            categoryKeyA,
            categoryKeyB
          ]
            .map((categoryKey) => unitsBuyersAgeSorting.indexOf(categoryKey.replace(/\d+/gu, "")));

          return sortingIndexA - sortingIndexB;
        }

        case "unitsBuyersGender": {
          const [
            sortingIndexA,
            sortingIndexB
          ] = [
            categoryKeyA,
            categoryKeyB
          ]
            .map((categoryKey) => unitsBuyersGenderSorting.indexOf(categoryKey));

          return sortingIndexA - sortingIndexB;
        }

        case "unitsOfferRooms": {
          const [
            sortingIndexA,
            sortingIndexB
          ] = [
            categoryKeyA,
            categoryKeyB
          ]
            .map((categoryKey) => unitsOfferRoomsSorting.indexOf(categoryKey));

          return sortingIndexA - sortingIndexB;
        }

        case "unitsSaleRooms": {
          const [
            sortingIndexA,
            sortingIndexB
          ] = [
            categoryKeyA,
            categoryKeyB
          ]
            .map((categoryKey) => unitsSaleRoomsSorting.indexOf(categoryKey));

          return sortingIndexA - sortingIndexB;
        }

        default:
          return 0;
      }
    });

  const pieData = sortedDataEntries
    .filter(([
      categoryKey,
      { absolute }
    ]) => absolute)
    .map(([
      categoryKey,
      category
    ], index) => {
      const slice = {
        id: categoryKey,
        label: chartLabels[camelCaseMainType][categoryKey],
        color: colorPalette[(index + colorOffset) % colorPalette.length],
        value: category.absolute,
        percentage: category.percentage,
        totalPercentage: category.percentage || category.totalPercentage
      };

      slice.tooltip = <HoverTooltip key={categoryKey} data={slice} tooltipStructure={tooltipStructure[tooltipType]} />;

      if (category.sub && Object.keys(category.sub).length > 0) {
        const camelCaseSubType = camelCase(subType);

        slice.sub = Object.entries(category.sub)
          .filter(([
            subCategoryKey,
            { absolute }
          ]) => absolute)
          .map(([
            subCategoryKey,
            subCategory
          ], index2) => {
            const subSliceLabel = fancyLabels
              ? [
                slice.label,
                chartLabels[camelCaseSubType][subCategoryKey]
              ].join(" | ")
              : chartLabels[camelCaseSubType][subCategoryKey];

            const subSliceLabelUse = ([
              "female",
              "male",
              "unknown"
            ].includes(categoryKey))
              ? chartLabels[camelCaseMainType][categoryKey]
              : subSliceLabel;

            const sliceChild = {
              id: [
                slice.id,
                subCategoryKey
              ].join("_"),
              label: subSliceLabelUse,
              color: colorPalette[(index + index2 + colorOffset) % colorPalette.length],
              value: subCategory.absolute,
              percentage: subCategory.percentage,
              totalPercentage: useTotalPercentage ? subCategory.totalPercentage : subCategory.percentage
            };

            if (averageSquareMeterPriceKey) {
              sliceChild.averageSquareMeterPrice = subCategory[averageSquareMeterPriceKey];
            }

            sliceChild.tooltip = <HoverTooltip key={subCategoryKey} data={sliceChild} tooltipStructure={tooltipStructure[tooltipType]} />;

            colorOffset += 1;

            return sliceChild;
          });
      }

      return slice;
    });

  return pieData;
};

const areaScaler = (datapointArea, min, max) => Math.round(8 + (16 * (datapointArea / max)));

const dataLabels = {
  dataWithZKey: "Mit Flächenangabe",
  dataWithoutZKey: "Ohne Flächenangabe"
};

/**
 *
 * @param data
 * @param options
 * @param options.xKey
 * @param options.yKey
 * @param options.zKey
 * @param options.color
 */
const transformToScatterData = (data, {
  xKey,
  yKey,
  zKey,
  color = "red"
}) => {
  const filteredData = data
    .filter(({
      [xKey]: xValue,
      [yKey]: yValue
    }) => {
      const neededValues = [
        xValue,
        yValue
      ];

      return neededValues
        .every((value, index) => {
          if (index === 0) {
            return value !== null;
          }
          const valueNumber = Number(value);

          return !Number.isNaN(valueNumber) && valueNumber !== 0;
        });
    });

  const {
    xMin,
    xMax,
    yMin,
    yMax,
    zMin,
    zMax
  } = filteredData
    .reduce(
      (
        accumulator,
        {
          [xKey]: xValue,
          [yKey]: yValue,
          [zKey]: zValue = 1
        }
      ) => ({
        xMin: Math.min(accumulator.xMin, new Date(xValue)),
        xMax: Math.max(accumulator.xMax, new Date(xValue)),
        yMin: Math.min(accumulator.yMin, yValue),
        yMax: Math.max(accumulator.yMax, yValue),
        zMin: Math.min(accumulator.zMin, zValue),
        zMax: Math.max(accumulator.zMax, zValue)
      }),
      {
        xMin: Infinity,
        xMax: -Infinity,
        yMin: Infinity,
        yMax: -Infinity,
        zMin: Infinity,
        zMax: -Infinity
      }
    );

  const dataWithZKey = filteredData.filter(({ [zKey]: zValue }) => zValue !== null);
  const dataWithoutZKey = filteredData.filter(({ [zKey]: zValue }) => zValue === null);

  return Object.entries({
    dataWithZKey,
    dataWithoutZKey
  })
    .map(([
      id,
      currentData
    ]) => ({
      id,
      label: dataLabels[id],
      color: color === "red" ? "#822c42bf" : "#275968bf",
      yMin,
      yMax,
      xMin,
      xMax,
      data: currentData.map((dataPoint) => ({
        id: dataPoint.id,
        x: new Date(dataPoint[xKey]),
        y: Number(dataPoint[yKey]),
        size: dataPoint[zKey] ? areaScaler(dataPoint[zKey], zMin, zMax) : 8,
        tooltip: <HoverTooltip
          data={dataPoint}
          xyz={{
            xKey,
            yKey,
            zKey
          }}
          tooltipStructure={tooltipStructure.scatter}
        />
      }))
    }));
};

export {
  transformToPieData, transformToScatterData, transformToBarData
};
