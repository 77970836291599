import { MapPinIcon } from "@heroicons/react/24/outline";
import { components } from "react-select";
import { default as Select } from "react-select/async";
import { debounce, throttle } from "throttle-debounce";

import API from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.currentStateId
 * @param root0.onUpdate
 */
export default function CitySelect({ currentStateId, onUpdate }) {
  const handleSelect = (selected) => {
    onUpdate({
      cityId: selected?.value,
      city: selected?.label
    });
  };

  const loadOptions = async (inputValue, cb) => {
    try {
      const res = await API.post("/search/city-suggestions", {
        city: inputValue,
        stateId: currentStateId
      });
      const options = res.data.payload.cities.map((city) => ({
        label: `${city.zipcode} ${city.name}`,
        value: city.id,
        body: {
          city
        }
      }));

      cb(options);
    }
    catch (e) {
      console.log("error", e);
    }
  };

  const loadOptionsDebounced = debounce(500, loadOptions);
  const loadOptionsThrottled = throttle(500, loadOptions);

  const handleLoadOptions = (inputValue) => new Promise((resolve, reject) => {
    if (inputValue.length < 4) {
      loadOptionsThrottled(inputValue, (options) => {
        resolve(options);
      });
    }
    else {
      loadOptionsDebounced(inputValue, (options) => {
        resolve(options);
      });
    }
  });

  return (
    <Select
      className="react-select"
      instanceId="city"
      styles={customStyles}
      value={""}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input
      }}
      cacheOptions={false}
      isClearable
      name="city"
      placeholder="PLZ / Ort"
      noOptionsMessage={noOptionsMessage}
      loadOptions={handleLoadOptions}
      onChange={handleSelect}
      blurInputOnSelect={true}
    />
  );
}

const noOptionsMessage = (data) => {
  if (data.inputValue.length > 0) {
    return "PLZ / Ort nicht gefunden...";
  }

  return "PLZ / Ort suchen...";
};

function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} className="pl-2">
      <MapPinIcon className="h-5 w-5 text-gray-400" /> {children}
    </components.Control>
  );
}

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = {
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af",
    fontSize: "16px"
  }),
  control: (styles, state) => ({
    ...styles,
    height: 44,
    minHeight: 44,
    borderRadius: 2,
    boxShadow: "state.isFocused ? \"0 0 0 20px rgba(56,178,172,1)\" : 0",
    borderColor: state.isFocused ? "#6b7280" : "#e5e5e5",
    borderWidth: state.isFocused ? "1px" : "1px",
    "&:hover": {
      borderColor: state.isFocused ? "#6b7280" : "#6b7280"
    }
  }),

  singleValue: (base) => ({
    ...base,
    borderRadius: "5px",
    padding: "0 6px",
    background: "#822c42",
    color: "white"
  })
};
