/* eslint-disable max-len */
import cn from "classnames";
import { useEffect, useState } from "react";

import { pluralize } from "~/src/modules/formatters";
import useCount from "~/src/hooks/use-count";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useStore from "~/src/hooks/use-store";
import useMediaQuery from "~/src/hooks/use-media-query";

/**
 *
 * @param props
 * @param props.className
 */
const ProjectCount = ({ className }) => {
  const isPrint = useMediaQuery("print");
  const { query } = useProjectQueryParams();
  const {
    projects: {
      total: projectCountTotal,
      filtered: projectCountFiltered,
      selected: projectCountSelected
    } = {},
    units: {
      selected: unitCountSelected
    } = {},
    mutate,
    isLoading: countIsLoading,
    isValidating: countIsValidating
  } = useCount({ query });

  const selectionLoading = useStore((state) => state.selectionLoading);
  const selectionMutate = useStore((state) => state.selectionMutate);

  const [
    countShouldBeSkeleton,
    setCountShouldBeSkeleton
  ] = useState(false);

  useEffect(() => {
    const mutating = async () => {
      if (!isPrint) {
        setCountShouldBeSkeleton(true);
        await mutate();
      }
    };

    mutating();
  }, [selectionMutate]);

  useEffect(() => {
    if (!selectionLoading && !countIsLoading && !countIsValidating) {
      setCountShouldBeSkeleton(false);
    }
  }, [
    selectionLoading,
    countIsLoading,
    countIsValidating
  ]);

  const textType = "text-sm";

  if (!projectCountTotal || countShouldBeSkeleton || false) {
    return (
      <div
        className={cn(
          "flex flex-col gap-[8px] items-start group-data-[collapsed=false]:xl:items-end justify-end",
          className
        )}
      >
        <div className="h-[1rem] w-64 animate-pulse rounded bg-gray-300" />
        <div className="h-[1rem] w-64 animate-pulse rounded bg-gray-300" />
      </div>
    );
  }

  const displayOf = projectCountSelected !== projectCountTotal;

  return (
    <span
      className={cn(
        "flex text-xs group-data-[collapsed=false]:@[1080px]:text-right print:text-center group-data-[collapsed=false]:@[1080px]:print:text-center",
        className,
        {
          "flex-row gap-1": isPrint,
          "flex-col": !isPrint
        }
      )}
    >
      {
        displayOf && !isPrint
          ? (
            <>
              <span>
                <span className={`${textType} font-bold`}>{projectCountSelected?.toLocaleString("de") || 0}</span>
                <span className={`${textType}`}> von </span>
                <span className={`${textType} font-bold`}>{projectCountTotal?.toLocaleString("de") || 0} {pluralize("Projekt", "Projekten", "Projekten", projectCountTotal)}</span>
                <span className={`${textType}`}> mit</span>
              </span>
              <span>
                <span className={`${textType}`}>{unitCountSelected?.toLocaleString("de") || 0} {pluralize("Wohneinheit", "Wohneinheiten", "Wohneinheiten", unitCountSelected)}</span>
                <span className={`${textType}`}> ausgewählt</span>
              </span>
            </>
          )
          : (
            <>
              <span>
                <span className={`${textType} font-bold`}>{projectCountSelected?.toLocaleString("de") || 0} {pluralize("Projekt", "Projekte", "Projekte", projectCountSelected)}</span>
                <span className={`${textType}`}> mit</span>
              </span>
              <span>
                <span className={`${textType}`}>{unitCountSelected?.toLocaleString("de") || 0} {pluralize("Wohneinheit", "Wohneinheiten", "Wohneinheiten", unitCountSelected)}</span>
                <span className={`${textType}`}> ausgewählt</span>
              </span>
            </>
          )
      }
    </span>
  );
};

export default ProjectCount;
