/* eslint-disable max-len */
import DetailStatistics from "~/src/features/projects-statistic-tile/chart.jsx";
import Page from "~/src/ui/page/index.jsx";
import ProjectsFiltersTile from "~/src/features/projects-filters-tile/index.jsx";
import StatsBox from "~/src/features/stats-box/index.jsx";

/**
 *
 */
const StatisticsPage = () => (
  <Page title="Statistik">
    <div className="flex h-[calc(100%-7rem)] max-h-[calc(100%-7rem)] gap-4 pb-28 lg:h-[calc(100vh-3rem)] lg:max-h-[calc(100vh-3rem)] lg:overflow-hidden lg:p-4 lg:pb-4">
      <ProjectsFiltersTile />

      <div className="flex w-full flex-col justify-between gap-4">
        <div className="z-20 @container">
          <StatsBox />
        </div>
        <div className="pointer-events-none z-10 flex h-full flex-col gap-1 pb-14 lg:pointer-events-auto lg:overflow-hidden lg:pb-0">
          <DetailStatistics />
        </div>
      </div>
    </div>
  </Page>
);

export default StatisticsPage;
