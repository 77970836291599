import { useEffect, useState } from "react";

import { transformToPieData } from "../../helpers";

import useUnitCategories from "~/src/hooks/use-unit-categories";
import CategoryDropdown from "~/src/ui/charts/dropdown";
import ChartError from "~/src/ui/charts/error";
import ChartLegend from "~/src/ui/charts/legend";
import MetaTooltip from "~/src/ui/charts/meta-tooltip";
import PieChart from "~/src/ui/charts/pie-chart";

/**
 *
 * @param props
 * @param props.statistic
 * @param props.isLoading
 * @param props.isError
 */
export default function RoomsOfferChart({
  statistic, isLoading, isError
}) {
  const [
    displayWithChild,
    setDisplayWithChild
  ] = useState(false);
  const [
    pieDataWithChild,
    setPieDataWithChild
  ] = useState([]);
  const [
    pieData,
    setPieData
  ] = useState([]);
  const [
    categories,
    setCategories
  ] = useState([]);
  const [
    activeCategory,
    setActiveCategory
  ] = useState(null);
  const { unit_categories: unitCategories } = useUnitCategories();

  const changeDisplayWithChild = () => {
    if (displayWithChild) {
      setDisplayWithChild(false);
    }
    else {
      setDisplayWithChild(true);
    }
  };

  const createPieDataWithChild = () => {
    const withChild = Object.entries(pieData)
      .filter(([
        sliceKey,
        sliceValue
      ]) => sliceValue.id === activeCategory && sliceValue.sub)
      .map(([
        sliceKey,
        sliceValue
      ]) => {
        for (const childValue of sliceValue.sub) {
          childValue.id = `${sliceKey}_${childValue.id}`;
        }

        return sliceValue.sub;
      });

    setPieDataWithChild(withChild.flat());
  };

  useEffect(() => {
    if (typeof statistic !== "undefined") {
      setCategories(
        Object.entries(statistic.data)
          .filter(([
            categoryKey,
            { absolute }
          ]) => absolute > 0).map(([categoryKey]) => categoryKey)
          .sort((categoryCodeA, categoryCodeB) => {
            const sortingA = unitCategories ? Number(unitCategories.find(({ code }) => code === categoryCodeA).sorting) : 0;

            const sortingB = unitCategories ? Number(unitCategories.find(({ code }) => code === categoryCodeB).sorting) : 0;

            return sortingA - sortingB;
          })
      );

      setPieData(
        transformToPieData(
          statistic,
          "rooms",
          {
            fancyLabels: false,
            averageSquareMeterPriceKey: "averageOfferPriceNormalPerSquareMeter"
          }
        )
      );
    }
  }, [statistic]);

  useEffect(() => {
    if (pieData.length > 0) {
      createPieDataWithChild();
    }
  }, [pieData]);

  useEffect(() => {
    if (activeCategory !== null && pieData.some((slice) => Boolean(slice.sub))) {
      createPieDataWithChild();
    }
  }, [activeCategory]);

  useEffect(() => {
    if (categories.length && activeCategory === null) {
      setActiveCategory(categories[0]);
    }
  }, [categories]);

  if (isError) {
    return (
      <div className="flex flex-col gap-4 p-4 bg-white border border-gray-200 rounded shadow-sm">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-medium">Angebotsdaten</h2>
        </div>
        <ChartError />
      </div>
    );
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col p-4 bg-white border border-gray-200 rounded shadow-sm">
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-medium">Angebotsdaten</h2>
          <MetaTooltip
            {...((pieData) && {
              meta: statistic.meta
            })}
          />
        </div>
        <CategoryDropdown activeCategory={activeCategory} categories={categories} setActiveCategory={setActiveCategory}/>
      </div>

      <div className="w-full h-[450px] relative">
        <div className="absolute w-full h-[450px]">
          <PieChart
            data={pieDataWithChild}
            changeDisplayWithChild={changeDisplayWithChild}
            sortByValue={false}
            small={false}
          />
        </div>
      </div>
      <div className="w-full">
        <ChartLegend data={pieDataWithChild}/>
      </div>
    </div>
  );
}
