const projectRentPriceChartHasError = "projectRentPriceChartHasError";
const projectPurchasePriceChartHasError = "projectPurchasePriceChartHasError";
const bothProjectPriceChartsHaveErors = "bothProjectPriceChartsHaveErrors";

const createStateSetter =
  (errorFlagKeys) => (set) => (thisChartHasError) => set((state) => {
    const updates = {};

    const noChangeInState =
        thisChartHasError === state[errorFlagKeys.thisChart];

    if (noChangeInState) {
      return updates;
    }

    updates[errorFlagKeys.thisChart] = thisChartHasError;

    const noErrorWhenBothChartsWereError =
      state[bothProjectPriceChartsHaveErors] && !thisChartHasError;

    const bothHaveError = thisChartHasError && state[errorFlagKeys.otherChart];

    const bothHaveErrorWhenNotBefore =
      bothHaveError && !state[bothProjectPriceChartsHaveErors];

    if (noErrorWhenBothChartsWereError) {
      updates[bothProjectPriceChartsHaveErors] = false;
    }

    if (bothHaveErrorWhenNotBefore) {
      updates[bothProjectPriceChartsHaveErors] = true;
    }

    return updates;
  });

const purchasePriceErrorFlagKeys = {
  thisChart: projectPurchasePriceChartHasError,
  otherChart: projectRentPriceChartHasError
};

const setProjectPurchasePriceChartHasError =
    createStateSetter(purchasePriceErrorFlagKeys);

const reverseErrorFlagKeys = (errorFlagKeys) => ({
  thisChart: errorFlagKeys.otherChart,
  otherChart: errorFlagKeys.thisChart
});

const rentPriceErrorFlagKeys = reverseErrorFlagKeys(
  purchasePriceErrorFlagKeys
);

const setProjectRentPriceChartHasError =
  createStateSetter(rentPriceErrorFlagKeys);

export {
  setProjectRentPriceChartHasError,
  setProjectPurchasePriceChartHasError
};
