import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";

/**
 *
 * @param root0
 * @param root0.open
 * @param root0.close
 * @param root0.resetDropdown
 */
export default function WatchlistDropdownHeader({
  open, close, resetDropdown
}) {
  useEffect(() => {
    if (!open) {
      resetDropdown();
    }
  }, [
    open,
    resetDropdown
  ]);

  return (
    <div className="pb-3 flex justify-between items-center border-b border-gray-200">
      <p className="font-light">Speichern in...</p>
      <button type="button" className="focus:outline-none text-gray-500 hover:text-gray-700" onClick={close}>
        <XMarkIcon className="w-5 h-5" />
      </button>
    </div>
  );
}
