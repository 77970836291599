import { PlusIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

/**
 *
 */
export default function EmptyProjectBucket() {
  return (
    <div className="mt-20 text-center">
      <h3 className="mt-2 text-sm font-medium text-gray-900">Diese Merkliste ist noch leer.</h3>
      <p className="mt-1 text-sm text-gray-500">Fügen Sie neue Projekte in der Projektliste hinzu.</p>
      <div className="mt-6">
        <Link
          to="/liste"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Zu den Projekten
        </Link>
      </div>
    </div>
  );
}
