import { useParams } from "react-router-dom";

import RoomsOfferChart from "~/src/features/charts/rooms/offer";
import RoomsSaleChart from "~/src/features/charts/rooms/sale";
import ChartContainer from "~/src/ui/containers/chart-container";
import ChartError from "~/src/ui/charts/error";
import useProjectStatistics from "~/src/hooks/use-project-statistics";

/**
 *
 */
export default function DetailStatistics() {
  const { id: projectId } = useParams();

  const typesOffer = [
    "units-main-category",
    "units-offer-rooms"
  ];
  const structureOffer = "composition";
  const {
    statistics: offerRooms,
    isLoading: isLoadingOffer,
    isError: isErrorOffer
  } = useProjectStatistics({
    projectId,
    types: typesOffer,
    structure: structureOffer
  });

  const typesSale = [
    "units-main-category",
    "units-sale-rooms"
  ];
  const structureSale = "composition";
  const {
    statistics: saleRooms,
    isLoading: isLoadingSale,
    isError: isErrorSale
  } = useProjectStatistics({
    projectId,
    types: typesSale,
    structure: structureSale
  });

  const isError = isErrorOffer && isErrorSale;
  const isEmpty = !saleRooms && !offerRooms;
  const showNoResultsMessage = isError || isEmpty;

  if (showNoResultsMessage) {
    return <ChartError />;
  }

  if (isErrorOffer && isErrorSale) {
    return (<ChartError />);
  }

  return (
    <ChartContainer cols={2} >
      <RoomsOfferChart statistic={offerRooms} isLoading={isLoadingOffer} isError={isErrorOffer}/>
      <RoomsSaleChart statistic={saleRooms} isLoading={isLoadingSale} isError={isErrorSale}/>
    </ChartContainer>
  );
}
