import { useParams } from "react-router-dom";

import ProjectsMapTile from "../projects-map-tile";

import ContentSection from "~/src/ui/sections/content-section";
import useProject from "~/src/hooks/use-project";

/**
 *
 */
const ProjectOverviewMap = () => {
  const { id } = useParams();
  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    slug, automated
  } = project;

  return (
    <ContentSection id="map" className="h-96">
      <ProjectsMapTile projectSlugs={slug} isSimple={true} automated={automated} handleCheckbox={() => {}} />
    </ContentSection>
  );
};

export default ProjectOverviewMap;
