import { gkaNotices } from "./labels";

import { formatArea } from "~/src/modules/formatters";

/**
 *
 * @param arr
 * @param key
 */
const getUnique = (arr, key) => [
  ...new Map(arr.map((item) => [
    item[key],
    item
  ])).values()
];

/**
 *
 * @param latLngs
 */
const parsePolygon = (latLngs) => {
  let polygon = "";
  let polygonStart = "";

  latLngs[0].forEach((latLng, index) => {
    const coordinate = `${latLng.lng}~${latLng.lat}`;

    if (index === 0) {
      polygonStart = coordinate;
      polygon += polygonStart;

      return;
    }
    polygon += `-${coordinate}`;
  });
  polygon += `-${polygonStart}`;

  return polygon;
};

/**
 *
 * @param project
 */
const displayRechtsform = (project) => {
  if (project?.rentable && project?.buyable) {
    return "Eigentum, Miete";
  }
  if (project?.rentable) {
    return "Miete";
  }
  if (project?.buyable) {
    return "Eigentum";
  }

  return "nicht bekannt";
};

/**
 *
 * @param value
 */
const castArray = (value) => (Array.isArray(value) ? value : [value]);

/**
 *
 * @param project
 * @param withBreak
 */
const generateGkaNotice = (project, withBreak = false) => {
  const notices = [];

  const hasBaurecht = (
    project?.transaction_filter === "baurecht" ||
    project?.transactions.some(({ transaction_category: { code } }) => code === "baurecht")
  );

  if (hasBaurecht) {
    notices.push("(keine Berechnung möglich)");

    notices.push([
      "Eine Berechnung des Grundkostenanteil ist nicht möglich,",
      "da es sich bei der Projektliegenschaft um eine Baurechtsliegenschaft handelt."
    ].join(withBreak ? "<br>" : " "));
  }
  else if (project?.gka && parseFloat(project?.gka) !== 0) {
    notices.push(gkaNotices[project?.gka_from]);

    if (project?.gka_notes) {
      notices.push(project.gka_notes.replace(/\n/gu, "<br>"));
    }
  }
  else if (project?.gka_notes) {
    notices.push("(keine Berechnung möglich)");
    notices.push(project.gka_notes.replace(/\n/gu, "<br>"));
  }

  return notices;
};

/**
 *
 * @param props
 * @param props.area
 * @param props.toArea
 */
const areaRange = ({ area, toArea }) => {
  if (area && toArea) {
    return `${formatArea(area)} - ${formatArea(toArea)}`;
  }

  if (area) {
    return `${formatArea(area)}`;
  }

  return "-";
};

export {
  areaRange,
  generateGkaNotice,
  getUnique,
  parsePolygon,
  displayRechtsform,
  castArray
};
