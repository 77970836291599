/* eslint-disable no-undefined */
/* eslint-disable import/max-dependencies */
/* eslint-disable max-len */
import {
  useRef, useState, useEffect
} from "react";

import SortDirectionButton from "../sort-direction-button/index.new";
import SortProjectsDropdown from "../sort-projects-dropdown/index.new";

import Pagination from "~/src/features/pagination/index.new";
import ProjectList from "~/src/features/project-list";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import SelectAllProjectsFilter from "~/src/ui/filters/select-all-projects-filter";
import SelectOnlyMarkedProjects from "~/src/ui/filters/only-marked-projects-filter";
import useStore from "~/src/hooks/use-store";
import useCount from "~/src/hooks/use-count";
import useMediaQuery from "~/src/hooks/use-media-query";

/**
 *
 */
// eslint-disable-next-line max-lines-per-function, max-statements
const ProjectsListTile = () => {
  const { query, setQuery } = useProjectQueryParams();

  const isHybrid = useMediaQuery("(max-width: 1380px)");

  const currentSelectionStatus = useStore((state) => state.currentSelectionStatus);
  const selectionLoading = useStore((state) => state.selectionLoading);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);
  const setChange = useStore((state) => state.setChange);
  const projectListRef = useRef(null);

  const onlyMarkedProjects = useStore((state) => state.onlyMarkedProjects);
  const setOnlyMarkedProjects = useStore((state) => state.setOnlyMarkedProjects);

  const {
    projects: {
      selected: projectCountSelected,
      filtered: projectCountFiltered
    } = {},
    mutate: mutateCount
  } = useCount({ query });

  const [
    isHandleItemClickDisabled,
    setIsHandleItemClickDisable
  ] = useState(false);

  useEffect(() => {
    if (projectCountSelected === 0) {
      setOnlyMarkedProjects(false);
    }
  }, [projectCountSelected]);

  const handleItemClickTimeoutInterval = 1250;

  const [
    currentTotal,
    setCurrentTotal
  ] = useState();

  useEffect(() => {
    setQuery({
      marked: onlyMarkedProjects
    });
  }, [onlyMarkedProjects]);

  const [
    isLoading,
    setIsLoading
  ] = useState(false);

  const [
    selectionStatus,
    setSelectionStatus
  ] = useState(null);

  const handleSortChange = (sort) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    setQuery({
      sort,
      page: 1
    });

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  const handleSortDirectionChange = (direction) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    setQuery({
      direction,
      page: 1
    });

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  const handlePageChange = (page) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    setQuery({ page });

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  const updateSelection = (update) => {
    setChange(update);
  };

  const setAll = (value) => {
    const newMainType = value ? "addAll" : "removeAll";

    setMainSelectionType(newMainType);

    updateSelection({
      type: newMainType,
      source: "list",
      sourcePage: query.page
    });

    if (newMainType === "removeAll") {
      setOnlyMarkedProjects(false);
    }
  };

  const handleAllChecked = async (value) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    if (!selectionLoading) {
      const checkAll = !(projectCountSelected === projectCountFiltered || projectCountSelected !== 0);

      await setAll(checkAll);
    }

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  const handleDisplayOnlyMarked = (value) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    setOnlyMarkedProjects(value);

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  return (
    <div className="relative flex flex-col flex-grow h-full overflow-hidden bg-white border-gray-200 rounded lg:border">
      <div className="top-0 z-10 flex flex-col items-center justify-between gap-2 p-4 text-sm bg-white border-b border-gray-200 lg:flex-row">
        <div className="flex justify-between gap-2 w-full sm:hidden">
          <Pagination reducedPagination={isHybrid} siblingCount={(isHybrid ? 0 : 1)} page={query.page} count={currentTotal} onPageChange={handlePageChange} isLoading={isLoading && !currentTotal} />
          <div className="flex items-center h-full gap-0.5 lg:gap-2">
            <SortProjectsDropdown isHandleItemClickDisabled={isHandleItemClickDisabled} sort={query.sort} hasStreet={Boolean(query.street)} onSortChange={handleSortChange} />

            <SortDirectionButton isHandleItemClickDisabled={isHandleItemClickDisabled} direction={query.direction} onSortDirectionChange={handleSortDirectionChange} />
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex sm:flex-col w-full justify-between lg:w-auto xl:justify-start lg:flex-col xl:flex-row gap-2">
            <SelectAllProjectsFilter
              selectionStatus={(query.marked && currentSelectionStatus !== "none")
                ? "all"
                : currentSelectionStatus}
              handleCheck={handleAllChecked}
              disabled={selectionLoading || projectCountFiltered === 0 || isHandleItemClickDisabled }
            />
            <SelectOnlyMarkedProjects
              onlyMarkedProjects={onlyMarkedProjects}
              handleCheck={handleDisplayOnlyMarked}
              disabled={selectionLoading || isHandleItemClickDisabled}
            />
          </div>

          <div className="hidden sm:flex items-center gap-2 h-[30px]">
            <div className="flex items-center h-full gap-2">
              <SortProjectsDropdown isHandleItemClickDisabled={isHandleItemClickDisabled} sort={query.sort} hasStreet={Boolean(query.street)} onSortChange={handleSortChange} />

              <SortDirectionButton isHandleItemClickDisabled={isHandleItemClickDisabled} direction={query.direction} onSortDirectionChange={handleSortDirectionChange} />
            </div>
            <div className="sm:flex">
              <Pagination reducedPagination={isHybrid} siblingCount={(isHybrid) ? 0 : 1} isHandleItemClickDisabled={isHandleItemClickDisabled} page={query.page} count={currentTotal} onPageChange={handlePageChange} isLoading={isLoading && !currentTotal} />
            </div>
          </div>
        </div>
      </div>
      <div ref={projectListRef} className="h-full overflow-y-scroll overscroll-y-contain">
        <ProjectList
          setCurrentTotal={setCurrentTotal}
          setIsLoading={setIsLoading}
          setSelectionStatus={setSelectionStatus}
        />
      </div>
    </div>
  );
};

export default ProjectsListTile;
