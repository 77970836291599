import cn from "classnames";

/**
 *
 * @param props
 * @param props.className
 */
const ItemSkeleton = ({ className }) => (
  <div className={cn("animate-pulse mb-12 px-4 py-6 sm:px-6", className)}>
    <div className="lg:grid grid-cols-9">

      <div className="col-span-6 xl:col-span-3 2xl:col-span-4 space-y-4">
        <div className="h-5 w-1/2 bg-gray-300 rounded" />
        <div className="h-5 w-1/3 bg-gray-300 rounded" />
        <div className="h-1" />
        <div className="h-5 w-1/2 bg-gray-300 rounded" />
        <div className="h-5 w-1/2 bg-gray-300 rounded" />
        <div className="h-5 w-1/2 bg-gray-300 rounded" />
      </div>

      <div className="lg:ml-8 xl:ml-0 mt-12 lg:mt-0 h-32 bg-gray-300 col-span-3 xl:col-span-2 w-64 lg:w-48 xl:w-64 rounded" />

      <div className="xl:ml-12 2xl:ml-0 mt-12 xl:mt-0 col-span-3 xl:col-span-4 2xl:col-span-3 space-y-4">
        <div className="h-5 w-1/2 bg-gray-300 rounded" />
        <div className="h-5 bg-gray-300 rounded" />
        <div className="h-5 bg-gray-300 rounded" />
        <div className="h-1" />
        <div className="h-5 bg-gray-300 rounded" />
        <div className="h-5 bg-gray-300 rounded" />
      </div>

    </div>
  </div>
);

export default ItemSkeleton;
