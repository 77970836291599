import {
  CubeIcon,
  KeyIcon,
  UsersIcon
} from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";

import useMediaQuery from "~/src/hooks/use-media-query";
import { companyRoleLabels } from "~/src/modules/labels";

/**
 *
 * @param props
 * @param props.role
 * @param props.automated
 */
export default function ProjectCompany({ role, automated }) {
  const isPrint = useMediaQuery("print");

  return (
    <div className="print:flex">
      <div
        className="flex items-center gap-2 text-sm text-gray-500 print:text-xs"
        data-tooltip-id={`tooltip-company-role-${role.name}`}
        data-tooltip-content={companyRoleLabels[automated ? "seller" : role.name]}
        data-tooltip-place="left"
      >
        {role.name === "builder" &&
        <>
          <CubeIcon className="h-4 w-4 mt-0.5 shrink-0 focus:outline-none print:leading-4" />
          <Tooltip
            id="tooltip-company-role-builder"
            positionStrategy="fixed"
            className="z-50"
            delayShow={500}
          />
        </>
        }
        {role.name === "owner" &&
        <>
          <KeyIcon className="h-4 w-4 mt-0.5 shrink-0 focus:outline-none print:leading-4" />
          <Tooltip
            id="tooltip-company-role-owner"
            positionStrategy="fixed"
            className="z-50"
            delayShow={500}
          />
        </>
        }
        {role.name === "marketer" &&
        <>
          <UsersIcon className="h-4 w-4 mt-0.5 shrink-0 focus:outline-none print:leading-4" />
          <Tooltip
            id="tooltip-company-role-marketer"
            positionStrategy="fixed"
            className="z-50"
            delayShow={500}
          />
        </>
        }
        <span className="mt-0.5  text-gray-500 font-light leading-5 print:leading-4" >
          {role.private ? "Privat" : role.company?.name}
        </span>
      </div>
    </div>
  );
}
