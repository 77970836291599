/* eslint-disable max-lines-per-function */
import {
  smoothZoomAndPan
} from "../_common/_exports.js";

import {
  createPopupClass,
  getIcon
} from "./create-markers/_exports.js";

/**
 *
 * @param props - The root object.
 * @param props.currentPoints - The root object.
 * @param props.map - The root object.
 * @param props.clusterExpansionZooms - The root object.
 * @param props.currentZoom - The root object.
 * @param props.setActiveProject - The root object.
 * @param props.activeProject - The root object.
 * @param props.setActivePopup - The root object.
 * @param props.activePopup - The root object.
 * @param props.point - The root object.
 * @param props.isSimple - The root object.
 * @param props.mainSelectionType - The root object.
 * @example
 */
const createMarkers = ({
  activePopup,
  activeProject,
  clusterExpansionZooms,
  currentZoom,
  isSimple,
  mainSelectionType,
  map,
  point,
  setActivePopup,
  setActiveProject
}) => {
  const Popup = createPopupClass();

  const position = {
    lat: point.geometry.coordinates[1],
    lng: point.geometry.coordinates[0]
  };

  const { id } = point;

  if (point.properties.cluster) {
    const label = point.properties.point_count.toString();

    const icon = getIcon({
      isCluster: true,
      point
    });

    const marker = new window.google.maps.Marker({
      id,
      icon,
      label: {
        color: "#fff",
        text: `${label}`
      },
      map,
      position
    });

    // To remove the marker with animation

    marker.addListener("click", () => {
      const newZoom = clusterExpansionZooms[marker.id];

      smoothZoomAndPan(map, newZoom, currentZoom, marker);

      setActiveProject(null);

      if (activePopup) {
        activePopup.setMap(null);
        setActivePopup(null);
      }
    });

    return marker;
  }

  const { selected, unselected } = point.properties;

  const opacity = (mainSelectionType === "addAll")
    ? ((unselected !== undefined && unselected))
    : !(selected !== undefined && selected);

  const icon = getIcon({
    isCluster: false,
    opacity,
    point
  });

  const marker = new window.google.maps.Marker({
    id,
    icon,
    label: "",
    map,
    position
  });

  marker.addListener("focus", () => {
    const markerElement = this.getIcon().parentNode;

    markerElement.style.outline = "none";
  });

  marker.addListener("click", () => {
    if (activeProject !== point.properties.slug && !isSimple) {
      setActiveProject(point.properties.slug);

      const content = document.querySelector("#content");
      const bounds = map.getBounds();

      // panForMarker(map, marker);

      const popup = new Popup({
        bounds,
        content,
        position,
        marker,
        map
      });

      popup.setMap(map);
      setActivePopup(popup);
    }
  });

  return marker;
};

export default createMarkers;
