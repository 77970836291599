import cn from "classnames";
import { components } from "react-select";
import { default as Select } from "react-select/async";
import { debounce, throttle } from "throttle-debounce";

import API from "~/src/modules/api";
import MarkerIcon from "~/src/ui/icons/marker-icon";

/**
 *
 * @param props
 * @param props.title
 * @param props.onSelect
 * @param props.className
 * @param props.isMobile
 */
export default function LocationSearchSelect({
  title,
  onSelect,
  isMobile = false,
  className
}) {
  const handleSelect = (selected) => {
    if (selected) {
      onSelect({
        title: selected.label,
        coords: selected.body.coords
      });
    }
    else {
      onSelect(null);
    }
  };

  return (
    <Select
      className={cn("react-select", className)}
      instanceId="location-search"
      styles={customStyles(isMobile)}
      value={title
        ? {
          label: title,
          value: 1
        }
        : ""}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input
      }}
      cacheOptions={false}
      isClearable
      name="location-search"
      placeholder="Adresse anzeigen"
      noOptionsMessage={noOptionsMessage}
      loadOptions={handleLoadOptions}
      onChange={handleSelect}
      blurInputOnSelect={true}
    />
  );
}

function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} >
      <MarkerIcon className="shrink-0 text-primary-lighter w-[15px] h-[15px]" /> {children}
    </components.Control>
  );
}

const loadOptions = async (inputValue, cb) => {
  try {
    const res = await API.post("/search/autosuggest-locations-maps", {
      input: inputValue
    });

    const options = res.data.payload.locations.map((location) => ({
      label: location?.address,
      value: location?.id,
      body: {
        coords: location?.position
      }
    }));

    cb(options);
  }
  catch (e) {
    console.log("error", e);
  }
};

const loadOptionsDebounced = debounce(500, loadOptions);
const loadOptionsThrottled = throttle(500, loadOptions);

const handleLoadOptions = (inputValue) => new Promise((resolve, reject) => {
  if (inputValue.length < 4) {
    loadOptionsThrottled(inputValue, (options) => {
      resolve(options);
    });
  }
  else {
    loadOptionsDebounced(inputValue, (options) => {
      resolve(options);
    });
  }
});

const noOptionsMessage = (data) => {
  if (data.inputValue.length > 0) {
    return "Keine Vorschläge gefunden...";
  }

  return "Adresse suchen...";
};

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = (isMobile) => ({
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af",
    fontSize: "inherit",
    fontWeight: 300
  }),
  control: (styles, state) => ({
    ...styles,
    "&:hover": {
      borderColor: "#cfcfcf"
    },
    borderColor: state.isFocused ? "#cfcfcf" : "#e5e5e5",
    borderRadius: "0.25rem",
    boxShadow: "none",
    cursor: "pointer",
    fontSize: "inherit",
    fontWeight: 300,
    gap: "0.3rem",
    height: isMobile ? "24px" : "30px", // getControlHeight(),
    minHeight: "unset",
    padding: "0.25rem 0.5rem"
  }),

  singleValue: (base) => ({
    ...base,
    borderRadius: "0.25rem",
    background: "#D1D5DB",
    color: "#374151",
    margin: "0px",
    padding: "0.1rem 0.25rem",
    fontSize: "inherit",
    fontWeight: 300
  }),

  input: (base) => ({
    ...base,
    margin: "0px",
    padding: "0px",
    fontSize: "inherit",
    fontWeight: 300
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px",
    margin: "0px",
    lineHeight: "100%",
    fontSize: "inherit",
    fontWeight: 300
  }),
  indicatorsContainer: (base) => ({
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px"
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: "0px"
  })
});
