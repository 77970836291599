import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import cn from "classnames";

import CloseIcon from "~/src/ui/icons/close-icon";
import {
  formatCurrency, formatMonth, formatDate
} from "~/src/modules/formatters";
import ShareDealOwnerCard from "~/src/features/share-deal-owner-card";
import SlidePropertyCard from "~/src/features/slide-property-card";
import FeedbackModal from "~/src/features/feedback-modal";

function renderDateList(dateList) {
  return dateList?.split(", ").map((date) => formatDate({
    date,
    withTime: false
  })).join(", ");
}

/**
 *
 * @param root0
 * @param root0.active
 * @param root0.hide
 * @param root0.transaction
 */
export default function ShareDealTransactionSlide({
  active, hide, transaction
}) {
  const [
    showProperties,
    setShowProperties
  ] = useState(false);
  const [
    showFeedback,
    setShowFeedback
  ] = useState(false);

  function handleHide() {
    setShowProperties(false);
    hide();
  }

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog as="div" static className="z-[150] fixed inset-0 overflow-hidden" open={active} onClose={handleHide}>

        <FeedbackModal show={showFeedback} close={() => setShowFeedback(false)} entity_id={transaction?.id} feedback_type="share-deal-transaction" feedback_type_label={`${formatMonth(transaction?.change_date)} - ${transaction?.company_fbnr}`} />

        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-5xl">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-white"
                      onClick={handleHide}
                    >
                      <span className="sr-only">Schließen</span>
                      <CloseIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col pb-6 bg-white shadow-xl overflow-y-scroll">

                  <div className="px-4 sm:px-12 h-16 flex justify-between items-center shrink-0 bg-primary">
                    <h2 id="slide-over-heading" className="text-lg font-medium text-white">
                      Details zum Share Deal
                    </h2>
                  </div>

                  <div className="mt-4 mb-8 flex-1">

                    <dl className="px-4 sm:px-8">

                      <div className="px-4">
                        <div className="flex justify-between items-center">
                          <h3 className="text-2xl font-semibold text-gray-700">{transaction?.company_name}<br />{transaction?.company_fbnr}</h3>
                          <button type="button" onClick={() => setShowFeedback(true)} className="px-3 py-1 text-sm bg-primary text-white hover:bg-primary-lighter border border-primary rounded focus:outline-none">Feedback</button>
                        </div>
                        <div className="mt-1 text-gray-900">
                          {/* <p>{shareDeal.state}</p> */}
                          <p className="leading-5">{transaction?.company_zipcode} {transaction?.company_city}, {transaction?.company_street}</p>
                        </div>
                      </div>

                      <div>

                        <div className="mt-4 p-4">

                          <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Datum</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{transaction?.date_list ? renderDateList(transaction.date_list) : formatMonth(transaction?.change_date)}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Art der Änderung</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{transaction?.category?.name}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Stammkapital</dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{transaction?.company_capital
                              ? formatCurrency({
                                val: transaction?.company_capital,
                                currency: transaction?.capital_currency
                              })
                              : "keine Angabe"}</dd>
                          </div>

                        </div>

                        <nav className="mt-10 px-4 flex space-x-4">
                          <button
                            type="button"
                            onClick={() => setShowProperties(false)}
                            className={cn("px-3 py-2 font-medium text-sm rounded-md focus:outline-none", {
                              "bg-primary text-white cursor-default": !showProperties,
                              "bg-gray-200 hover:bg-gray-300 text-gray-600 hover:text-gray-700": showProperties
                            })}
                          >
                            Personen- und Anteilsänderungen ({transaction?.owners?.length})
                          </button>

                          <button
                            type="button"
                            onClick={() => setShowProperties(true)}
                            className={cn("px-3 py-2 font-medium text-sm rounded-md focus:outline-none", {
                              "bg-primary text-white cursor-default": showProperties,
                              "bg-gray-200 hover:bg-gray-300 text-gray-600 hover:text-gray-700": !showProperties
                            })}
                          >
                            Liegenschaften ({transaction?.properties?.length})
                          </button>
                        </nav>

                        {!showProperties &&
                          <div className="my-1 px-4">

                            <div className="mt-1">
                              <p className="mr-1 text-right text-sm text-gray-800">PersNr.</p>
                            </div>

                            <div className="mt-2 px-4 sm:px-0 space-y-5">
                              {transaction?.owners?.map((owner, index) => (
                                <ShareDealOwnerCard key={`owner_${index}`} owner={owner} />
                              ))}
                            </div>

                          </div>
                        }

                        {showProperties &&
                          <div className="my-1 px-4">
                            <div className="mt-8 space-y-5">
                              {transaction?.properties?.map((property, index) => (
                                <SlidePropertyCard key={`property_${index}`} property={property} />
                              ))}
                            </div>
                          </div>
                        }

                      </div>

                    </dl>

                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
