import StatisticsFilters from "../project-statistics-filter";

/**
 * Header Component, which allows the user to switch between the different routes 
 * to see the corresponding statistics.
 *
 * @return {React.ReactElement} Returns the StatisticFilters, which contains a RadioButtonGroup 
 */
export default function StatisticsHeader() {
  const categoryStatistics = [
    {
      value: "preise",
      label: "Preise"
    },
    {
      value: "zielgruppen",
      label: "Zielgruppen"
    },
    {
      value: "zimmer",
      label: "Zimmer"
    }
  ];

  return (
    <StatisticsFilters
      categoryStatistics = {categoryStatistics}
    />
  );
}
