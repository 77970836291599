import { statsCols } from "./columns-spec";
import calculateStats from "./calculate-stats.js";

/**
 *
 * @param workbook
 * @param statsAndCounts
 * @param projects
 */
export default function statsAndCountsSheetGenerator(
  workbook,
  statsAndCounts,
  projects
) {
  statsAndCounts.exports = calculateStats({ projects });
  statsAndCounts.exports.projectCountSelected = projects.length;
  statsAndCounts.exports.unitCountSelected = projects
    .map(({ count_wohnungen }) => count_wohnungen)
    .reduce((sum, val_n) => sum + val_n, 0);
  for (const statName in statsAndCounts.exports) {
    const val = statsAndCounts.exports[statName];

    if (!statName.includes("Count") && !val) {
      statsAndCounts.exports[statName] = "";
    }
  }
  const statsAndCountsSheet = workbook.addWorksheet("Übersicht");
  const headerRow = statsAndCountsSheet.addRow();
  const selectionRow = statsAndCountsSheet.addRow();
  const exportsRow = statsAndCountsSheet.addRow();
  const columns = statsCols;

  for (const colKey in columns) {
    const col = columns[colKey];

    applyHeaderRowSpecByCol(headerRow, col);

    selectionRow.getCell(col.number + 1).value =
      statsAndCounts.selection[colKey];
    exportsRow.getCell(col.number + 1).value =
      statsAndCounts.exports[colKey];
    applyStylesAndFormatToColumn(
      colKey,
      col,
      statsAndCountsSheet,
      selectionRow,
      statsAndCounts.selection
    );
    applyStylesAndFormatToColumn(
      colKey,
      col,
      statsAndCountsSheet,
      exportsRow,
      statsAndCounts.exports
    );
  }
  headerRow.font = {
    size: 12,
    bold: true
  };
  selectionRow.getCell(1).value = "Selektierte Projekte";
  exportsRow.getCell(1).value = "Exportierte Projekte";
  statsAndCountsSheet.getColumn(1).width =
    parseInt(("Exportierke Projekte".length + 5) * 1.10);
  statsAndCountsSheet.getColumn(1).font = {
    size: 12,
    bold: true
  };
  headerRow.font = {
    size: 12,
    bold: true,
    color: { argb: "FFFFFFFF" }
  };
  headerRow.getCell(2).font = {
    size: 12,
    bold: true,
    color: { argb: "00000000" }
  };
  headerRow.getCell(3).font = {
    size: 12,
    bold: true,
    color: { argb: "00000000" }
  };

  return null;
}

const applyHeaderRowSpecByCol = (headerRow, col) => {
  headerRow.getCell(col.number + 1).value = col.label;
  if (col.headerBackgroundColor) {
    headerRow.getCell(col.number + 1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: col.headerBackgroundColor
      }
    };
  }
};

const applyStylesAndFormatToColumn = (colKey, col, statsAndCountsSheet, selectionRow, statsAndCounts) => {
  const sheetCol = statsAndCountsSheet.getColumn(col.number + 1);

  sheetCol.width = parseInt((col.label.length + 5) * 1.10);
  sheetCol.font = { size: 12 };
  sheetCol.alignment = {
    vertical: "middle",
    horizontal: "left"
  };
  if (col.formatType === "numFmt") {
    sheetCol.numFmt = col.formatString;
  }
};

