import useSignedSource from "~/src/hooks/use-signed-source";

/**
 *
 * @param root0
 * @param root0.document
 */
export default function SignedUnitDocument({ document }) {
  const { src } = useSignedSource(document.file_name);

  return (
    <a href={src || ""} target="_blank" rel="noreferrer" className="underline text-sm font-semibold text-gray-500 hover:text-gray-400 focus:outline-none">
      {document.public_name}
    </a>
  );
}
