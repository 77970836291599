/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable import/max-dependencies */
import { useEffect, useState } from "react";
import cn from "classnames";

import {
  constructionPhaseLabels,
  liegenschaftLabels,
  marketingPhaseLabels,
  priceLabels
} from "~/src/modules/labels.js";
import CheckmarkCircleIcon from "~/src/ui/icons/checkmark-circle-icon/index.jsx";
import ProjectCompany from "~/src/ui/displays/project-company/index.jsx";
import ProjectLink from "~/src/ui/links/project-link/index.jsx";
import ProjectLocation from "~/src/ui/displays/project-location/index.jsx";
import dateFormatter from "~/src/modules/date-formatter.js";
import { displayRechtsform } from "~/src/lib/project-interface.js";
import { formatEuro } from "~/src/modules/formatters.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import EsgIcon from "~/src/ui/icons/esg-icon/index.jsx";

/**
 *
 * @param props
 * @param props.project
 * @param props.handleCheckbox
 * @param props.handleSingleMapProject
 * @param props.watchlists
 * @param props.mutateWatchlists
 * @param props.disabled
 * @param props.mainSelectionType
 */
// eslint-disable-next-line max-lines-per-function, complexity
const ProjectListItemPrint4 = ({
  project
}) => {
  const { query: { sort } } = useProjectQueryParams();
  const [
    currentSortValue,
    setCurrentSortValue
  ] = useState(null);
  const [
    showMultipleLocations,
    setShowMultipleLocations
  ] = useState(false);

  useEffect(() => {
    if (sort) {
      setCurrentSortValue(sort);
    }
    else {
      setCurrentSortValue(null);
    }
  }, [sort]);

  const builders = project?.company_roles.filter((role) => role.name === "builder");
  const owners = project?.company_roles.filter((role) => role.name === "owner");
  const marketers = project?.company_roles.filter((role) => role.name === "marketer");

  const completed = project?.timeline?.current_construction_phase === "construction_phase_completed";
  let completedDate;

  if (completed) {
    completedDate = (project?.timeline?.construction_phase_completed_date !== null && project?.timeline?.construction_phase_completed_date_format !== null)
      ? dateFormatter(project?.timeline?.construction_phase_completed_date, project?.timeline?.construction_phase_completed_date_format)
      : project.finish_year;
  }

  if (!completed && project?.construction_infos?.estimated_finish_date) {
    completedDate = dateFormatter(project.construction_infos.estimated_finish_date, project.construction_infos?.estimated_finish_date_format);
  }

  const lastContractDate = project?.timeline?.last_contract_date ? dateFormatter(project.timeline.last_contract_date, "year") : null;

  return (
    <li key={project.slug} className="flex gap-4 p-2 mb-4 border border-gray-200 rounded-lg break-inside-avoid" style={{ zoom: 0.7 }}>

      <div className="flex items-start justify-between w-3/5 gap-4 pb-2 mb-2">
        <div className="flex flex-col items-start gap-2 break-words">
          <div className="flex items-center gap-2 pl-0.5 text-sm">
            <ProjectLink project={project} inNewTab={true} backlink="projects">
              <p className="inline-block font-medium text-primary">
                <span className="border-b-2 border-primary hover:text-primary-dark">{project.name}</span>
              </p>
            </ProjectLink>
          </div>

          <div>
            <div className="flex" key={`single_location_${location.id}`}>
              <ProjectLocation location={project.locations[0]} />
            </div>
          </div>

          <div className="overflow-hidden">
            {[
              ...owners,
              ...builders,
              ...marketers
            ].map((role, index) => <ProjectCompany key={index} role={role} automated={project?.automated} />)}
          </div>
        </div>

        <div>
          {project?.show_image_public && project?.image_file_name &&
          <ProjectLink project={project} inNewTab={true} backlink="projects">
            <div className="relative w-48 overflow-hidden rounded-lg h-[7.5rem] mt-0.5">
              <img className={cn("absolute object-cover w-full h-full transition duration-500 transform", { "hover:scale-110": !project?.automated })} src={`https://immodeveloper.fra1.digitaloceanspaces.com/${project?.image_file_name}`} alt={project?.name} />
            </div>
          </ProjectLink>
          }
        </div>
      </div>

      <div className="w-2/5 col-span-4 text-xs">
        <div className="flex items-center justify-between w-full">
          <div
            className={cn(
              "flex items-center text-xs gap-1",
              {
                "text-primary font-semibold": sort === "finishYear",
                "text-gray-500 font-medium": sort !== "finishYear"
              }
            )}
          >
            <CheckmarkCircleIcon className="w-5 h-5 -ml-0.5" />
            <span className="text-xs">
              {
                project?.automated
                  ? `Historisches Projekt${lastContractDate ? ` - ${lastContractDate}` : ""}`
                  : (completedDate ?? "nicht bekannt")

              }
            </span>
          </div>
          {
            (project?.project_certificates && project?.project_certificates.length > 0)
              ? <EsgIcon className="!w-5 !h-5" innerClassName="!w-[14px] !h-3" />
              : null
          }
        </div>

        <div className="flex flex-col mt-2">
          <div className="w-full">
            {(project?.timeline?.current_construction_phase && project.timeline.current_construction_phase !== "unknown") &&
              <div className="flex justify-between w-full">
                <div className="shrink-0">
                  <p className="font-semibold text-gray-600">Bauphase:</p>
                </div>
                <div>
                  <p>{constructionPhaseLabels[project.timeline.current_construction_phase || ""]}</p>
                </div>
              </div>
            }

            {(project?.timeline?.current_marketing_phase && project.timeline.current_marketing_phase !== "unknown") &&
              <div className="flex justify-between w-full">
                <div className="shrink-0">
                  <p className="font-semibold text-gray-600">Vermarktungsphase:</p>
                </div>
                <div>
                  <p>{marketingPhaseLabels[project.timeline.current_marketing_phase || ""]}</p>
                </div>
              </div>
            }
          </div>

          <div className="mt-2">

            {(project?.transaction_filter && liegenschaftLabels[project?.transaction_filter]) &&
              <div className="flex justify-between w-full">
                <div>
                  <p className="font-semibold text-gray-600">Liegenschaftsankauf: </p>
                </div>
                <div>
                  <p>{liegenschaftLabels[project.transaction_filter]}</p>
                </div>
              </div>
            }

            <div className="flex justify-between w-full">
              <div>
                <p className="font-semibold text-gray-600">Rechtsform:</p>
              </div>
              <div>
                {displayRechtsform(project)}
              </div>
            </div>

            {
              project.count_wohnungen !== 0 &&
              <div className="flex justify-between w-full">
                <div>
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "countWohnungen" })}>
                    {project.automated ? "Erfasste Transaktionen" : "Gesamtanzahl Wohneinheiten"}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "countWohnungen" })}>
                    {project.count_wohnungen}
                  </p>
                </div>
              </div>
            }
          </div>

          {
            (project?.funding?.not_funded || project?.funding?.normal_funded) &&
            <div className="flex justify-between w-full">
              <div>
                <p className={cn("font-semibold text-gray-600")}>
                  Förderung:
                </p>
              </div>
              <div>
                {project.funding.not_funded && project.funding.normal_funded &&
                  <p>gemischt</p>
                }
                {!project.funding.not_funded && project.funding.normal_funded &&
                  <p>gefördert</p>
                }
                {project.funding.not_funded && !project.funding.normal_funded &&
                  <p>freifinanziert</p>
                }
              </div>
            </div>
          }
          {project?.gka &&
              <div className="flex justify-between w-full">
                <div>
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "gka" })}>
                    {priceLabels.gka}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "gka" })}>
                    {formatEuro(project?.gka)}/m²
                  </p>
                </div>
              </div>
          }
          {project?.avg_offer_price_eigennutzer &&
              <div className="flex justify-between w-full">
                <div>
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgOfferPriceEigennutzer" })}>
                    {priceLabels.averageOfferPriceNormal}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgOfferPriceEigennutzer" })}>
                    {formatEuro(project?.avg_offer_price_eigennutzer)}/m²
                  </p>
                </div>
              </div>
          }
          {project?.avg_offer_price_investor &&
              <div className="flex justify-between w-full">
                <div>
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgOfferPriceEigennutzer" })}>
                    {priceLabels.averageOfferPriceInvestorNet}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgOfferPriceEigennutzer" })}>
                    {formatEuro(project?.avg_offer_price_investor)}/m²
                  </p>
                </div>
              </div>
          }
          {project?.avg_miete &&
              <div className="flex justify-between w-full">
                <div>
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgMiete" })}>
                    {priceLabels.averageRentNet}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgMiete" })}>
                    {formatEuro(project?.avg_miete)}/m²
                  </p>
                </div>
              </div>
          }
          {project?.avg_rent_bk &&
              <div className="flex justify-between w-full">
                <div>
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgMiete" })}>
                    {priceLabels.averageRentTotal}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgMiete" })}>
                    {formatEuro(project?.avg_rent_bk)}/m²
                  </p>
                </div>
              </div>
          }
          {project?.avg_sale_price_eigennutzer &&
              <div className="flex justify-between w-full">
                <div>
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgSalePriceEigennutzer" })}>
                    {priceLabels.averageSalePriceNormal}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgSalePriceEigennutzer" })}>
                    {formatEuro(project?.avg_sale_price_eigennutzer)}/m²
                  </p>
                </div>
              </div>
          }
          {project?.avg_sale_price_investor &&
              <div className="flex justify-between w-full">
                <div>
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgSalePriceEigennutzer" })}>
                    {priceLabels.averageSalePriceInvestorNet}:
                  </p>
                </div>
                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgSalePriceEigennutzer" })}>
                    {formatEuro(project?.avg_sale_price_investor)}/m²
                  </p>
                </div>
              </div>
          }
        </div>

      </div>
    </li>
  );
};

export default ProjectListItemPrint4;
