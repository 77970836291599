import { formatDate } from "~/src/modules/formatters";
import SingleFilterTag from "~/src/ui/search-filter-tags/single-filter-tag";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.onSubmit
 * @param root0.handleClearFilters
 */
export default function PurchaseFilterTags({
  query, onSubmit, handleClearFilters
}) {
  const activeFilters = [];

  Object.entries(query).forEach(([
    key,
    val
  ]) => {
    if (![
      "page",
      "direction",
      "sortBy",
      "dateType",
      "automated",
      "selection"
    ].includes(key) && val !== undefined) {
      activeFilters.push(key);
    }
  });

  return (
    <div className="flex justify-start items-center flex-wrap gap-4 min-h-10">
      <SingleFilterTag label={query.state} show={query.stateId && query.state} onClick={() => onSubmit({
        stateId: undefined,
        state: undefined
      })} />
      <SingleFilterTag label={query.city} show={query.cityId && query.city} onClick={() => onSubmit({
        cityId: undefined,
        city: undefined
      })} />
      <SingleFilterTag label={query.purchaseType} show={query.purchaseTypeId && query.purchaseType} onClick={() => onSubmit({
        purchaseTypeId: undefined,
        purchaseType: undefined
      })} />
      <SingleFilterTag label={`Datum von: ${formatDate({ date: query.dateFrom })}`} show={query.dateFrom} onClick={() => onSubmit({
        dateFrom: undefined,
        direction: query.dateTo ? "desc" : undefined
      })} />
      <SingleFilterTag label={`Datum bis: ${formatDate({ date: query.dateTo })}`} show={query.dateTo} onClick={() => onSubmit({
        dateTo: undefined,
        direction: query.dateFrom ? "asc" : undefined
      })} />

      {activeFilters.length > 0 &&
        <button type="button" onClick={handleClearFilters} className="mt-0.5 ml-2 focus:outline-none tracking-tight text-sm font-semibold hover:underline text-primary ">
          Filter zurücksetzen
        </button>
      }
    </div>
  );
}
