import { useQueryParams } from "use-query-params";

import { ApiModel } from "~/src/lib/models/projects";

/**
 *
 */
export default function useProjectQueryParams() {
  const [
    query,
    setQuery
  ] = useQueryParams(
    ApiModel.schemaForUseQueryParams
  );

  return {
    query,
    setQuery
  };
}
