import { useParams } from "react-router-dom";

import DetailBuyableChart from "~/src/features/charts/detail/buyable";
import DetailRentableChart from "~/src/features/charts/detail/rentable";
import ChartContainer from "~/src/ui/containers/chart-container";
import useProjectStatistics from "~/src/hooks/use-project-statistics";
import ChartError from "~/src/ui/charts/error";
import {
  labelsBuyable,
  priceTypesBuyable,
  dateTypesBuyable,
  areaTypesBuyable,
  labelsRentable,
  priceTypesRentable,
  dateTypesRentable,
  areaTypesRentable
} from "~/src/modules/labels";
import useStore from "~/src/hooks/use-store";

/**
 *
 */
export default function DetailStatistics() {
  const bothInnerChartComponentsHaveError =
    useStore((state) => state.bothProjectPriceChartsHaveErrors);

  const { id: projectId } = useParams();

  const structureBuyable = "composition";
  const typesBuyable = [
    "units-main-category",
    "units-main-name",
    ...priceTypesBuyable,
    ...dateTypesBuyable,
    ...areaTypesBuyable
  ];

  const {
    statistics: statisticsBuyable,
    isLoading: isLoadingBuyable,
    isError: isErrorBuyable
  } = useProjectStatistics({
    projectId,
    types: typesBuyable,
    structure: structureBuyable
  });

  const structureRentable = "composition";
  const typesRentable = [
    "units-main-category",
    "units-main-name",
    ...priceTypesRentable,
    ...dateTypesRentable,
    ...areaTypesRentable
  ];

  const {
    statistics: statisticsRentable,
    isLoading: isLoadingRentable,
    isError: isErrorRentable
  } = useProjectStatistics({
    projectId,
    types: typesRentable,
    structure: structureRentable
  });

  const getBuyableChart = () => <DetailBuyableChart
    key="detail-buyable-chart"
    statistics={statisticsBuyable}
    isLoading={isLoadingBuyable}
    isError={isErrorBuyable}
    labels={labelsBuyable}
    priceTypes={priceTypesBuyable}
    dateTypes={dateTypesBuyable}
    areaTypes={areaTypesBuyable}
  />;
  const getRentableChart = () => <DetailRentableChart
    key="detail-rentable-chart"
    statistics={statisticsRentable}
    isLoading={isLoadingRentable}
    isError={isErrorRentable}
    labels={labelsRentable}
    priceTypes={priceTypesRentable}
    dateTypes={dateTypesRentable}
    areaTypes={areaTypesRentable}/>;

  const charts = [
    {
      getChart: getBuyableChart,
      hasError: isErrorBuyable,
      isEmpty: !statisticsBuyable
    },
    {
      getChart: getRentableChart,
      hasError: isErrorRentable,
      isEmpty: !statisticsRentable
    }
  ];

  const hasDataError = (chart) => chart.hasError || chart.isEmpty;
  const allChartsHaveDataError = charts.every((chart) => hasDataError(chart));

  const noChartsAreDisplayed =
    allChartsHaveDataError || bothInnerChartComponentsHaveError;

  if (noChartsAreDisplayed) {
    charts.push({
      getChart: () => <ChartError key="project-price-chart-error" />
    });
  }

  const chartComponents = charts.map((chart) => chart.getChart());

  return (
    <ChartContainer>{chartComponents}</ChartContainer>);
}
