/**
 *
 * @param owner
 * @param type
 */
export function hasActiveOwnerFields(owner, type) {
  let active = false;
  const ownerObj = {};

  Object.entries(owner).forEach(([
    key,
    value
  ]) => {
    if (key.includes(type)) {
      ownerObj[key.replace(`_${type}`, "")] = value;
    }

    if (key.includes(type) && value !== null) {
      active = true;
    }
  });

  return {
    active,
    ownerObj
  };
}

/**
 *
 * @param diffObj
 */
export function hasDiffValues(diffObj) {
  return Object.keys(diffObj).length > 0;
}
