/* eslint-disable consistent-return */
/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable no-empty-function */
/* eslint-disable max-lines-per-function */
import cn from "classnames";
import {
  useEffect, useMemo, useState
} from "react";
import { Triangle } from "lucide-react";
import { Tooltip } from "react-tooltip";

const tooltipDelay = 500;

/**
 * @typedef {import("react").JSX.Element} JSXElement
 */

/**
 * Collapsible container component.
 *
 * @param {object} props - The component props.
 * @param {JSXElement|JSXElement[]} props.children - The child element(s).
 * @param {boolean} props.collapsed - Whether the container is collapsed.
 * @param {Function} props.onChange - The change event handler, fired when collapse button is
 * clicked.
 * @param {"left"|"right"|"top"|"bottom"} props.position - The position of the collapse button.
 * @param {string|object} props.className - The class name of the container.
 * @param {string|object} props.collapsedClassName - The class name when the container is collapsed.
 * @param {object} props.tooltipTexts - The tooltip texts.
 * @param {string} props.tooltipTexts.collapsed - The tooltip text when collapsed.
 * @param {string} props.tooltipTexts.expanded - The tooltip text when expanded.
 * @param {boolean} props.active - Whether the collapsible functionality is active at all.
 * @param {boolean} props.custom - Whether the collapsible functionality is custom. Children will
 * not be hidden when collapsed.
 *
 * @return {JSXElement} The rendered JSX element.
 */
const CollapsibleContainer = ({
  children,
  collapsed: defaultCollapsed = false,
  onChange = () => { },
  position,
  className,
  collapsedClassName,
  tooltipTexts: {
    collapsed: collapsedTooltipText = "anzeigen",
    expanded: expandedTooltipText = "ausblenden"
  } = {
    collapsed: "anzeigen",
    expanded: "ausblenden"
  },
  active = true,
  custom = false,
  ...props
}) => {
  const tooltipId = useMemo(() => `tooltip-collapsible-${crypto.randomUUID()}`, []);
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const [hovered, setHovered] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const handleClick = () => {
    // this is to make the border also immediately unhovered (otherwise chart animations are blocking this setHovered)
    setHovered(false);

    setCollapsed(!collapsed);
    onChange(!collapsed);
  };

  useEffect(() => {
    setCollapsed(defaultCollapsed);
  }, [defaultCollapsed]);

  useEffect(() => {
    if (tooltipIsOpen) {
      const timeoutId = setTimeout(() => {
        if (tooltipIsOpen) {
          setDisplayTooltip(true);
        }
        else {
          setDisplayTooltip(false);
        }
      }, tooltipDelay);

      return () => clearTimeout(timeoutId);
    }
    else if (!tooltipIsOpen) {
      setDisplayTooltip(false);
    }
  }, [tooltipIsOpen]);

  return (
    <div
      className={cn(
        "relative h-full",
        {
          "border-b-4": position === "bottom" && active,
          "border-b-primary": hovered && position === "bottom" && active,
          "border-b-zinc-300": !hovered && position === "bottom" && active,
          "border-l-4": position === "left" && active,
          "border-l-primary": hovered && position === "left" && active,
          "border-l-zinc-300": !hovered && position === "left" && active,
          "border-r-4": position === "right" && active,
          "border-r-primary": hovered && position === "right" && active,
          "border-r-zinc-300": !hovered && position === "right" && active,
          "border-t-4": position === "top" && active,
          "border-t-primary": hovered && position === "top" && active,
          "border-t-zinc-300": !hovered && position === "top" && active,
          [className]: className === collapsedClassName || !collapsed,
          [collapsedClassName]: className === collapsedClassName || collapsed
        }
      )}
      {...props}
    >
      <button
        className={cn(
          "bg-zinc-300 hover:bg-primary rounded-full w-8 h-8 lg:w-5 lg:h-5 p-[0.4rem] lg:p-1 flex items-center justify-center absolute",
          {
            "-right-3 top-1/4": position === "right",
            "-left-3 top-1/4": position === "left",
            "-bottom-3 right-3 lg:right-1/4": position === "bottom",
            "-top-3 right-3 lg:right-1/4": position === "top",
            hidden: !active
          }
        )}
        onPointerOver={(event) => {
          if (event.pointerType !== "touch") {
            setTooltipIsOpen(true);

            setHovered(true);
          }
        }}
        onPointerOut={(event) => {
          if (event.pointerType !== "touch") {
            setTooltipIsOpen(false);
            setHovered(false);
          }
        }}
        onClick={handleClick}
        data-tooltip-id={tooltipId}
        data-tooltip-content={
          collapsed
            ? collapsedTooltipText
            : expandedTooltipText
        }
      >
        <Triangle
          fill="white"
          stroke="transparent"
          className={cn(
            "pb-0.5",
            {
              "-rotate-90": (position === "right" && !collapsed) || (position === "left" && collapsed),
              "rotate-90": (position === "right" && collapsed) || (position === "left" && !collapsed),
              "-rotate-180": (position === "top" && !collapsed) || (position === "bottom" && collapsed)
            }
          )}
        />
      </button>
      <Tooltip
        id={tooltipId}
        place={position}
        className="z-50"
        isOpen={displayTooltip}
        globalCloseEvents={{
          escape: true,
          scroll: true,
          resize: true,
          clickOutsideAnchor: true
        }}
        delayShow={tooltipDelay}
        delayHide={0}
        positionStrategy="fixed"
      />
      {
        (!collapsed || custom) && children
      }
    </div>
  );
};

export default CollapsibleContainer;
