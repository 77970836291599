import useSWR from "swr";
import { useLocation } from "react-router-dom";

import { fetcher } from "~/src/modules/api";

/**
 *
 */
export default function useRegions() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const userId = searchParams.get("userId");

  const {
    data, mutate, error
  } = useSWR(`/regions${userId ? `?userId=${userId}` : ""}`, fetcher);

  return {
    regions: data?.payload?.regions,
    states: data?.payload?.states,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
