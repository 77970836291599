import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { insolvencyPropertySortLabels } from "~/src/modules/labels";

/**
 *
 * @param root0
 * @param root0.sortByProperty
 * @param root0.onSubmit
 */
export default function SortInsolvencyPropertiesDropdown({ sortByProperty = "dateLastChange", onSubmit }) {
  function label() {
    return insolvencyPropertySortLabels[sortByProperty];
  }

  return (
    <Menu as="div" className="relative ml-8">
      <Menu.Button className="w-full h-10 inline-flex border-b border-secondary-light justify-center items-center text-base font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        {label()}
        <ChevronDownIcon className="ml-0.5 -mr-1 h-5 w-5 text-gray-500" aria-hidden="true" />
      </Menu.Button>
      <Menu.Items className="origin-top-right z-10 absolute right-0 -mt-0.5 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1 w-full">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortByProperty: "datePublished" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortByProperty === "datePublished"
                })}
              >
                <span>{insolvencyPropertySortLabels.datePublished}</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortByProperty: "dateLastChange" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortByProperty === "dateLastChange"
                })}
              >
                <span>{insolvencyPropertySortLabels.dateLastChange}</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortByProperty: "kg" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortByProperty === "kg"
                })}
              >
                <span>{insolvencyPropertySortLabels.kg}</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortByProperty: "ez" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortByProperty === "ez"
                })}
              >
                <span>{insolvencyPropertySortLabels.ez}</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortByProperty: "area" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortByProperty === "area"
                })}
              >
                <span>{insolvencyPropertySortLabels.area}</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortByProperty: "zipcode" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortByProperty === "zipcode"
                })}
              >
                <span>{insolvencyPropertySortLabels.zipcode}</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortByProperty: "street" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortByProperty === "street"
                })}
              >
                <span>{insolvencyPropertySortLabels.street}</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortByProperty: "company" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortByProperty === "company"
                })}
              >
                <span>{insolvencyPropertySortLabels.company}</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => onSubmit({ sortByProperty: "fbnr" })}
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active,
                  "font-bold": sortByProperty === "fbnr"
                })}
              >
                <span>{insolvencyPropertySortLabels.fbnr}</span>
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
