import { snakeCase } from "lodash-es";
import { stringify } from "query-string";
import useSWR from "swr";
import { Temporal } from "@js-temporal/polyfill";

import { getPolygons } from "~/src/schema/filters";
import { fetcher } from "~/src/modules/api";

const { Now } = Temporal;

const getSortPath = (sort) => {
  switch (sort) {
    case "zipcode":
      return "locations[0].city.zipcode";
    case "radius":
      return "locations[0]";
    default:
      return snakeCase(sort ?? "name");
  }
};

const getSortType = (sort) => {
  switch (sort) {
    case "radius":
      return "fromStreet";
    default:
      return "default";
  }
};

/**
 *
 * @param options
 * @param options.query
 * @param options.query.sort
 * @param options.query.polygon
 * @param options.swrOptions
 * @param options.shouldFetch
 * @param options.isSimple
 */
export default function useProjectList({
  shouldFetch = true,
  query: {
    sort, polygon, ...query
  },
  swrOptions,
  isSimple = false
}) {
  if (!isSimple) {
    const newQuery = {
      ...query,
      polygon: getPolygons(polygon),
      sortPath: getSortPath(sort),
      sortType: getSortType(sort)
    };

    const {
      data,
      data: {
        payload: {
          selectionSlugs = [],
          selectionType = "addAll"
        } = {}
      } = {},
      mutate,
      error,
      isValidating
    } = useSWR(() => (shouldFetch ? `/project-list?${stringify(newQuery, { arrayFormat: "comma" })}` : null), fetcher, swrOptions);

    if (data?.payload?.projects) {
      for (const slug of selectionSlugs) {
        const index = data?.payload?.projects?.findIndex((project) => project.slug === slug);

        if (index !== -1) {
          if (selectionType === "addAll") {
            data.payload.projects[index].unselected = true;
          }
          else {
            data.payload.projects[index].selected = true;
          }
        }
      }
    }

    return {
      isError: error,
      isLoading: !error && !data,
      isValidating,
      mutate,
      projects: data?.payload?.projects,
      selectionSlugs,
      selectionStatus: data?.payload?.selectionStatus,
      selectionType: data?.payload?.selectionType,
      total: data?.payload?.total,
      updateTime: Now.instant().epochMilliseconds
    };
  }

  return { selectionStatus: null };
}
