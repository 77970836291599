import { PropTypes } from "prop-types";
import leaflet, { Map, Control } from "leaflet";
import "leaflet-draw";
import isEqual from "fast-deep-equal";
import { useEffect, useRef } from "react";
import { useLeafletContext } from "@react-leaflet/core";

const eventHandlers = {
  onDeleteStart: "draw:deletestart",
  onDeleteStop: "draw:deletestop",
  onDeleted: "draw:deleted",
  onDrawStart: "draw:drawstart",
  onDrawStop: "draw:drawstop",
  onDrawVertex: "draw:drawvertex",
  onEditMove: "draw:editmove",
  onEditResize: "draw:editresize",
  onEditStart: "draw:editstart",
  onEditStop: "draw:editstop",
  onEditVertex: "draw:editvertex",
  onEdited: "draw:edited"
};

/**
 *
 * @param props
 */
function EditControl(props) {
  const context = useLeafletContext();
  const drawRef = useRef();
  const propsRef = useRef(props);

  useEffect(() => {
    const { map } = context;

    if (!drawRef.current) {
      drawRef.current = createDrawElement(props, context);

      map.addControl(drawRef.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { map } = context;
    const { onMounted } = props;

    const onDrawCreate = (e) => {
      const { onCreated } = props;
      const container = context.layerContainer || context.map;

      container.addLayer(e.layer);
      onCreated && onCreated(e);
    };

    for (const key in eventHandlers) {
      if (props[key]) {
        map.on(eventHandlers[key], props[key]);
      }
    }
    map.on(leaflet.Draw.Event.CREATED, onDrawCreate);

    onMounted && onMounted(drawRef.current);

    return () => {
      map.off(leaflet.Draw.Event.CREATED, onDrawCreate);

      for (const key in eventHandlers) {
        if (props[key]) {
          map.off(eventHandlers[key], props[key]);
        }
      }
    };
  }, [
    context,
    props
  ]);

  useEffect(() => {
    if (
      isEqual(props.draw, propsRef.current.draw) &&
      isEqual(props.edit, propsRef.current.edit) &&
      props.position === propsRef.current.position
    ) {
    }
    else {
      const { onMounted } = props;

      onMounted && onMounted(drawRef.current);
    }
  }, [
    props.draw,
    props.edit,
    props.position,
    context,
    props
  ]);

  return null;
}

function createDrawElement(props, context) {
  const { layerContainer } = context;
  const {
    draw, edit, position
  } = props;
  const options = {
    edit: {
      ...edit,
      featureGroup: layerContainer
    }
  };

  if (draw) {
    options.draw = { ...draw };
  }

  if (position) {
    options.position = position;
  }

  return new Control.Draw(options);
}

EditControl.propTypes = {
  ...Object.keys(eventHandlers).reduce((acc, val) => {
    acc[val] = PropTypes.func;

    return acc;
  }, {}),
  onCreated: PropTypes.func,
  onMounted: PropTypes.func,
  draw: PropTypes.shape({
    polyline: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    polygon: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    rectangle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    circle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    marker: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ])
  }),
  edit: PropTypes.shape({
    edit: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    remove: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    poly: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    allowIntersection: PropTypes.bool
  }),
  position: PropTypes.oneOf([
    "topright",
    "topleft",
    "bottomright",
    "bottomleft"
  ]),
  leaflet: PropTypes.shape({
    map: PropTypes.instanceOf(Map),
    layerContainer: PropTypes.shape({
      addLayer: PropTypes.func.isRequired,
      removeLayer: PropTypes.func.isRequired
    })
  })
};

export default EditControl;
