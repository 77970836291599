/* eslint-disable id-length */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import { Line, ResponsiveLine } from "@nivo/line";
import { Temporal } from "@js-temporal/polyfill";
import { area } from "d3-shape";

import useMediaQuery from "~/src/hooks/use-media-query";

const {
  PlainYearMonth
} = Temporal;

const numberFormatter = new Intl.NumberFormat("de-AT");

const monthFormatterShort = new Intl.DateTimeFormat("de-AT", {
  calendar: "iso8601",
  year: "numeric",
  month: "short"
});

const monthFormatterLong = new Intl.DateTimeFormat("de-AT", {
  calendar: "iso8601",
  year: "numeric",
  month: "long"
});

const meterFormatter = new Intl.NumberFormat("de-AT", {
  style: "unit",
  unit: "meter"
});

const currencyFormatter = new Intl.NumberFormat("de-AT", {
  style: "currency",
  currency: "EUR"
});

const formatNumber = (number) => numberFormatter.format(number).replaceAll("\u00A0", ".");

const formatMonthShort = (date) => monthFormatterShort.format(date);
const formatMonthLong = (date) => monthFormatterLong.format(date);

const formatSquareMeters = (number) => `${meterFormatter.format(number)}²`;
const formatSquareMetersRange = (numberA, numberB) => `${meterFormatter.formatRange(numberA, numberB)}²`;

const formatEuro = (number) => currencyFormatter.format(number);

const formatEuroPerSquareMeter = (number) => `${formatEuro(number)}/m²`;

const AreaLayer = ({
  series,
  xScale,
  yScale
}) => {
  const areaGenerator = area()
    .x((d) => xScale(d.data.x))
    .y0((d) => yScale(d.data.ymin))
    .y1((d) => yScale(d.data.ymax));

  if (!series.length) {
    return null;
  }

  return (
    <path
      d={areaGenerator(series[0].data)}
      fill="#27596838"
      stroke="#00000080"
      strokeWidth={1}
    />
  );
};

const splitOnDash = (stringToSplit) => {
  const [
    x0,
    x1
  ] = stringToSplit.split("-");

  return [
    parseInt(x0),
    parseInt(x1)
  ];
};

const splitOnArrow = (stringToSplit) => ((stringToSplit.includes("<="))
  ? [
    0,
    parseInt(stringToSplit.replace("<=", ""))
  ]
  : [
    parseInt(stringToSplit.replace(">=", "")),
    200
  ]);

const BarsLayer = ({
  series,
  xScale,
  yScale
}) => {
  const areaGenerator = area()
    .x((d) => xScale(d.x))
    .y0((d) => yScale(d.y0))
    .y1((d) => yScale(d.y1));

  if (!series.length) {
    return null;
  }

  const areaObject = Object.entries(series[0].dataCategory).map(([
    key,
    value
  ]) => {
    const [
      x0,
      x1
    ] = (key.includes("-"))
      ? splitOnDash(key)
      : splitOnArrow(key);

    const { min, max } = value;

    return [
      {
        x: x0,
        y0: min,
        y1: max
      },
      {
        x: x1,
        y0: min,
        y1: max
      }
    ];
  });

  return areaObject.map((singleObject, index) => (
    <path key={`bar_${index}`}
      d={areaGenerator(singleObject)}
      fill="#27596838"
      stroke="#000000"
      strokeWidth={0}
    />
  ));
};

const PointsCategoryLayer = ({
  currentSlice,
  xScale,
  yScale,
  data
}) => {
  if (currentSlice !== null) {
    const currentXPosition = currentSlice.points[0].data.x;

    const categoryRange = Object.entries(data[0].dataCategory).find(([
      key,
      val
    ]) => {
      const [
        rangeLow,
        rangeHigh
      ] = key.includes("-")
        ? splitOnDash(key)
        : splitOnArrow(key);

      if (rangeLow <= currentSlice.points[0].data.x && rangeHigh >= currentSlice.points[0].data.x) {
        const { min, max } = val;

        return {
          min,
          max
        };
      }
    });

    const currentYPosition = {
      y: currentSlice.points?.[0]?.data?.y,
      y0: categoryRange[1]?.min,
      y1: categoryRange[1]?.max
    };

    if (currentXPosition && currentYPosition.y0 && currentYPosition.y1) {
      return <>
        <circle key={`circle_bot_out_${currentYPosition.y0}`} cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y0)} r="5"/>
        <circle key={`circle_bot_in_${currentYPosition.y0}`} fill="#FFFFFF" cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y0)} r="3"/>
        <circle key={`circle__mid_out_${currentYPosition.y}`} cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y)} r="5"/>
        <circle key={`circle__mid_in_${currentYPosition.y}`} fill="#FFFFFF" cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y)} r="3"/>
        <circle key={`circle_top_out_${currentYPosition.y1}`} cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y1)} r="5"/>
        <circle key={`circle_top_in_${currentYPosition.y1}`} fill="#FFFFFF" cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y1)} r="3"/>
      </>;
    }
  }

  return null;
};

const PointsDetailLayer = ({
  currentSlice,
  xScale,
  yScale
}) => {
  if (currentSlice !== null) {
    const currentXPosition = currentSlice.points[0].data.x;
    const currentYPosition = {
      y: currentSlice.points[0].data.y,
      y0: currentSlice.points[0].data.ymin,
      y1: currentSlice.points[0].data.ymax
    };

    if (currentXPosition && currentYPosition.y0 && currentYPosition.y1) {
      return <>
        <circle key={`circle_bot_out_${currentYPosition.y0}`} cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y0)} r="5"/>
        <circle key={`circle_bot_in_${currentYPosition.y0}`} fill="#FFFFFF" cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y0)} r="3"/>
        <circle key={`circle__mid_out_${currentYPosition.y}`} cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y)} r="5"/>
        <circle key={`circle__mid_in_${currentYPosition.y}`} fill="#FFFFFF" cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y)} r="3"/>
        <circle key={`circle_top_out_${currentYPosition.y1}`} cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y1)} r="5"/>
        <circle key={`circle_top_in_${currentYPosition.y1}`} fill="#FFFFFF" cx={xScale(currentXPosition)} cy={yScale(currentYPosition.y1)} r="3"/>
      </>;
    }
  }

  return null;
};

const getTooltip = ({
  slice: {
    points: [
      {
        data: {
          x: xValue,
          y: yValue,
          count
        }
      }
    ]
  }
}) => (
  <div className="flex flex-col gap-1 p-2 text-sm bg-white border rounded">
    <span className="text-xs font-medium text-gray-600">{formatMonthLong(xValue)}</span>
    <span>Durchschnitt: {formatEuroPerSquareMeter(yValue)}</span>
    <span>Einheiten: {formatNumber(count)}</span>
  </div>
);

const getTooltipWithArea = ({
  slice: {
    points: [
      {
        data: {
          x: xValue,
          y: yValue,
          ymax: yMaxValue,
          ymin: yMinValue,
          count
        }
      }
    ]
  }
}) => (
  <div className="flex flex-col gap-1 p-2 text-sm bg-white border rounded">
    <span className="text-xs font-medium text-gray-600">{formatMonthLong(xValue)}</span>
    <span>Maximum: {formatEuroPerSquareMeter(yMaxValue)}</span>
    <span>Durchschnitt: {formatEuroPerSquareMeter(yValue)}</span>
    <span>Minimum: {formatEuroPerSquareMeter(yMinValue)}</span>
    <span>Einheiten: {formatNumber(count)}</span>
  </div>
);

const getTooltipWithBar = (
  {
    slice: {
      points: [
        {
          data: {
            x: xValue,
            y: yValue,
            count
          }
        }
      ]
    }
  },
  data
) => {
  const {
    low: categoryLow,
    high: categoryHigh,
    value: {
      min: categoryMin,
      max: categoryMax,
      average: categoryAverage,
      count: categoryCount
    }

  } = Object.entries(data[0].dataCategory)
    .map(([
      key,
      value
    ]) => {
      const [
        low,
        high
      ] = key.includes("-")
        ? splitOnDash(key)
        : splitOnArrow(key);

      return {
        low,
        high,
        value
      };
    })
    .find(({ low, high }) => low <= xValue && high >= xValue);

  let categoryString = "";

  if (categoryLow === 0 || !Number.isFinite(categoryLow)) {
    categoryString = `≤ ${formatSquareMeters(categoryHigh)}`;
  }
  // eslint-disable-next-line no-negated-condition
  else if (!Number.isFinite(categoryHigh)) {
    categoryString = `≥ ${formatSquareMeters(categoryLow)}`;
  }
  else {
    categoryString = formatSquareMetersRange(categoryLow, categoryHigh);
  }

  if (categoryAverage === null) {
    return null;
  }

  return (
    // eslint-disable-next-line max-len
    <div className="flex flex-col p-2 text-sm bg-white border divide-y-2 divide-gray-200 rounded divide">
      <div className="flex flex-col gap-1 pb-2">
        <span className="flex items-center gap-1 text-xs font-medium text-gray-600">
          <div className="w-3 h-3 rounded-full bg-[#27596838]" />
          <span>{categoryString}</span>
        </span>
        <span>Maximum: {formatEuroPerSquareMeter(categoryMax)}</span>
        <span>Durchschnitt: {formatEuroPerSquareMeter(categoryAverage)}</span>
        <span>Minimum: {formatEuroPerSquareMeter(categoryMin)}</span>
        <span>Einheiten: {formatNumber(categoryCount)}</span>
      </div>

      <div className="flex flex-col gap-1 pt-2">
        <span className="flex items-center gap-1 text-xs font-medium text-gray-600">
          <div className="w-3 h-3 rounded-full bg-primary" />
          <span>{formatSquareMeters(xValue)}</span>
        </span>
        <span>Durchschnitt: {formatEuroPerSquareMeter(yValue)}</span>
        <span>Einheiten: {formatNumber(count)}</span>
      </div>

    </div>
  );
};

const getXScale = ({
  type: xType,
  min: xMin = "auto",
  max: xMax = "auto"
}) => {
  switch (xType) {
    case "month":
      return {
        type: "time",
        format: "%Y-%m",
        precision: "month",
        useUTC: false,
        tickValues: "every month"
      };
    case "squareMeters":
      return {
        type: "linear",
        min: xMin,
        max: xMax,
        tickSize: 5
      };
    default:
      return {
        type: "linear",
        min: xMin,
        max: xMax,
        tickSize: 5
      };
  }
};

const getAxisBottom = (xType, isMobile) => {
  switch (xType) {
    case "month":
      return {
        format: (date) => {
          const monthNumber = date.toTemporalInstant().toZonedDateTimeISO("Europe/Vienna").month;

          const interval = isMobile ? 6 : 3;

          return (monthNumber - 1) % interval === 0
            ? formatMonthShort(date)
            : "";
        },
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        legend: "Monat",
        legendOffset: 65,
        legendPosition: "middle",
        tickValues: "every month"
      };
    case "squareMeters":
      return {
        format: (number) => {
          const interval = isMobile ? 50 : 10;

          return number % interval === 0
            ? formatSquareMeters(number)
            : "";
        },
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Fläche",
        legendOffset: 45,
        legendPosition: "middle",
        tickValues: Array(41)
          .fill()
          .map((empty, index) => index * 5),
        renderTick: ({
          x, y, value
        }) => {
          const interval = isMobile ? 50 : 10;

          const isStrong = value % interval === 0;

          return (
            <g transform={`translate(${x},${y})`}>
              <line y1={0}
                y2={isStrong
                  ? 6
                  : 4}
                stroke="currentColor"
                strokeWidth={isStrong
                  ? 1
                  : 0.5}
                opacity={0.75}
              />
              <text y={20} textAnchor="middle" fontSize={10}>
                {
                  value % interval === 0
                    ? formatSquareMeters(value)
                    : ""
                }
              </text>
            </g>
          );
        }
      };
    default:
      return {
      };
  }
};

const getXFormat = (xType) => {
  switch (xType) {
    case "month":
      return "time:%Y-%m";

    default:
      // eslint-disable-next-line no-undefined
      return undefined;
  }
};

// eslint-disable-next-line max-lines-per-function
const getProps = ({
  data,
  withArea,
  withCategories,
  x,
  x: {
    type: xType
  },
  y: {
    min: yMin = "auto",
    max: yMax = "auto"
  } = {
    min: "auto",
    max: "auto"
  },
  isMobile,
  theme
}) => {
  const commonProps = {
    animate: true,
    axisBottom: getAxisBottom(xType, isMobile),
    axisLeft: {
      format: (value) => `${value}`,
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -45,
      legend: data?.[0]?.label,
      legendOffset: -50,
      legendPosition: "middle"
    },
    colors: () => "#822C42",
    data,
    enableSlices: "x",
    isInteractive: true,
    margin: {
      top: 10,
      right: 48,
      bottom: 80,
      left: 60
    },
    pointBorderColor: { from: "serieColor" },
    pointBorderWidth: 0,
    pointColor: "#822C42",
    pointLabelYOffset: -12,
    pointSize: 4,
    theme,
    xFormat: getXFormat(xType),
    xScale: getXScale(x),
    yScale: {
      type: "linear",
      min: yMin,
      max: yMax,
      stacked: false,
      reverse: false
    }
  };

  const backgroundLayers = [
    "grid",
    "markers",
    "axes",
    "areas"
  ];

  const middleLayers = [
    "crosshair",
    "points",
    "lines"
  ];

  const foregroundLayers = [
    "slices",
    "mesh",
    "legends"
  ];

  if (withArea && !withCategories) {
    commonProps.layers = [
      ...backgroundLayers,
      AreaLayer,
      ...middleLayers,
      PointsDetailLayer,
      ...foregroundLayers
    ];

    commonProps.sliceTooltip = getTooltipWithArea;
  }

  if (!withArea && withCategories) {
    commonProps.layers = [
      ...backgroundLayers,
      BarsLayer,
      ...middleLayers,
      (layerProps) => <PointsCategoryLayer {...layerProps} data={data} />,
      ...foregroundLayers
    ];

    commonProps.sliceTooltip = (datum) => getTooltipWithBar(datum, data);
  }

  if (!withArea && !withCategories) {
    commonProps.layers = [
      ...backgroundLayers,
      ...middleLayers,
      PointsDetailLayer,
      ...foregroundLayers
    ];

    commonProps.sliceTooltip = getTooltip;
  }

  return commonProps;
};

// eslint-disable-next-line max-lines-per-function
/**
 *
 * @param props
 * @param props.data
 * @param props.withArea
 * @param props.withCategories
 * @param props.xType
 * @param props.x
 * @param props.y
 */
export default function LineChart({
  data = [],
  withArea = false,
  withCategories = false,
  x,
  y,
  ...props
}) {
  const isPrint = useMediaQuery("print");
  const isMobile = useMediaQuery("(max-width: 1023px)") || isPrint;

  const theme = {
    axis: {
      legend: {
        text: {
          fontSize: isPrint ? 12 : 16,
          fill: "black"
        }
      }
    }
  };

  const ChartComponent = isPrint ? Line : ResponsiveLine;

  const [
    animatedData,
    setAnimatedData
  ] = useState([]);

  useEffect(() => {
    if (!isPrint) {
      // Delay setting the animated data to create the initial animation
      const timeoutId = setTimeout(() => {
        setAnimatedData(data);
      }, 1);

      // Cleanup function
      return () => clearTimeout(timeoutId);
    }
  }, [data]);

  const chartProps = getProps({
    data: isPrint ? data : animatedData,
    animate: !isPrint,
    withArea,
    withCategories,
    x,
    y,
    isMobile,
    theme
  });

  if (isPrint ? !data.length : !animatedData.length) {
    return null;
  }

  return (
    <ChartComponent
      {...chartProps}
      {...(isPrint && {
        width: 755.9055,
        height: 320
      })}
    />
  );
}
