/* eslint-disable no-magic-numbers */
import { useParams } from "react-router-dom";

import ContentSection from "~/src/ui/sections/content-section";
import useProject from "~/src/hooks/use-project";

/**
 *
 */
const ProjectOverviewEnergyClasses = () => {
  const { id } = useParams();
  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    energy_classes: energyClasses
  } = project;

  return (
    <ContentSection id="energyClasses" title="Energieausweise">
      <ul className="flex flex-col gap-1.5">
        {
          energyClasses.length === 0
            ? "-"
            : energyClasses
              .map((
                {
                  gst_numbers: gstNumbers,
                  hwb_from: hwbFromNumber,
                  hwb_to: hwbToNumber,
                  hwb_class: hwbClass,
                  fgee_from: fgeeFromNumber,
                  fgee_to: fgeeToNumber,
                  fgee_class: fgeeClass,
                  description
                },
                index
              ) => {
                const [
                  hwbFrom,
                  hwbTo
                ] = [
                  hwbFromNumber,
                  hwbToNumber
                ].map((number) => (number ? number.toFixed(2).replace(".", ",") : null));

                const [
                  fgeeFrom,
                  fgeeTo
                ] = [
                  fgeeFromNumber,
                  fgeeToNumber
                ].map((number) => (number ? number.toFixed(3).replace(".", ",") : null));

                return (
                  <li key={index} className="flex flex-col">
                    <span>
                      {
                        (hwbFrom || hwbClass) && (
                          <>
                            <span className="font-semibold">HWB: </span>
                            <span>{hwbTo ? `${hwbFrom} - ${hwbTo}` : hwbFrom || "-"} </span>
                            <span className="font-semibold">Klasse: </span>
                            <span>{hwbClass}</span>
                          </>
                        )
                      }
                    </span>
                    <span>
                      {
                        (fgeeFrom || fgeeClass) && (
                          <span>
                            <span className="font-semibold">fGEE: </span>
                            <span>{fgeeTo ? `${fgeeFrom} - ${fgeeTo}` : fgeeFrom || "-"} </span>
                            <span className="font-semibold">Klasse: </span>
                            <span>{fgeeClass}</span>
                          </span>
                        )
                      }
                    </span>
                    <span>
                      {
                        gstNumbers.length > 0 && (
                          <>
                            <span className="font-semibold">GST-Nr.: </span>
                            <span>&nbsp;</span>
                            <span>{gstNumbers.join(",")} </span>
                          </>
                        )
                      }</span>
                    <span>
                      {
                        description && (
                          <>
                            <span className="font-semibold">Bereich/Objekt: </span>
                            <span className="font-semibold">{description}</span>
                          </>
                        )
                      }
                    </span>

                  </li>
                );
              })
        }
      </ul>

    </ContentSection>
  );
};

export default ProjectOverviewEnergyClasses;
