import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import {
  Fragment, useRef, useState
} from "react";

import useStore from "~/src/hooks/use-store";
import API from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.isOpen
 */
export default function LogoutModal({ isOpen = false }) {
  const [
    isSubmitting,
    setIsSubmitting
  ] = useState(false);

  const toggleLogoutModal = useStore((state) => state.toggleLogoutModal);

  const initialInputRef = useRef(null);

  function handleClose() {
    toggleLogoutModal();
  }

  async function handleLogout(e) {
    e.preventDefault();

    setIsSubmitting(true);
    const res = await API.post("/auth/logout");

    window.location.replace(res.data.payload.logoutUrl);
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog initialFocus={initialInputRef} as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-full px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >

            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">

              <form onSubmit={handleLogout}>

                <div className="sm:grid sm:grid-cols-5 sm:gap-4 sm:items-start">
                  <div className="items-center justify-center hidden w-12 h-12 border-2 rounded-full md:flex border-primary">
                    <ArrowRightOnRectangleIcon className="w-6 h-6 text-primary" aria-hidden="true" />
                  </div>
                  <div className="mt-1 sm:mt-2 sm:col-span-4">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Logout
                    </Dialog.Title>
                    <div className="mt-1 space-y-1">
                      <p className="text-sm text-gray-500">Sind Sie sicher, dass Sie sich ausloggen möchten?</p>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Abbrechen
                    </button>

                    {isSubmitting &&
                      <button
                        type="submit"
                        disabled
                        className="inline-flex justify-center py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm cursor-not-allowed w-44 bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-lighter"
                      >
                        <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </button>
                    }
                    {!isSubmitting &&
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm w-44 bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-lighter"
                      >
                        Logout
                      </button>
                    }
                  </div>
                </div>

              </form>
            </div>

          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
