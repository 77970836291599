import { diff } from "deep-object-diff";

import { hasActiveOwnerFields } from "~/src/modules/share-deal-helper";
import ShareDealOwnerCardItem from "~/src/features/share-deal-owner-card-item";

/**
 *
 * @param root0
 * @param root0.owner
 */
export default function ShareDealOwnerCard({ owner }) {
  const { active: hasCurrentOwner, ownerObj: currentOwnerObj } = hasActiveOwnerFields(owner, "current");
  const { active: hasLegacyOwner, ownerObj: legacyOwnerObj } = hasActiveOwnerFields(owner, "legacy");

  const ownerDiff = diff(legacyOwnerObj, currentOwnerObj);

  return (
    <div className="bg-gray-100 text-gray-800 shadow overflow-hidden px-3 pb-3 sm:rounded-md text-sm divide-y space-y-4">

      {hasCurrentOwner &&
        <ShareDealOwnerCardItem owner={owner} type="current" hasCurrentOwner={hasCurrentOwner} hasLegacyOwner={hasLegacyOwner} diff={ownerDiff} />
      }

      {hasLegacyOwner &&
        <ShareDealOwnerCardItem owner={owner} type="legacy" hasCurrentOwner={hasCurrentOwner} hasLegacyOwner={hasLegacyOwner} diff={ownerDiff} />
      }

    </div>
  );
}
