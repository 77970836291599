import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import CloseIcon from "~/src/ui/icons/close-icon";
import { formatEuro, pluralize } from "~/src/modules/formatters";
import { priceLabels } from "~/src/modules/labels.js";

/**
 *
 * @param root0
 * @param root0.active
 * @param root0.hide
 * @param root0.stats
 */
export default function StatsSlide({
  active, hide, stats
}) {
  if (!stats) {
    return null;
  }

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog as="div" static className="z-[150] fixed inset-0 overflow-hidden" open={active} onClose={hide}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-lg">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-white hover:text-gray-200 focus:outline-none focus:ring-none"
                      onClick={hide}
                    >
                      <span className="sr-only">Close panel</span>

                      <CloseIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="relative flex-1">
                    <div className="bg-gray-white px-4 sm:px-6 py-4">
                      <Dialog.Title className="text-lg sm:text-2xl text-gray-800">Statistik</Dialog.Title>

                      {stats.project_count && stats.project_count > 0 &&
                        <div className="mt-2 text-lg font-light text-gray-800">
                          <p className="leading-tight">
                            aus {stats.project_count?.toLocaleString("de")} {pluralize("Projekt", "Projekten", "Projekten", stats.gka_count)} {stats.unit_count ? "" : "berechnet"}
                          </p>
                          {stats.unit_count &&
                            <p className="leading-tight">und {stats.unit_count?.toLocaleString("de")} {pluralize("Wohneinheit", "Wohneinheiten", "Wohneinheiten", stats.unit_count)} berechnet</p>
                          }
                        </div>
                      }
                      {(!stats.project_count || (stats.project_count === 0)) &&
                        <p className="mt-2 text-lg text-gray-800">
                          Keine Projekte ausgewählt
                        </p>
                      }

                    </div>

                    <div className="mt-4 mx-6 space-y-8">

                      <div className="bg-secondary-light">
                        <div className="p-8 border-l-4 border-gray-500">
                          <p className="font-medium text-lg text-gray-500">
                            <span>{priceLabels.averageGka}</span>
                          </p>
                          {stats.gka_avg &&
                            <p className="mt-2 text-4xl font-medium text-gray-800">{formatEuro(stats.gka_avg)} / m² <span className="text-base font-light">({stats.gka_count} {pluralize("Projekt", "Projekte", "Projekte", stats.gka_count)})</span></p>
                          }
                          {!stats.gka_avg &&
                            <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                          }
                        </div>
                      </div>

                      <div>
                        <p className="-ml-1 flex items-center"><span className="inline-block bg-primary w-3 h-3" /><span className="ml-2">Angebotsdaten</span></p>
                        <div className="mt-1 bg-secondary-light divide-y-2 divide-gray-200 border-l-4 border-primary">

                          <div className="p-8 ">
                            <p className="font-medium text-lg text-gray-500">
                              <span>{priceLabels.averageOfferPriceNormal}</span></p>
                            {stats.avg_offer_price_eigennutzer &&
                              <p className="mt-2 text-4xl font-medium text-gray-800">{formatEuro(stats.avg_offer_price_eigennutzer)} / m² <span className="text-base font-light">({stats.avg_offer_price_eigennutzer_count} {pluralize("Projekt", "Projekte", "Projekte", stats.avg_offer_price_eigennutzer_count)})</span></p>
                            }
                            {!stats.avg_offer_price_eigennutzer &&
                              <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                            }
                          </div>

                          <div className="p-8">
                            <p className="font-medium text-lg text-gray-500">
                              <span>{priceLabels.averageOfferPriceInvestorNet}</span></p>
                            {stats.avg_offer_price_investor &&
                              <p className="mt-2 text-4xl font-medium text-gray-800">{formatEuro(stats.avg_offer_price_investor)} / m² <span className="text-base font-light">({stats.avg_offer_price_investor_count} {pluralize("Projekt", "Projekte", "Projekte", stats.avg_offer_price_investor_count)})</span></p>
                            }
                            {!stats.avg_offer_price_investor &&
                              <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                            }
                          </div>

                          <div className="p-8">
                            <p className="font-medium text-lg text-gray-500">
                              <span>{priceLabels.averageRentNet}</span></p>
                            {stats.rent_avg &&
                              <p className="mt-2 text-4xl font-medium text-gray-800">{formatEuro(stats.rent_avg)} / m² <span className="text-base font-light">({stats.rent_count} {pluralize("Projekt", "Projekte", "Projekte", stats.rent_count)})</span></p>
                            }
                            {!stats.rent_avg &&
                              <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                            }
                          </div>

                        </div>
                      </div>

                      <div>
                        <p className="-ml-1 flex items-center"><span className="inline-block bg-primary-green w-3 h-3" /><span className="ml-2">Verkaufsdaten</span></p>

                        <div className="mt-1 bg-secondary-light divide-y-2 divide-gray-200 border-l-4 border-primary-green">
                          <div className="p-8">
                            <p className="font-medium text-lg text-gray-500">
                              <span>{priceLabels.averageSalePriceNormal}</span></p>
                            {stats.avg_sale_price_eigennutzer &&
                              <p className="mt-2 text-4xl font-medium text-gray-800">{formatEuro(stats.avg_sale_price_eigennutzer)} / m² <span className="text-base font-light">({stats.avg_sale_price_eigennutzer_count} {pluralize("Projekt", "Projekte", "Projekte", stats.avg_sale_price_eigennutzer_count)})</span></p>
                            }
                            {!stats.avg_sale_price_eigennutzer &&
                              <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                            }
                          </div>
                        </div>

                        <div className="mt-1 bg-secondary-light divide-y-2 divide-gray-200 border-l-4 border-primary-green">
                          <div className="p-8">
                            <p className="font-medium text-lg text-gray-500">
                              <span>{priceLabels.averageSalePriceInvestorNet}</span></p>
                            {stats.avg_sale_price_investor &&
                              <p className="mt-2 text-4xl font-medium text-gray-800">{formatEuro(stats.avg_sale_price_investor)} / m² <span className="text-base font-light">({stats.avg_sale_price_investor_count} {pluralize("Projekt", "Projekte", "Projekte", stats.avg_sale_price_eigennutzer_count)})</span></p>
                            }
                            {!stats.avg_sale_price_investor &&
                              <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                            }
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
