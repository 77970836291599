/* eslint-disable no-magic-numbers */
import { MapPinIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import { components } from "react-select";
import Select from "react-select/async";
import { debounce, throttle } from "throttle-debounce";

import { ApiModel } from "~/src/lib/models/projects.js";
import API from "~/src/modules/api.js";

/**
 *
 * @param props
 * @param props.street
 * @param props.handleUpdate
 * @param props.className
 */

const boldLocationTypes = [
  "city",
  "plz"
];

// eslint-disable-next-line func-style
function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} className="pl-2">
      <MapPinIcon className="w-5 h-5 text-gray-400" /> {children}
    </components.Control>
  );
}

const loadOptions = async (inputValue, cb) => {
  try {
    const res = await API.post("/search/autosuggest-locations", {
      input: inputValue
    });

    const options = res.data.payload.locations.items.map((location) => ({
      label: location?.address.label.replace(", Österreich", ""),
      value: `${location?.position?.lat},${location?.position?.lng}`,
      locationType: location?.type,
      locationId: location?.locationId ?? "",
      body: {
      }
    }));

    if (options.locationType !== "street_address") {
      delete options.value;
    }

    if (ApiModel.locationTypesWithLocationId.includes(options.locationType)) {
      delete options.locationId;
    }

    cb(options);
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
  }
};

const loadOptionsDebounced = debounce(500, loadOptions);
const loadOptionsThrottled = throttle(500, loadOptions);

const handleLoadOptions = (inputValue) => new Promise((resolve, reject) => {
  if (inputValue.length < 4) {
    loadOptionsThrottled(inputValue, (options) => {
      resolve(options);
    });
  }
  else {
    loadOptionsDebounced(inputValue, (options) => {
      resolve(options);
    });
  }
});

const noOptionsMessage = (data) => {
  if (data.inputValue.length > 0) {
    return "Keine Straße gefunden...";
  }

  return "Straße suchen...";
};

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = {
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af"
  }),
  option: (styles, state) => {
    const { locationType } = state.data;

    return styles;

    /*
    // eslint-disable-next-line no-unreachable
    return ({
      ...styles,
      "font-weight": "bolder"
    });
    */
  },
  control: (styles, state) => ({
    ...styles,
    "&:hover": {
      borderColor: state.isFocused ? "#525252" : "#a3a3a3"
    },
    background: "#f4f4f5",
    borderColor: state.isFocused ? "#a3a3a3" : "#e5e7eb",
    borderRadius: "0.25rem",
    borderWidth: state.isFocused ? "1px" : "1px",
    boxShadow: "state.isFocused ? \"0 0 0 20px rgba(56,178,172,1)\" : 0",
    height: 33,
    minHeight: 33,
    paddingRight: 62
  }),

  singleValue: (base) => ({
    ...base,
    borderRadius: "5px",
    padding: "0 6px",
    background: "#822c42",
    color: "white"
  })
};

/**
 *
 * @param root0
 * @param root0.street
 * @param root0.handleUpdate
 * @param root0.className
 */
export default function AddressSelect({
  street, handleUpdate, className
}) {
  const handleSelect = (selected) => {
    handleUpdate("street", selected);
  };

  return (
    <Select
      className={cn("react-select", className)}
      classNames={{ placeholder: () => "text-sm" }}
      instanceId="street"
      styles={customStyles}
      value={street || ""}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input
      }}
      cacheOptions={false}
      isClearable
      placeholder="Straße"
      noOptionsMessage={noOptionsMessage}
      loadOptions={handleLoadOptions}
      onChange={handleSelect}
      blurInputOnSelect={true}
    />
  );
}
