import { fundingFilterLabels } from "~/src/modules/labels";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function FundingFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const funding = queryItems.split(",");

  function handleClick(fund) {
    const newFund = funding.filter((item) => item !== fund).join(",");

    submit("foerderung", newFund);
  }

  return funding.map((fund, index) => (
    <DefaultFilterTag key={`fund_tag_${index}`} handleClick={() => handleClick(fund)}>
      <span>Förderungen: {fundingFilterLabels[fund]}</span>
    </DefaultFilterTag>
  ));
}
