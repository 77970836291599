import { Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import animatedScrollTo from "animated-scroll-to";
import cn from "classnames";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

import AreaSettingsModal from "~/src/features/area-settings-modal";
import LogoutModal from "~/src/features/logout-modal";
import ProjectSuggestionModal from "~/src/features/project-suggestion-modal";
import FilterBehaviorModal from "~/src/features/filter-behavior-modal";
import useStore from "~/src/hooks/use-store";

/**
 *
 * @param props
 * @param props.title
 * @param props.children
 * @param props.fixed
 * @param props.className
 * @param props.id
 */
export default function Page({
  title = "",
  children,
  fixed,
  className,
  id = title.toLocaleLowerCase()
    .replace(/[^A-Za-z0-9]/gu, "-")
    .replace(/-+/gu, "-")
    .replace(/^-|-$/gu, ""),
  ...props
}) {
  const [
    showScrollTop,
    setShowScrollTop
  ] = useState(false);

  const showLogoutModal = useStore((state) => state.showLogoutModal);
  const showAreaModal = useStore((state) => state.showAreaModal);
  const showProjectSuggestionModal = useStore((state) => state.showProjectSuggestionModal);
  const showFilterBehaviorModal = useStore((state) => state.showFilterBehaviorModal);

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = Math.abs(currPos.y) > 800;

    if (isShow !== showScrollTop) {
      setShowScrollTop(isShow);
    }
  }, [showScrollTop]);

  function handleScrollTop() {
    animatedScrollTo(0, {
      speed: 50
    });
  }

  return (
    <>
      <Helmet
        defer={false}
      >
        <title>{title} | IMMOdeveloper</title>
      </Helmet>

      <LogoutModal isOpen={showLogoutModal} />
      <AreaSettingsModal isOpen={showAreaModal} />
      <ProjectSuggestionModal isOpen={showProjectSuggestionModal} />
      <FilterBehaviorModal isOpen={showFilterBehaviorModal} />

      <div className={cn("relative bg-secondary-light", className)} id={id} {...props} >
        {children}
      </div>

      <Transition
        show={showScrollTop}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <button onClick={handleScrollTop} type="button" className="fixed z-10 flex items-center justify-center w-10 h-10 text-white bottom-4 right-4 bg-primary hover:bg-primary-dark">
          <ChevronUpIcon className="w-10 h-10" />
        </button>
      </Transition>

    </>
  );
}
