/* eslint-disable no-magic-numbers */
/* eslint-disable no-warning-comments */
/* eslint-disable import/exports-last */
import { projectCols } from "./columns-spec.js";

const applyStylesAndFormatToColumn = (colKey, columns, sheet) => {
  const col = columns[colKey];
  const sheetCol = sheet.getColumn(col.number);

  sheetCol.font = { size: 12 };
  sheetCol.width = parseInt((col.label.length + 5) * 1.10);
  sheetCol.alignment = {
    vertical: "middle",
    horizontal: "left"
  };
  if (col.formatType === "numFmt") {
    sheetCol.numFmt = col.formatString;
  }
};

/**
 *
 * @param workbook
 * @param projects
 */
export default function projectsSheetGenerator(workbook, projects) {
  const projectsSheet = workbook.addWorksheet("Exportierte Projekte");

  const headerRow = projectsSheet.addRow();

  for (const colKey in projectCols) {
    const col = projectCols[colKey];

    headerRow.getCell(col.number).value = col.label;
    applyStylesAndFormatToColumn(colKey, projectCols, projectsSheet);
  }

  for (const project of projects) {
    const row = projectsSheet.addRow();

    for (const colKey in projectCols) {
      const col = projectCols[colKey];
      const cell = row.getCell(col.number);
      const val = col.get(project);

      // todo: parseFloat should be done in the getter
      if (col.formatType === "numFmt" && val !== "") {
        cell.value = parseFloat(val);
      }
      else if (col.formatType === "boolean" && val !== "") {
        cell.value = (val)
          ? "Ja"
          : "Nein";
      }
      else {
        cell.value = val;
      }
    }
  }
  headerRow.font = {
    bold: true,
    size: 12
  };

  return null;
}
