import getKeyComponents from "./define-labels-given-key-and-label-components/get-key-components";
import getLabelComponents from "./define-labels-given-key-and-label-components/get-label-components";

import { cartesianProduct } from "~/src/lib/standard";

/**
 *
 * @param options
 * @param options.components
 * @param options.keyComponentPositions
 * @param options.labelComponentPositions
 * @param options.labelFormatter
 */
const defineLabelsGivenKeyAndLabelComponents = ({
  components,
  keyComponentPositions,
  labelComponentPositions,
  labelFormatter: _labelFormatter
}) => {
  const labelFormatter = _labelFormatter ??
  (
    (currentComponents) => currentComponents.join(" ")
  );

  const componentsByKey = cartesianProduct(...components);

  const concattedKeysAndLabels = componentsByKey
    .map((keyAndLabelComponents) => {
      const key = getKeyComponents(keyAndLabelComponents, keyComponentPositions).join("_").replace(/_$/, "");
      const labelComponents = getLabelComponents(keyAndLabelComponents, labelComponentPositions);
      const label = labelFormatter(labelComponents);

      return [
        key,
        label
      ];
    });

  return Object.fromEntries(concattedKeysAndLabels);
};

export default defineLabelsGivenKeyAndLabelComponents;
