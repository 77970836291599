import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import ProjectsMapTile from "../../projects-map-tile";

import useKeyPress from "~/src/hooks/use-key-press";
import CloseIcon from "~/src/ui/icons/close-icon";

/**
 *
 * @param root0
 * @param root0.projectSlugs
 * @param root0.show
 * @param root0.hide
 */
export default function MapSlide({
  projectSlugs, show, hide
}) {
  useKeyPress("Escape", hide);

  function handleHide() {
    hide();
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" static className="z-[150] fixed inset-0 overflow-hidden" open={show} onClose={hide}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-2xl">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={hide}
                    >
                      <span className="sr-only">Close panel</span>
                      <CloseIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="relative flex-1">
                    <div className="absolute inset-0 inset-x-4 sm:inset-x-6">
                      <ProjectsMapTile projectSlugs={projectSlugs} isSimple={true} handleCheckbox={() => {}} />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
