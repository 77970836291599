import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 */
export default function useWatchlists() {
  const {
    data, error, mutate
  } = useSWR("/watchlists", fetcher);

  return {
    watchlists: data?.payload?.watchlists,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
