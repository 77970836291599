import cn from "classnames";

/**
 *
 * @param root0
 * @param root0.className
 */
export default function MarkerIconPurchase({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={cn("-ml-0.5", className)}>
      <path
        d="M81.3,80.4c-4,1.5-7.6,2.2-11.6,2.2c-15.3,0-24.4-6.9-29.1-16h23.6l2.9-13.1H36.9c0-1.1,0-2.2,0-3.3c0-1.5,0-2.9,0-4h32.4l2.9-13.5H40.9C46,24,55.1,17.1,69.6,17.1c2.9,0,6.2,0.4,9.1,1.1l5.8-16C79.8,0.7,74.7,0,69.6,0C42.7,0,28.2,14.2,22.7,33.1h-9.8v13.5h7.6c0,1.5,0,2.9,0,4v3.3h-7.3v13.1h9.5C27.8,85.8,42,100,70.4,100c5.5,0,11.6-0.7,16.7-2.2L81.3,80.4z"
        fill="currentColor"
      />
    </svg>
  );
}
