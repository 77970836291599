import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Listbox } from "@headlessui/react";
import cn from "classnames";

import { priceLabels } from "~/src/modules/labels";
import { nullFn } from "~/src/lib/standard";

/**
 *
 * @param props
 * @param props.value
 * @param props.onChange
 * @param props.className
 * @param props.buttonClassName
 * @param props.options
 * @param props.enabledOptions
 */
const PriceSelect = ({
  value,
  options = [],
  enabledOptions = null,
  onChange = nullFn,
  className = "",
  buttonClassName = ""
}) => {
  const mapLabelsToListBoxOptions = (option) => {
    const optionDisabled = enabledOptions && !enabledOptions.includes(option);
    const listBoxClassName = optionDisabled
      ? "text-gray-700 bg-gray-300 cursor-default select-none relative px-2 py-1"
      : ({ active }) => `${active ? "text-gray-900 bg-gray-100" : "text-gray-700"} cursor-pointer select-none relative px-2 py-1`;

    return (<Listbox.Option
      key={option}
      disabled={optionDisabled}
      className={listBoxClassName}
      value={option}
    >

      {({ selected }) => (
        <>
          <span className={`${selected ? "font-medium" : "font-normal"} block truncate`}>
            {priceLabels[option]}
          </span>
        </>
      )}
    </Listbox.Option>);
  };

  return (
    <Listbox value={value} onChange={onChange} className={cn("relative flex flex-row rounded cursor-pointer", className)} as="div">
      <Listbox.Button className="flex items-center justify-between w-full px-2 py-1 bg-white border border-gray-300 rounded">
        <span className="text-sm text-gray-900">{priceLabels[value]}</span>
        <ChevronDownIcon className="w-5 h-5 text-gray-400" />
      </Listbox.Button>
      <Listbox.Options className="absolute z-10 w-full py-1 mt-8 overflow-auto text-base bg-white rounded shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        {options.map((option) => (mapLabelsToListBoxOptions(option)
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

export default PriceSelect;
