/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable camelcase */
import { ScaleIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";

import useProjectExport from "~/src/hooks/use-project-export";
import priceTerms from "~/src/lib/price-terms.js";
import { calcAreaRatios } from "~/src/modules/area-calculation-helper";
import dateFormatter from "~/src/modules/date-formatter";
import {
  formatArea, formatDate, formatEuro, formatYear
} from "~/src/modules/formatters";
import { priceLabels } from "~/src/modules/labels.js";

const {
  normal: normalTerm,
  investment: investmentTerm,
  net: netTerm,
  gross: grossTerm
} = priceTerms;

/**
 *
 * @param root0
 * @param root0.unit
 * @param root0.unit
 * @param root0.unit.name
 * @param root0.unit.id
 * @param root0.unit.building_part
 * @param root0.unit.staircase
 * @param root0.unit.floor
 * @param root0.unit.verwertet
 * @param root0.unit.verwertet_date
 * @param root0.unit.verwertet_date_format
 * @param root0.unit.offer_data_available
 * @param root0.unit.sale_data_available
 * @param root0.unit.kv_id
 * @param root0.unit.tz_number
 * @param root0.unit.tz_year
 * @param root0.unit.sale_date
 * @param root0.unit.offer_price_normal
 * @param root0.unit.offer_price_investor
 * @param root0.unit.rent_netto
 * @param root0.unit.rent_bk
 * @param root0.unit.offer_area
 * @param root0.unit.sale_price_net
 * @param root0.unit.sale_price_gross
 * @param root0.unit.sale_area
 * @param root0.unit.offer_loggia_count
 * @param root0.unit.offer_loggia_area
 * @param root0.unit.offer_balkon_count
 * @param root0.unit.offer_balkon_area
 * @param root0.unit.offer_terrasse_count
 * @param root0.unit.offer_terrasse_area
 * @param root0.unit.offer_garten_count
 * @param root0.unit.offer_garten_area
 * @param root0.unit.offer_keller_count
 * @param root0.unit.offer_keller_area
 * @param root0.unit.sale_loggia_count
 * @param root0.unit.sale_loggia_area
 * @param root0.unit.sale_balkon_count
 * @param root0.unit.sale_balkon_area
 * @param root0.unit.sale_terrasse_count
 * @param root0.unit.sale_terrasse_area
 * @param root0.unit.sale_garten_count
 * @param root0.unit.sale_garten_area
 * @param root0.unit.sale_keller_count
 * @param root0.unit.sale_keller_area
 * @param root0.unit.offer_notice
 * @param root0.unit.offer_room_count
 * @param root0.unit.buyer
 * @param root0.unit.sale_notice
 * @param root0.unit.sale_room_count
 */
// eslint-disable-next-line max-lines-per-function, complexity, max-statements
export default function ProjectUnit({
  unit,
  unit: {
    name,
    id,
    building_part,
    staircase,
    floor,
    verwertet,
    verwertet_date,
    verwertet_date_format,
    offer_data_available,
    sale_data_available,
    kv_id,
    tz_number,
    tz_year,
    sale_date,
    offer_price_normal,
    offer_price_investor,
    rent_netto,
    rent_bk,
    offer_area,
    sale_price_net,
    sale_price_gross,
    sale_area,

    offer_loggia_count,
    offer_loggia_area,
    offer_balkon_count,
    offer_balkon_area,
    offer_terrasse_count,
    offer_terrasse_area,
    offer_garten_count,
    offer_garten_area,
    offer_keller_count,
    offer_keller_area,

    sale_loggia_count,
    sale_loggia_area,
    sale_balkon_count,
    sale_balkon_area,
    sale_terrasse_count,
    sale_terrasse_area,
    sale_garten_count,
    sale_garten_area,
    sale_keller_count,
    sale_keller_area,

    offer_notice,
    offer_room_count,

    buyer,
    sale_notice,
    sale_room_count
  }
}) {
  const { id: projectId } = useParams();
  const { userSettings } = useProjectExport(projectId);

  const showCustom = userSettings.use_custom_area_ratios;

  let customAreas = {};

  if (showCustom) {
    customAreas = calcAreaRatios(unit, userSettings);
  }

  const {
    offerArea: customOfferArea,
    saleArea: customSaleArea
  } = customAreas;

  const customOfferAreaDifference = customOfferArea
    ? (Math.round(Number(customOfferArea) * 100) / 100) - offer_area
    : 0;
  const customSaleAreaDifference = customSaleArea
    ? (Math.round(Number(customSaleArea) * 100) / 100) - sale_area
    : 0;

  const unitMainDataLabels = [
    "Preis / m²",
    "Nutzfläche",
    "Preis"
  ];

  const unitOtherDataLabels = [
    "Loggia",
    "Balkon",
    "Terrasse",
    "Garten",
    "Keller"
  ];

  const showOfferPriceNormal = (offer_price_normal && (offer_area || customOfferArea));
  const showOfferPriceInvestor = (offer_price_investor && (offer_area || customOfferArea));
  const showRentNetto = (rent_netto && offer_area);

  const unitMainOfferData = [
    [
      {
        label: normalTerm,
        value: showOfferPriceNormal && formatEuro(
          (offer_price_normal) / (customOfferArea ?? offer_area)
        )
      },
      {
        label: investmentTerm,
        value: showOfferPriceInvestor && formatEuro(
          (offer_price_investor) / (customOfferArea ?? offer_area)
        )
      },
      {
        label: priceLabels.rentNet,
        value: showRentNetto && formatEuro(
          (rent_netto) / (customOfferArea ?? offer_area)
        )
      }
    ]
      .filter(({ value }) => value),
    [
      (customOfferArea && (Math.round(Number(customOfferArea) * 100) / 100) !== offer_area) && {
        label: <span className="text-primary">individuelle Gewichtung</span>,
        value: formatArea(customOfferArea)
      },
      {
        label: "Standardgewichtung",
        value: offer_area && formatArea(offer_area)
      }
    ]
      .filter((row) => row)
      .filter(({ value }) => value),
    [
      {
        label: normalTerm,
        value: offer_price_normal && formatEuro(offer_price_normal)
      },
      {
        label: investmentTerm,
        value: offer_price_investor && formatEuro(offer_price_investor)
      },
      {
        label: priceLabels.rentNet,
        value: rent_netto && formatEuro(rent_netto)
      },
      {
        label: priceLabels.rentTotal,
        value: rent_bk && formatEuro(rent_bk)
      }
    ]
      .filter(({ value }) => value)
  ];

  const unitOtherOfferData = [
    `${formatArea(offer_loggia_area) || "-"} (${offer_loggia_count || "-"})`,
    `${formatArea(offer_balkon_area) || "-"} (${offer_balkon_count || "-"})`,
    `${formatArea(offer_terrasse_area) || "-"} (${offer_terrasse_count || "-"})`,
    `${formatArea(offer_garten_area) || "-"} (${offer_garten_count || "-"})`,
    `${formatArea(offer_keller_area) || "-"} (${offer_keller_count || "-"})`
  ]
    .map((value) => (value === "- (-)" ? "-" : value))
    .map((value) => (value.endsWith(" (-)") ? value.replace(/^(?<value>.*?) \(-\)$/u, "$<value>") : value))
    .map((value) => (value.startsWith("- ") ? value.replace(/^- \((?<value>.*?)\)$/u, "$<value>") : value));

  const showSalePriceNet = (sale_price_net && (sale_area || customSaleArea));
  const showSalePriceGross = (sale_price_gross && (sale_area || customSaleArea));

  const unitMainSaleData = [
    [
      {
        label: netTerm,
        value: showSalePriceNet && formatEuro(
          (sale_price_net) / (customSaleArea ?? sale_area)
        )
      },
      {
        label: grossTerm,
        value: showSalePriceGross && formatEuro(
          (sale_price_gross) / (customSaleArea ?? sale_area)
        )
      }
    ]
      .filter(({ value }) => value),
    [
      (customSaleArea && (Math.round(Number(customSaleArea) * 100) / 100) !== sale_area) && {
        label: <span className="text-primary">individuelle Gewichtung</span>,
        value: formatArea(customSaleArea)
      },
      {
        label: "Standardgewichtung",
        value: sale_area && formatArea(sale_area)
      }
    ]
      .filter((row) => row)
      .filter(({ value }) => value),
    [
      {
        label: netTerm,
        value: sale_price_net && formatEuro(sale_price_net)
      }
    ]
      .filter(({ value }) => value)
  ];

  const unitOtherSaleData = [
    `${formatArea(sale_loggia_area) || "-"} (${sale_loggia_count || "-"})`,
    `${formatArea(sale_balkon_area) || "-"} (${sale_balkon_count || "-"})`,
    `${formatArea(sale_terrasse_area) || "-"} (${sale_terrasse_count || "-"})`,
    `${formatArea(sale_garten_area) || "-"} (${sale_garten_count || "-"})`,
    `${formatArea(sale_keller_area) || "-"} (${sale_keller_count || "-"})`
  ]
    .map((value) => (value === "- (-)" ? "-" : value))
    .map((value) => (value.endsWith(" (-)") ? value.replace(/^(?<value>.*?) \(-\)$/u, "$<value>") : value))
    .map((value) => (value.startsWith("- ") ? value.replace(/^- \((?<value>.*?)\)$/u, "$<value>") : value));

  const offerRoomCountText = offer_data_available && `${offer_room_count ?? "unbekannt"}${sale_data_available ? "(Angebot)" : ""}`;
  const saleRoomCountText = sale_data_available && `${sale_room_count ?? "unbekannt"}${offer_data_available ? "(Verkauf)" : ""}`;

  const roomCountText = `${offer_room_count === sale_room_count
    ? offer_room_count
    : [
      offerRoomCountText,
      saleRoomCountText
    ].filter((text) => text).join(" / ")}`;

  const showRoomCount = (offer_room_count || sale_room_count);

  return (
    <div className="ring-1 ring-inset ring-black p-2 break-inside-avoid">
      <span className="font-bold">{name}</span>
      {showRoomCount && <span className="block">Zimmer: {roomCountText}</span>}
      <div className="flex justify-between mb-1">
        <div className="flex gap-2">
          {
            [
              [
                "Bauteil",
                building_part?.name
              ],
              [
                "Stiege",
                staircase
              ],
              [
                "Geschoss",
                floor
              ]
            ]
              .map(([
                label,
                value
              ]) => (
                <span key={label}>{label}: {value ?? "-"}</span>
              ))
          }
        </div>
        <div className="text-right">
          {
            (offer_data_available && verwertet && verwertet_date) && (
              <span className="block">
                verwertet: {dateFormatter(verwertet_date, verwertet_date_format)}
              </span>
            )
          }
          {
            sale_data_available && (
              <span className="block">
                {kv_id &&
                  <span>
                    <a className="text-black underline" href={`https://immobase.immounited.com/IMMOmapping/Default.aspx?mapmode=1&contractOfPurchaseId=${kv_id}`}>
                      TZ {(tz_number && tz_year) && `${tz_number}/${formatYear(tz_year)}`}
                    </a>
                  </span>
                }
                {!kv_id &&
                  <span>TZ {(tz_number && tz_year) && `${tz_number}/${formatYear(tz_year)}`}</span>
                }

                <span> vom {formatDate({ date: sale_date })}</span>
              </span>
            )
          }
        </div>
      </div>
      <div>
        <div className="flex">
          {
            unitMainDataLabels.map((label, index) => (
              <div
                className="w-[calc(50%/3)] odd:bg-gray-200 grid p-1"
                key={`main_${index}`}
              >
                <span className="font-medium margin-auto text-center">{label}</span>
              </div>
            ))
          }
          {
            unitOtherDataLabels.map((label, index) => (
              <div
                className="w-[calc(50%/5)] odd:bg-gray-200 grid p-1"
                key={`other_${index}`}
              >
                <span className="font-medium margin-auto text-center">{label}</span>
              </div>
            ))
          }
        </div>
        {
          offer_data_available && (
            <div className="flex relative">
              <div className="absolute border-b-2 border-primary w-full flex bg-primary/10 items-center">
                <span className="bg-primary text-white h-6 w-[calc(50%/3)] flex items-center justify-center">Angebot</span>
                {
                  customOfferAreaDifference > 0 && (
                    <ScaleIcon
                      data-tooltip-id="tooltip-unit-offer-avg-price"
                      data-tooltip-content="Preis / m² aus individueller Gewichtung berechnet" className="w-4 h-4 text-white -ml-5 -mb-[3.25rem] p-0.5 rounded-full bg-primary" />
                  )
                }
              </div>
              <div className="flex w-6/12">
                {
                  unitMainOfferData
                    .map((column, index) => (
                      <div
                        className="w-[calc(100%/3)] odd:bg-gray-200 space-y-2 px-2 pt-8 pb-4"
                        key={index}
                      >
                        {
                          column.length > 0 && column.map(({ label, value }) => (
                            <div key={label}>
                              <span className="block text-[0.9em] text-center font-bold">{value}</span>
                              <span className="block text-[0.6em] text-center font-medium leading-none">{label}</span>
                            </div>
                          ))
                        }
                        {
                          column.length === 0 && (
                            <span className="block text-[0.9em] text-center font-bold">-</span>
                          )
                        }
                      </div>
                    ))
                }
              </div>
              <div className="flex w-6/12">
                {
                  unitOtherOfferData
                    .map((value, index) => (
                      <div
                        className="w-[calc(100%/5)] even:bg-gray-200 space-y-2 px-2 pt-8 pb-4"
                        key={index}
                      >
                        <div>
                          <span className="block text-[0.6em] text-center font-bold">{value}</span>
                        </div>
                      </div>
                    ))
                }
              </div>
            </div>
          )
        }
        {
          sale_data_available && (
            <div className="flex relative">
              <div className="absolute border-b-2 border-primary-green w-full flex bg-primary-green/10  items-center">
                <span className="bg-primary-green text-white h-6 w-[calc(50%/3)] flex items-center justify-center">Verkauf</span>
                {
                  customSaleAreaDifference > 0 && (
                    <ScaleIcon
                      data-tooltip-id="tooltip-unit-sale-avg-price"
                      data-tooltip-content="Preis / m² aus individueller Gewichtung berechnet" className="w-4 h-4 text-white -ml-5 -mb-[3.25rem] p-0.5 rounded-full bg-primary" />
                  )
                }
              </div>
              <div className="flex w-6/12">
                {
                  unitMainSaleData
                    .map((column, index) => (
                      <div
                        className="w-[calc(100%/3)] odd:bg-gray-200 space-y-2 px-2 pt-8 pb-4"
                        key={index}
                      >
                        {
                          column.length > 0 && column.map(({ label, value }) => (
                            <div key={label}>
                              <span className="block text-[0.9em] text-center font-bold">{value}</span>
                              <span className="block text-[0.6em] text-center font-medium leading-none">{label}</span>
                            </div>
                          ))
                        }
                        {
                          column.length === 0 && (
                            <span className="block text-[0.9em] text-center font-bold">-</span>
                          )
                        }
                      </div>
                    ))
                }
              </div>
              <div className="flex w-6/12">
                {
                  unitOtherSaleData
                    .map((value, index) => (
                      <div
                        className="w-[calc(100%/5)] even:bg-gray-200 space-y-2 px-2 pt-8 pb-4"
                        key={index}
                      >
                        <div>
                          <span className="block text-[0.6em] text-center font-bold">{value}</span>
                        </div>
                      </div>
                    ))
                }
              </div>
            </div>
          )
        }
        <div className="py-2 text-[0.9em] leading-none">
          <dl className="space-y-0.5">
            <div>
              <dt className="inline float-left clear-left mr-1">Bemerkungen (Angebot):</dt>
              <dd className="inline">{offer_notice || "-"}</dd>
            </div>
            <div>
              <dt className="inline float-left clear-left mr-1">Bemerkungen (Verkauf):</dt>
              <dd className="inline">{sale_notice || "-"}</dd>
            </div>
            <div>
              <dt className="inline float-left clear-left mr-1">Kaufende Partei:</dt>
              <dd className="inline [font-variant-ligatures:no-contextual]">{buyer.length > 0 ? buyer.join(", ") : "-"}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
