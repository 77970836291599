import CloseIcon from "~/src/ui/icons/close-icon";

/**
 *
 * @param root0
 * @param root0.label
 * @param root0.onClick
 * @param root0.show
 */
export default function SingleFilterTag({
  label, onClick, show
}) {
  if (!label || !show) {
    return null;
  }

  return (
    <button type="button" onClick={onClick} className="flex items-center gap-x-1 text-xs px-1.5 py-0.5 rounded bg-white text-secondary-darkest shadow hover:bg-gray-50 focus:outline-none">
      <span>{label}</span>
      <CloseIcon className="-mr-0.5 w-4 h-4 text-secondary-darkest" />
    </button>
  );
}
