import { Popover, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import {
  Fragment, useEffect, useState
} from "react";
import { Radius } from "lucide-react";

/**
 *
 * @param props
 * @param props.onChange
 * @param props.radius
 * @param props.className
 */
export default function RadiusDropdown({
  onChange, radius, className
}) {
  const [
    rangeValue,
    setRangeValue
  ] = useState(radius || 2);

  function handleChange(e) {
    setRangeValue(e.target.value);
  }

  function calcLeft(rangeValue) {
    const newValue = Number((rangeValue - 0.1) * 100 / (25.0 - 0.1));
    const newPosition = 24 - (newValue * 0.48);

    return `calc(${newValue}% + (${newPosition}px))`;
  }

  function handleSave(closePopup) {
    onChange(rangeValue);
    closePopup();
  }

  useEffect(() => {
    setRangeValue(radius);
  }, [radius]);

  return (
    <div className={className}>
      <Popover className="relative">
        {({ open }) => (
          <div>
            <Popover.Button
              className={cn("flex px-2.5 py-0.5 justify-center items-center text-center border text-gray-600 bg-secondary-dark transition duration-300 hover:bg-secondary-darker border-secondary-darker group rounded text-xs font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 h-[23px]", {
                "border-gray-300 hover:bg-gray-50": !open,
                "border-gray-200 bg-gray-200": open
              })}
            >
              <Radius className="w-4 h-4" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                unmount={true}
                className="z-40 w-80 -left-[13rem] lg:-left-[137px] top-8 absolute"
              >
                {({ open, close }) => (
                  <div className="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 h-44">

                    <div className="flex items-center justify-between p-3 border-b border-gray-200">
                      <p className="font-medium text-gray-800">Radius (km)</p>
                      <button type="button" className="text-gray-500 focus:outline-none hover:text-gray-700" onClick={close}>
                        <XMarkIcon className="w-5 h-5" />
                      </button>
                    </div>

                    <div className="relative w-full px-4 py-2 mt-8">
                      <div className="range-value" style={{ left: calcLeft(rangeValue) }}>
                        <span>{rangeValue}</span>
                      </div>
                      <input type="range" min="0.1" max="25" value={rangeValue} step={0.1} onChange={handleChange} className="slider appearance-none w-full outline-none h-1.5 bg-secondary-dark rounded" />
                    </div>

                    <div className="px-4 mt-2">

                      <button onClick={() => handleSave(close)} type="button" className="inline-flex justify-center w-full px-8 py-1 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-dark focus:outline-none">
                        Speichern
                      </button>

                    </div>

                  </div>
                )}
              </Popover.Panel>

            </Transition>

          </div>
        )}
      </Popover>
    </div>
  );
}
