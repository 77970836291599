import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import RadioGroup from "~/src/ui/forms/radio-group";
import Select from "~/src/ui/forms/select";

// eslint-disable-next-line max-lines-per-function
/**
 *
 * @param props
 * @param props.onChange
 * @param props.offerPriceOptions
 * @param props.dateOptions
 * @param props.typeOptions
 */
// eslint-disable-next-line max-lines-per-function
export default function RentableForm({
  onChange,
  offerPriceOptions,
  dateOptions,
  typeOptions
}) {
  const [
    priceOptions,
    setPriceOptions
  ] = useState([]);

  const {
    handleSubmit,
    control,
    setValue
  } = useForm({
    mode: "onChange",
    defaultValues: {
      xType: "",
      yType: "",
      type: ""
    }
  });

  const xType = useWatch({
    control,
    name: "xType"
  });
  const yType = useWatch({
    control,
    name: "yType"
  });

  const type = useWatch({
    control,
    name: "type"
  });

  useEffect(() => {
    setValue("type", typeOptions.filter(({ shown }) => shown)[0]?.value);
  }, [typeOptions]);

  useEffect(() => {
    setValue("xType", dateOptions.filter(({ shown }) => shown)[0]?.value);
  }, [dateOptions]);

  useEffect(() => {
    setPriceOptions(offerPriceOptions);
  }, [
    type,
    offerPriceOptions
  ]);

  useEffect(() => {
    if (priceOptions.length > 0) {
      setValue(
        "yType",
        offerPriceOptions[0]?.value
      );
    }
  }, [priceOptions]);

  useEffect(() => {
    onChange({
      xType,
      yType,
      type
    });
  }, [
    xType,
    yType,
    type
  ]);

  return (
    <form className="flex flex-col lg:flex-row justify-between gap-8" onSubmit={handleSubmit(onChange)}>
      <div className="flex flex-col gap-4 w-full">
        <Select
          name="yType"
          label=""
          options={priceOptions}
          key="yType"
          color={type === "offer" ? "red" : "green"}
          {...{ control }}
        />
      </div>

      <div className="flex flex-col gap-4 w-full lg:w-1/2 xl:w-1/3 hidden">
        <div className="flex flex-row gap-1 items-center ">
          <h3 className="flex text-lg font-medium">Datum</h3>
          <ArrowRightIcon className="w-5 h-5" />
        </div>
        <RadioGroup
          name="xType"
          label="Datum"
          options={dateOptions}
          key="xType"
          {...{ control }}
        />
      </div>

    </form>
  );
}
