import { useMapEvents } from "react-leaflet";

import useMediaQuery from "~/src/hooks/use-media-query";

/**
 *
 * @param root0
 * @param root0.setClusterState
 * @param root0.setUserManipulated
 * @param root0.setMapViewState
 * @param root0.isSimple
 */
const MapEvents = ({
  setClusterState, setMapViewState, isSimple
}) => {
  const isPrint = useMediaQuery("print");

  useMapEvents({
    moveend: (event) => {
      if (!isSimple || isPrint) {
        const map = event.target;

        const center = map.getCenter();
        const zoom = map.getZoom();
        const bounds = map.getBounds();
        const boundingBoxString = bounds.toBBoxString();

        setMapViewState({
          zoom,
          lat: center.lat,
          lng: center.lng
        });

        setClusterState([
          boundingBoxString,
          zoom
        ].join("_"));
      }
    }
  });

  return null;
};

export default MapEvents;
