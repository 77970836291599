import {
  createContext, useState, useCallback
} from "react";

export const ModalContext = createContext({
  active: false,
  showModal: () => {},
  hideModal: () => {}
});

/**
 *
 * @param root0
 * @param root0.children
 */
const FilterProvider = ({ children }) => {
  const [
    active,
    setActive
  ] = useState(false);

  const showModal = () => setActive(true);
  const hideModal = () => setActive(false);

  const contextValue = {
    active,
    showModal: useCallback(() => showModal(), []),
    hideModal: useCallback(() => hideModal(), [])
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export default FilterProvider;
