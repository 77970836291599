import { useEffect, useState } from "react";

import { transformToPieData } from "../../helpers";

import ChartError from "~/src/ui/charts/error";
import ChartLegend from "~/src/ui/charts/legend";
import MetaTooltip from "~/src/ui/charts/meta-tooltip";
import PieChart from "~/src/ui/charts/pie-chart";

/**
 *
 * @param props
 * @param props.statistic
 * @param props.isLoading
 * @param props.isError
 */
export default function BuyersGenderChart({
  statistic, isLoading, isError
}) {
  const [
    displayWithChild,
    setDisplayWithChild
  ] = useState(false);
  const [
    pieDataWithChild,
    setPieDataWithChild
  ] = useState([]);
  const [
    pieData,
    setPieData
  ] = useState([]);

  const changeDisplayWithChild = () => {
    if (displayWithChild) {
      setDisplayWithChild(false);
    }
    else {
      setDisplayWithChild(true);
    }
  };

  const createPieDataWithChild = () => {
    const withChild = pieData.map((sliceValue) => sliceValue?.sub ?? sliceValue);

    setPieDataWithChild(withChild.flat());
  };

  useEffect(() => {
    if (typeof statistic !== "undefined") {
      setPieData(transformToPieData(statistic, "buyers", {
        fancyLabels: false,
        useTotalPercentage: true
      }));
    }
  }, [statistic]);

  useEffect(() => {
    if (pieData.length > 0) {
      createPieDataWithChild();
    }
  }, [pieData]);

  if (isError) {
    return (
      <div className="flex flex-col gap-4 rounded border border-gray-200 bg-white shadow-sm p-4">
        <div className="flex gap-2 items-center">
          <h2 className="text-xl font-medium">Geschlecht</h2>
        </div>
        <ChartError />
      </div>
    );
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 rounded border border-gray-200 bg-white shadow-sm p-4">
      <div className="flex gap-2 items-center">
        <h2 className="text-xl font-medium">Geschlecht</h2>
        <MetaTooltip
          {...((pieData) && {
            meta: statistic.meta
          })}
        />
      </div>
      <div className="w-full h-[450px] relative">
        <div className="absolute w-full h-[450px]">
          <PieChart
            data={(displayWithChild) ? pieData : pieDataWithChild}
            changeDisplayWithChild={changeDisplayWithChild}
            sortByValue={false}
            small={true}
          />
        </div>
        <div className="absolute w-full h-[450px]">
          <PieChart
            data={(displayWithChild) ? pieDataWithChild : pieData}
            changeDisplayWithChild={changeDisplayWithChild}
            sortByValue={false}
            small={false}
          />
        </div>
      </div>
      <div className="w-full">
        <ChartLegend data={(displayWithChild) ? pieDataWithChild : pieData}/>
      </div>
    </div>
  );
}
