/* eslint-disable no-underscore-dangle */
import {
  DomUtil,
  point as toPoint
} from "leaflet";

import { adjustPoint } from "./_common/_exports.js";

const directions = ["north", "south"]
  .flatMap((verticalDirection) => ["center", "west", "east"]
    .map((horizontalDirection) => `${verticalDirection}-${horizontalDirection}`));

// don't switch to arrow functions, because of leaflet's rotten class system

/**
 * @this {object}
 */
const updatePosition = function () {
  if (!this._map) {
    return;
  }

  for (const direction of directions) {
    this._tipContainer.classList.remove(direction);
  }

  const anchor = this._getAnchor();

  let offset = toPoint(this.options.offset);

  const point = adjustPoint({
    map: this._map,
    container: this._container,
    tipContainer: this._tipContainer,
    pointCoordinates: this._latlng,
    anchor
  });

  if (this._zoomAnimated) {
    DomUtil.setPosition(this._container, point);
  }
  else {
    offset = offset.add(point);
  }

  this._containerBottom = -offset.y;
  this._containerLeft = -Math.round(this._containerWidth / 2) + offset.x;

  this._container.style.bottom = `${this._containerBottom}px`;
  this._container.style.left = `${this._containerLeft}px`;
};

export default updatePosition;
