import cn from "classnames";
import { useParams } from "react-router-dom";

import { formatNumber, pluralize } from "~/src/modules/formatters";
import useProject from "~/src/hooks/use-project";
import { priceLabels } from "~/src/modules/labels.js";

/**
 *
 */
export default function ProjectStatistics() {
  const { id: projectId } = useParams();
  const { project } = useProject(projectId);

  const {
    buyable,
    rentable,
    gka,
    gka_notes,
    gka_from,
    transaction_filter
  } = project;

  const columns = [
    {
      className: "bg-primary/10 border-primary",
      rows: [
        {
          condition: buyable || (!buyable && !rentable),
          cells: {
            avg_offer_price_eigennutzer: priceLabels.offerPriceNormal,
            avg_offer_price_investor: priceLabels.offerPriceInvestorNet
          }
        },
        {
          condition: rentable,
          cells: {
            avg_miete: priceLabels.rentNet,
            avg_rent_bk: priceLabels.rentTotal
          }
        }
      ]
    },
    {
      className: "bg-primary-green/10 border-primary-green",
      rows: [
        {
          condition: buyable || (!buyable && !rentable),
          cells: {
            avg_sale_price_eigennutzer: priceLabels.salePriceNormal,
            avg_sale_price_investor: priceLabels.salePriceInvestorNet
          }
        }
      ]
    },
    {
      className: "bg-gray-500/10 border-gray-500",
      rows: [
        {
          cells: {
            gka: priceLabels.gka
          }
        }
      ]
    }
  ]
    .map(({ rows, className }) => ({
      rows: rows.filter(({ condition }) => (typeof condition === "undefined") || condition),
      className
    }));

  return (
    <div className="flex gap-4 w-full justify-between items-stretch">
      {
        columns.map(({ rows, className }, index) => (
          <div
            className={cn("border-t-4 flex flex-col p-4 gap-4 w-full items-center", className)}
            key={index}
          >
            {
              rows.length > 0
                ? rows
                  .map(({ cells }) => Object
                    .entries(cells)
                    .map(([
                      key,
                      label
                    ], innerIndex) => (
                      <div className="flex flex-col items-center text-center" key={`${key}${innerIndex}`}>
                        <div className="flex gap-2 font-semibold">
                          <span>ø</span>
                          <span>{label}</span>
                        </div>
                        <span className="text-lg font-bold">{project[key] ? `€ ${formatNumber(project[key])} / m²` : "-"}</span>
                        {
                          project[`${key}_count`] && (
                            <span>
                              (aus {project[`${key}_count`]} {pluralize("Einheit", "Einheiten", "Einheiten", project[`${key}_count`])} berechnet)
                            </span>
                          )
                        }
                        {
                          key === "gka" && (
                            <>
                              {
                                (gka && gka_from) && (
                                  <span>
                                    (aus {`${gka_from === "offer" ? "Angebotsdaten" : "Verkaufsdaten"}`} berechnet)
                                  </span>
                                )
                              }
                              {transaction_filter === "baurecht" &&
                                <p className="text-[0.6em] leading-tight">
                                  Eine Berechnung des Grundkostenanteil ist nicht möglich, da es sich bei der Projektliegenschaft um eine Baurechtsliegenschaft handelt.
                                </p>
                              }
                              {gka_notes && transaction_filter !== "baurecht" &&
                                <p className="text-[0.6em] leading-tight">
                                  {gka_notes}
                                </p>
                              }
                            </>
                          )
                        }

                      </div>
                    )))
                  .flat()
                : (
                  <span className="text-xl font-bold">-</span>
                )
            }
          </div>
        ))
      }
    </div>
  );
}
