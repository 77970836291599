import { UserGroupIcon } from "@heroicons/react/24/outline";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

/**
 *
 * @param root0
 * @param root0.onUpdate
 */
export default function InsolvencyCompanySelect({ onUpdate }) {
  const handleCreate = (value) => {
    onUpdate(value);
  };

  return (
    <CreatableSelect
      inputProps={{
        autoComplete: "none",
        autoCorrect: "off",
        spellCheck: "off"
      }}
      className="react-select"
      instanceId="insolvency-company-select"
      value={""}
      styles={customStyles}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input
      }}
      isClearable
      name="insolvency-company"
      placeholder="Unternehmen/Schuldner"
      onCreateOption={handleCreate}
      noOptionsMessage={noOptionsMessage}
      formatCreateLabel={(userinput) => `Nach "${userinput}" suchen`}
      blurInputOnSelect={true}
    />
  );
}

const noOptionsMessage = (data) => "Firmennamen oder FB-Nr. suchen...";

function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} className="pl-2">
      <UserGroupIcon className="h-5 w-5 text-gray-400" /> {children}
    </components.Control>
  );
}

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = {
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af",
    fontSize: "16px"
  }),
  control: (styles, state) => ({
    ...styles,
    height: 44,
    minHeight: 44,
    borderRadius: 2,
    boxShadow: "state.isFocused ? \"0 0 0 20px rgba(56,178,172,1)\" : 0",
    borderColor: state.isFocused ? "#9ca3af" : "#e5e5e5",
    borderWidth: state.isFocused ? "1px" : "1px",
    "&:hover": {
      borderColor: state.isFocused ? "#9ca3af" : "#9ca3af"
    }
  }),

  singleValue: (base) => ({
    ...base,
    borderRadius: "5px",
    padding: "0 6px",
    background: "#822c42",
    color: "white"
  })
};
