import {
  MapContainer,
  Marker,
  WMSTileLayer,
  ZoomControl
} from "react-leaflet";
import {
  useEffect,
  useRef,
  useState
} from "react";
import { LatLngBounds } from "leaflet";
import drawLocales from "leaflet-draw-locales";
import useInterval from "use-interval";

import useStore from "~/src/hooks/use-store";

drawLocales("de-at");

function useForceUpdate() {
  const [
    value,
    setValue
  ] = useState(0); // integer state

  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}

/**
 *
 * @param props
 * @param props.purchase
 * @param props.slideIsOpen
 */
export default function PurchaseMap({ purchase, slideIsOpen }) {
  const mapRef = useRef(null);
  const forceUpdate = useForceUpdate();

  const [
    searchLocation,
    setSearchLocation
  ] = useState(null);
  const [
    polygonState,
    setPolygonState
  ] = useState(null);
  const [
    controlMounted,
    setControlMounted
  ] = useState(false);
  const [
    controlState,
    setControlState
  ] = useState(null);
  const [
    shouldFocusMap,
    setShouldFocusMap
  ] = useState(null);
  const [
    intervalCount,
    setIntervalCount
  ] = useState(0);
  const [
    intervalDelay,
    setIntervalDelay
  ] = useState(100);
  const [
    pageLocked,
    setPageLocked
  ] = useState(true);

  const mapViewState = useStore((state) => state.mapViewState);
  const setMapViewState = useStore((state) => state.setMapViewState);
  const mapViewStateFresh = useStore((state) => state.mapViewStateFresh);
  const setMapViewStateFresh = useStore((state) => state.setMapViewStateFresh);
  const readPolygonsFromQuery = useStore((state) => state.readPolygonsFromQuery);
  const setReadPolygonsFromQuery = useStore((state) => state.setReadPolygonsFromQuery);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize();
      mapRef.current.attributionControl.setPrefix("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapRef.current,
    purchase
  ]);

  useEffect(() => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    if (!purchase) {
      map.fireEvent("dataloading");
    }
    else {
      map.fireEvent("dataload");
    }
  }, [purchase]);

  useEffect(() => {
    if (slideIsOpen) {
      forceUpdate();
    }
  }, [slideIsOpen]);

  function handleMounted(control) {
    if (!controlMounted) {
      setControlMounted(true);
      setControlState(control);
    }
  }

  function resetInterval() {
    setShouldFocusMap(false);
    setIntervalCount(0);
    setIntervalDelay(100);
  }

  function focusMap() {
    const map = mapRef.current;
    const group = groupRef.current;

    try {
      const bounds = new LatLngBounds([
        [
          purchase.coords_y,
          purchase.coords_x
        ],
        [
          purchase.coords_y,
          purchase.coords_x
        ]
      ]);

      map.fitBounds(bounds.pad(0.3));

      return true;
    }
    catch {
      return false;
    }
  }

  useInterval(() => {
    if (intervalCount > 10 || !shouldFocusMap) {
      resetInterval();
    }
    else {
      const mapFocused = focusMap();

      if (mapFocused) {
        resetInterval();
      }
      else {
        setIntervalCount(intervalCount + 1);
        setIntervalDelay(Math.floor(intervalDelay * 1.5));
      }
    }
  }, shouldFocusMap ? intervalDelay : null, false);

  return (
    <MapContainer
      zoomControl={false}
      scrollWheelZoom={true}
      center={[
        purchase.coords_y,
        purchase.coords_x
      ]}
      bounds={[
        purchase.coords_y,
        purchase.coords_x
      ]}
      zoom={15}
      maxZoom={18}
      minZoom={6}
      zoomSnap={0}
      zoomDelta={1}
      tap={false}
      dragging={true}
      style={{
        backgroundColor: "#fff",
        borderRadius: "5px"
      }}
      ref={mapRef}
    >
      <WMSTileLayer
        key="project-map"
        attribution={"&copy; <a href=\" http://basemap.at\">Basemap.at</a>"}
        url="https://mapsneu.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png"
        subdomains={[
          "maps",
          "maps1",
          "maps2",
          "maps3",
          "maps4"
        ]}
        maxZoom={34}
        format="image/png"
        version="1.0.0"
        updateInterval={50}
        maxNativeZoom={18}
        minNativeZoom={6}
        noWrap={true}
        bounds={new LatLngBounds([
          46.4318173285,
          9.47996951665
        ], [
          49.0390742051,
          16.9796667823
        ])}
      />

      <ZoomControl position="topright" />

      <Marker position={[
        purchase.coords_y,
        purchase.coords_x
      ]} />
    </MapContainer>
  );
}
