import { useState, useEffect } from "react";

/**
 *
 * @param targetKey
 * @param cb
 */
export default function useKeyPress(targetKey, cb) {
  const [
    keyPressed,
    setKeyPressed
  ] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const keyAction = (cb) => {
    if (keyPressed) {
      cb();
    }
  };

  useEffect(() => {
    if (keyPressed) {
      cb();
    }
  }, [
    keyPressed,
    cb
  ]);

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return keyPressed;
}
