/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* eslint-disable import/max-dependencies */
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";
import cn from "classnames";
import { snakeCase } from "lodash-es";
import { useParams } from "react-router-dom";
import { camel, dash } from "radash";

import {
  constructionPhaseLabels,
  marketingPhaseLabels,
  priceLabels
} from "~/src/modules/labels.js";
import { displayRechtsform, generateGkaNotice } from "~/src/modules/helpers.js";
import { formatEuro, pluralize } from "~/src/modules/formatters.js";
import ContentSection from "~/src/ui/sections/content-section.jsx";
import dateFormatter from "~/src/modules/date-formatter.js";
import useProject from "~/src/hooks/use-project.js";

const StatsItem = ({ children, statsLoaded }) => {
  if (statsLoaded) {
    return children;
  }

  return <div className="mt-0.5 h-4 w-52 bg-gray-300 rounded animate-pulse" />;
};

/**
 *
 */
// eslint-disable-next-line max-lines-per-function, complexity
const ProjectOverviewStats = () => {
  const { id } = useParams();
  const {
    project,
    isLoading,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    automated,
    finish_year: finishYear,
    rentable,
    buyable,
    timeline: {
      current_construction_phase: currentConstructionPhase,
      current_marketing_phase: currentMarketingPhase,
      last_contract_date: lastContractDate
    },
    construction_infos: {
      estimated_finish_date: estimatedFinishDate,
      estimated_finish_date_format: estimatedFinishDateFormat
    },

    avg_offer_price_eigennutzer: avgOfferPriceEigennutzer,
    avg_offer_price_eigennutzer_count: avgOfferPriceEigennutzerCount,
    avg_offer_price_investor: avgOfferPriceInvestor,
    avg_offer_price_investor_count: avgOfferPriceInvestorCount,
    avg_miete: avgMiete,
    avg_miete_count: avgMieteCount,
    avg_rent_bk: avgRentBk,
    avg_rent_bk_count: avgRentBkCount,
    avg_sale_price_eigennutzer: avgSalePriceEigennutzer,
    avg_sale_price_eigennutzer_count: avgSalePriceEigennutzerCount,
    avg_sale_price_investor: avgSalePriceInvestor,
    avg_sale_price_investor_count: avgSalePriceInvestorCount,
    gka,
    gka_count: gkaCount
  } = project;

  const completed = currentConstructionPhase === "construction_phase_completed";

  let completedDate;

  if (completed) {
    completedDate = (project?.timeline?.construction_phase_completed_date !== null && project?.timeline?.construction_phase_completed_date_format !== null)
      ? dateFormatter(project?.timeline?.construction_phase_completed_date, project?.timeline?.construction_phase_completed_date_format)
      : project.finish_year;
  }

  if (!completed && estimatedFinishDate) {
    completedDate = dateFormatter(estimatedFinishDate, estimatedFinishDateFormat);
  }

  const lastContractYear = lastContractDate ? dateFormatter(lastContractDate, "year") : null;

  const statsSectionTitle = automated
    ? `Historisches Projekt${lastContractYear ? ` - ${lastContractYear}` : ""}`
    : (completedDate ?? "-");

  const hasBaurecht = (
    project?.transaction_filter === "baurecht" ||
    project?.transactions.some(({ transaction_category: { code } }) => code === "baurecht")
  );

  const gkaNotice = generateGkaNotice(project, true);

  return (
    <ContentSection id="stats" icon={<CheckCircleIcon />} title={statsSectionTitle}>
      <div className="grid items-start w-full grid-cols-4 justify-items-start">
        <dl className="grid grid-cols-2-auto gap-x-4 gap-y-0.5 xl:col-span-1 col-span-4">
          {
            currentConstructionPhase && (
              <>
                <dt className="font-medium text-gray-500">Bauphase: </dt>
                <dd>{constructionPhaseLabels[currentConstructionPhase]}</dd>
              </>
            )
          }
          {
            currentMarketingPhase && (
              <>
                <dt className="font-medium text-gray-500">Vermarktungsphase: </dt>
                <dd>{marketingPhaseLabels[currentMarketingPhase]}</dd>
              </>
            )
          }
          <dt className="font-medium text-gray-500">Rechtsform: </dt>
          <dd>
            {displayRechtsform({
              rentable,
              buyable
            })}
          </dd>
        </dl>

        <div className="flex flex-col justify-between w-full col-span-4 gap-4 mt-6 xl:-mt-6 xl:col-span-3 md:grid-cols-3">
          {
            [
              {
                items: [
                  {
                    className: "border-primary-lighter",
                    valueKey: "avgOfferPriceEigennutzer",
                    countKey: "avgOfferPriceEigennutzerCount"
                  },
                  {
                    className: "border-primary-lighter",
                    valueKey: "avgOfferPriceInvestor",
                    countKey: "avgOfferPriceInvestorCount"
                  },
                  {
                    className: "border-primary-lighter",
                    valueKey: "avgMiete",
                    countKey: "avgMieteCount"
                  },
                  {
                    className: "border-primary-lighter",
                    valueKey: "avgRentBk",
                    countKey: "avgRentBkCount"
                  }
                ]
              },
              {
                items: [
                  {
                    className: "border-primary-green",
                    valueKey: "avgSalePriceEigennutzer",
                    countKey: "avgSalePriceEigennutzerCount"
                  },
                  {
                    className: "border-primary-green",
                    valueKey: "avgSalePriceInvestor",
                    countKey: "avgSalePriceInvestorCount"
                  },
                  {
                    className: "border-gray-400",
                    valueKey: "gka",
                    countKey: "gkaCount"
                  }
                ]
              }
            ]
              .filter((row, rowIndex) => !automated || (automated && rowIndex === 1))
              // eslint-disable-next-line max-lines-per-function
              .map(({ items }, index) => (
                <div className="grid grid-cols-1 overflow-hidden sm:grid-cols-3 gap-x-5 gap-y-2" key={index}>
                  {
                    items
                      .filter(({
                        valueKey
                      }) => {
                        if (
                          (!rentable && [
                            "avgMiete",
                            "avgRentBk"
                          ].includes(valueKey)) ||
                          (!buyable && [
                            "avgOfferPriceEigennutzer",
                            "avgOfferPriceInvestor"
                          ].includes(valueKey))
                        ) {
                          return false;
                        }

                        return true;
                      })
                      .map(({
                        className,
                        valueKey,
                        countKey
                      }, blockIndex) => {
                        const value = project?.[snakeCase(valueKey)];
                        const count = project?.[snakeCase(countKey)];

                        const labelKey = camel(
                          dash(valueKey)
                            .split("-")
                            .map((part) => {
                              switch (part) {
                                case "avg":
                                  return "average";
                                case "eigennutzer":
                                  return "normal";
                                case "investor":
                                  return "investorNet";
                                case "miete":
                                  return "rent";
                                case "bk":
                                  return "total";
                                default:
                                  return part;
                              }
                            })
                            .join("-")
                        );

                        const label = priceLabels[labelKey];

                        return (
                          (valueKey === "gka")
                            ? <div className={cn("pl-4 border-l-4", className)} key={blockIndex}>
                              <dt className="font-light text-gray-500 truncate">{label}</dt>
                              <StatsItem statsLoaded={!isLoading}>
                                <dd className="mt-0.5 font-semibold text-gray-900 truncate">
                                  {(value && !hasBaurecht) &&
                                    <div className="flex flex-row gap-0.5">
                                      {`${formatEuro(value)} / m² `}

                                      <span className="font-light text-gray-900 truncate">
                                        {gkaNotice?.[0] ?? ""}
                                      </span>

                                      {
                                        gkaNotice?.[1] && (
                                          <svg
                                            data-tooltip-id="tooltip-gka"
                                            data-tooltip-html={gkaNotice[1]}
                                            className="w-5 h-5 text-gray-500 focus:outline-none hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                          </svg>
                                        )
                                      }
                                    </div>
                                  }
                                  {(!value || hasBaurecht) &&
                                    <div className="flex flex-row gap-0.5">
                                      <span className="font-light">-</span>
                                      <span className="font-light text-gray-900 truncate">
                                        {gkaNotice?.[0] ?? ""}
                                      </span>
                                      {
                                        gkaNotice?.[1] && (
                                          <svg
                                            data-tooltip-id="tooltip-gka"
                                            data-tooltip-html={gkaNotice[1]}
                                            className="w-5 h-5 text-gray-500 focus:outline-none hover:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                          </svg>
                                        )
                                      }
                                    </div>
                                  }
                                </dd>
                              </StatsItem>
                              <Tooltip
                                id="tooltip-gka"
                                place="bottom"
                                className="z-50 max-w-md"
                                positionStrategy="fixed"
                                delayShow={500}
                              />

                            </div>

                            : <div className={cn("pl-4 border-l-4", className)} key={blockIndex}>
                              <dt className="font-light text-gray-500 truncate">{label}</dt>
                              <StatsItem statsLoaded={!isLoading}>
                                <dd className="mt-0.5 font-semibold text-gray-900 truncate">
                                  {(value)
                                    ? <>
                                      {`${formatEuro(value)} / m² `}
                                      <span className="font-light">({count ?? 0} {pluralize("Einheit", "Einheiten", "Einheiten", count)})</span>
                                    </>
                                    : null
                                  }
                                  {!value &&
                                    <span className="font-light">-</span>
                                  }
                                </dd>
                              </StatsItem>
                            </div>

                        );
                      })
                  }
                </div>
              ))
          }
        </div>
      </div>
    </ContentSection>
  );
};

export default ProjectOverviewStats;
