import cn from "classnames";

/**
 *
 * @param props
 * @param props.className
 * @param props.style
 */
export default function LogoColor({
  className
}) {
  return (
    <svg
      className={cn(className ?? "w-[208px] h-[24px]")}
      width={0}
      height={0}
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 665.1 72.69"
      xmlSpace="preserve"
    >
      <style dangerouslySetInnerHTML={{ __html: ".st0{fill:#822c42}.st1{fill:#97999b}" }} />
      <path className="st0" d="M.89 11.65h9.32v49.8H.89zM62.43 25.38L46.21 47h-1.85L28.5 25.31v36.21h-9.39v-49.8h10.81L45.5 33.13l15.58-21.41h10.74v49.8h-9.39zM124.04 25.38L107.82 47h-1.85L90.1 25.31v36.21h-9.39v-49.8h10.82l15.58 21.41 15.58-21.41h10.74v49.8h-9.39zM191.2 36.83c-.14 12.88-8.04 25.75-25.54 25.75s-25.61-12.59-25.61-25.68 8.39-26.25 25.61-26.25c17.14 0 25.68 13.16 25.54 26.18m-41.9.21c.21 8.18 4.62 16.93 16.36 16.93 11.74 0 16.15-8.82 16.29-17 .14-8.39-4.55-17.78-16.29-17.78-11.74 0-16.58 9.46-16.36 17.85M269.72 72.69h-57.59c-4.17 0-7.55-3.31-7.55-7.38V7.38c0-4.07 3.39-7.38 7.55-7.38h57.59c4.16 0 7.55 3.31 7.55 7.38v57.94c0 4.06-3.39 7.37-7.55 7.37M212.13 3.73c-2.06 0-3.73 1.64-3.73 3.65v57.94c0 2.01 1.68 3.65 3.73 3.65h57.59c2.06 0 3.73-1.64 3.73-3.65V7.38c0-2.01-1.67-3.65-3.73-3.65h-57.59z" />
      <g>
        <path className="st1" d="M264.33 35.35c.3 13.17-8.14 26.49-26.27 26.49h-17.54v-51.8h17.54c17.53 0 25.97 12.58 26.27 25.31zm-39.82-21.53V58h13.54c15.39 0 22.5-11.54 22.28-22.65-.22-10.81-7.33-21.54-22.28-21.54h-13.54zM293.62 33.95h30.34v3.63h-30.34V58h32.71v3.85H289.7v-51.8h35.74v3.77h-31.82v20.13zM378.95 10.04l-21.31 51.95h-4.29l-21.31-51.95h4.29L355.5 57.4l19.17-47.36h4.28zM389.75 33.95h30.34v3.63h-30.34V58h32.71v3.85h-36.63v-51.8h35.74v3.77h-31.82v20.13zM435.26 10.04v48.03h29.16v3.77h-33.15v-51.8h3.99zM520.43 36.02c-.07 13.62-8.73 26.72-26.12 26.72s-26.12-13.17-26.12-26.86c0-13.91 8.66-26.64 26.12-26.64 17.47-.01 26.2 12.79 26.12 26.78zm-48.32-.08c.07 11.69 7.47 23.09 22.2 23.09 14.8 0 22.28-11.4 22.28-23.09 0-11.99-7.4-23.02-22.28-23.02s-22.28 10.81-22.2 23.02zM533.9 43.94v17.91h-4v-51.8h22.72c22.5 0 22.5 33.89 0 33.89H533.9zm0-30.2v26.57h18.72c17.24 0 17.24-26.57 0-26.57H533.9zM582.22 33.95h30.34v3.63h-30.34V58h32.71v3.85H578.3v-51.8h35.74v3.77h-31.82v20.13zM665.1 61.85h-5.25l-17.91-19.61H627.8v19.61h-4v-51.8h22.79c11.25 0 16.95 8.07 17.02 16.13.07 8.44-5.48 15.84-16.8 15.84l18.29 19.83zm-37.3-23.39h18.35c9.03 0 13.02-5.11 13.1-12.36.07-6.07-4.07-12.36-12.66-12.36h-18.8v24.72z" />
      </g>
    </svg>
  );
}
