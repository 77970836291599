import cn from "classnames";

import { formatCurrencyInt } from "~/src/modules/formatters";
import { hasDiffValues } from "~/src/modules/share-deal-helper";

/**
 *
 * @param root0
 * @param root0.owner
 * @param root0.type
 * @param root0.hasCurrentOwner
 * @param root0.hasLegacyOwner
 * @param root0.diff
 */
export default function ShareDealOwnerCardItem({
  owner, type, hasCurrentOwner, hasLegacyOwner, diff
}) {
  const hasDiff = hasDiffValues(diff);

  const unchanged = hasCurrentOwner && hasLegacyOwner && !hasDiff;

  if (unchanged && type === "legacy") {
    return null;
  }

  const ownerShares = owner[`owner_shares_${type}`]?.toString();

  return (
    <div className="pt-4">

      <div className="grid grid-cols-8">
        <div className="col-span-2">
          {type === "current" && unchanged &&
            <p className="inline-block bg-gray-300 rounded uppercase font-semibold text-gray-800 px-2 ">unverändert</p>
          }
          {type === "current" && !unchanged &&
            <p className={cn("inline-block rounded uppercase font-semibold px-2", {
              "bg-emerald-200 text-emerald-800": hasCurrentOwner && !hasLegacyOwner,
              "bg-amber-200 text-amber-800": !(hasCurrentOwner && !hasLegacyOwner)
            })}>
              {hasCurrentOwner && !hasLegacyOwner ? "neu" : "änderung"}
            </p>
          }
          {type === "legacy" && !unchanged &&
            <p className={cn("inline-block rounded uppercase font-semibold px-2", {
              "bg-red-200 text-red-800": !hasCurrentOwner && hasLegacyOwner,
              "text-gray-700": !(!hasCurrentOwner && hasLegacyOwner)
            })}>
              {!hasCurrentOwner && hasLegacyOwner ? "gelöscht" : "vorher"}
            </p>
          }
        </div>
        <div className="col-span-5">
          <p className="text-gray-800 truncate">{owner[`owner_function_${type}`]}</p>
        </div>
        <div className="text-right">
          <span className="px-2 text-center py-0.5 bg-gray-300 rounded font-semibold text-gray-700">{owner[`owner_number_${type}`] ? owner[`owner_number_${type}`] : "-"}</span>
        </div>
      </div>

      <div className="mt-3 grid grid-cols-8">
        <div className="col-span-2 pl-2">
          <p>
            Firmenanteil: <span className={cn("", {
              "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_shares,
              "text-emerald-600": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_shares && type === "current",
              "text-red-600": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_shares && type === "legacy"
            })}>{ownerShares
                ? `${(parseFloat(ownerShares.replace(",", ".")) * 100).toLocaleString("de", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })} %`
                : ""}</span>
          </p>
          <p className={cn("", {
            "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_capital,
            "text-emerald-600": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_capital && type === "current",
            "text-red-600": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_capital && type === "legacy"
          })}>{owner[`owner_capital_${type}`]
              ? formatCurrencyInt({
                val: owner[`owner_capital_${type}`],
                currency: owner[`owner_currency_${type}`]
              })
              : "nicht bekannt"}</p>
        </div>

        <div className="col-span-5">
          <p className={cn("", {
            "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_name
          })}>{owner[`owner_name_${type}`]}</p>

          <p className={cn("", {
            "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_fbnr
          })}>{owner[`owner_fbnr_${type}`]}</p>

          <p className={cn("", {
            "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && (diff?.owner_title || diff?.owner_first_name || diff?.owner_last_name)
          })}>
            {owner[`owner_title_${type}`] && `${owner[`owner_title_${type}`]} `}{owner[`owner_first_name_${type}`] && `${owner[`owner_first_name_${type}`]} `}{owner[`owner_last_name_${type}`]}</p>
          <p className={cn("", {
            "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_birthday
          })}>{owner[`owner_birthday_${type}`]}</p>
        </div>
      </div>

    </div>
  );
}
