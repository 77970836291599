import { quantityLabels } from "~/src/modules/labels";
import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0
 * @param root0.queryItems
 * @param root0.submit
 */
export default function QuantityFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const quantity = queryItems.split(",");

  function handleClick(qu) {
    const newQu = quantity.filter((item) => item !== qu).join(",");

    submit("quantity", newQu);
  }

  return quantity.map((qu, index) => (
    <DefaultFilterTag key={`qu_tag_${index}`} handleClick={() => handleClick(qu)}>
      <span>Projektgröße: {quantityLabels[qu]}</span>
    </DefaultFilterTag>
  ));
}
