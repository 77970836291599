import cn from "classnames";
import {
  Children, cloneElement, useEffect, useRef, useState
} from "react";

import ChartLayout from "~/src/ui/layouts/charts";

/**
 *
 * @param props
 * @param props.children
 * @param props.cols
 * @param props.className
 */
const ChartContainer = ({
  children, cols = 1, className = ""
}) => {
  const childrenRef = useRef([]);
  const [
    allChildrenNull,
    setAllChildrenNull
  ] = useState(false);

  useEffect(() => {
    if (childrenRef.current.every((child) => child === null)) {
      setAllChildrenNull(true);
    }
    else {
      setAllChildrenNull(false);
    }
  }, [JSON.stringify(childrenRef.current.map((child) => child === null))]);

  return (

    <ChartLayout className={cn(
      `grid grid-cols-1 gap-x-4 gap-y-4 ${className}`,
      {
        "2xl:grid-cols-1": cols === 1,
        "2xl:grid-cols-2": cols === 2
      }
    )}>
      {
        Children.map(children, (child, index) => cloneElement(child, {
          // ref: (ref) => (childrenRef.current[index] = ref)
        }))
      }
    </ChartLayout>
  );
};

export default ChartContainer;
