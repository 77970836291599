import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0
 * @param root0.query
 * @param root0.tempQuery
 * @param root0.update
 */
export default function QuantitySearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const tempItems = tempQuery?.split(",") || [];

  const has1 = items.includes("1");
  const has10 = items.includes("10");
  const has25 = items.includes("25");
  const has50 = items.includes("50");
  const has100 = items.includes("100");

  const hasTemp1 = tempItems.includes("1");
  const hasTemp10 = tempItems.includes("10");
  const hasTemp25 = tempItems.includes("25");
  const hasTemp50 = tempItems.includes("50");
  const hasTemp100 = tempItems.includes("100");

  function onCheck(event, key) {
    handleCheck("quantity", event, tempQuery, items, tempItems, key, update);
  }

  function checked(item, tempItem) {
    return handleCheckedStatus(query, item, tempQuery, tempItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Anzahl <span className="font-light">(Wohneinheiten)</span>
      </p>
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 font-light">
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has1, hasTemp1)} onChange={(e) => onCheck(e, "1")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">1-9</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has10, hasTemp10)} onChange={(e) => onCheck(e, "10")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">10-24</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has25, hasTemp25)} onChange={(e) => onCheck(e, "25")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">25-49</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has50, hasTemp50)} onChange={(e) => onCheck(e, "50")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">50-99</span>
          </label>
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(has100, hasTemp100)} onChange={(e) => onCheck(e, "100")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">+100</span>
          </label>
        </div>
      </div>
    </div>
  );
}
