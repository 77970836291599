import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 */
export default function useEsgCertificates() {
  const searchParams = new URLSearchParams(location.search);

  const userId = searchParams.get("userId");

  const {
    data, mutate, error
  } = useSWR(`/esg-certificates${userId ? `?userId=${userId}` : ""}`, fetcher);

  return {
    certificates: data?.payload?.certificates,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
